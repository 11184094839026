import dayjs from "dayjs";

export function dateToHms(date) {
  return dayjs(date).format("HH:mm:ss");
}

export function frameToHms(frame) {
  const sec = frame / 30;

  let minutes = Math.floor(sec / 60);
  let seconds = Math.floor(sec - minutes * 60);

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return minutes + ":" + seconds;
}

export function secondsToHms(sec) {
  sec = Math.floor(sec);

  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  if (hours === "00") {
    return minutes + ":" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}

export function inRange (value, min, max) {
  return min <= value && value < max;
}

export function minutesToSeconds(minutes) {
  return minutes * 60; 
}

export function extractVoiceName(inputString) {
  const regex = /[a-zA-Z0-9]+(?=Neural$)/;
  const match = inputString.match(regex);
  
  if (match) {
    return match[0];
  }
  
  return "";
}

export function extractLanguage(inputString) {
  const regex =/^[a-zA-Z]+(?=-)/;
  const match = inputString.match(regex);

  if (match && match[0]) {
    return match[0];
  } 

  return "";
}

export function extractLocale(inputString) {
  const regex = /^[a-zA-Z]+\-[a-zA-Z]+(?=-)/;

  const match = inputString.match(regex);
  const result = match ? match[0] : "";
  return result;
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getVideoDuration(url) {
  return new Promise((resolve, reject) => { 
    const videoElement = document.createElement('video');
    videoElement.src = url;
    videoElement.addEventListener('loadedmetadata', () => {
      resolve(videoElement.duration);
    });
    videoElement.addEventListener('error', e=> {
      console.log("error");
      reject(e);
    });
  });
}

export function getAudioDuration(url) {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    audio.addEventListener('loadedmetadata',() => {
      resolve(audio.duration)
    });
    audio.addEventListener('error', (e) => reject(e));
  });
}

export function clamp(a, min, max) {
  return Math.max(min, Math.min(max, a));
}

export function courtship(num) {
  return num & ~1;
}

export function formatStyle(str) {
  return str.toLowerCase().replace(/_/g, "-");
}

export function calcRate(d1, d2) {
  const res = Math.trunc((d1 / d2) * 10) / 10;
  const min = 0.1;
  const max = 4;
  return clamp(res, min, max);
}