<template>
  <div
    class="timeline-container"
    tabindex="-1"
    @keydown.delete.stop="remove"
    :style="style"
  >
    <timeline-tools />
    <div class="timeline" ref="timelineRef" @wheel="handleWheel">
      <track-list />
      <timeline-operation v-show="!empty" />
    </div>
    <div class="handler" :class="{ active }" @mousedown="handlerDown"></div>
  </div>
</template>
<script setup>
import TimelineOperation from "./timeline-operation.vue";
import TrackList from "./track-list.vue";
import TimelineTools from "./timeline-tools.vue";
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { clamp } from "../../utils";

const { empty, timeline, removeActiveNodes } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const autoFit = ref(true);
const ro = ref(null);
const timelineRef = ref(null);
const active = ref(false);
const height = ref(253);
const beforePosition = reactive({ pageY: 0, height: 0 });

const style = computed(() => ({
  height: `${height.value}px`,
}));

onBeforeMount(resetPosition);

onMounted(() => {
  const element = document.querySelector(".track-list");
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);

  if (element) {
    ro.value = new ResizeObserver(handleResize);
    ro.value.observe(element);
  }
});
onBeforeUnmount(() => {
  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);

  if (ro.value) {
    ro.value.disconnect();
    ro.value = null;
  }
});

function handleResize() {
  const element = document.querySelector(".track-list");
  const currentHeight = element.clientHeight + 48;
  
  if (autoFit.value) {
    height.value = clamp(currentHeight, 253, 362);
  }
}

function mouseMove(e) {
  if (active.value) {
    resize(e);
  }
}

function handlerDown(e) {
  active.value = true;
  savePosition(e);
}

function mouseUp() {
  if (active.value) {
    autoFit.value = false;
    active.value = false;
  }
  resetPosition();
}

function resize(e) {
  const deltaY = beforePosition.pageY - e.pageY;
  const newHeight = clamp(beforePosition.height + deltaY, 220, 438);

  height.value = newHeight;
}

function savePosition(e) {
  beforePosition.pageY = e.pageY;
  beforePosition.height = height.value;
}

function resetPosition() {
  beforePosition.pageY = 0;
  beforePosition.height = 0;
}

function remove() {
  removeActiveNodes();
  commit();
  updateDraft();
}

function handleWheel(e) {
  if (e.ctrlKey) {
    timeline.scale = clamp(
      timeline.scale - timeline.scale * e.deltaY * 0.01,
      timeline.minScale,
      timeline.maxScale
    );
    timeline.autoFit = false;
  }
}

provide("timeline", timelineRef);
</script>
<style scoped>
.timeline-container {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  outline: none;
}
.timeline {
  width: 100%;
  height: calc(100% - 48px);
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
.handler {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  z-index: 1;
}
.handler:before {
  width: 100%;
  content: "";
  position: absolute;
  height: calc(100% + 6px);
  transform: translateY(-3px);
  cursor: row-resize;
}
.handler.active {
  background-color: #875eff;
}
</style>
