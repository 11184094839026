<template>
  <footer>
    <nav class="content-container">
      <div>
        <img class="boolvideo-icon" :src="boolvideoIcon" alt="Boolvideo" />
        <ul class="social-media-list">
          <li v-for="item of socialMediaList">
            <img :src="item.icon" :alt="`${item.name} icon`" />
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <ul class="footer-menu">
        <li v-for="(item, i) of footerMenuList" :data-i="i">
          <span class="footer-menu-title">{{ item.name }}</span>
          <div class="footer-sub-menu">
            <div v-for="(subItem, i) of item.items" class="">
              <a v-if="/^[a-z]+:/.test(subItem.path)" :href="subItem.path" target="_blank">{{ subItem.name }}</a>
              <router-link v-else-if="subItem.link" :to="{path: subItem.path, query: subItem.query }">{{ subItem.name }}</router-link>
              <p :data-i="i" @click="handlePath(item)" v-else>
                {{ subItem.name }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script setup lang="ts">
import { footerMenuList, socialMediaList } from './data';
import boolvideoIcon from '@/assets/icons/home/boolvideo_text.svg';
import { useRouter } from 'vue-router';

const router = useRouter();

const handlePath = (item: any) => {
  const path = item.path
  if (item.handler) {
   item.handler()
  } else {
    if (/^[a-z]+:/.test(path)) {
      window.open(path);
    } else {
      router.push({ path, query: item.query });
    }
  }
}

function handleClickNav(e: Event) {
  const targetElement = e.target as HTMLElement;
  const parentMenuELement =
    targetElement.closest<HTMLElement>('.footer-menu > li')!;

  if (targetElement.tagName !== 'LI') return;
  let targetNav =
    footerMenuList[Number(parentMenuELement.dataset.i)].items[
      Number(targetElement.dataset.i)
    ];

  if ('handler' in targetNav) {
    targetNav.handler(router);
  } else {
    const path = targetNav.path;
    if (/^[a-z]+:/.test(path)) {
      window.open(path);
    } else {
      router.push({ path, query: targetNav.query });
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  padding-block: 85px;
  background-color: #000;
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: var(--view-width);
  margin-inline: auto;
  color: #ffffff;
}

.boolvideo-icon {
  height: 19px;
  margin-bottom: 25px;
}

.social-media-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
  transform: translate3d(-6px, 0, 0);

  & > li {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }
  }
}

.footer-menu {
  display: flex;
  gap: 56px;
  cursor: default;
}

.footer-menu-title {
  font-size: 18px;
}

.footer-sub-menu {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  font-size: 14px;
  color: #cccccc;

  & > div{
    width: fit-content;
    padding-block: 9px;
    cursor: pointer;
    &:hover {
      color: #875eff;
    }
  }
}
</style>
