<template>
  <div class="list-wrapper">
    <Loading :loading="loading"/>
    <el-scrollbar>
      <div class="list-container" >
        <NewCard @preview="preview"/>
        <Card
          v-for="(character, index) in characterList" 
          :key="character.mediaId"
          :data="character"
          @update="(newItem) => handleUpdate(character.mediaId, newItem)"
          @delete="handleDelete(index)"
        />
      </div>
    </el-scrollbar>
  </div>
  <LearningDialog 
    v-model="learningModalVisible"
  />
</template>

<script setup>
import Card from "./card.vue";
import NewCard from './newcard.vue';
import Loading from "../loading/loading.vue";
import LearningDialog from "../dialog/learndialog.vue";
import { getCharacterList } from "@/api/character";

const size = 10;
const page = ref(0);
const loading = ref(false);
const loadAll = ref(false);
const characterList = ref([]);
const learningModalVisible = ref(false);

const preview = () => {
  learningModalVisible.value = true;
};

const handleUpdate = (id, newItem) => {
  const index = characterList.value.findIndex(
    i => i.mediaId === id
  );
  if (index > -1) {
    characterList.value.splice(index, 1, newItem);
  }
};

const handleDelete = (index) => {
  characterList.value.splice(index, 1);
};

const loadMore = async () => {
  if (loadAll.value) return;

  loading.value = true;
  const params = {
    size: size,
    page: page.value + 1,
    type: 1,
  };
  
  const {code, data} = await getCharacterList(params);
  if (code === 0) {
    if (data.records.length > 0) {
      characterList.value = [...characterList.value, ...data.records];
      page.value ++;
    }
    else if (data.records.length === 0) {
      loadAll.value = true;
    }
  }
  loading.value = false;
};

const setup = () => {
  loadMore();

  const hadLearnt = localStorage.getItem("learning-character-modal") || 0;
  console.log(hadLearnt);
  if (!hadLearnt) {
    preview();
    localStorage.setItem("learning-character-modal", 1);
  }
};

onMounted(setup)
</script>

<style lang="scss" scoped>
.list-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 3px;
}
.list-container {
  width: 100%;
  padding: 38px 48px 150px;
  display: grid;
  row-gap: 38px;
  column-gap: 36px;
  grid-template-rows: repeat(auto-fill, minmax(331px, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(253px, 1fr));
}
</style>