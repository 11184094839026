const generatingStep = {

  generateVoiceVideo: [
    'Script generation',
    'Subtitle and voiceover generation',
    'Video generation'
  ],

  generateVideo: [
    'Image/video recognition',
    'Script generation',
    'Music generation',
    'Text generation',
    'Video generation',
  ],

  ideaToVideo: [
    'Script generation',
    'Subtitle and voiceover generation',
    'Video generation',
  ],

  scriptToVideo: [
    'Script generation',
    'Subtitle and voiceover generation',
    'Video generation',
  ],


  blogToVideo: [
    'Blog extraction',
    'Script generation',
    'Subtitle and voiceover generation',
    'Video generation'
  ],


  templateToVideo: [
    'Image/video recognition',
    'Text generation',
    'Video generation',
  ],

  similarVideo: [
    'Subtitle and voiceover generation',
    'Video generation',
  ],

}

const routeMap = {
  '/product-to-video/generating': generatingStep.generateVideo,
  '/product-to-oral-video/generating': generatingStep.generateVoiceVideo,
  '/visuals-to-video/generating': generatingStep.generateVideo,
  '/visuals-to-video/voice/generating': generatingStep.generateVoiceVideo,
  '/template-to-video/generating': generatingStep.templateToVideo,
  '/idea-to-video/generating': generatingStep.ideaToVideo,
  '/script-to-video/generating': generatingStep.scriptToVideo,
  '/blog-to-video/generating': generatingStep.blogToVideo,
  '/idea-to-video/generating': generatingStep.ideaToVideo,
  '/similar-video/generating': generatingStep.similarVideo,
  '/similar-video/script-generating': generatingStep.similarVideo,
}

export {
  routeMap
}