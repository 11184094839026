<template>
  <div class="tools-container">
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.tools-container {
  min-height: 24px;
  display: flex;
  justify-content: end;

  & > :not(:first-child) {
    margin-left: 12px;
  }
}
</style>