import { _get, _post, _put, _singleGet } from "@/utils/https";
const { VITE_API_BASE, VITE_ACE_BASE } = import.meta.env;

// 查询用户权益
export const userEquity = async () => {
  return await _get<ResData.UserEquity>(
    `${VITE_API_BASE}/subscribe/v1/quota/list`,
  );
};

// 查看当前订阅
export const getCurrentSubscription = async () => {
  return await _get<ResData.GetCurrentSubscription>(
    `${VITE_API_BASE}/subscribe/v1/subscription/current`,
  );
};

// 获取pricing列表
export const getPricingList = async () => {
  return await _get<ResData.GetPricingList>(
    `${VITE_API_BASE}/subscribe/v1/sub_plan/list`,
  );
};

// 查询操作状态
export const getOperType = async (payload: ReqParams.GetOperType) => {
  return await _get<ResData.GetOperType>(
    `${VITE_API_BASE}/subscribe/v1/subscription/oper_type`,
    payload,
  );
};

// 初始化SetupIntent
export const initSetupIntent = async (payload: ReqParams.InitSetupIntent) => {
  return await _post<ResData.InitSetupIntent>(
    `${VITE_API_BASE}/subscribe/v1/init_new_subscription_intent`,
    payload,
  );
};

// 取消订阅
export const cancelSubscription = async () => {
  return await _put<ResData.CancelSubscription>(
    `${VITE_API_BASE}/subscribe/v1/subscription/cancel`,
  );
};

// 查询订单状态
export const getOrderState = async ({ order_id }: ReqParams.GetOrderState) => {
  return await _get<ResData.GetOrderState>(
    `${VITE_API_BASE}/subscribe/v1/query/order/${order_id}`,
  );
};

// 更新订阅价格信息（新订阅）
export const updateNewPriceData = async (
  payload: ReqParams.UpdateNewPriceData,
) => {
  return await _get<ResData.UpdateNewPriceData>(
    `${VITE_API_BASE}/subscribe/v1/sub_new_price_data`,
    payload,
  );
};

// 更新订阅价格信息（升降级）
export const updateChangingPriceData = async (
  payload: ReqParams.UpdateChangingPriceData,
) => {
  return await _get<ResData.UpdateChangingPriceData>(
    `${VITE_API_BASE}/subscribe/v1/sub_update_price_data`,
    payload,
  );
};

// 创建新订阅
export const createSubscription = async (
  payload: ReqParams.CreateSubscription,
) => {
  return await _post<ResData.ComfirmSubscription>(
    `${VITE_API_BASE}/subscribe/v1/create_new_subscription`,
    payload,
  );
};

// 更新订阅
export const updateSubscription = async (
  payload: ReqParams.ComfirmSubscription,
) => {
  return await _post<ResData.ComfirmSubscription>(
    `${VITE_API_BASE}/subscribe/v1/update_subscription`,
    payload,
  );
};

// 恢复订阅
export const recoverSubscription = async () => {
  return await _put<{}>(`${VITE_API_BASE}/subscribe/v1/subscription/recover`);
};

// 查询取消订阅折扣
export const queryCancelDetails = async () => {
  return await _get<ResData.QueryCancelDetails>(
    `${VITE_API_BASE}/subscribe/v1/query/subscription/cancel_discount`,
  );
};

// 查询取消提醒文案
export const queryCancelAlert = async (payload: ReqParams.QueryCancelAlert) => {
  return await _get<ResData.QueryCancelAlert>(
    `${VITE_API_BASE}/subscribe/v1/query/subscription/cancel_alert`,
  );
};

// 取消发放优惠券
export const grantCancelDiscount = async () => {
  return await _put<{}>(
    `${VITE_API_BASE}/subscribe/v1/subscription/grant_cancel_discount`,
  );
};

// langding page 价格详情
export const initLandingPagePrice = async (
  payload: ReqParams.InitLandingPagePrice,
) => {
  return await _get<ResData.InitLandingPagePrice>(
    `${VITE_ACE_BASE}/v1/price-page`,
    payload,
  );
};

export const comfirmLTD = async (payload: ReqParams.ComfirmLTD) => {
  return await _post<ResData.ComfirmLTD>(
    `${VITE_ACE_BASE}/v1/life-time-deal-charge`,
    payload,
  );
};

export const helpRegister = async () => {
  return await _post<ResData.HelpRegister>(
    `${VITE_ACE_BASE}/v1/life-time-deal-charge`,
  );
};

export const getLtdOrderState = async ({
  order_id,
}: ReqParams.GetOrderState) => {
  return await _get<ResData.GetOrderState>(
    `${VITE_ACE_BASE}/v1/query/order/${order_id}`,
  );
};

export const comfirmAnnual = async (payload: ReqParams.ComfirmAnnual) => {
  return await _post<ResData.ComfirmSubscription>(
    `${VITE_ACE_BASE}/v1/subscribe`,
    payload,
  );
};

export const updateLtdPrice = async (payload: ReqParams.UpdateNewPriceData) => {
  return await _get<ResData.UpdateNewPriceData>(
    `${VITE_ACE_BASE}/v1/life-time-deal-price-page`,
    payload,
  );
};

export const updateSubPrice = async (payload: ReqParams.UpdateNewPriceData) => {
  return await _get<ResData.UpdateNewPriceData>(
    `${VITE_ACE_BASE}/v1/subscription-price-page`,
    payload,
  );
};

//LTD退款
export const ltdRefund = async () => {
  return await _put<{}>(
    `${VITE_API_BASE}/subscribe/v1/subscription/apply_refund`,
  );
};

// 主动重试扣款
export const retryPayment = async () => {
  return await _post<{}>(
    `${VITE_API_BASE}/subscribe/v1/payment_attempt `,
  );
};
