<template>
  <div class="h-full w-full flex items-center justify-center">
    <GenerateLoading
      :current="currentStep"
      :showSteps="copyGenerateSteps"
      :stepsLength="generateSteps.length"
      :stepNum="4"
      :perStepPercent="[60, 70, 80, 90, 100]"
    />
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, watch, onUnmounted, nextTick } from 'vue';
import { sessionGetItem, sessionSetItem } from '@/utils/storage';
import { routeMap } from '@/components/common/generateLoading/data.js';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import { useLoading } from '@/components/common/generateLoading/useLoading.js';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useSingleMessage } from '@/utils';
import { useRoute, useRouter } from 'vue-router';
import { productVideoRender, videoRenderState } from '@/api/index';
import { validImport } from '@/pages/createVideos/utils/import';
import { useTrackStore } from '@/store/modules/track';
import { useFeatureEdit } from './components/featureEdit/store/index.js';

const featureEditStore = useFeatureEdit();
const { collectData, track } = useTrackStore();
const Message = useSingleMessage();
const modalManager = useModalManager();
const { typeOptions } = storeToRefs(featureEditStore);
const { currentStep, clearTimer, generateSteps, copyGenerateSteps, renderVO } =
  useLoading();
const router = useRouter();
const route = useRoute();

const leftPage = ref(false);
const reqId = ref('');
const formState = ref(null);
const subscribeModalVisible = ref(false);

const handleGenerated = async (formState) => {
  // 生成接口
  const { data, noCredit, msg, code, success } = await productVideoRender(
    formState
  );

  if (!data) {
    Message.error('Please try again');
    router.replace({
      path: '/script-to-video',
    });
    return;
  }
  const trackHelper = (reqId) => {
    collectData('boolvideo_create_input', {
      video_type: 'product_to_oral_video',
      video_subtype: typeOptions.value
        .find((item) => item.value == formState.type)
        .label.toLowerCase()
        .replaceAll(' ', '_'),
      duration: formState.duration > 0 ? formState.duration : undefined,
      ratio: formState.size?.replace(':', '_'),
      video_purpose: formState.purposeIds
        .map((item) => featureEditStore.getPurposeName(item))
        .join(','),
      customization: formState.customize.join(','),
      language: formState.language,
      brand_kit_usage: formState.bkId !== '',
      reqId,
    });
    track('boolvideo_create_input');
  };
  trackHelper(data);

  router.replace({
    path: route.path,
    query: {
      reqId: data,
    },
  });

  reqId.value = data;
};

watch(
  () => renderVO.value,
  (renderVO) => {
    if (renderVO) {
      clearTimer();
      if (leftPage.value) {
        return;
      }

      collectData("boolvideo_generation_editor_page_view", {
        draftId: renderVO.draftId,
        video_type: 'product_to_oral_video',
      });
      track("boolvideo_generation_editor_page_view");
      // router.push({
      //   path: '/product-to-oral-video/script-write',
      // });
      router.push({
        path: '/scene-editor',
        query: {
          draftId: renderVO.draftId,
        },
      });
    }
  }
);

onMounted(async () => {
  await nextTick();
  formState.value = sessionGetItem('generateParams');
  reqId.value = route.query.reqId;
  if (reqId.value) {
    return;
  }

  handleGenerated(formState.value);
});

onBeforeUnmount(() => {
  leftPage.value = true;
});

onUnmounted(() => {
  clearTimer();
});
</script>
