import { _get, _post, _delete, _singleGet } from "@/utils/https";
const { VITE_API_BASE } = import.meta.env;

export const getCharacterList = async (params) => {
  return await _post( VITE_API_BASE + "/library/similar/voice", params);
}

export const addNewCharacter = async () => {
  return await _post( VITE_API_BASE + "/library/similar/character");
}

export const getCharacterDetail = async (params) => {
  return await _singleGet( VITE_API_BASE + "/library/similar/character", params)
}

export const deleteCharacter = async (params) => {
  return await _delete( VITE_API_BASE + "/library/similar/character/" + params)
}

export const putCharacterName = async (params) => {
  return await _post( VITE_API_BASE + "/library/similar/character/update_name", params);
}

export const deleteCharacterMaterial = async (params) => {
  return await _post( VITE_API_BASE + "/library/similar/character/material/del", params);
}

export function startAiProcess(params) {
  return _post( VITE_API_BASE + "/library/similar/character/material/upload", params);
}