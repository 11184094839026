<template>
  <div
    class="collection-item"
    @click="handleSelect(item)"
  > 
    <div class="image-box">
      <div class="lock-mask" v-if="item.lock">
        <svg-icon
          name="icon_lock"
          :size="16"
        />
      </div>
      <div class="error-mask" v-else-if="!item.url && item.state === 0">
        <el-popover
          popper-class="character-video-item-popover"
          popper-style="padding: 12px 24px; background-color: #000; border: none; "
          :popper-options="{ modifiers: [{ name: 'offset', options: { offset: [0, -20] } }]}"
          width="320"
          placement="bottom" 
          trigger="hover"
        >
          <template #reference>
            <div class="error-tip-container">
              <svg-icon 
                name="icon_warn" 
                :size="24" 
                color="#FFFFFF" 
                alt="warn icon" 
              />
              <span class="error-tip">Not Available</span>
            </div>
          </template>
          <p class="popper-container">
            No voice available. Please check 
            <span @click="goToEdit">
              here
            </span>
          </p>
        </el-popover>
      </div>
      <img v-if="item.coverPic" 
        :src="item.coverPic" 
        class="coverPic" 
      />
    </div>
    <div class="detail">
      <p>{{ item.name || "Unnamed" }}</p>
      <p class="number">
        {{ item.size }} {{ selectedStyleId == 1 ? 'Clips' : 'Stickers' }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useMessage } from '@/utils';
import { useModalManager } from '@/components/common/custom-modal/instance';

const message = useMessage();
const modalManager = useModalManager();
const props = defineProps({
  item: Object,
  selectedStyleId: Number,
});

const emit = defineEmits(['select', 'refresh']);

const goToEdit = () => {
  window.open(`/avatar/edit?characterId=${props.item.mediaId}`, "_blank");
  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => emit("refresh"),
  });
};

const handleSelect = (item) => {
  if (props.item.lock) {
    modalManager.applyTemplate("upgradeTips", {
      icon: "orangeWarn",
      title: "Upgrade to edit and use it",
      content: "The character is unavailable, upgrade to unlock this feature.",
    });
    return ;
  };
  if (!item.url && item.state === 0) {
    return;
  }
  emit('select', item);
}
</script>

<style lang="scss" scoped>
.collection-item {
  position: relative;
  cursor: pointer;
  height: 124px;
  padding: 12px 17px;
  display: flex;
  border-radius: 8px;
  &:hover {
    background: #f8f5ff;
  }
}

.novoice-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.image-box {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  background: #ebedef;

  & > .lock-mask {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.80);
    padding: 6px;
    border-radius: 4px;
    color: #ffe4ae;
  }

  & > .error-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.54);  
    z-index: 1;

    & > .error-tip-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    & .error-tip {
      color: #ffffff;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
}

.detail {
  flex: 1 1;
  margin-left: 12px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.number {
  color: #646a73;
  margin-top: 6px;
  line-height: 22px;
}
</style>