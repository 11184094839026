<template>
  <iframe
    :data-tally-src="`${VITE_CANCEL_URL}?transparentBackground=1`"
    width="100%"
    height="100%"
    style="border: none; margin: 0"
    title="Boolvideo Lite Subscription Cancellation Survey"
  ></iframe>
</template>

<script setup>
const { VITE_CANCEL_URL } = import.meta.env;
const emits = defineEmits(["submit"]);

const submit = (e) => {
  if (typeof e?.data === 'string') {
    const data = JSON.parse(e?.data);
    if (data.event === "Tally.FormSubmitted") {
      emits("submit");
    }
  }
};
onMounted(() => {
  Tally.loadEmbeds();

  window.addEventListener('message', submit);
});
onBeforeUnmount(() => {
  window.removeEventListener('message', submit);
});
</script>

<style scoped></style>
