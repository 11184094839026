<script setup>
import { useCreatorStore } from "../../stores";
import { getVideoSpritesheet } from "@/api/material";
import Segment from "./segment.vue";

const emit = defineEmits([
  "update:start",
  "update:end",
  "update:ss",
  "update:keyframes",
  "update:mask",
]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  start: {
    type: Number,
    default: 0,
  },
  end: {
    type: Number,
    default: 0,
  },
  ss: {
    type: Number,
    default: 0,
  },
  keyframes: {
    type: Object,
    default: {},
  },
  mask: {
    type: Object,
    default: {},
  },
  src: {
    type: String,
    default: null,
  },
  coverPic: {
    type: String,
    default: null,
  },
});

const { videoSpritesheetMap, frameToWidth, timeline, fps, secondToFrame } =
  useCreatorStore();

const spritesheet = ref(null);
const isIntersecting = ref(false);
const element = ref(null);
const left = ref(0);
const frameWidth = ref(0);
const frameHeight = ref(0);
const frameNum = ref(0);
const io = ref(null);
const style = computed(() => ({
  left: `${left.value}px`,
}));

watch(
  () => props.src,
  async (newSrc) => {
    if (newSrc) spritesheet.value = await getSpritesheet(newSrc);
  },
  { immediate: true }
);
watch(element, (newElement) => {
  if (newElement) {
    io.value = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        isIntersecting.value = entry.isIntersecting;
      }
    });
    io.value.observe(newElement);
  }
});
watch(
  [isIntersecting, spritesheet, () => props.ss, () => timeline.frameWidth],
  () => nextTick(setup)
);

onBeforeUnmount(() => {
  if (io.value && element.value) {
    io.value.unobserve(element.value);
    io.value.disconnect();
  }
  io.value = null;
  frameNum.value = 0;
  element.value = null;
  isIntersecting.value = false;
});

function setup() {
  if (!element.value || !isIntersecting.value || !spritesheet.value) {
    return;
  }
  const node = props.node;
  const height = element.value.clientHeight;
  const info = node.getInfo();
  const ss = node.getSs();
  const speed = node.getSpeed();
  const totalWidth = frameToWidth(secondToFrame(info.duration / speed));
  const transparent = node.transparent >> 0;
  const ratio = info.videoWidth / (transparent + 1) / info.videoHeight;
  const w = height * ratio;

  left.value = -frameToWidth(ss / speed);
  frameNum.value = Math.ceil(totalWidth / w);
  frameWidth.value = w;
  frameHeight.value = height;
}

async function getSpritesheet(url) {
  if (!videoSpritesheetMap[url]) {
    const response = await getVideoSpritesheet({ url });

    if (response.success) {
      videoSpritesheetMap[url] = response.data;
    }
  }
  return videoSpritesheetMap[url];
}

function getStyle(i) {
  const node = props.node;
  const { url, col, row, frames } = spritesheet.value;
  const secWidth = timeline.frameWidth * fps.value;
  const width = frameWidth.value;
  const height = frameHeight.value;
  const speed = node.getSpeed();
  const transparent = node.transparent >> 0;
  const backgroundWidth = width * (transparent + 1) * col;
  const backgroundHeight = height * row;
  const index = Math.min(
    Math.floor(((i * width) / secWidth) * speed),
    frames - 1
  );
  const x = width * (index % col) * (transparent + 1) + width * transparent;
  const y = height * Math.floor(index / col);

  return {
    width: `${width}px`,
    backgroundSize: `${backgroundWidth}px ${backgroundHeight}px`,
    backgroundImage: `url(${url})`,
    backgroundPosition: `${-x}px ${-y}px`,
  };
}
</script>
<template>
  <Segment
    :style="{ background: node.isPrepared ? '#F3F5F7' : '#99A3A4' }"
    :node="node"
    :start="start"
    :end="end"
    :ss="ss"
    :keyframes="keyframes"
    :mask="mask"
    @update:start="$emit('update:start', $event)"
    @update:end="$emit('update:end', $event)"
    @update:ss="$emit('update:ss', $event)"
    @update:keyframes="$emit('update:keyframes', $event)"
    @update:mask="$emit('update:mask', $event)"
  >
    <div v-if="src" ref="element" class="segment-frames" :style="style">
      <div
        class="frame"
        v-for="i in frameNum"
        :key="i"
        :style="getStyle(i - 1)"
      ></div>
    </div>
    <div
      v-else
      class="segment-screenshot"
      :style="{ backgroundImage: `url(${coverPic})` }"
    ></div>
  </Segment>
</template>
<style scoped>
.segment-frames {
  position: absolute;
  height: 100%;
  display: flex;
}
.segment-frames .frame {
  height: 100%;
  background-repeat: no-repeat;
}
.segment-screenshot {
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-repeat: repeat-x;
}
</style>
