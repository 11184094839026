<template>
  <div>
    <el-dialog
      v-model="visible"
      width="448"
      modal-class="tips-modal"
      center
      align-center
      :title="null"
      :footer="null"
      :show-close="false"
    >
      <template #header>
        <div class="header-container">
          <p class="header-title">Upgrade to use this feature</p>
          <div class="modal-close-btn" @click="handleClose">
            <SvgIcon
              :size="20"
              name="icon_close"
              :style="{ color: '#646A73' }"
            />
          </div>
        </div>
      </template>
      <div class="content">
        Upgrade now to unlock all amazing features and take your experience to
        the next level.
      </div>
      <template #footer>
        <div class="dialog-footer">
          <PrimaryButton size="extra-small" @click="handleConfirm">
            <span class="font-normal">Upgrade</span>
          </PrimaryButton>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'confirm', 'update:modelValue']);
const visible = ref(false);
const loading = ref(false);

watch(
  () => props.modelValue,
  (modelValue) => {
    visible.value = modelValue;
  }
);

const close = () => {
  visible.value = false;
  emit('update:modelValue', visible.value);
};

const handleClose = () => {
  emit('close');
  close();
};

const handleConfirm = () => {
  emit('confirm');
  close();
};
</script>

<style lang="scss">
.tips-modal {
  .el-dialog {
    border-radius: 4px;
  }
  .el-dialog__header {
    margin: 0px;
    padding: 24px;
  }
  .el-dialog__footer {
    padding: 24px;
  }
  .el-dialog__body {
    padding: 0px 24px;
  }
}
</style>

<style lang="scss" scoped>
.modal-close-btn {
  width: 24px;
  height: 24px;
  margin-inline: auto 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 16px;
  font-weight: 500;
  color: #060606;
}

.content {
  font-size: 14px;
  color: #060606;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
