<template>
  <import-file :showCase="true"/>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter , useRoute} from 'vue-router'
import ImportFile from '../commonComponents/ImportFile.vue'
import { sessionRemoveItem } from '@/utils/storage';

const router = useRouter()
const route = useRoute()
onMounted(() => {
  router.replace({
    path: route.path,
    query: {
      type: 'upload'
    }
  })

})
sessionRemoveItem('cacheForm');
</script>