<template>
  <bubble>
    <template #avatar>
      <svg
        width="30"
        height="30"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="30"
          height="30"
          rx="15"
          fill="white"
          stroke="#E5E7EB"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.6832 7.12804C15.8788 7.01298 16.1214 7.01298 16.317 7.12804L19.8587 9.21137L23.4003 11.2947C23.5912 11.407 23.7084 11.6119 23.7084 11.8334V20.1667C23.7084 20.3882 23.5912 20.5932 23.4003 20.7055L19.8587 22.7888L16.317 24.8721C16.1214 24.9872 15.8788 24.9872 15.6832 24.8721L8.59986 20.7055C8.40896 20.5932 8.29175 20.3882 8.29175 20.1667V11.8334C8.29175 11.6119 8.40896 11.407 8.59986 11.2947L15.6832 7.12804ZM16.0001 8.39186L9.54175 12.1909V19.8093L16.0001 23.6083L22.4584 19.8093V12.1909L19.2249 10.2888L16.0001 8.39186Z"
          fill="#262626"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 7.04175C16.3452 7.04175 16.625 7.32157 16.625 7.66675V16.0001C16.625 16.3453 16.3452 16.6251 16 16.6251C15.6548 16.6251 15.375 16.3453 15.375 16.0001V7.66675C15.375 7.32157 15.6548 7.04175 16 7.04175Z"
          fill="#262626"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4614 15.6832C15.6364 15.3857 16.0195 15.2864 16.317 15.4614L23.4003 19.6281C23.6978 19.8031 23.7972 20.1861 23.6221 20.4837C23.4471 20.7812 23.0641 20.8805 22.7665 20.7055L15.6832 16.5388C15.3857 16.3638 15.2864 15.9807 15.4614 15.6832Z"
          fill="#262626"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5386 15.6832C16.7137 15.9807 16.6143 16.3638 16.3168 16.5388L9.23349 20.7055C8.93597 20.8805 8.55291 20.7812 8.37789 20.4837C8.20288 20.1861 8.3022 19.8031 8.59972 19.6281L15.683 15.4614C15.9806 15.2864 16.3636 15.3857 16.5386 15.6832Z"
          fill="#262626"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 19.5417C16.3452 19.5417 16.625 19.8216 16.625 20.1667V24.3334C16.625 24.6786 16.3452 24.9584 16 24.9584C15.6548 24.9584 15.375 24.6786 15.375 24.3334V20.1667C15.375 19.8216 15.6548 19.5417 16 19.5417Z"
          fill="#262626"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.6134 11.5021C23.7964 11.7948 23.7074 12.1804 23.4147 12.3633L20.0813 14.4467C19.7886 14.6296 19.403 14.5406 19.2201 14.2479C19.0371 13.9552 19.1261 13.5696 19.4188 13.3867L22.7522 11.3033C23.0449 11.1204 23.4305 11.2094 23.6134 11.5021Z"
          fill="#262626"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.38659 11.5021C8.56953 11.2094 8.95513 11.1204 9.24784 11.3033L12.5812 13.3867C12.8739 13.5696 12.9629 13.9552 12.7799 14.2479C12.597 14.5406 12.2114 14.6296 11.9187 14.4467L8.58534 12.3633C8.29263 12.1804 8.20364 11.7948 8.38659 11.5021Z"
          fill="#262626"
        />
      </svg>
    </template>
    <template #nickname> AI Assistant </template>
    <template #context>
      <div class="context" v-if="['success', 'typing'].includes(message.state)">
        <img
          v-if="['sticker', 'image'].includes(currentType)"
          class="context-img"
          alt=""
          :src="displayContent"
        />
        <div v-else-if="currentType === 'text'">
          <p v-for="(content, index) in displayContent">
            {{ content }}
            <i
              class="ins"
              v-if="
                message.state === 'typing' &&
                index === displayContent.length - 1
              "
            />
          </p>
        </div>
      </div>
      <p class="error-context context" v-if="message.state === 'error'">
        Something wrong, please try again.
      </p>
      <p class="nocredit-context context" v-if="message.state === 'nocredit'">
        <span>
          Oops, it seems you don't have enough credits, clicking
          <a @click="openPremiumInTab">here </a>
          to upgrade :)
        </span>
      </p>
      <div class="loading-context context" v-if="message.state === 'loading'">
        <svg-icon name="assistance_loading" class="rotate" :size="16" />
        <span>Generating...</span>
      </div>
    </template>
    <template #footer>
      <div
        class="bubble-footer"
        v-if="['success', 'error'].includes(message.state)"
      >
        <div class="tools">
          <icon-button
            v-if="canAddToTrack"
            name="assistance_add"
            tip="Add to track"
            :loading="adding"
            :size="18"
            @click="add(currentContent)"
          />
          <icon-button
            v-if="canAddToSpace"
            :class="{ disabled: isSaved }"
            name="assistance_add"
            :tip="isSaved ? 'Saved to my space' : 'Save to my space'"
            :loading="uploading"
            :size="18"
            @click="handleSaveMaterial(currentContent)"
          />
          <icon-button
            v-if="canCopy"
            name="assistance_copy"
            tip="Copy"
            :size="18"
            @click="copy(currentContent)"
          />
          <div v-if="copyDone">
            <svg-icon name="assistance_done" :size="18" />
          </div>
          <icon-button
            v-if="message.state === 'success'"
            name="assistance_regenerate"
            tip="Regenerate"
            :size="18"
            @click="reGen(index)"
          />
          <icon-button
            v-if="message.state === 'error'"
            name="assistance_regenerate"
            tip="Regenerate"
            :size="18"
            @click="reGen(index)"
          />
        </div>
        <div
          class="pagination"
          v-if="message.state === 'success' && message.totalPage > 1"
        >
          <svg-icon
            name="icon_left"
            :size="12"
            :disabled="disabledPre"
            @click="pre(index)"
          />
          <span class="page">
            {{ message.currentPage + " / " + message.totalPage }}
          </span>
          <svg-icon
            name="icon_left"
            style="transform: rotate(180deg)"
            :size="12"
            :disabled="disabledNext"
            @click="next(index)"
          />
        </div>
      </div>
    </template>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </bubble>
</template>

<script setup>
import Bubble from "./bubble.vue";
import { defaultFontFamily } from "@/constants/text";
import {
  useDraftStore,
  useCreatorStore,
  useHistoryStore,
} from "@/pages/editor/stores";
import { useAssistStore } from "@/store/modules/assist";
import { useTrackStore } from "@/store/modules/track";
import { openPremiumInTab } from "@/utils/premium";
import { base64ToBlob, useSingleMessage } from "@/utils";
import { fileUploadPre, uploadFile, fileUploadState } from "@/api/upload";
import SubscribeDialog from "@/layout/components/workspace/subscribeDialog/index.vue";
import { assistantSaveMaterial } from "@/api/index";
import { emitter } from "@/pages/space/utils/instance";
const emit = defineEmits("saveMaterial");
const route = useRoute();
const Message = useSingleMessage();
const { commit } = useHistoryStore();
const { updateDraft } = useDraftStore();
const { messages } = storeToRefs(useAssistStore());
const { regenerate } = useAssistStore();
const { track, collectData } = useTrackStore();
const { getXY, addNode, updateProject, updateCloud } = useCreatorStore();

const props = defineProps({
  message: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
  },
});

const currentContent = computed(() => {
  const { message } = props;
  return message.contents[message.currentPage - 1]?.content;
});

const isSaved = computed(() => {
  const { message } = props;
  return message.contents[message.currentPage - 1]?.isSaved;
});

const currentType = computed(() => {
  const { message } = props;
  return message.contents[message.currentPage - 1]?.type;
});
const displayContent = computed(() => {
  const { message } = props;
  const type = message.contents[message.currentPage - 1]?.type;
  if (type === "text") {
    return message.contents[message.currentPage - 1].content.split("\n\n");
  } else if (["sticker", "image"].includes(type)) {
    return message.contents[message.currentPage - 1]?.content;
  }
});
const canAddToTrack = computed(() => {
  const { message } = props;
  return message.state === "success" && route.path === "/editor";
});
const canAddToSpace = computed(() => {
  const { message } = props;
  return (
    message.state === "success" &&
    route.path !== "/editor" &&
    ["image", "sticker"].includes(currentType.value)
  );
});
const canCopy = computed(() => {
  const { message } = props;
  return (
    message.state === "success" &&
    route.path !== "/editor" &&
    currentType.value === "text" &&
    !copyDone.value
  );
});
const disabledPre = computed(() => {
  const { message } = props;
  return message.currentPage === 1;
});
const disabledNext = computed(() => {
  const { message } = props;
  return message.currentPage === message.totalPage;
});
const uploading = ref(false);
const adding = ref(false);
const copyDone = ref(false);
const timer = ref(null);

const subscribeModalVisible = ref(false);

onUnmounted(() => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = null;
});

const submit = () => {
  commit();
  updateDraft();
};

const add = async (content) => {
  adding.value = true;

  const type = currentType.value;
  Message.loading("Loading, it will take a while");
  if (type === "image") {
    const file = await saveImage(content);
    await addNode({
      ...getXY(),
      type: "image",
      sourceId: file.id,
      src: file.previewUrl,
      fit: "contain",
      duration: 90,
      hdUrl: file.preview1080Url,
      materialMeta: {
        width480: file.width480,
        width1080: file.width1080,
        url1080: file.preview1080Url,
      },
    });
  } else if (type === "text") {
    await addNode({
      ...getXY(),
      type: "text",
      fontFamily: defaultFontFamily,
      fill: "#000000",
      duration: 90,
      text: content,
      fontSize: 24,
    });
  } else if (type === "sticker") {
    const file = await saveImage(content);
    await addNode({
      ...getXY(),
      type,
      coverPic: file.coverPic,
      sourceId: file.id,
      src: file.url,
      scale: 0.5,
      mode: file.type === 1 ? "animated" : "static",
      duration: 90,
      materialMeta: {
        width480: file.width480,
        width1080: file.width1080,
        url1080: file.preview1080Url,
      },
    });
  }

  collectData("boolvideo_ai_assistant_use", {
    click: `add_${type}`,
  });
  track("boolvideo_ai_assistant_use");
  Message.close();
  updateProject.value++;
  updateCloud.value++;
  submit();
  adding.value = false;
};

const handleSaveMaterial = async (content) => {
  if (isSaved.value) {
    return;
  }
  uploading.value = true;
  await saveImage(content)
    .then(() => {
      Message.success('Saved in " My Space_Materials "');
      emit("saveMaterial", props.index, content);
      emitter.emit("uploadFile", content);
    })
    .catch((e) => {
      console.error(e);
      Message.error("Upload failed");
    })
    .finally(() => (uploading.value = false));
};

const saveImage = async (img) => {
  return await new Promise((resolve, reject) => {
    const params = {
      imgUrl: img,
    };
    assistantSaveMaterial(params)
      .then((res) => {
        if (res.code === 40001) {
          const { code, msg } = response;
          modalManager.applyTemplate("upgradeTips", {
            msg,
            code,
            onConfirm: () => {
              subscribeModalVisible.value = true;
            },
          });
          reject();
        }
        resolve(res.data);
      })
      .catch(() => {
        console.log("upload error");
      });
  });
};

async function getUploadState(mid) {
  return new Promise((resolve, reject) => {
    const getState = async () => {
      const { success, data } = await fileUploadState(mid);

      if (success) {
        if (data.state === 0) {
          resolve(data);
        } else if (data.state === 1) {
          reject({ error: "failure", success: false });
        } else {
          timer.value = setTimeout(getState, 1000);
        }
      } else {
        reject({ error: "failure", success: false });
      }
    };
    getState();
  });
}

const copy = async (value) => {
  await navigator.clipboard.writeText(value);
  copyDone.value = true;
  setTimeout(() => {
    copyDone.value = false;
  }, 1000);
};

const reGen = (index) => {
  regenerate(index).then(resetState);
};

const resetState = () => {
  copyDone.value = false;
};

const pre = (index) => {
  const message = messages.value[index];
  const { currentPage } = message;
  if (currentPage === 1) {
    return;
  }
  message.currentPage = currentPage - 1;

  resetState();
};

const next = (index) => {
  const message = messages.value[index];
  const { currentPage, totalPage } = message;
  if (currentPage === totalPage) {
    return;
  }
  message.currentPage = currentPage + 1;

  resetState();
};
</script>

<style lang="scss" scoped>
:deep(.icon-button) {
  .icon-wapper {
    padding: 0;
  }

  svg {
    color: #646a73;
  }

  svg.disabled {
    cursor: default;
    color: #646a73 !important;
  }

  svg.loading {
    color: #646a73 !important;
  }
}

:deep(.icon-button:hover) {
  background-color: transparent;

  svg {
    color: #1c1b1e;
  }
}

.pagination svg.disabled {
  color: #646a73 !important;
  pointer-events: none;
}

.bubble-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.tools {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tools svg {
  color: #646a73;
  cursor: pointer;

  &:hover {
    color: #1c1b1e;
  }
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination svg {
  cursor: pointer;
  color: #1c1b1e;

  &.disabled {
    color: #8f959e;
  }
}

.pagination span {
  font-variant-numeric: tabular-nums;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 0 8px;
}

.context {
  user-select: text;
  white-space: pre-wrap;
}

.context p + p {
  margin-top: 1.25em;
}

.context-img {
  max-width: 100%;
  border-radius: 12px;
  background-color: #f3f5f7;
}

.error-context {
  color: #ff5449;
}

.loading-context {
  display: flex;
  align-items: center;
}

.loading-context span {
  margin-left: 10px;
}

.nocredit-context a {
  color: #6741ff;
  cursor: pointer;
}

.rotate {
  animation: rotate 2s linear infinite;
}

.ins {
  margin-left: 5px;
  border: 1px solid transparent;
  animation: blink 0.5s linear infinite;
}

:deep(.icon-button.disabled) {
  svg {
    color: #bbbfc4;
    cursor: auto;
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: #060606;
  }
}
</style>
