/**
 * config 文件解析
 * */

class ParseConfig {
  /**
   * 构造函数
   * @param config {Object}
   * */
  constructor(config) {
    this.init(config);
  }

  /**
   * 初始化函数
   * @param config {Object}
   * */
  init(config) {
    this.initialization();
    this.configJson =
      typeof config === "object" ? config : JSON.parse(JSON.stringify(config));
    // eslint-disable-next-line prefer-destructuring
    this.templateWidth = this.configJson.size && this.configJson.size[0];
    // eslint-disable-next-line prefer-destructuring
    this.templateHeight = this.configJson.size && this.configJson.size[1];
    this.duration = this.configJson.duration;
    this.fps = this.configJson.fps;
    this.verticalVersion = this.templateHeight > this.templateWidth;
    this.formateLayersData(this.configJson.layers);
    this.groupingByAssetKey(this.configJson.assets);
  }

  /**
   * 变量初始化
   * */
  initialization() {
    // configJson
    this.configJson = {};
    // 带ui字段的 assets
    this.uiAssets = {};
    // 顺序数组
    this.orderList = [];
    // 编辑组 键列表
    this.groupKeys = {};
    // 编辑组
    this.groups = {};
    // 编辑组 size
    this.uiGroupSize = [];
    // 所有图层合集
    this.layersData = [];
    // 帧率
    this.fps = 30;
    this.duration = 0;
    // 替换点个数
    this.counter = {
      totalCount: 0,
      imageCount: 0,
      textCount: 0,
    };
    this.templateWidth = this.templateHeight = 0;
    this.verticalVersion = true;
    this.totalTime = 0;
  }

  /**
   * 对象|数组 遍历
   * @param list {Array|Object}
   * @param callback {Function}
   * */
  // eslint-disable-next-line class-methods-use-this
  forEach(list, callback) {
    const isFn = typeof callback !== "function";
    const isArray = Array.isArray(list);

    if (isFn) throw new Error("callback is not a function!");
    let i = 0;
    let keys = [];
    let len = list.length;

    if (!isArray) {
      keys = Object.keys(list);
      len = keys.length;
    }

    for (; i < len; i++) {
      // eslint-disable-next-line no-unused-expressions
      isArray ? callback(list[i], i) : callback(list[keys[i]], keys[i]);
    }
  }

  /**
   *  获取总时长
   * @return totalTime {Number}
   * */
  getTotalTime() {
    this.totalTime = Math.ceil(this.configJson.duration / this.configJson.fps);
    return this.totalTime;
  }

  /**
   * 根据宽度获取比例 和 高度
   * @param width {Number}
   * @return scaleInfo {Object}
   * */
  getHeightScale(width) {
    const scale = width / this.templateWidth;
    const height = this.templateHeight * scale;

    return {
      scale,
      height,
    };
  }

  /**
   * 根据高度获取比例 和 宽度
   * @param height {Number}
   * @return scaleInfo {Object}
   * */
  getWidthScale(height) {
    const scale = height / this.templateHeight;
    const width = this.templateWidth * scale;
    return {
      scale,
      width,
    };
  }

  /**
   * 根据高度获取编辑器和组的 比例 和 宽度
   * @param height {Number}
   * @param groupKey {Number|String}
   * @return scaleInfo {Object}
   * */
  getWidthScaleByGroup(height, groupKey) {
    let sizeArray = [this.templateWidth, this.templateHeight];
    if (this.groups[groupKey]) {
      sizeArray = this.groups[groupKey].groupSize;
    }

    const scale = height / sizeArray[1];
    const width = sizeArray[0] * scale;
    return {
      scale,
      width,
    };
  }

  /**
   * 根据高度获取编辑器和组的 比例 和 宽度
   * @param width {Number}
   * @param groupKey {Number|String}
   * @return scaleInfo {Object}
   * */
  getHeightScaleByGroup(width, groupKey) {
    let sizeArray = [this.templateWidth, this.templateHeight];
    if (this.groups[groupKey]) {
      sizeArray = this.groups[groupKey].groupSize;
    }

    const scale = width / sizeArray[0];
    const height = sizeArray[1] * scale;

    return {
      scale,
      height,
    };
  }

  /**
   * 根据带有 ui asset 的key 分组
   * @param assets {Array}
   * */
  groupingByAssetKey(assets) {
    let textCount = 0;
    let imageCount = 0;
    let totalCount = 0;
    this.forEach(assets, (currentAssets) => {
      if (currentAssets.ui) {
        const {
          ui: { group, type },
          key,
        } = currentAssets;

        this.uiAssets[key] = currentAssets;
        this.orderList.push(key);
        if (!this.groupKeys[group]) {
          this.groupKeys[group] = [];
        }
        this.groupKeys[group].push(key);
        if (currentAssets.type === 1) imageCount++;
        if (currentAssets.type === 6) textCount++;
        totalCount++;
      }
    });

    this.counter.textCount = textCount;
    this.counter.imageCount = imageCount;
    this.counter.totalCount = totalCount;
  }

  /**
   * 根据layers 遍历出所有的layer
   */

  forEachLayer(layers, list) {
    this.forEach(layers, (layer) => {
      if (layer.uuid) {
        layer.layerId = layer.uuid;
      }

      if (layer.layers && layer.layers.length) {
        this.forEachLayer(layer.layers, list);
      }
      list.push(layer);
    });
  }

  formateLayersData(list) {
    this.forEachLayer(list, this.layersData);
  }
}

export default ParseConfig;
