<template>
  <div class="mobile-footer-container">
    <h1>Stop wasting time creating videos Simply input and click generate</h1>

    <router-link to="/premium">
      <div class="cta-button">
        <p class="button-text">Subscribe Now</p>
        <!-- <p class="button-tip">（ 3-day-free-trial  ）</p> -->
      </div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.mobile-footer-container {
  margin-top: 60px;
  width: 100%;
  height: 564px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(356deg, #632cff -11.56%, #8e68ff 97.31%);
  padding: 114px 16px 131px 16px;

  h1 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .cta-button {
    display: flex;
    margin-top: 60px;
    width: 263px;
    height: 74px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 500px;
    background: #181818;
    cursor: pointer;
  }

  .button-text {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */

  }

  .button-tip {
    color: #bfbfbf;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 200% */
  }
}
</style>