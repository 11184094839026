import { ref, provide, type Ref, watchEffect } from 'vue';
import { getToken } from './auth';
import { getItem } from '@/utils/storage';
import { useSubscriptionInfo } from '@/store/modules/user';
import { getOrderState as bGetOrderState } from '@/api/premium';
import { getOrderState as sGetOrderState } from '@/api/svPremium';

const premiumWindow: Ref<Window | null> = ref(null);

const windoeCloselistener = () => {
  const winLoop = setInterval(function () {
    if (premiumWindow.value?.closed) {
      clearInterval(winLoop);
      window.removeEventListener('message', messageListenerFunc);
    }
  }, 500);
};
export const openPremiumInTab = () => {
  premiumWindow.value = window.open('/premium', '_blank');

  window.addEventListener('message', messageListenerFunc);
  windoeCloselistener();
};

const messageListenerFunc = (e: MessageEvent) => {
  if (e.origin === window.location.origin) {
    const { subscribeSuccess, token } = e.data;
    if (token === getToken()) {
      const subscriptionInfo = useSubscriptionInfo();
      subscribeSuccess == 'true' && subscriptionInfo.refresh();
    }
  }
};
const tapfiliateConversionTrack = (globalDomain: number,  stripeCustomerId: string) => {
  if (globalDomain == 2) {
    tap('create', '49342-e2f8da', { integration: 'stripe' });
    // tap('conversion', Date.now(), payAmount);
    tap('trial', stripeCustomerId);
  }
};

// 支付完轮询订单状态
export const paymentPolling = (orderId: number) => {
  let count = 0;
  const globalDomain = getItem('currentDomain');

  const getOrderState = async (params) => {
    switch (globalDomain) {
      case 1:
        return await bGetOrderState(params);
      case 2:
        return await sGetOrderState(params);
    }
  };
  return new Promise((resolve, reject) => {
    async function call() {
      await getOrderState({ order_id: orderId }).then((res) => {
        const { data } = res;
        if (data.state === 'FINISH') {
          tapfiliateConversionTrack(globalDomain,  data.stripeCustomerId);
          resolve(data);
        } else if (data.state === 'PAYMENT_ANOMALY') {
          reject(data);
        } else if (data.state === 'CANCEL') {
          reject(data);
        } else if (count > 1000) {
          reject({ state: 'PAYMENT_ERROR' });
        } else {
          count += 1;
          setTimeout(() => {
            call();
          }, 1000);
        }
      });
    }
    call();
  });
};
