<template>
  <div
    ref="element"
    class="widget"
    tabindex="-1"
    :data-index="index"
    :class="{ active, dragging, resizing, rotating }"
    :style="style"
    @mousedown.stop="elementDown"
    @keydown="handleKeyDown"
    @dblclick.stop="handleDblclick"
    @contextmenu.stop.prevent="handleContextMenu"
  >
    <span
      v-for="(handler, i) in handlers"
      v-show="shouldShowHandler(handler)"
      class="handler"
      :key="i"
      :class="'handler-' + handler"
      @mousedown.stop="handlerDown($event, handler)"
    >
    </span>
    <span
      v-show="active && !dragging && !resizing"
      class="handler-rotate"
      @mousedown.stop="rotateDown"
    >
      <svg-icon name="editor_rotation" :size="18" />
    </span>
  </div>
</template>
<script setup>
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useKeyboard,
} from "../../stores";
import { getComputedSize } from "../../utils";

const emit = defineEmits([
  "update:x",
  "update:y",
  "update:width",
  "update:height",
  "update:scale",
  "update:rotate",
  "update:fit",
]);
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  nodes: {
    type: Array,
    default: () => [],
  },
  node: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: -1,
  },
  mask: {
    type: Object,
    default: null,
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  scale: {
    type: Number,
    default: 1,
  },
  fit: {
    type: String,
    default: "none",
  },
  rotate: {
    type: Number,
    default: 0,
  },
  anchor: {
    type: Array,
    default: [0.5, 0.5],
  },
  dirtyId: {
    type: Number,
    default: 0,
  },
});

const {
  widgetMenu,
  attrTabMap,
  creator,
  activeNodeMap,
  setActiveNode,
  removeActiveNodes,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { modifier, pressed } = useKeyboard();

const showRefLineLeft = inject("showRefLineLeft");
const showRefLineCenter = inject("showRefLineCenter");
const showRefLineRight = inject("showRefLineRight");
const showRefLineTop = inject("showRefLineTop");
const showRefLineMiddle = inject("showRefLineMiddle");
const showRefLineBottom = inject("showRefLineBottom");

const left = ref(props.x);
const top = ref(props.y);
const right = ref(0);
const bottom = ref(0);
const width = ref(0);
const height = ref(0);
const rotation = ref(props.rotate);

const aspectRatio = ref(null);
const parentWidth = ref(null);
const parentHeight = ref(null);

const dragStart = ref(false);
const dragging = ref(false);
const resizeStart = ref(false);
const resizing = ref(false);
const rotateStart = ref(false);
const rotating = ref(false);

const element = ref(null);
const currentHandler = ref(null);
const bounds = ref(null);
const ro = ref(null);

const beforePosition = reactive({ pageX: 0, pageY: 0, width: 0, height: 0 });

const isSubtitle = computed(() => props.node.type === "subtitle");
const lockAspectRatio = computed(
  () => (!props.mask && props.node.type !== "graphic") || modifier.shift
);
const handlers = computed(() =>
  props.mask || props.node.type === "graphic"
    ? ["tl", "tm", "tr", "bl", "bm", "br", "ml", "mr"]
    : ["tl", "tr", "bl", "br"]
);
const style = computed(() => ({
  transform: `translate(${left.value}px, ${top.value}px) rotate(${rotation.value}rad)`,
  transformOrigin: `${props.anchor[0] * 100}% ${props.anchor[1] * 100}%`,
  width: `${width.value}px`,
  height: `${height.value}px`,
}));

watch(
  () => props.x,
  (newX) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    left.value = newX;
    right.value = parentWidth.value - width.value - left.value;
  }
);
watch(
  () => props.y,
  (newY) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    top.value = newY;
    bottom.value = parentHeight.value - height.value - top.value;
  }
);
watch(
  () => props.width,
  (newWidth) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    const ratio = newWidth / height.value;
    width.value = newWidth;
    aspectRatio.value = ratio;
  }
);
watch(
  () => props.height,
  (newHeight) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    const ratio = width.value / newHeight;
    height.value = newHeight;
    aspectRatio.value = ratio;
  }
);
watch(
  () => props.rotate,
  (newRotation) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    rotation.value = newRotation;
  }
);
watch(
  () => props.active,
  (newActive) => {
    const { node, mask } = props;

    if (newActive) {
      // unrefElement(widget).focus({ preventScroll: true });
    } else if (
      !newActive &&
      !mask &&
      node.mask &&
      attrTabMap[node.id] !== "mask"
    ) {
      attrTabMap[node.id] = "mask";
    }
  },
  { flush: "post" }
);
watch(
  [() => props.node.updateId, () => props.dirtyId],
  () => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    fitWidgetRect();
  },
  { flush: "post" }
);

onBeforeMount(resetPosition);
onMounted(() => {
  const parentElement = element.value.parentElement;
  const [pw, ph] = getComputedSize(parentElement);

  parentWidth.value = pw;
  parentHeight.value = ph;
  fitWidgetRect();

  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);

  ro.value = new ResizeObserver(parentResize);
  ro.value.observe(parentElement);
});

onBeforeUnmount(() => {
  ro.value.unobserve(element.value.parentElement);
  ro.value.disconnect();
  ro.value = null;

  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);

  element.value = null;
  currentHandler.value = null;
});

function parentResize() {
  const [w, h] = getComputedSize(element.value.parentElement);

  parentWidth.value = w;
  parentHeight.value = h;

  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;
}

function mouseMove(e) {
  if (rotateStart.value) {
    rotate(e);
  } else if (resizeStart.value) {
    resize(e);
  } else if (dragStart.value) {
    drag(e);
  }
}

function mouseUp(e) {
  resetPosition();

  if (dragStart.value) {
    dragStart.value = false;

    if (!dragging.value) {
      const nextElements = [];
      let el = element.value;

      while ((el = el.nextElementSibling)) {
        if (el.classList.contains("widget")) {
          nextElements.push(el);
        }
      }
      for (let i = nextElements.length - 1; i >= 0; i--) {
        const element = nextElements[i];
        const { left, right, top, bottom } = element.getBoundingClientRect();

        if (
          left <= e.pageX &&
          e.pageX <= right &&
          top <= e.pageY &&
          e.pageY <= bottom
        ) {
          const node = props.nodes[element.dataset.index];
          setTimeout(() => setActiveNode(node, !pressed.value));
          break;
        }
      }
    }
  }
  if (dragging.value) {
    dragging.value = false;
    submit();
  }
  if (resizing.value) {
    resizing.value = false;
    emit("update:fit", "none");
    submit();
  }
  if (rotating.value) {
    rotating.value = false;
    submit();
  }

  resizeStart.value = false;
  rotateStart.value = false;
  currentHandler.value = null;

  showRefLineLeft.value = false;
  showRefLineCenter.value = false;
  showRefLineRight.value = false;
  showRefLineTop.value = false;
  showRefLineMiddle.value = false;
  showRefLineBottom.value = false;
}

function elementDown(e) {
  setTimeout(() => setActiveNode(props.node, !pressed.value));
  dragStart.value = true;
  savePosition(e);
}

function handlerDown(e, handler) {
  resizeStart.value = true;
  currentHandler.value = handler;

  savePosition(e);
  calcResizeBounds();
}

function rotateDown() {
  rotateStart.value = true;

  const { x, y, width, height } = element.value.getBoundingClientRect();
  const pageX = x + width / 2;
  const pageY = y + height / 2;

  savePosition({ pageX, pageY });
}

function drag(e) {
  dragging.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;

  let newLeft = beforePosition.left - deltaX;
  let newRight = beforePosition.right + deltaX;
  let newTop = beforePosition.top - deltaY;
  let newBottom = beforePosition.bottom + deltaY;

  if (rotation.value === 0) {
    [newLeft, newRight, newTop, newBottom] = align(
      newLeft,
      newRight,
      newTop,
      newBottom
    );
  }

  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
  bottom.value = newBottom;

  const [x, y] = getNodeXY(newLeft, newTop);

  emit("update:x", x);
  emit("update:y", y);
  isSubtitle.value && applyAllSubtitle("x", x);
  isSubtitle.value && applyAllSubtitle("y", y);
}

function resize(e) {
  resizing.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;
  const handler = currentHandler.value;
  const boundsRef = bounds.value;
  const ratio = aspectRatio.value;

  let newLeft = left.value;
  let newRight = right.value;
  let newTop = top.value;
  let newBottom = bottom.value;

  if (handler.includes("l")) {
    newLeft = restrictToBounds(beforePosition.left - deltaX, boundsRef.maxLeft);
  } else if (handler.includes("r")) {
    newRight = restrictToBounds(
      beforePosition.right + deltaX,
      boundsRef.maxRight
    );
  }
  if (handler.includes("t")) {
    newTop = restrictToBounds(beforePosition.top - deltaY, boundsRef.maxTop);
    if (lockAspectRatio.value) {
      if (handler[1] === "l") {
        newLeft = left.value - (top.value - newTop) * ratio;
      } else if (handler[1] === "r") {
        newRight = right.value - (top.value - newTop) * ratio;
      }
    }
  } else if (handler.includes("b")) {
    newBottom = restrictToBounds(
      beforePosition.bottom + deltaY,
      boundsRef.maxBottom
    );
    if (lockAspectRatio.value) {
      if (handler[1] === "l") {
        newLeft = left.value - (bottom.value - newBottom) * ratio;
      } else if (handler[1] === "r") {
        newRight = right.value - (bottom.value - newBottom) * ratio;
      }
    }
  }

  if (rotation.value === 0) {
    [newLeft, newRight, newTop, newBottom] = align(
      newLeft,
      newRight,
      newTop,
      newBottom,
      handler
    );
  }

  newLeft = restrictToBounds(newLeft, boundsRef.maxLeft);
  newRight = restrictToBounds(newRight, boundsRef.maxRight);
  newTop = restrictToBounds(newTop, boundsRef.maxTop);
  newBottom = restrictToBounds(newBottom, boundsRef.maxBottom);

  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
  bottom.value = newBottom;

  const newWidth = computeWidth(parentWidth.value, newLeft, newRight);
  const newHeight = computeHeight(parentHeight.value, newTop, newBottom);

  width.value = newWidth;
  height.value = newHeight;
  aspectRatio.value = newWidth / newHeight;

  // console.log('[]', { newLeft, newRight, newTop, newBottom })

  const [x, y] = getNodeXY(newLeft, newTop);
  const [w, h] = getNodeSize(newWidth, newHeight);
  const scale = getNodeScale(newWidth);

  emit("update:x", x);
  emit("update:y", y);
  emit("update:width", w);
  emit("update:height", h);
  emit("update:scale", scale);
  isSubtitle.value && applyAllSubtitle("x", x);
  isSubtitle.value && applyAllSubtitle("y", y);
  isSubtitle.value && applyAllSubtitle("scale", scale);
}

function rotate(e) {
  rotating.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;
  const newRotation = Math.atan2(deltaX, -deltaY);

  rotation.value = newRotation;
  emit("update:rotate", newRotation);
  isSubtitle.value && applyAllSubtitle("rotate", newRotation);
}

function align(left, right, top, bottom, handler) {
  const ratio = aspectRatio.value;
  const asp = 10;

  let newLeft = left;
  let newRight = right;
  let newTop = top;
  let newBottom = bottom;

  let isAdsorptionLeft = false;
  let isAdsorptionCenter = false;
  let isAdsorptionRight = false;
  let isAdsorptionTop = false;
  let isAdsorptionMiddle = false;
  let isAdsorptionBottom = false;

  let isLeft = false;
  let isRight = false;
  let isTop = false;
  let isBottom = false;

  if (-asp <= left && left <= asp) {
    isLeft = isAdsorptionLeft = true;
    newLeft = 0;
  } else if (
    parentWidth.value - asp <= left &&
    left <= parentWidth.value + asp
  ) {
    isLeft = isAdsorptionRight = true;
    newLeft = parentWidth.value;
  }
  if (isLeft) {
    if (!handler) {
      newRight = parentWidth.value - width.value - newLeft;
    } else if (lockAspectRatio.value && handler.includes("l")) {
      if (handler[0] === "t") {
        newTop = top - (left - newLeft) / ratio;
      } else if (handler[0] === "b") {
        newBottom = bottom - (left - newLeft) / ratio;
      }
    }
  }

  if (-asp <= right && right <= asp) {
    isRight = isAdsorptionRight = true;
    newRight = 0;
  } else if (
    parentWidth.value - asp <= right &&
    right <= parentWidth.value + asp
  ) {
    isRight = isAdsorptionLeft = true;
    newRight = parentWidth.value;
  }
  if (isRight) {
    if (!handler) {
      newLeft = parentWidth.value - width.value - newRight;
    } else if (lockAspectRatio.value && handler.includes("r")) {
      if (handler[0] === "t") {
        newTop = top - (right - newRight) / ratio;
      } else if (handler[0] === "b") {
        newBottom = bottom - (right - newRight) / ratio;
      }
    }
  }

  if (-asp <= top && top <= asp) {
    isTop = isAdsorptionTop = true;
    newTop = 0;
  } else if (
    parentHeight.value - asp <= top &&
    top <= parentHeight.value + asp
  ) {
    isTop = isAdsorptionBottom = true;
    newTop = parentHeight.value;
  }
  if (isTop) {
    if (!handler) {
      newBottom = parentHeight.value - height.value - newTop;
    } else if (lockAspectRatio.value && handler.includes("t")) {
      if (handler[1] === "l") {
        newLeft = left - (top - newTop) * ratio;
      } else if (handler[1] === "r") {
        newRight = right - (top - newTop) * ratio;
      }
    }
  }

  if (-asp <= bottom && bottom <= asp) {
    isBottom = isAdsorptionBottom = true;
    newBottom = 0;
  } else if (
    parentHeight.value - asp <= bottom &&
    bottom <= parentHeight.value + asp
  ) {
    isBottom = isAdsorptionTop = true;
    newBottom = parentHeight.value;
  }
  if (isBottom) {
    if (!handler) {
      newTop = parentHeight.value - height.value - newBottom;
    } else if (lockAspectRatio.value && handler.includes("b")) {
      if (handler[1] === "l") {
        newLeft = left - (bottom - newBottom) * ratio;
      } else if (handler[1] === "r") {
        newRight = right - (bottom - newBottom) * ratio;
      }
    }
  }

  if (-asp <= left - right && left - right <= asp) {
    isAdsorptionCenter = true;

    if (!handler) {
      newLeft = (parentWidth.value - width.value) / 2;
      newRight = newLeft;
    }
  }
  if (-asp <= top - bottom && top - bottom <= asp) {
    isAdsorptionMiddle = true;

    if (!handler) {
      newTop = (parentHeight.value - height.value) / 2;
      newBottom = newTop;
    }
  }

  // console.log('[]', { isLeft, isRight, isTop, isBottom })

  showRefLineLeft.value = isAdsorptionLeft;
  showRefLineCenter.value = isAdsorptionCenter;
  showRefLineRight.value = isAdsorptionRight;
  showRefLineTop.value = isAdsorptionTop;
  showRefLineMiddle.value = isAdsorptionMiddle;
  showRefLineBottom.value = isAdsorptionBottom;

  return [newLeft, newRight, newTop, newBottom];
}

function calcResizeBounds() {
  const boundsRef = bounds.value;
  const ratio = aspectRatio.value;
  let minW = 1;
  let minH = 1;

  if (lockAspectRatio.value) {
    if (minW / minH > ratio) {
      minH = minW / ratio;
    } else {
      minW = ratio * minH;
    }
  }

  boundsRef.maxLeft = left.value + width.value - minW;
  boundsRef.maxRight = right.value + width.value - minW;
  boundsRef.maxTop = top.value + height.value - minH;
  boundsRef.maxBottom = bottom.value + height.value - minH;
}

function savePosition(e) {
  beforePosition.pageX = e.pageX;
  beforePosition.pageY = e.pageY;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;
  beforePosition.bottom = bottom.value;
  beforePosition.width = width.value;
  beforePosition.height = height.value;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.pageY = 0;
  beforePosition.width = 0;
  beforePosition.height = 0;

  bounds.value = {
    maxLeft: null,
    maxRight: null,
    maxTop: null,
    maxBottom: null,
  };
}

function restrictToBounds(value, max) {
  if (max !== null && max < value) {
    return max;
  }
  return value;
}

function computeWidth(parentWidth, left, right) {
  return parentWidth - left - right;
}

function computeHeight(parentHeight, top, bottom) {
  return parentHeight - top - bottom;
}

function shouldShowHandler(handler) {
  if (props.active) {
    if (
      (handler[0] === "m" && height.value < 50) ||
      (handler[1] === "m" && width.value < 50)
    ) {
      return false;
    }
    if (
      (resizing.value && currentHandler.value !== handler) ||
      dragging.value ||
      rotating.value
    ) {
      return false;
    }
    return true;
  }
  return false;
}

function submit() {
  commit();
  updateDraft();
}

function applyAllSubtitle(key, value) {
  const node = props.node;
  const subtitles = node.parent.children.filter((s) => s !== node);

  for (const subtitle of subtitles) {
    subtitle.conf[key] = value;
  }
}

function handleKeyDown(e) {
  const { x, y } = props;

  switch (e.code) {
    case "Delete":
    case "Backspace":
      removeActiveNodes();
      submit();
      e.stopPropagation();
      break;
    case "ArrowLeft":
      emit("update:x", x - 1);
      isSubtitle.value && applyAllSubtitle("x", x - 1);
      submit();
      e.stopPropagation();
      break;
    case "ArrowRight":
      emit("update:x", x + 1);
      isSubtitle.value && applyAllSubtitle("x", x + 1);
      submit();
      e.stopPropagation();
      break;
    case "ArrowUp":
      emit("update:y", y - 1);
      isSubtitle.value && applyAllSubtitle("y", y - 1);
      submit();
      e.stopPropagation();
      break;
    case "ArrowDown":
      emit("update:y", y + 1);
      isSubtitle.value && applyAllSubtitle("y", y + 1);
      submit();
      e.stopPropagation();
      break;
  }
}

function getWidgetShift() {
  const target = props.mask || props.node;
  const anchor = target.getAnchor();
  const shiftX = width.value * anchor.x;
  const shiftY = height.value * anchor.y;
  return [shiftX, shiftY];
}

function fitWidgetRect() {
  const target = props.mask || props.node;
  const [nodeX, nodeY] = target.getXY();
  const [nodeWidth, nodeHeight] = target.getWH();
  const nodeAnchor = target.getAnchor();
  const nodeRotation = target.getRotation();
  const nodeTop = nodeY - nodeHeight * nodeAnchor.y;
  const nodeLeft = nodeX - nodeWidth * nodeAnchor.x;

  const { scaleX, scaleY } = creator.value;
  const newTop = nodeTop * scaleY;
  const newLeft = nodeLeft * scaleX;
  const newWidth = nodeWidth * scaleX;
  const newHeight = nodeHeight * scaleY;

  left.value = newLeft;
  top.value = newTop;
  width.value = newWidth;
  height.value = newHeight;
  rotation.value = nodeRotation;
  aspectRatio.value = width.value / height.value;
  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;

  // console.log('[fitWidget]', { newLeft, newTop, nodeWidth, newWidth });
}

function getNodeXY(x, y) {
  const { scaleX, scaleY } = creator.value;
  const [shiftX, shiftY] = getWidgetShift();
  const nodeX = (x + shiftX) / scaleX;
  const nodeY = (y + shiftY) / scaleY;
  return [nodeX, nodeY];
}

function getNodeSize(width, height) {
  const { scaleX, scaleY } = creator.value;
  const nodeWidth = width / scaleX;
  const nodeHeight = height / scaleY;
  return [nodeWidth, nodeHeight];
}

function getNodeScale(width) {
  const target = props.mask || props.node;
  const { scaleX } = creator.value;
  const texture = target.getTexture();

  if (!texture) {
    return 1;
  }
  const nodeWidth = width / scaleX;
  const scale = nodeWidth / texture.width;
  return scale;
}

function handleDblclick() {
  const { active, node, mask } = props;

  if (active && mask && attrTabMap[node.id]) {
    attrTabMap[node.id] = "props";
  }
}

function handleContextMenu(e) {
  const node = props.node;

  setTimeout(() => {
    widgetMenu.visible = true;
    widgetMenu.left = e.pageX;
    widgetMenu.top = e.pageY;
  });
  if (activeNodeMap.has(node.id)) {
    return;
  }
  setTimeout(() => setActiveNode(node));
}
</script>
<style scoped>
.widget {
  position: absolute;
  z-index: 0;
}
.widget.active {
  border: 1px solid #875eff;
  z-index: 1;
}
.handler {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #875eff;
}
.handler-rotate {
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  left: 50%;
  transform: translate(-50%, 100%);
  bottom: -8px;
  cursor: pointer;
  position: absolute;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.08);
}
.handler-rotate img {
  width: 18px;
  height: 18px;
}
.handler-tm,
.handler-bm {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: ns-resize;
}
.handler-ml,
.handler-mr {
  height: 32px;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: ew-resize;
}
.handler-tl,
.handler-tm,
.handler-tr {
  top: -5px;
}
.handler-tl,
.handler-ml,
.handler-bl {
  left: -5px;
}
.handler-tr,
.handler-mr,
.handler-br {
  right: -5px;
}
.handler-bl,
.handler-bm,
.handler-br {
  bottom: -5px;
}
.handler-tl,
.handler-br {
  cursor: nwse-resize;
}
.handler-tr,
.handler-bl {
  cursor: nesw-resize;
}
</style>
