import { useTrackStore } from "@/store/modules/track";
// 点击事件实例
class Click {
  add(el, value) {
    const { track } = useTrackStore();
    if (value) {
      const en = value;
      el.addEventListener("click", () => {
        track(en);
      });
    } else {
      console.log("have not bound event");
    }
  }
}

// 曝光事件实例
class Exposure {
  add(el, value) {
    const { track } = useTrackStore();
    if (value) {
      const en = value;
      track(en);
    } else {
      console.log("havent bound event");
    }
  }
}

const cli = new Click();
const exp = new Exposure();

export default {
  mounted(el, { arg, value }, vNode) {
    switch (arg) {
      case "click":
        cli.add(el, value);
        break;
      case "exposure":
        exp.add(el, value);
        break;
      default:
        break;
    }
  },
};
