import router from "@/router/router";
import type { ResponseType } from "./https";

type ApiFn<T = any> = (...args: any[]) => Promise<ResponseType<T>>;

export function apiCall<
  T extends ApiFn,
  R = T extends ApiFn<infer R> ? R : never,
>(apiFn: T, ...args: Parameters<T>) {
  return new Promise<R>((res, rej) => {
    apiFn(...args).then(({ code, data, msg }) => {
      code === 0 ? res(data) : rej({ code, error: new Error(msg) });
    });
  });
}

export function apiApply<
  T extends ApiFn,
  R = T extends ApiFn<infer R> ? R : never,
>(apiFn: T, args: Parameters<T>) {
  return apiCall<T, R>(apiFn, ...args);
}

export function apiBind<
  T extends ApiFn,
  R = T extends ApiFn<infer R> ? R : never,
>(apiFn: T) {
  return (...args: Parameters<T>) => apiCall<T, R>(apiFn, ...args);
}

export async function downloadFile(url: string, filename: string) {
  const response = fetch(url);
  const blob = await response.then((res) => res.blob());
  const a = document.createElement("a");

  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  URL.revokeObjectURL(a.href);
}
