<template>
  <!-- Card container -->
  <div class="card-box">
    <div
      class="card-container"
      v-for="card in displayCardList"
      :key="card.name"
    >
      <PricingCardItem
        :card="card"
        :active="currentHightLightCard == card.packageCode"
        :subscriptionState="subscriptionState"
        :isLoading="isLoading"
        :userOperationMap="userOperationMap"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PricingCardItem from './pricing-card-item.vue';
import type { CardItemInfo } from '../../type';
import { useSubscriptionInfo } from '@/store/modules/user';

const { subscriptionState } = storeToRefs(useSubscriptionInfo());

const props = defineProps({
  cardList: {
    type: Array<CardItemInfo>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
});

const currentPlan = ref('');
const currentPackageCode = ref(-1);
const starterCodes = [300401, 300402];

const subscribeList = [
  {
    packageCode: -1,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300401,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300402,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300201,
    highLightPackageCode: 300301,
    highLightCardName: "Pro",
    cycleType: "month"
  },
  {
    packageCode: 300301,
    highLightPackageCode: 300202,
    highLightCardName: "Standard",
    cycleType: "year"
  },
  {
    packageCode: 300202,
    highLightPackageCode: 300302,
    highLightCardName: "Pro",
    cycleType: "year"
  },
  {
    packageCode: 300302,
    highLightPackageCode: 10,
    highLightCardName: "Pro",
    cycleType: "year"
  }, 
  {
    packageCode: 300401,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300402,
    highLightPackageCode: 300202,
    highLightCardName: "Standard",
    cycleType: "year"
  }
]

const currentHightLightCard = computed(() => {
  return subscribeList.find(
    (item) => item.packageCode == currentPackageCode.value
  )?.highLightPackageCode;
});

const displayCardList = computed(() => {
  const { currentPlanName, packageCode } = subscriptionState.value;
  currentPlan.value = currentPlanName;
  currentPackageCode.value = packageCode;
  if (starterCodes.includes(packageCode)) {
    if(!props.cardList) {
      return
    }
    const list = [props.cardList[0], props.cardList[1]];
    return list;
  } else {
    return props.cardList.filter(
      (item) => item.name !== 'Starter' && item.name !== 'Ultra'
    );
  }
});
</script>

<style lang="scss" scoped>
.card-box {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  margin: 18px 0;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 24px 36px;
  height: 638px;
  width: 100%;
  align-items: center;
  background-color: #ffffff;

  &:first-child {
    border-right-width: 1px;
    border-right-color: #e5e7eb;
  }
}

.active-border {
  border: 2px solid #6741ff;
}

.card-box {
  grid-template-columns: 1fr 1fr;
}
</style>
