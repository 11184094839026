<template>
  <div class="visuals-upload-container">
    <el-upload
      class="upload-box"
      drag
      multiple
      :before-upload="handlePreUpload"
      :http-request="handleUpload"
      :accept="accept"
      :show-file-list="false"
    >
      <svg-icon
        name="icon_common_upload"
        :style="{ width: '46px', height: '58px', color: '#FFE68E' }"
      ></svg-icon>

      <p class="click-text">Click or drop visuals here</p>
      <p class="upload-text" @click.stop="handleOpenCloud">Or add from cloud</p>
      <div class="tip-container">
        <p class="tip">Up to 12 images or 5 videos ( each within 30s )</p>
        <p class="tip">
          Visuals over 1080P will be compressed and take more time
        </p>
      </div>
    </el-upload>

    <div class="case-container" :style="{ opacity: showCase ? 1 : 0 }">
      <example-guide :list="caseList" @select="handleSelect" />
    </div>

    <CloudAssetModal
      :visible="isOpenAssetDialog"
      @close="handleCloseAssetDialog"
      @confirm="handleConfirm"
      :filterTypes="['image', 'video']"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { IMAGEANDVIDEOTYPE } from '@/utils/type.ts';
import CloudAssetModal from '@/components/asset-modal/cloudAssetDialog/index.vue';
import ExampleGuide from './components/example-guide/index.vue';
import { getFormCaseList } from '@/api/index';
import { sessionRemoveItem, sessionGetItem } from '@/utils/storage';

import { useSingleMessage } from '@/utils';
import { useTrackStore } from '@/store/modules/track';
const { clearEventData, collectData, track } = useTrackStore();

const props = defineProps({
  uploadComplete: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits([
  'onReadFileComplete',
  'onSelectCase',
  'onLoading',
  'onSelectCloud',
]);

const accept = ref(IMAGEANDVIDEOTYPE);
const isOpenAssetDialog = ref(false);
const imageList = ref([]);

const fileList = ref([]);

const caseList = ref([{}, {}, {}, {}, {}, {}]);
const showCase = ref(false);
const timer = ref(null);
const videoLength = ref(0);
const imageLength = ref(0);
const checkFileList = ref([]);
const uploadLimit = {
  number: 5,
  duration: 30,
};

const Message = useSingleMessage();

const handleOpenCloud = () => {
  checkFileList.value = [];
  isOpenAssetDialog.value = true;
};

const handleCloseAssetDialog = () => {
  isOpenAssetDialog.value = false;
};

const handleConfirm = (list) => {
  imageList.value = list;
  emit('onSelectCloud', imageList.value);
};

const handleSelect = (item) => {
  const imageList = item.materials.map((item) => {
    return {
      ...item,
      url: item.preview480Url,
      case: true,
    };
  });
  emit('onSelectCase', imageList, {
    productDesc: item.productDescription,
    caseId: item.caseId,
    caseMaterial: item.materials,
  });
};

const getCaseList = async () => {
  const { data } = await getFormCaseList();
  caseList.value = data.map((item) => {
    return {
      ...item,
      case: true,
    };
  });

  showCase.value = true;
};

// 上传视频限制
const checkVideoSizeAndLength = (file) => {
  return new Promise((resolve) => {
    const isVideo = file.type.includes('video');

    if (!isVideo) {
      imageLength.value += 1;
      resolve(true);
      return;
    }

    let video = document.createElement('video');
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = function (e) {
      const duration = video.duration;
      if (duration > uploadLimit.duration) {
        resolve(false);
      } else {
        if (videoLength.value >= uploadLimit.number) {
          resolve(false);
        } else {
          resolve(true);
        }
      }

      videoLength.value += 1;
    };

    video.onerror = (e) => {
      resolve(true);
      videoLength.value += 1;
    };
  });
};

const handlePreUpload = (file) => {
  emit('onLoading', true);
  fileList.value.push(file);

  if (timer.value) {
    return;
  }

  timer.value = setTimeout(() => {
    videoLength.value = 0;
    imageLength.value = 0;
    fileList.value.forEach(async (file) => {
      const data = await checkVideoSizeAndLength(file);
      checkFileList.value.push(data);
    });
  }, 1000);
};

const reset = () => {
  checkFileList.value = [];
  clearTimeout(timer.value);
  timer.value = null;
  videoLength.value = 0;
  imageLength.value = 0;
};
watch(
  () => checkFileList.value,
  () => {
    if (
      fileList.value.length == checkFileList.value.length &&
      fileList.value.length
    ) {
      // 存在一个条件不满足，阻断上传
      const idx = checkFileList.value.findIndex((item) => !item);
      if (idx >= 0) {
        reset();
        fileList.value = [];
        Message.error(
          ` Upload failed. Max ${uploadLimit.number} videos, each video < ${uploadLimit.duration}S.`,
          { duration: 2000 }
        );
        emit('onLoading', false);
        return;
      }

      if (imageLength.value > 12) {
        reset();
        fileList.value = [];
        Message.error(
          ` Upload failed. Max ${uploadLimit.number} videos, each video < ${uploadLimit.duration}S.`,
          { duration: 2000 }
        );

        emit('onLoading', false);
        return;
      }

      reset();

      emit('onLoading', false);
      emit('onReadFileComplete', fileList.value);
      fileList.value = [];
    }
  },
  {
    deep: true,
  }
);

const handleUpload = (file) => {};

onMounted(() => {
  getCaseList();
  sessionRemoveItem('cacheForm');
  sessionRemoveItem('caseInfo');


onMounted(() => {
  collectData('boolvideo_create_upload_result', {
    video_type: 'visuals_to_video',
    click_type: sessionGetItem('create_from'),
    result: 'success'
  });
  track('boolvideo_create_upload_result');
});

});

onBeforeUnmount(() => {
  fileList.value = [];
});
</script>

<style lang="scss">
.visuals-upload-container {
  .el-upload:focus .el-upload-dragger {
    border: 1px solid #d5d6d7;
  }

  .el-upload-dragger {
    border-radius: 4px;
    border: 1px solid #d5d6d7;
    background: #fff;
    height: 355px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;

    &:hover {
      background: #f8f9fd;
    }
  }
}
</style>

<style lang="scss" scoped>
.visuals-upload-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tip {
    font-size: 12px;
    line-height: 20px;
    color: #646a73;
  }
}

.upload-box {
  margin-top: 130px;
  width: 60%;
  max-width: 710px;
  min-width: 400px;
  height: 355px;
}

.craw-container {
  :deep(.el-input__inner) {
    height: 54px;
    width: 100%;
  }
}

.tip-container {
  position: absolute;
  bottom: 30px;
  width: 384px;
  height: 64px;
  margin-top: 40px;
  border-radius: 4px;
  border: 0.5px solid #f0f0f0;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes slide-up {
  0% {
    transform: translateY(50%);
    animation-timing-function: ease-out;
    opacity: 0.5;
  }

  60% {
    transform: translateY(-10%);
    animation-timing-function: ease-in;
    opacity: 1;
  }

  80% {
    transform: translateY(0%);
    animation-timing-function: ease-out;
  }

  90% {
    transform: translateY(3%);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateY(0%);
  }
}

.case-container {
  width: 60%;
  max-width: 710px;
  min-width: 400px;
  // min-height: 182px;
  margin-top: 79px;
  padding-bottom: 80px;
  animation: slide-up 0.5s ease-out;
}

.click-text {
  color: #060606;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
}

.upload-text {
  color: #6741ff;
  text-align: center;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 12px;
}
</style>
