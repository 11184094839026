<template>
  <div
    v-show="showTransitionAdder"
    ref="element"
    class="transition-adder"
    :style="style"
    @mousemove.stop
    @mouseup.stop
    @click="handleClick"
  >
    <bv-tip content="Add transition" placement="top" :show-after="0">
      <svg-icon name="editor_segment_transition" color="#000" :size="18" />
    </bv-tip>
    <Teleport to="body">
      <div
        v-show="showPopover"
        v-click-outside:[element]="hide"
        class="transition-popover"
        :style="popoverStyle"
      >
        <el-scrollbar>
          <el-skeleton animated :loading="loading">
            <template #template>
              <div v-for="(_, i) in Array(16)" class="skeleton-item" :key="i">
                <el-skeleton-item variant="rect" />
                <el-skeleton-item variant="text" />
              </div>
            </template>
            <div class="file-list">
              <div
                v-for="(file, i) in files"
                class="file-item"
                :key="i"
                @mouseenter="hoverFile = file"
                @mouseleave="hoverFile = null"
              >
                <div class="image-wrapper" @click="add(file)">
                  <img
                    loading="lazy"
                    draggable="false"
                    :src="hoverFile === file ? file.dynamicPic : file.coverPic"
                  />
                </div>
                <div class="title">{{ file.name }}</div>
              </div>
            </div>
          </el-skeleton>
        </el-scrollbar>
      </div>
    </Teleport>
  </div>
</template>
<script setup>
import { useCreatorStore, useHistoryStore, useDraftStore } from "../../stores";
import { getTransitionList } from "@/api/resources";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  x: {
    type: Number,
    default: 0,
  },
});

const { showTransitionAdder, showPopover, addTransitionNode, widthToFrame } =
  useCreatorStore();
const { commit } = useHistoryStore();
const { updateDraft } = useDraftStore();
const element = ref(null);
const hoverFile = ref(null);
const files = ref([]);
const loading = ref(false);
const left = ref(0);
const top = ref(0);

const style = computed(() => ({
  left: `${props.x}px`,
}));
const popoverStyle = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
}));

function submit() {
  commit();
  updateDraft();
}

async function add(file) {
  await addTransitionNode(
    {
      name: file.name,
      sourceId: file.id,
    },
    widthToFrame(props.x),
    { shouldRefresh: true }
  );
  submit();
  hide();
}

async function getTransitions() {
  loading.value = true;
  const { success, data } = await getTransitionList();

  if (success) {
    files.value = data;
  }
  loading.value = false;
}

function handleClick() {
  const bound = element.value.getBoundingClientRect();

  showPopover.value = true;
  left.value = bound.x;
  top.value = bound.y - 404;

  if (files.value.length === 0) {
    getTransitions();
  }
}

function hide() {
  showPopover.value = false;
  showTransitionAdder.value = false;
}
</script>
<style scoped>
.transition-adder {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 3px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  z-index: 3;
}
.transition-popover {
  position: fixed;
  z-index: 1000;
  width: 360px;
  height: 400px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  box-shadow: 0 4px 30px #0000001f;
}
.transition-popover .file-list,
.transition-popover .el-skeleton {
  padding: 18px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
}
.transition-popover .skeleton-item {
  display: flex;
  flex-direction: column;
}
.transition-popover .el-skeleton__rect {
  width: 100%;
  height: 70px;
  border-radius: 4px;
}
.transition-popover .image-wrapper {
  width: 100%;
  height: 70px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
.transition-popover .el-skeleton__text {
  margin: 4px 0;
  height: 12px;
}
.transition-popover .image-wrapper:hover {
  border-width: 2px;
  border-color: #875eff;
}
.transition-popover .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.transition-popover .title {
  padding: 0 2px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
</style>
