<template>
  <el-popover
    popper-class="script-effect-popper"
    width="356"
    placement="bottom-end"
    :visible="visible"
    :show-arrow="false"
    :popper-options="{
      modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
    }"
  >
    <template #reference>
      <icon-button
        name="script_effect"
        tip="Effect"
        :size="18"
        :tipDelay="0"
        @click="handleClick"
      />
    </template>
    <div v-click-outside="() => (visible = false)" class="tab-container">
      <div v-show="!isLast" class="tab-wrapper">
        <div class="tab-header">
          <div
            v-for="item in tabs"
            class="tab-name"
            :class="{ active: item.name === currentTab }"
            @click="currentTab = item.name"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="tab-content">
        <effect-list
          v-model="loading"
          v-show="currentTab === 'effect'"
          :visible="visible"
          :scene="scene"
        />
        <transition-list
          v-model="loading"
          v-show="currentTab === 'transition'"
          :current-tab="currentTab"
          :scene="scene"
        />
        <transition name="fade">
          <div class="loading" v-show="loading">
            <svg-icon name="editor_loading" :size="24" />
          </div>
        </transition>
      </div>
    </div>
  </el-popover>
</template>
<script setup>
import { useScriptStore } from "../../stores/script";
import TransitionList from "./transition-list.vue";
import EffectList from "./effect-list.vue";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  isLast: {
    type: Boolean,
    default: false,
  },
});

const { pause, setActiveScene } = useScriptStore();
const currentTab = ref("effect");
const visible = ref(false);
const loading = ref(false);
const tabs = [
  {
    title: "Effects",
    name: "effect",
  },
  {
    title: "Transitions",
    name: "transition",
  },
];

async function handleClick() {
  visible.value = true;
  setActiveScene(props.scene);
  await pause();
}
</script>

<style lang="scss">
.script-effect-popper .tab-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.script-effect-popper .tab-wrapper {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 24px;
}
.script-effect-popper .tab-content {
  flex-grow: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.script-effect-popper .tab-header {
  height: 28px;
  display: flex;
  background-color: #f3f5f7;
  border: 0.5px solid #e5e7eb;
  border-radius: 6px;
  padding: 1px;
}
.script-effect-popper .tab-name {
  width: 106px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}
.script-effect-popper .tab-name.active {
  box-shadow:
    0px 0.25px 0.25px 0px #e5e7eb,
    0px 1px 0.75px 0px #e5e7eb;
  background-color: #fff;
  color: #6741ff;
}

.script-effect-popper .loading {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.88);
}
.script-effect-popper .loading svg {
  animation: rotate 1s linear infinite;
}
.script-effect-popper.el-popper.el-popper {
  height: 427px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
  padding: 0;
}
.script-effect-popper .fade-enter-active,
.script-effect-popper .fade-leave-active {
  transition: opacity 300ms;
}

.script-effect-popper .fade-enter-from,
.script-effect-popper .fade-leave-to {
  opacity: 0;
}
</style>
