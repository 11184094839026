<template>
  <common-button
    v-bind="commonProps"
    type="primary"
    :class="{
      'bv-primary-button': !disabled,
      'bv-primary-button-disabled': disabled,
      'bv-primary-button-shadow': shadow,
    }"
  >
    <slot name="preIcon" v-if="$slots.preIcon"></slot>
    <template v-if="loading">
      <slot v-if="$slots.loading" name="loading"></slot>
      <el-icon v-else :class="ns.is('loading')">
        <component :is="loadingIcon" />
      </el-icon>
    </template>
    <el-icon v-else-if="icon || $slots.icon">
      <component :is="icon" v-if="icon" />
      <slot v-else name="icon"></slot>
    </el-icon>
    <span v-if="$slots.default" class="flex">
      <slot></slot>
    </span>
    <slot name="suffixIcon" v-if="$slots.suffixIcon"></slot>
  </common-button>
</template>

<script setup lang="ts">
import CommonButton from "./common-button.vue";
import { type PropType, toRefs, reactive } from "vue";
import { buttonProps, useNamespace } from "element-plus";
import type { ButtonType } from "../type";

const ns = useNamespace("button");
const props = defineProps({
  ...buttonProps,
  shadow: Boolean,
  padding: String,
  fontColor: String,
  size: {
    type: String as PropType<ButtonType>,
    default: "default",
  },
  round: {
    type: Boolean,
    default: true,
  },
  onclick: Function as PropType<(e: MouseEvent) => void>,
});

const { shadow, ..._commonProps } = toRefs(props);
const commonProps = reactive(_commonProps);
</script>

<style lang="scss" scoped>
.bv-primary-button {
  :deep(.el-button--primary) {
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;
  }

  :deep(.el-button) {
    border: none;
    background: #fff;
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;

    span {
      color: #060606;
    }

    &:hover {
      background: #ebedef;
      color: #060606;
    }
  }
}

.bv-primary-button-disabled {
  :deep(.el-button--primary) {
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;
    --el-button-disabled-bg-color: none !important;
  }

  :deep(.el-button) {
    border: none;
    background: #fff !important;
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;

    span {
      color: #bbbfc4 !important;
    }
  }
}

.bv-primary-button-shadow {
  :deep(.el-button--primary) {
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.1);
  }
}
</style>
