import { _get, _post, _put, _delete } from '@/utils/https';

const urlList = {
  sessionList: '/ai/assistant/session/page',
  createSession: '/ai/assistant/session',
  updateSession: '/ai/assistant/session',
  deleteSession: '/ai/assistant/session',
  upload: '/ai/assistant/session/save_to_myspace',
};

const { VITE_API_BASE } = import.meta.env;

type Session = {
  content: string;
  name: string;
  sessionId?: string;
};

type Pagination = {
  page: number;
  size: number;
};

export function sessionList(params: Pagination) {
  return _post(VITE_API_BASE + urlList.sessionList, params);
}

export function createSession(params: Session) {
  return _post(VITE_API_BASE + urlList.createSession, params);
}

export function updateSession(params: Session) {
  return _put(VITE_API_BASE + urlList.updateSession, params);
}

export function deleteSession(sessionId: number) {
  return _delete(VITE_API_BASE + `${urlList.deleteSession}/${sessionId}`);
}

export function assistantSaveMaterial(params: object) {
  return _post(VITE_API_BASE + urlList.upload, params);
}
