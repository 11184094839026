<template>
  <div
    :class="['bv-space', `space-${direction}`, reversed ? 'space-reverse' : '']"
    :style="spaceStyle"
  >
    <template v-for="(vnode, i) of vnodes">
      <template v-if="i !== 0">
        <span v-if="spacer === null" class="default-spacer"></span>
        <v-render v-else :vnode="spacer" />
      </template>
      <v-render :vnode="vnode" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useSlots, computed } from "vue";
import type { PropType, VNode } from "vue";

const slots = useSlots();
const porps = defineProps({
  direction: {
    type: String as PropType<"row" | "column">,
    default: "row",
  },
  reversed: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 0,
  },
  alignment: {
    type: String as PropType<"start" | "end" | "center">,
    default: "",
  },
  spacer: {
    type: Object as PropType<VNode>,
    default: null,
  },
});

const vnodes = computed(() => {
  return slots.default?.() || [];
});

const spaceStyle = computed(() => ({
  alignItems: porps.alignment,
  "--spacer-size": `${porps.size}px`,
}));
</script>

<style lang="scss" scoped>
.default-spacer {
  display: inline-block;
}

.bv-space {
  display: flex;
  align-items: center;
}

.space-row {
  flex-direction: row;

  & > .default-spacer {
    width: var(--spacer-size, 0);
  }
}

.space-row.space-reverse {
  flex-direction: row-reverse;
}

.space-column {
  flex-direction: column;

  & > .default-spacer {
    height: var(--spacer-size, 0);
  }
}
</style>
