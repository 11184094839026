import { useSubscriptionInfo } from "@/store/modules/user.js";
import { EventListener } from "./common";

type AuthListeners = {
  loaded: ((authenticator: Authenticator) => void)[];
  refreshed: ((authenticator: Authenticator) => void)[];
};

class Authenticator extends EventListener<AuthListeners> {
  store: ReturnType<typeof useSubscriptionInfo> | null = null;

  constructor() {
    super({
      loaded: [],
      refreshed: [],
    });
  }

  /** 绑定事件 (覆写) */
  on<K extends keyof AuthListeners>(
    event: K,
    callback: ArrayItemType<AuthListeners[K]>,
  ) {
    if (this.store !== null) {
      if (event === "loaded") {
        callback(this);
      }
    } else {
      super.on(event, callback);
    }
  }

  /** 刷新权限 */
  async refresh() {
    if (this.store === null) {
      await loadSubscriptionInfoStore();
      return;
    }

    await new Promise((resolve) => {
      this.on("loaded", resolve);
    });

    await this.store.refresh();

    if (this.store !== null) {
      this.emit("refreshed", this);
    }
  }

  /** 检查权限 */
  async authenticate(permission: string) {
    if (permission === "") {
      return true;
    }

    await new Promise((resolve) => {
      this.on("loaded", resolve);
    });

    const usedPermission = permission.toUpperCase();
    const resource = this.store!.userResourceList.find(
      (item) => item.resourceCode === usedPermission,
    );
    return resource !== void 0 && (resource.unlimitedNum || resource.num > 0);
  }
}

export const authenticator = new Authenticator();

async function loadSubscriptionInfoStore() {
  const store = useSubscriptionInfo();

  store.refresh().finally(() => {
    authenticator.store = store;
    authenticator.emit("loaded", authenticator);
    authenticator.off("loaded");
  });
}
