/**
 *  imageData缩放
 * @param {*} imageData
 * @param {*} width
 * @param {*} height
 */

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const resizeImageData = async (imageData, width, height) => {
  const resizeWidth = width;
  const resizeHeight = height;
  const ibm = await createImageBitmap(
    imageData,
    0,
    0,
    imageData.width,
    imageData.height,
    {
      resizeWidth,
      resizeHeight,
    }
  );
  const canvas = document.createElement('canvas');
  canvas.width = resizeWidth;
  canvas.height = resizeHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(ibm, 0, 0, resizeWidth, resizeHeight);
  return ctx.getImageData(0, 0, resizeWidth, resizeHeight);
};

const convertCanvasToImage = (canvas) => {
  var image = new Image();
  image.src = canvas.toDataURL('image/png');
  return image;
};

const downloadFn = (dom, name) => {
  const image = convertCanvasToImage(dom);
  var a = document.createElement('a');
  a.href = image.src;
  a.download = name || 'remover-background.png';
  a.click();
  window.URL.revokeObjectURL(image.src);
};

const dataURLtoFile = (dataurl, filename) => {
  //将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const transformFile = (canvas, fileName) => {
  const src = canvas.toDataURL('image/png');
  const file = dataURLtoFile(src, fileName);
  return file;
};

const downloadWithUrl = (url, name) => {
  var a = document.createElement('a');
  a.href = url;
  a.download = name || `${Date.now()}.png`;
  a.click();
  window.URL.revokeObjectURL(url);
};

const createCanvasCtx = (size, id) => {
  let tempCtx = null;
  var tempCanvas = null;

  if (id) {
    tempCanvas = document.getElementById(id);
    if (!tempCanvas) {
      return;
    }

    tempCanvas.width = size?.width || 0;
    tempCanvas.height = size?.height || 0;
    tempCtx = tempCanvas.getContext('2d');
  } else {
    tempCanvas = document.createElement('canvas');
    tempCanvas.width = size?.width || 0;
    tempCanvas.height = size?.height || 0;
    tempCtx = tempCanvas.getContext('2d');
  }
  return tempCtx;
};

const getImageScaleSize = (imgWidth, imgHeight, refSize = 512) => {
  let imgResizeHeight;
  let imgResizeWidth;
  if (
    Math.max(imgHeight, imgWidth) < refSize ||
    Math.min(imgHeight, imgWidth) > refSize
  ) {
    if (imgWidth >= imgHeight) {
      imgResizeHeight = refSize;
      imgResizeWidth = Math.round((imgWidth / imgHeight) * refSize);
    } else {
      imgResizeWidth = refSize;
      imgResizeHeight = Math.round((imgHeight / imgWidth) * refSize);
    }
  } else {
    imgResizeHeight = imgHeight;
    imgResizeWidth = imgWidth;
  }

  imgResizeWidth = imgResizeWidth - (imgResizeWidth % 32);
  imgResizeHeight = imgResizeHeight - (imgResizeHeight % 32);

  return {
    width: imgResizeWidth,
    height: imgResizeHeight,
  };
};

const clearCanvas = (id) => {
  const element = document?.getElementById(id);
  if (!element) {
    return;
  }
  const ctx = element.getContext('2d');
  if (!ctx) {
    return;
  }
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
};

const showImage = (originSize, resizeSize, containerBox) => {
  const originWidth = originSize.width;
  const originHeight = originSize.height;
  const resizeSizeWidth = resizeSize.width;
  const resizeSizeHeight = resizeSize.height;
  let imgMultiple = 1;
  if (originWidth > resizeSizeWidth || originHeight > resizeSizeHeight) {
    // 长 > 宽
    if (resizeSizeWidth / originWidth > resizeSizeHeight / originHeight) {
      if (
        resizeSizeWidth / originWidth < 1 &&
        resizeSizeHeight / originHeight < 1
      ) {
        imgMultiple = resizeSizeHeight / originHeight;
      } else if (
        resizeSizeWidth / originWidth >= 1 &&
        resizeSizeHeight / originHeight < 1
      ) {
        imgMultiple = resizeSizeHeight / originHeight;
      }
    } else if (
      resizeSizeWidth / originWidth <
      resizeSizeHeight / originHeight
    ) {
      // 宽 > 长
      if (
        resizeSizeWidth / originWidth <= 1 &&
        resizeSizeHeight / originHeight < 1
      ) {
        imgMultiple = resizeSizeWidth / originWidth;
      } else if (
        resizeSizeWidth / originWidth < 1 &&
        resizeSizeHeight / originHeight > 1
      ) {
        imgMultiple = resizeSizeWidth / originWidth;
      }
    } else {
      imgMultiple = resizeSizeWidth / originWidth;
    }
  } else if (
    originWidth <= resizeSizeWidth &&
    originHeight <= resizeSizeHeight
  ) {
    // 长 > 宽
    if (resizeSizeWidth / originWidth > resizeSizeHeight / originHeight) {
      imgMultiple = resizeSizeHeight / originHeight;
    } else if (
      resizeSizeWidth / originWidth <
      resizeSizeHeight / originHeight
    ) {
      imgMultiple = resizeSizeWidth / originWidth;
    } else {
      imgMultiple = 1;
    }
  }
  const imgWidth = imgMultiple * originWidth;
  const imgHeight = imgMultiple * originHeight;
  const imgX = (containerBox.width - imgWidth) / 2;
  const imgY = (containerBox.height - imgHeight) / 2;
  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight,
  };
};

const resizeImage = (imageData, ratio) => {
  const originWidth = imageData.width;
  const originHeight = imageData.height;
  const ratioList = ratio.split(':');
  const ratioNumber = Number(ratioList[0]) / Number(ratioList[1]);
  // 裁剪原则: 以中心为基准点，修改比例小的一侧

  if (
    ratioNumber >= 1 &&
    (originWidth * Number(ratioList[1])) / Number(ratioList[0]) < originHeight
  ) {
    return {
      width: originWidth,
      height: Math.floor(
        (originWidth * Number(ratioList[1])) / Number(ratioList[0])
      ),
      sx: 0,
      sy:
        (originHeight -
          (originWidth * Number(ratioList[1])) / Number(ratioList[0])) /
        2,
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: originWidth,
      dHeight: originHeight,
    };
  }

  return {
    width: Math.floor(
      (originHeight * Number(ratioList[0])) / Number(ratioList[1])
    ),
    height: originHeight,
    sx:
      (originWidth -
        (originHeight * Number(ratioList[0])) / Number(ratioList[1])) /
      2,
    sy: 0,
    sWidth: originWidth,
    sHeight: originHeight,
    dx: 0,
    dy: 0,
    dWidth: originWidth,
    dHeight: originHeight,
  };
};

const resizeImageWidthOrHeight = (imageData, imageSize) => {
  const originWidth = imageData.width;
  const originHeight = imageData.height;
  const resultWidth = imageSize.width;
  const resultHeight = imageSize.height;
  if (resultWidth <= originWidth && resultHeight <= originHeight) {
    return {
      width: resultWidth,
      height: resultHeight,
      sx: (originWidth - resultWidth) / 2,
      sy: (originHeight - resultHeight) / 2,
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: originWidth,
      dHeight: originHeight,
    };
  }
  if (resultWidth < originWidth && resultHeight > originHeight) {
    const resizeWidth = (originWidth * resultHeight) / originHeight;
    return {
      width: resultWidth,
      height: resultHeight,
      sx:
        Math.abs(resizeWidth - originWidth) / ((2 * resizeWidth) / originWidth),
      sy: 0,
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: resizeWidth,
      dHeight: resultHeight,
    };
  }
  if (resultWidth > originWidth && resultHeight < originHeight) {
    const resizeHeight = (originHeight * resultWidth) / originWidth;
    return {
      width: resultWidth,
      height: resultHeight,
      sx: 0,
      sy:
        Math.abs(resizeHeight - originHeight) /
        ((2 * resizeHeight) / originHeight),
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: resultWidth,
      dHeight: resizeHeight,
    };
  }
  if (resultWidth > originWidth && resultHeight > originHeight) {
    const ratioWidth = resultWidth / originWidth;
    const ratioHeight = resultHeight / originHeight;
    if (ratioWidth > ratioHeight) {
      const resizeHeight = (originHeight * resultWidth) / originWidth;
      return {
        width: resultWidth,
        height: resultHeight,
        sx: 0,
        sy:
          Math.abs(resizeHeight - resizeHeight) /
          ((2 * resizeHeight) / originHeight),
        sWidth: originWidth,
        sHeight: originHeight,
        dx: 0,
        dy: 0,
        dWidth: resultWidth,
        dHeight: resizeHeight,
      };
    }

    if (ratioWidth < ratioHeight) {
      const resizeWidth = (originWidth * resultHeight) / originHeight;
      return {
        width: resultWidth,
        height: resultHeight,
        sx:
          Math.abs(resizeWidth - resultWidth) /
          ((2 * resizeWidth) / originWidth),
        sy: 0,
        sWidth: originWidth,
        sHeight: originHeight,
        dx: 0,
        dy: 0,
        dWidth: resizeWidth,
        dHeight: resultHeight,
      };
    }
  }
};

export const gaussBlur = function (imgData, value = 1) {
  var pixes = imgData.data;
  var width = imgData.width;
  var height = imgData.height;
  var gaussMatrix = [],
    gaussSum = 0,
    x,
    y,
    r,
    g,
    b,
    a,
    i,
    j,
    k,
    len;

  var radius = 10;
  var sigma = 5;

  a = 1 / (Math.sqrt(2 * Math.PI) * sigma);
  b = -1 / (2 * sigma * sigma);
  //生成高斯矩阵
  for (i = 0, x = -radius; x <= radius; x++, i++) {
    g = a * Math.exp(b * x * x);
    gaussMatrix[i] = g;
    gaussSum += g;
  }

  //归一化, 保证高斯矩阵的值在[0,1]之间
  for (i = 0, len = gaussMatrix.length; i < len; i++) {
    gaussMatrix[i] /= gaussSum;
  }
  //x 方向一维高斯运算
  for (y = 0; y < height; y++) {
    for (x = 0; x < width; x++) {
      r = g = b = a = 0;
      gaussSum = 0;
      for (j = -radius; j <= radius; j++) {
        k = x + j;
        if (k >= 0 && k < width) {
          //确保 k 没超出 x 的范围
          //r,g,b,a 四个一组
          i = (y * width + k) * 4;
          r += pixes[i] * gaussMatrix[j + radius];
          g += pixes[i + 1] * gaussMatrix[j + radius];
          b += pixes[i + 2] * gaussMatrix[j + radius];
          // a += pixes[i + 3] * gaussMatrix[j];
          gaussSum += gaussMatrix[j + radius];
        }
      }
      i = (y * width + x) * 4;
      // 除以 gaussSum 是为了消除处于边缘的像素, 高斯运算不足的问题
      // console.log(gaussSum)
      pixes[i] = r / gaussSum;
      pixes[i + 1] = g / gaussSum;
      pixes[i + 2] = b / gaussSum;
      // pixes[i + 3] = a ;
    }
  }
  //y 方向一维高斯运算
  for (x = 0; x < width; x++) {
    for (y = 0; y < height; y++) {
      r = g = b = a = 0;
      gaussSum = 0;
      for (j = -radius; j <= radius; j++) {
        k = y + j;
        if (k >= 0 && k < height) {
          //确保 k 没超出 y 的范围
          i = (k * width + x) * 4;
          r += pixes[i] * gaussMatrix[j + radius];
          g += pixes[i + 1] * gaussMatrix[j + radius];
          b += pixes[i + 2] * gaussMatrix[j + radius];
          // a += pixes[i + 3] * gaussMatrix[j];
          gaussSum += gaussMatrix[j + radius];
        }
      }
      i = (y * width + x) * 4;
      pixes[i] = r / gaussSum;
      pixes[i + 1] = g / gaussSum;
      pixes[i + 2] = b / gaussSum;
    }
  }
  return imgData;
};

/**
 * @public
 * 暴露的异步模糊方法
 * ---------------------
 * @param URL       图片地址,需要跨域支持
 * @param r         模糊半径 {Int}
 * @param shrink    缩小比率 {Number}
 * @return {Promise}
 */
export const blur = (URL, r, shrink = 1) => {
  return new Promise((resolve, reject) => {
    const IMG = new Image();
    IMG.crossOrigin = '*'; //需要图片跨域支持

    IMG.onload = function () {
      const Canvas = document.createElement('CANVAS'); //大量使用可考虑只创建一次

      let w = IMG.width,
        h = IMG.height;

      //缩小比例不为1时 , 重新计算宽高比
      if (shrink !== 1) {
        w = Math.ceil(w / shrink);
        h = Math.ceil(h / shrink);
        r = Math.ceil(r / shrink);
      }

      //因为懒, 就全Try了, 实际上只 Try跨域错误 即可
      try {
        //设置Canvas宽高,获取上下文
        Canvas.width = w;
        Canvas.height = h;
        let ctx = Canvas.getContext('2d');

        ctx.drawImage(IMG, 0, 0, w, h);

        //提取图片信息
        let d = ctx.getImageData(0, 0, w, h);

        //进行高斯模糊
        let gd = gaussBlur(d, r, 0);

        //绘制模糊图像
        ctx.putImageData(gd, 0, 0);

        resolve(Canvas.toDataURL());
      } catch (e) {
        reject(e);
      }
    };
    IMG.src = URL;
  });
};

/**
 * @public
 * 暴露的异步模糊方法
 * ---------------------
 * @param URL       图片地址,需要跨域支持
 * @param r         模糊半径 {Int}
 * @param w         输出宽度 {Number}
 * @param h         输出高度 {Number}
 * @return {Promise}
 */
export const blurWH = (URL, r, w, h) => {
  return new Promise((resolve, reject) => {
    const IMG = new Image();
    IMG.crossOrigin = '*'; //需要图片跨域支持

    IMG.onload = function () {
      const Canvas = document.createElement('CANVAS'); //大量使用可考虑只创建一次

      //锁定输出宽高之后, 就不需要Care 原图有多宽多高了
      //let w = IMG.width, h = IMG.height;

      //因为懒, 就全Try了, 实际上只 Try跨域错误 即可
      try {
        //设置Canvas宽高,获取上下文
        Canvas.width = w;
        Canvas.height = h;
        let ctx = Canvas.getContext('2d');

        ctx.drawImage(IMG, 0, 0, w, h);

        //提取图片信息
        let d = ctx.getImageData(0, 0, w, h);

        //进行高斯模糊
        let gd = gaussBlur(d, r, 0);

        //绘制模糊图像
        ctx.putImageData(gd, 0, 0);

        resolve(Canvas.toDataURL());
      } catch (e) {
        reject(e);
      }
    };
    IMG.src = URL;
  });
};

/**
 * @param {any} base
 * @param {string | number | Symbol} key
 * @description 创建一个引用类型
 */
export function createRef(base, key) {
  return new Proxy(base, {
    apply(_, thisArgs, args) {
      base[key].apply(thisArgs, args);
    },
    construct(_, args) {
      return new base[key](...args);
    },
    defineProperty(_, prop, descriptor) {
      Object.defineProperty(base[key], prop, descriptor);
      return true;
    },
    deleteProperty(_, prop) {
      delete base[key][prop];
      return true;
    },
    getPrototypeOf(_) {
      return Object.getPrototypeOf(base[key]);
    },
    setPrototypeOf(_, prototype) {
      Object.setPrototypeOf(base[key], prototype);
      return true;
    },
    get(_, prop) {
      const value = base[key][prop];

      if (typeof value === 'function') {
        return value.bind(base[key]);
      }

      return value;
    },
    set(_, prop, value) {
      base[key][prop] = value;
      return true;
    },
    has(_, prop) {
      return prop in base[key];
    },
  });
}

/**
 * @param {() => any} getValue
 * @descrption 创建一个动态引用类型
 */
export function createDynamicRef(getValue) {
  const ref = { value: null };

  Object.defineProperty(ref, 'value', {
    get() {
      return getValue();
    },
  });

  return createRef(ref, 'value');
}

const isPlainObject = (obj) =>
  typeof obj === 'object' &&
  obj !== null &&
  (!('constructor' in obj) || obj.constructor === Object);
const isSameType = (target, source) => {
  if (source === null) {
    return typeof target === 'object';
  } else if (target === null) {
    return typeof source === 'object';
  } else if (source === undefined || target === undefined) {
    return true;
  } else {
    return (
      isPlainObject(source) === isPlainObject(target) ||
      source.constructor === target.constructor
    );
  }
};

export function deepMergeObject(target, ...sources) {
  for (const source of sources) {
    for (const [key, value] of Object.entries(source)) {
      const targetValueIsObject = isPlainObject(target[key]);
      const sourceValueIsObject = isPlainObject(value);

      if (targetValueIsObject && sourceValueIsObject) {
        deepMergeObject(target[key], value);
      } else {
        target[key] = sourceValueIsObject ? deepMergeObject({}, value) : value;
      }
    }
  }

  return target;
}

export function safeDeepAssign(target, source) {
  if (typeof target !== 'object' || typeof source !== 'object') {
    return;
  }

  for (const [key, value] of Object.entries(source)) {
    if (key in target && isSameType(target[key], value)) {
      if (isPlainObject(target[key])) {
        safeDeepAssign(target[key], value);
      } else {
        target[key] = value;
      }
    }
  }

  return target;
}

export const secondsToHms = (sec: number): string => {
  sec = Math.floor(sec);

  let hours: number | string = Math.floor(sec / 3600);
  let minutes: number | string = Math.floor((sec - hours * 3600) / 60);
  let seconds: number | string = sec - hours * 3600 - minutes * 60;

  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');
  seconds = seconds.toString().padStart(2, '0');

  if (hours === '00') {
    return minutes + ':' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
};

export const secondsToMs = (sec: number): string => {
  sec = Math.floor(sec);
  return dayjs.duration(sec, "seconds").format("mm:ss");
}

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName, browserVersion;

  // 使用正则表达式解析浏览器信息
  if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Chrome';
    browserVersion = userAgent
      .substring(userAgent.indexOf('Chrome/') + 7)
      .split(' ')[0];
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'Safari';
    browserVersion = userAgent
      .substring(userAgent.indexOf('Version/') + 8)
      .split(' ')[0];
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Firefox';
    browserVersion = userAgent
      .substring(userAgent.indexOf('Firefox/') + 8)
      .split(' ')[0];
  } else if (
    userAgent.indexOf('MSIE') !== -1 ||
    userAgent.indexOf('Trident/') !== -1
  ) {
    browserName = 'Internet Explorer';
    if (userAgent.indexOf('MSIE') !== -1) {
      browserVersion = userAgent.substring(
        userAgent.indexOf('MSIE/') + 5,
        userAgent.indexOf(';')
      );
    } else {
      browserVersion = userAgent.substring(
        userAgent.indexOf('rv:') + 3,
        userAgent.indexOf(')')
      );
    }
  } else {
    browserName = 'Unknown';
    browserVersion = 'Unknown';
  }

  return { browserName, browserVersion };
};

export default {
  resizeImageData,
  downloadFn,
  downloadWithUrl,
  createCanvasCtx,
  getImageScaleSize,
  clearCanvas,
  showImage,
  resizeImage,
  resizeImageWidthOrHeight,
  transformFile,
  gaussBlur,
  createRef,
  createDynamicRef,
  deepMergeObject,
  safeDeepAssign,
  secondsToHms,
  secondsToMs,
  getBrowserInfo,
};
