import { ref } from "vue";
import icon_head_sculpture from "@/assets/icons/common/icon_head_sculpture.svg";
import { setItem, getItem, removeItem, userConstant } from "./storage";
const { USER_EMAIL, USER_NAME, USER_TOKEN, USER_AVATAR } = userConstant;

export const isLogin = ref(!!getItem(USER_TOKEN));
export const getToken = () => getItem(USER_TOKEN);
export const getEmail = () => getItem(USER_EMAIL) || "none";
export const getUserName = () => getItem(USER_NAME) || "";
export const getAvatar = () => getItem(USER_AVATAR) || icon_head_sculpture;

export const setEmail = (value: string) => {
  setItem(USER_EMAIL, value);
};

export const setUsername = (value: string) => {
  setItem(USER_NAME, value);
};

export const setToken = (token: string) => {
  setItem(USER_TOKEN, token);
  isLogin.value = true;
};

export const setAvatar = (url: string) => {
  setItem(USER_AVATAR, url);
}

export const clearToken = () => {
  removeItem(USER_EMAIL);
  removeItem(USER_NAME);
  removeItem(USER_TOKEN);
  removeItem(USER_AVATAR);
  isLogin.value = false;
};

addEventListener("storage", (event) => {
  if (event.key === USER_TOKEN) {
    isLogin.value = !!event.newValue;
  }
});
