import { getOS } from "@/utils/platform";

const isMacOS = getOS() === "Mac OS";
const keyCode = isMacOS ? "⌘" : "Ctrl";
const modifier = reactive({ shift: false, ctrl: false, meta: false });
const pressed = computed(() => isMacOS && modifier.meta || !isMacOS && modifier.ctrl);

function keyDown(e) {
  modifier.shift = e.shiftKey;
  modifier.ctrl = e.ctrlKey;
  modifier.meta = e.metaKey;
}

function keyUp(e) {
  modifier.shift = e.shiftKey;
  modifier.ctrl = e.ctrlKey;
  modifier.meta = e.metaKey;
}

export function useKeyboard(initial) {
  if (initial) {
    onMounted(() => {
      window.addEventListener("keydown", keyDown, true);
      window.addEventListener("keyup", keyUp, true);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keydown", keyDown, true);
      window.removeEventListener("keyup", keyUp, true);
    });
  }

  return {
    modifier,
    pressed,
    keyCode,
  };
}