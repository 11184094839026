<template>
  <div class="edit-wrapper">
    <Loading :loading="loading"/>
    <div class="edit-header">
      <div class="back" @click="back">
        <svg-icon name="icon_arrow_left_brandkit" color="#1C1B1E" :size="24" />
        Back
      </div>
    </div>
    <el-scrollbar class="edit-scrollbar" v-if="info">
      <div class="edit-container">
        <CharacterInfo :info="info"/>
        <Voice :info="info" />
        <Stickers :info="info"/>
        <Hook :info="info"/>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import Hook from "./hook.vue";  
import Voice from "./voice.vue";
import Stickers from "./stickers.vue";
import Loading from "../loading/loading.vue";
import CharacterInfo from './characterInfo.vue';
import { getCharacterDetail } from "@/api/character";

const route = useRoute();
const router = useRouter();
const loading = ref(false);
const info = ref(null);

const back = () => {
  router.push({
    path: "/avatar",
  });
};

const setup = async () => {
  loading.value = true;

  const { characterId } = route.query;
  if (!characterId) {
    router.back();
    return;
  }
  else {
    const { success, data } = await getCharacterDetail(characterId);
    if (success) {
      info.value = data;
    }
    else {
      router.back();
    }
  }

  loading.value = false;
};
onMounted(setup);
</script>

<style lang="scss" scoped>
.edit-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 3px;
  display: flex;
}

.edit-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 28px 46px;
  background: linear-gradient(180deg, #FFF 43.27%, rgba(255, 255, 255, 0.00) 100%);
  z-index: 1;
  display: flex;
}

.back {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #000000;
  cursor: pointer;
  z-index: 2;

  & > svg {
    margin-right: 2px;
  }
}

.edit-scrollbar {
  width: 100%;
}

.edit-container {
  width: 50%;
  min-width: 430px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-top: 84px;
  padding-bottom: 160px;
  margin-right: auto;
  margin-left: auto;
}
</style>