const dragData = reactive({
  target: null,
  segment: null,
  data: null,
  file: null,
  canvasMouseUp: false,
});

export function useDragStore() {
  return dragData;
}
