<template>
  <common-button
    v-bind="props"
    plain
    :class="{
      'bv-plain-button': !disabled,
      'bv-plain-button-disabled': disabled,
    }"
  >
    <slot name="preIcon" v-if="$slots.preIcon"></slot>
    <template v-if="loading">
      <slot v-if="$slots.loading" name="loading"></slot>
      <el-icon v-else :class="ns.is('loading')">
        <component :is="loadingIcon" />
      </el-icon>
    </template>
    <el-icon v-else-if="icon || $slots.icon">
      <component :is="icon" v-if="icon" />
      <slot v-else name="icon"></slot>
    </el-icon>
    <span v-if="$slots.default" class="flex">
      <slot></slot>
    </span>
    <slot name="suffixIcon" v-if="$slots.suffixIcon"></slot>
  </common-button>
</template>

<script setup lang="ts">
import CommonButton from "./common-button.vue";
import { computed, type PropType } from "vue";
import { buttonProps } from "element-plus";
import { useNamespace } from "element-plus";
import type { ButtonType } from "../type";

const ns = useNamespace("button");
const props = defineProps({
  ...buttonProps,
  padding: String,
  fontColor: String,
  size: {
    type: String as PropType<ButtonType>,
    default: "default",
  },
  round: {
    type: Boolean,
    default: true,
  },
  onClick: Function as PropType<(e: MouseEvent) => void>,
});
</script>

<style lang="scss" scoped>
.bv-plain-button {
  :deep(.el-button) {
    background-color: #fff;
    border-color: #875eff;
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;

    span {
      color: #875eff !important;
    }

    &:hover,
    &:focus {
      border-color: #875eff;
      background-color: #f8f5ff;

      & span {
        color: #a378ff !important;
      }
    }

    &:active {
      border-color: #875eff;
      background-color: #ede4ff;

      & span {
        color: #6741ff;
      }
    }
  }
}

.bv-plain-button-disabled {
  :deep(.el-button) {
    border-color: #bbbfc4;

    span {
      color: #bbbfc4 !important;
    }
  }
}
</style>
