<script setup>
import { watch } from 'vue';
import UserBubble from './userbubble.vue';
import SystemBubble from './systembubble.vue';
import { useAssistStore } from '@/store/modules/assist';

const { messages } = storeToRefs(useAssistStore());
const {
  changeCurrentPanel,
  savePrePage,
  handleUpdateSession,
  refreshSpaceMedia,
} = useAssistStore();

const showEmpty = ref(false);
const ro = ref(null);
const processingIndex = ref(0);
function observeheight() {
  ro.value = new ResizeObserver(function () {
    const scrollEl = document.querySelector('.content-scroll');
    const bubbleDivArray = scrollEl.querySelectorAll('.bubble');
    const scrollState = ['loading', 'typing'];
    const index = messages.value.findIndex((item) =>
      scrollState.includes(item.state)
    );
    if (index >= 0) {
      processingIndex.value = index;
    }
    bubbleDivArray[processingIndex.value]?.scrollIntoView(false);
  });
  if (document.querySelector('.chat-container')) {
    ro.value?.observe(document.querySelector('.chat-container'));
  }
}

const handleToHistory = () => {
  savePrePage('chat');
  changeCurrentPanel('history');
};

const handleToHome = () => {
  changeCurrentPanel('preset');
};

// 保存之后打上tag,不允许再次保存
const handleSaveMaterial = (index, saveUrl) => {
  const item = messages.value[index];

  item.contents.forEach((e) => {
    if (e.content === saveUrl) {
      e.isSaved = true;
    }
  });

  handleUpdateSession(messages.value);
  refreshSpaceMedia();
};

watch(
  () => messages.value,
  () => {
    if (!messages.value || !messages.value.length) {
      showEmpty.value = true;
    } else {
      showEmpty.value = false;
    }
  },
  {
    immediate: true,
  }
);

onMounted(observeheight);

onBeforeUnmount(() => {
  if (!ro.value) return;
  ro.value.unobserve(document.querySelector('.chat-container'));
  ro.value.disconnect();
  ro.value = null;
});
</script>

<template>
  <div class="chat-container">
    <div class="nav-header">
      <svg-icon
        name="icon_menu"
        :size="24"
        class="cursor-pointer"
        @click="handleToHistory"
      ></svg-icon>
      <div>
        <bv-tip content="New conversation" placement="bottom" :showAfter="0">
          <svg-icon
            @click="handleToHome"
            name="icon_assistant_edit"
            :size="24"
            class="cursor-pointer"
          ></svg-icon>
        </bv-tip>
      </div>
    </div>
    <template v-if="showEmpty">
      <div class="empty-container">This history has been deleted</div>
    </template>
    <template v-for="(message, index) in messages" v-else>
      <user-bubble
        v-if="message.role === 'user'"
        :message="message"
        :index="index"
      />
      <system-bubble
        v-if="message.role === 'assistant'"
        :message="message"
        @saveMaterial="handleSaveMaterial"
        :index="index"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.nav-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 22px 18px 22px;
}

.empty-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  font-size: 14px;
  color: #bbbfc4;
}
</style>
