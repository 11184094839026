export const validImport = (code: number) => {
  const failCodeArray = [
    103007,
    103008,
    103009,
    103010,
    50033,
    50034,
    50035,
    50036,
    50037,
    30401,
    30402,
    90001,
    90011
  ];
  if (failCodeArray.includes(code)){
    return true;
  }
  else {
    return false;
  }
}