<template>
  <bv-overlay :model-value="showCropper" @close="showCropper = false">
    <div class="crop-dialog">
      <div class="modal-header">
        <span class="title">Crop</span>
        <icon-button
          name="modal_close"
          :size="20"
          @click="showCropper = false"
        />
      </div>
      <div class="modal-content">
        <div
          class="crop-wrapper"
          :style="{ height: isVideo ? 'calc(100% - 70px)' : '100%' }"
        >
          <div
            ref="container"
            class="preview-container"
            :style="[containerStyle, elementStyle]"
          >
            <img v-show="isVideo && !canPlay" :src="poster" />
          </div>
          <div
            class="element-mask"
            :style="[containerStyle, elementStyle]"
          ></div>
          <div class="crop-container" :style="containerStyle">
            <div class="crop" :style="cropStyle" @mousedown.stop="elementDown">
              <div class="crop-mask"></div>
              <span
                v-for="(handler, i) in handlers"
                v-show="shouldShowHandler(handler)"
                class="handler"
                :key="i"
                :class="'handler-' + handler"
                @mousedown.stop="handlerDown($event, handler)"
              >
              </span>
            </div>
          </div>
        </div>
        <div v-show="isVideo" class="video-tools">
          <div class="tools-top">
            <svg-icon
              clickable
              v-show="playing"
              name="editor_pause"
              :size="24"
              @click="pause"
            />
            <svg-icon
              clickable
              v-show="!playing"
              name="editor_play"
              color="#1C1B1E"
              :disabled="!canPlay"
              :size="24"
              @click="play"
            />
            <span class="time" :class="{ disabled: !canPlay }">
              <span class="current-time">{{ frameToHms(currentFrame) }}</span>
              <span class="duration">{{ ` / ${frameToHms(totalFrame)}` }}</span>
            </span>
          </div>
          <div class="tools-bottom">
            <bv-slider
              :model-value="currentFrame"
              :min="0"
              :max="totalFrame"
              :step="1"
              :show-tooltip="false"
              @input="seekTo"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="footer-left">
          <icon-button name="editor_crop_reset" :size="24" @click="reset" />
        </div>
        <div class="footer-center">
          <input-number v-model="rotation" angle :step="Math.PI / 180">
            <template #prefix>
              <svg-icon name="editor_angle" :size="16" />
            </template>
          </input-number>
          <bv-select
            v-model="aspectRatio"
            width="133px"
            :options="aspectRatioOptions"
            @change="setCrop"
          >
            <template #prefix>
              <svg-icon name="editor_crop" :size="16" />
            </template>
          </bv-select>
        </div>
        <div class="footer-right">
          <primary-button @click="handleApply">Apply</primary-button>
        </div>
      </div>
    </div>
  </bv-overlay>
</template>
<script setup>
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { getComputedSize, clamp, frameToHms } from "../../utils";

const handlers = ["tl", "tm", "tr", "bl", "bm", "br", "ml", "mr"];
const aspectRatioOptions = [
  {
    label: "Free",
    value: 0,
  },
  {
    label: "1:1",
    value: 1 / 1,
  },
  {
    label: "3:2",
    value: 3 / 2,
  },
  {
    label: "4:3",
    value: 4 / 3,
  },
  {
    label: "9:16",
    value: 9 / 16,
  },
  {
    label: "16:9",
    value: 16 / 9,
  },
];

const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  crop: {
    type: Object,
    default: null,
  },
  src: {
    type: String,
    default: null,
  },
  poster: {
    type: String,
    default: null,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
});
const { showCropper, creator } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const FPS = 30;
const ticker = ref(null);
const currentFrame = ref(0);
const totalFrame = ref(0);
const playing = ref(false);
const container = ref(null);
const element = ref(null);
const canPlay = ref(false);
const canvas = ref(null);
const alphaCanvas = ref(null);
const left = ref(0);
const top = ref(0);
const right = ref(0);
const bottom = ref(0);
const width = ref(0);
const height = ref(0);
const rotation = ref(0);
const scale = ref(1);
const aspectRatio = ref(0);
const parentWidth = ref(null);
const parentHeight = ref(null);
const dragStart = ref(false);
const dragging = ref(false);
const resizeStart = ref(false);
const resizing = ref(false);
const currentHandler = ref(null);
const dragBounds = ref(null);
const resizeBounds = ref(null);
const beforePosition = reactive({ pageX: 0, pageY: 0, width: 0, height: 0 });
const previewer = computed(() => alphaCanvas.value || element.value);
const isVideo = computed(() =>
  props.node ? props.node.type === "video" : false
);
const cropStyle = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
  width: `${width.value}px`,
  height: `${height.value}px`,
}));
const containerStyle = computed(() => ({
  width: `${parentWidth.value}px`,
  height: `${parentHeight.value}px`,
}));
const elementStyle = computed(() => ({
  transform: `scale(${scale.value}) rotate(${rotation.value}rad)`,
}));

onBeforeMount(resetPosition);
onMounted(() => {
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);
});

watch(
  rotation,
  (a) => {
    const sinA = Math.abs(Math.sin(a));
    const cosA = Math.abs(Math.cos(a));
    const w = container.value.clientWidth;
    const h = container.value.clientHeight;
    const rotatedWidth = w * cosA + h * sinA;
    const rotatedHeight = w * sinA + h * cosA;
    const scaleWidth = rotatedWidth / parentWidth.value;
    const scaleHeight = rotatedHeight / parentHeight.value;

    scale.value = Math.max(scaleWidth, scaleHeight);
  },
  { flush: "post" }
);
watch(showCropper, (value) => {
  if (!value) {
    pause();
    seekTo(0);
  }
});
watch(
  [() => props.src, showCropper],
  ([newSrc, showCropper]) => {
    if (showCropper && newSrc) {
      destroy();
      load(newSrc);
    }
  },
  { flush: "post" }
);

async function play() {
  if (playing.value || !element.value) {
    return;
  }
  if (currentFrame.value >= totalFrame.value) {
    await seekTo(0);
  }
  playing.value = true;
  await element.value.play();

  let lastTime = performance.now();
  let nextUpdateTime = 0;

  const tick = (now) => {
    if (currentFrame.value >= totalFrame.value) {
      pause();
    } else {
      const elapsed = now - lastTime;
      nextUpdateTime = Math.floor(nextUpdateTime - elapsed);

      if (nextUpdateTime <= 0) {
        currentFrame.value++;
        draw();
        nextUpdateTime = Math.floor(1000 / FPS);
      }
      if (playing.value) {
        ticker.value = requestAnimationFrame(tick);
      }
    }
    lastTime = now;
  };
  ticker.value = requestAnimationFrame(tick);
}

function frame2Seconds(frame) {
  return Math.floor((frame / FPS) * 1000) / 1000;
}

function secondsToFrame(seconds) {
  return Math.floor(seconds * FPS);
}

function destroy() {
  currentFrame.value = 0;
  totalFrame.value = 0;
  canPlay.value = false;
  playing.value = false;

  pause();

  if (element.value) {
    element.value.src = "";
    element.value.load?.();
  }
  if (previewer.value) {
    const parentElement = previewer.value.parentElement;

    if (parentElement) {
      parentElement.removeChild(previewer.value);
    }
  }
  element.value = null;
  canvas.value = null;
  alphaCanvas.value = null;
}

function pause() {
  playing.value = false;

  if (element.value) {
    element.value.pause?.();
  }
  if (ticker.value) {
    cancelAnimationFrame(ticker.value);
  }
  ticker.value = null;
}

function load(src) {
  const handleLoaded = () => {
    container.value.appendChild(previewer.value);
    previewer.value.style.width = "100%";
    previewer.value.style.height = "100%";
    initFrame();
  };
  if (isVideo.value) {
    element.value = document.createElement("video");

    if (props.transparent) {
      canvas.value = document.createElement("canvas");
      alphaCanvas.value = document.createElement("canvas");
    }
    const handleLoadedData = () => {
      if (alphaCanvas.value) {
        canvas.value.width = element.value.videoWidth;
        canvas.value.height = alphaCanvas.value.height =
          element.value.videoHeight;
        alphaCanvas.value.width = element.value.videoWidth / 2;
      }
      totalFrame.value = secondsToFrame(element.value.duration);
      canPlay.value = true;
      handleLoaded();
      seekTo(0);
    };
    element.value.addEventListener("loadedmetadata", handleLoadedData, {
      once: true,
    });
    element.value.crossOrigin = "anonymous";
    element.value.src = src;
    element.value.load();
  } else {
    element.value = document.createElement("img");
    element.value.addEventListener("load", handleLoaded, { once: true });
    element.value.src = src;
  }
}

function seekTo(frame) {
  return new Promise((resolve) => {
    const handleSeeked = () => {
      element.value.removeEventListener("seeked", handleSeeked);
      currentFrame.value = Math.min(frame, totalFrame.value);
      draw();
      resolve();
    };
    element.value.addEventListener("seeked", handleSeeked);
    element.value.currentTime = frame2Seconds(frame);
  });
}

function draw() {
  if (!alphaCanvas.value) {
    return;
  }
  const context = canvas.value.getContext("2d");
  const alphaContext = alphaCanvas.value.getContext("2d");
  const w = canvas.value.width;
  const h = canvas.value.height;
  const hw = w / 2;

  context.drawImage(element.value, 0, 0, w, h);

  const mask = context.getImageData(0, 0, hw, h);
  const source = context.getImageData(hw, 0, hw, h);

  for (let i = 3; i < source.data.length; i += 4) {
    source.data[i] = mask.data[i - 1];
  }
  alphaContext.putImageData(source, 0, 0);
}

function submit() {
  commit();
  updateDraft();
}

function initFrame() {
  const crop = props.crop;
  const transparent = props.transparent >> 0;
  const w =
    (element.value.naturalWidth || element.value.videoWidth) /
    (transparent + 1);
  const h = element.value.naturalHeight || element.value.videoHeight;
  const ratio = w / h;
  const parentElement = container.value.parentElement;
  let [pw, ph] = getComputedSize(parentElement);

  if (ratio > pw / ph) {
    ph = pw / ratio;
  } else {
    pw = ph * ratio;
  }
  parentWidth.value = Math.floor(pw);
  parentHeight.value = Math.floor(ph);

  let newLeft = 0;
  let newTop = 0;
  let newWidth = parentWidth.value;
  let newHeight = parentHeight.value;

  if (crop) {
    const [scaleX, scaleY] = getScale();
    newLeft = crop.x * scaleX;
    newTop = crop.y * scaleY;
    newWidth = crop.width * scaleX;
    newHeight = crop.height * scaleY;
    rotation.value = crop.rotate;
  }
  left.value = newLeft;
  top.value = newTop;
  width.value = newWidth;
  height.value = newHeight;
  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;
}

function getScale() {
  const transparent = props.transparent >> 0;
  return [
    parentWidth.value /
      ((element.value.naturalWidth || element.value.videoWidth) /
        (transparent + 1)),
    parentHeight.value /
      (element.value.naturalHeight || element.value.videoHeight),
  ];
}

function setCrop() {
  if (parentWidth.value / parentHeight.value > aspectRatio.value) {
    height.value = parentHeight.value;
    width.value = aspectRatio.value
      ? height.value * aspectRatio.value
      : parentWidth.value;
    top.value = 0;
    bottom.value = 0;
    left.value = parentWidth.value / 2 - width.value / 2;
    right.value = parentWidth.value - width.value - left.value;
  } else {
    width.value = parentWidth.value;
    height.value = aspectRatio.value
      ? width.value / aspectRatio.value
      : parentHeight.value;
    left.value = 0;
    right.value = 0;
    top.value = parentHeight.value / 2 - height.value / 2;
    bottom.value = parentHeight.value - height.value - top.value;
  }
}

function handleApply() {
  const node = props.node;
  const [scaleX, scaleY] = getScale();

  const x = left.value / scaleX;
  const y = top.value / scaleY;
  const w = width.value / scaleX;
  const h = height.value / scaleY;
  const newCrop = { x, y, width: w, height: h, rotate: rotation.value };

  node.conf.crop = newCrop;
  node.setCrop(newCrop);
  node.setDisplaySize();
  showCropper.value = false;
  creator.value.render();
  node.updateId++;
  submit();
}

function reset() {
  aspectRatio.value = 0;
  rotation.value = 0;
  setCrop();
}

function mouseMove(e) {
  if (resizeStart.value) {
    resize(e);
  } else if (dragStart.value) {
    drag(e);
  }
}

function mouseUp() {
  resetPosition();
  dragStart.value = false;
  resizeStart.value = false;
  dragging.value = false;
  resizing.value = false;
  currentHandler.value = null;
}

function elementDown(e) {
  dragStart.value = true;
  savePosition(e);
  calcDragBounds();
}

function handlerDown(e, handler) {
  resizeStart.value = true;
  currentHandler.value = handler;
  savePosition(e);
  calcResizeBounds();
}

function drag(e) {
  dragging.value = true;

  const bounds = dragBounds.value;
  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;
  const newLeft = clamp(
    beforePosition.left - deltaX,
    bounds.minLeft,
    bounds.maxLeft
  );
  const newTop = clamp(
    beforePosition.top - deltaY,
    bounds.minTop,
    bounds.maxTop
  );

  left.value = newLeft;
  top.value = newTop;
  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;
}

function resize(e) {
  resizing.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;
  const handler = currentHandler.value;
  const bounds = resizeBounds.value;
  const ratio = aspectRatio.value;

  let newLeft = left.value;
  let newRight = right.value;
  let newTop = top.value;
  let newBottom = bottom.value;

  if (handler.includes("l")) {
    newLeft = clamp(
      beforePosition.left - deltaX,
      bounds.minLeft,
      bounds.maxLeft
    );
  } else if (handler.includes("r")) {
    newRight = clamp(
      beforePosition.right + deltaX,
      bounds.minRight,
      bounds.maxRight
    );
  }
  if (handler.includes("t")) {
    newTop = clamp(beforePosition.top - deltaY, bounds.minTop, bounds.maxTop);

    if (ratio) {
      if (handler[1] === "l") {
        newLeft = left.value - (top.value - newTop) * ratio;
      } else if (handler[1] === "r") {
        newRight = right.value - (top.value - newTop) * ratio;
      }
    }
  } else if (handler.includes("b")) {
    newBottom = clamp(
      beforePosition.bottom + deltaY,
      bounds.minBottom,
      bounds.maxBottom
    );
    if (ratio) {
      if (handler[1] === "l") {
        newLeft = left.value - (bottom.value - newBottom) * ratio;
      } else if (handler[1] === "r") {
        newRight = right.value - (bottom.value - newBottom) * ratio;
      }
    }
  }

  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
  bottom.value = newBottom;

  const newWidth = parentWidth.value - newLeft - newRight;
  const newHeight = parentHeight.value - newTop - newBottom;

  width.value = newWidth;
  height.value = newHeight;
}

function calcDragBounds() {
  const boundsRef = dragBounds.value;

  boundsRef.minLeft = 0;
  boundsRef.maxLeft = parentWidth.value - width.value;
  boundsRef.minTop = 0;
  boundsRef.maxTop = parentHeight.value - height.value;
}

function calcResizeBounds() {
  const boundsRef = resizeBounds.value;
  const ratio = aspectRatio.value;
  let minW = 1;
  let minH = 1;

  if (ratio) {
    if (minW / minH > ratio) {
      minH = minW / ratio;
    } else {
      minW = ratio * minH;
    }
  }
  boundsRef.minLeft = 0;
  boundsRef.maxLeft = left.value + width.value - minW;
  boundsRef.minRight = 0;
  boundsRef.maxRight = right.value + width.value - minW;
  boundsRef.minTop = 0;
  boundsRef.maxTop = top.value + height.value - minH;
  boundsRef.minBottom = 0;
  boundsRef.maxBottom = bottom.value + height.value - minH;
}

function savePosition(e) {
  beforePosition.pageX = e.pageX;
  beforePosition.pageY = e.pageY;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;
  beforePosition.bottom = bottom.value;
  beforePosition.width = width.value;
  beforePosition.height = height.value;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.pageY = 0;
  beforePosition.width = 0;
  beforePosition.height = 0;

  dragBounds.value = {
    minLeft: null,
    maxLeft: null,
    minRight: null,
    maxRight: null,
  };
  resizeBounds.value = {
    minLeft: null,
    maxLeft: null,
    minRight: null,
    maxRight: null,
  };
}

function shouldShowHandler(handler) {
  if (
    (handler[0] === "m" && height.value < 50) ||
    (handler[1] === "m" && width.value < 50) ||
    playing.value
  ) {
    return false;
  }
  if ((resizing.value && currentHandler.value !== handler) || dragging.value) {
    return false;
  }
  return true;
}
</script>
<style scoped>
.crop-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crop {
  position: absolute;
  border: 1px solid #875eff;
}
.crop-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 80%;
  border-radius: 4px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 26px;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
}
.modal-content {
  height: calc(100% - 187px);
  padding: 28px 46px;
  background-color: #f6f6f6;
  overflow: hidden;
}
.element-mask {
  position: absolute;
  background-color: #00000080;
}
.crop-mask {
  position: absolute;
  inset: 0;
  background-color: #fff;
  mix-blend-mode: overlay;
}
.crop-container {
  position: absolute;
}
.modal-footer {
  height: 114px;
  padding: 0 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
}

.footer-left {
  flex: 0 0 120px;
}

.footer-center {
  display: flex;
  justify-content: center;
  height: 38px;
  width: 296px;
}

.footer-right {
  flex: 0 0 120px;
}

:deep(.bv-select-button) {
  margin-left: 30px;
}

:deep(.el-input) {
  width: 133px;
  height: 100%;
}

:deep(.el-input__wrapper),
:deep(.select-wapper) {
  padding: 0 10px 0 12px;
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
  box-shadow: none;
  transition: all 200ms;
}
:deep(.el-input .el-input__wrapper:hover),
:deep(.bv-select-button .select-wapper:hover) {
  outline-color: #be9fff;
  border-color: #be9fff;
  cursor: pointer;
}

:deep(.select-wapper .prefix) {
  margin: 0 12px 0 2px;
}

:deep(.select-wapper.border) {
  border: 0.5px solid #bbbfc4;
}

:deep(.select-wapper .select-value) {
  color: #060606;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.handler {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #875eff;
}
.handler-tm,
.handler-bm {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: ns-resize;
}
.handler-ml,
.handler-mr {
  height: 32px;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: ew-resize;
}
.handler-tl,
.handler-tm,
.handler-tr {
  top: -5px;
}
.handler-tl,
.handler-ml,
.handler-bl {
  left: -5px;
}
.handler-tr,
.handler-mr,
.handler-br {
  right: -5px;
}
.handler-bl,
.handler-bm,
.handler-br {
  bottom: -5px;
}
.handler-tl,
.handler-br {
  cursor: nwse-resize;
}
.handler-tr,
.handler-bl {
  cursor: nesw-resize;
}
.video-tools {
  width: 100%;
  height: 72px;
  padding-top: 12px;
}
.tools-top {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tools-bottom {
  width: 100%;
  height: 12px;
}
.tools-top svg {
  margin-right: 14px;
}

.tools-top .time {
  font-size: 14px;
  height: 22px;
}

.tools-top .time.disabled .current-time,
.tools-top .time.disabled .duration {
  color: #bbbfc4;
}

.tools-top .time .current-time {
  color: #000000;
  line-height: 22px;
  font-variant: tabular-nums;
}

.tools-top .time .duration {
  color: #646a73;
  line-height: 22px;
}
</style>
