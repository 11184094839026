<template>
  <div class="switcher-wrapper">
    <div class="switcher-container">
      <div 
        v-for="tab in tabs"
        class="tab" 
        :class="{active: tab.value == modelValue}"
        @click="handleClick(tab)"
      >
        {{ tab.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  tabs: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Number,
    required: true,
  },
})
const emit = defineEmits('switch');

const handleClick = (item) => {
  if (props.modelValue === item.value) return;
  emit('switch', item.value);
};
</script>

<style lang="scss" scoped>
.switcher-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(117px, 1fr));
  padding: 1px;
  border-radius: 6px;
  border: 0.5px solid #E5E7EB;
  background: #F3F5F7;
}

.tab {
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid transparent;
  background: transparent;
  box-shadow: none;
  text-align: center;
  color: #060606;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  transition: all 0.2s;
  cursor: pointer;

  &.active {
    border: 0.5px solid rgba(0, 0, 0, 0.02);
    background: #FFF;
    box-shadow: 0px 0.25px 0.25px 0px #E5E7EB, 0px 1px 0.75px 0px #E5E7EB;
    color: #6741FF;
  }
}
</style>