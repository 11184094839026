<script setup lang="ts">
import { useHead } from '@vueuse/head';
import PricingCardBox from '../card/pricing-card-box.vue';
import PricingModal from '../modal/modal.vue';
import SubscribingModal from '../modal/subscribing.vue';
import Footer from './footer.vue';
import {
  cancelSubscription,
  recoverSubscription,
  ltdRefund,
} from '@/api/premium';
import { getToken, isLogin } from '@/utils/auth';
import { icon_gift } from '@/assets/icons/common';
import type { Ref } from 'vue';
import type {
  CardItemInfo,
  ModalArguments,
  ModalName,
  ModalInfo,
} from '../../type';
import { QuestionnaireVisible } from '../../type';
import { useSubscriptionInfo } from '@/store/modules/user';
import { useTrackStore } from '@/store/modules/track';
import { usePricingStore } from '../../stores';

const route = useRoute();
const router = useRouter();
useHead({
  title: route.meta.title,
  meta: [
    {
      property: 'og:title',
      content: route.meta.title,
    },
    {
      name: 'twitter:title',
      content: route.meta.title,
    },
  ],
});
const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { updateSubscriptionState, refresh } = useSubscriptionInfo();
const { collectData, track, getTrackPlanName } = useTrackStore();
const { cardList, setCardList, getCardInfo } = usePricingStore();
const isLoading = ref(true);
// 弹窗参数
const modalArguments: ModalArguments = reactive({
  type: 'subscribeSuccess',
  card: null,
  visible: false,
});
// 调查问卷显隐
const questionnaireVisible = ref(false);
// 订阅中弹窗
const subscribingVisible = ref(false);
const subscribingStep = ref(1);
// 月末是否支付状态
const isPaid = ref(true);
const initActivedItem: Ref<HTMLElement | null> = ref(null);
let activedItem: HTMLElement | null = null;

provide(QuestionnaireVisible, questionnaireVisible);
provide('isPaid', isPaid);

async function handleClickSwitcher(e: Event) {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  const target: HTMLElement | null = (e.target as HTMLElement).closest(
    '.switcher > li'
  );
  await handleSwitch(target);
  isLoading.value = false;
}

async function handleSwitch(target: HTMLElement | null) {
  // 更新选择状态
  if (target === null) {
    return;
  } else {
    if (activedItem !== null) {
      activedItem.classList.remove('active');
    }
    target.classList.add('active');
    activedItem = target;
    setCardList(target.dataset.cycleType as string);
  }
}

// 判断是否订阅成功跳转
watchEffect(() => {
  const subscriptionType = route.query.subscriptionType;
  (async () => {
    switch (subscriptionType) {
      case 'NEW_SUBSCRIPTION':
        await nextTick();
        userOperationMap.subscribeSuccess();
        break;
      case 'UPGRADE_SUBSCRIPTION':
        await nextTick();
        userOperationMap.upgradeSuccess();

        break;
      case 'DEGRADE_SUBSCRIPTION':
        await nextTick();
        userOperationMap.downgradeSuccess();
        break;
      default:
        break;
    }
  })();
});

const initSwitcher = () => {
  let targetElement = (activedItem = initActivedItem.value as HTMLElement);
  const subscriptionCycle = getCardInfo(subscriptionState.value.packageCode)
    ?.subscriptionCycle;
  if (subscriptionCycle !== undefined) {
    let selectPrams = `:scope > [data-cycle-type="${subscriptionCycle}"]`;
    targetElement =
      targetElement.parentElement?.querySelector(selectPrams) ?? targetElement;
  }
  handleSwitch(targetElement);
};

onMounted(async () => {
  isLoading.value = true;
  // 更新订阅状态
  if (isLogin.value) {
    await updateSubscriptionState();
  }
  initSwitcher();
  isLoading.value = false;
});

// 点击订阅
const handleSubscribe = (card: CardItemInfo) => {
  collectData('boolvideo_subscribe_click', {
    click: 'click_subscribe',
    plan_name: getTrackPlanName(card.packageCode),
  });
  track('boolvideo_subscribe_click');
  router.push({
    path: '/payment',
    query: {
      packageCode: card.packageCode,
      subscriptionOperType: 'NEW_SUBSCRIPTION',
    },
  });
};

// 点击取消订阅逻辑
const handleCancel = async () => {
  collectData('boolvideo_subscribe_cancel', {
    click: 'cancel_confirm',
    plan_name: getTrackPlanName(subscriptionState.value.packageCode),
  });
  return new Promise((resolve, reject) => {
    try {
      cancelSubscription().then(async (res) => {
        const { code } = res;
        if (code === 0) {
          await refresh();
          await updateSubscriptionState();
          router.replace({ query: {} });
          const o = window.opener;
          if (o && o.location.host === window.location.host) {
            const m = {
              subscribeSuccess: 'true',
              token: getToken(),
            };
            window.opener.postMessage(m, o.location.origin);
          }
          track('boolvideo_subscribe_cancel_confirm');
          track('boolvideo_subscribe_cancel');
          openModal('cancelSuccess');
          resolve(res);
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// 点击升级
const handleUpgrade = (card: CardItemInfo) => {
  collectData('boolvideo_subscribe_change', {
    click: 'click_upgrade',
    plan_name: getTrackPlanName(card.packageCode),
  });
  track('boolvideo_subscribe_change');
  router.push({
    path: '/payment',
    query: {
      packageCode: card.packageCode,
      subscriptionOperType: 'UPGRADE_SUBSCRIPTION',
    },
  });
};

const handleSubscribing = (card: CardItemInfo, step: number = 1) => {
  subscribingVisible.value = true;
  subscribingStep.value = step;
};

const handleDowngrade = (
  card: CardItemInfo,
  info: ResData.BoolvideoUpdateSubPopMsg
) => {
  collectData('boolvideo_subscribe_change', {
    click: 'click_downgrade',
    plan_name: getTrackPlanName(card.packageCode),
  });
  track('boolvideo_subscribe_change');
  openModal('downgrade', { card, downgradeInfo: info });
};

const handleSubscribeSuccess = () => {
  updateSubscriptionState();
  openModal('subscribeSuccess');
};

const handleUpgradeSuccess = () => {
  openModal('upgradeSuccess');
};

const handleDowngradeSuccess = () => {
  openModal('downgradeSuccess');
};

const handleContactUs = () => {
  collectData('boolvideo_subscribe_click', {
    click: 'click_subscribe',
    plan_name: 'contact_us',
  });
  track('boolvideo_subscribe_click');
  openModal('contactUs');
};

const handleCancelSuccess = () => {
  openModal('cancelSuccess');
};

const handleRecover = async () => {
  await recoverSubscription().then((res) => {
    if (res.code === 0) {
      const cardInfo = getCardInfo(subscriptionState.value.packageCode);
      const pricingArr = cardInfo?.monthlyPrice?.split('$') || [];
      gtag('event', `click_resume_success`, {
        type:
          cardInfo?.subscriptionCycle == 'MONTH'
            ? 'month'
            : cardInfo?.subscriptionCycle == 'YEAR'
            ? 'year'
            : '',
        package_code: cardInfo?.packageCode,
        price: Number(pricingArr[1]),
        currency: 'USD',
      });
      userOperationMap.recoverSuccess();
    }
  });
};

const handleRecoverSuccess = () => {
  openModal('recoverSuccess');
};

const handleYearToMonthTips = () => {
  openModal('yearToMonth');
};

const handleLtdTips = () => {
  openModal('ltdTips');
};

const handleRefundSuccess = () => {
  openModal('ltdRefundSuccess');
};

const openModal = (_type: ModalName, otherInfo?: ModalInfo) => {
  modalArguments.type = _type;
  if (otherInfo?.card) {
    modalArguments.card = otherInfo.card;
  }
  if (otherInfo?.downgradeInfo) {
    modalArguments.downgradeInfo = otherInfo.downgradeInfo;
  }
  if (otherInfo?.giftInfo) {
    modalArguments.giftInfo = otherInfo.giftInfo;
  }
  if (otherInfo?.subscribingInfo) {
    modalArguments.subscribingInfo = otherInfo?.subscribingInfo;
  }
  modalArguments.visible = true;
};

const modalCloseCallback = () => {
  modalArguments.visible = false;
};

const userOperationMap = {
  subscribe: handleSubscribe,
  subscribeSuccess: handleSubscribeSuccess,
  upgradeSuccess: handleUpgradeSuccess,
  subscribing: handleSubscribing,
  upgrade: handleUpgrade,
  downgrade: handleDowngrade,
  downgradeSuccess: handleDowngradeSuccess,
  cancel: handleCancel,
  cancelSuccess: handleCancelSuccess,
  recover: handleRecover,
  recoverSuccess: handleRecoverSuccess,
  contactUs: handleContactUs,
  yearToMonthTips: handleYearToMonthTips,
  ltdTips: handleLtdTips,
  ltdRefundSuccess: handleRefundSuccess,
};
</script>

<template>
  <div class="premium-container" v-track:exposure="'boolvideo_pricing_view'">
    <div class="premium-inner">
      <!-- title -->
      <p class="premium-title">
        Easy-to-use, 3 Days free trial, Cancel anytime
      </p>
      <ul class="switcher" @click="handleClickSwitcher">
        <li data-cycle-type="YEAR">
          <img :src="icon_gift" alt="" />
          Yearly -20% off
        </li>
        <li data-cycle-type="MONTH" class="active" ref="initActivedItem">
          Monthly
        </li>
      </ul>
      <PricingCardBox
        :cardList="cardList"
        :subscriptionState="subscriptionState"
        :isLoading="isLoading"
        :userOperationMap="userOperationMap"
      />
    
    </div>
    <Footer />
  </div>
  <PricingModal
    :modalArguments="modalArguments"
    :closeCallback="() => modalCloseCallback()"
    :userOperationMap="userOperationMap"
  />
  <SubscribingModal
    v-if="subscribingVisible"
    v-model="subscribingVisible"
    :initStep="subscribingStep"
  />
</template>

<style lang="scss" scoped>
.premium-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  user-select: text;
}

.premium-inner {
  position: relative;
  width: 100%;
  background: #e5eaff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 16px 63px 16px;
}

.premium-title {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.premium-tips {
  font-weight: 600;
  margin-top: 24px;
}
.switcher {
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-top: 36px;
  border-radius: 9999px;
  color: #000;
  background: #fff;
  border: 2px solid #313131;
  font-size: 16px;
  font-weight: 500;

  & > li {
    flex: 1;
    padding: 17px 20px;
    background-color: transparent;
    transition: background-color 0.2s;
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    color: #060606;
    text-align: center;
    font-family: Inter-variant;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.active {
      background-color: #000;
      color: #fff;
    }

    & > img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }

  & > li:first-child {
    flex: 1.5
  }
}

.questionnaire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>
