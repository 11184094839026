<template>
  <Teleport :to="root" :disabled="teleported">
    <Overlay :[modal.scopeId]="''" :open="modal.open" :zIndex="modal.zIndex">
      <!-- 实验性功能 (自动填充scopeId) -->
      <div
        :[modal.scopeId]="''"
        :key="modal.key"
        class="modal-container"
        :class="modal.modalClass"
      >
        <div :[modal.scopeId]="''" class="flex">
          <span
            :[modal.scopeId]="''"
            v-if="modal.icon !== null"
            class="modal-icon"
            style="margin-right: 16px"
          >
            <v-render :vnode="modal.icon" />
          </span>
          <div :[modal.scopeId]="''" class="modal-content-container">
            <div :[modal.scopeId]="''" class="modal-header">
              <p :[modal.scopeId]="''">
                <v-render :vnode="modal.title" />
              </p>
              <img
                :[modal.scopeId]="''"
                v-if="modal.showClose"
                class="modal-close-btn"
                :src="closeIcon"
                width="24"
                height="24"
                @click="modal.onClose"
              />
            </div>
            <div :[modal.scopeId]="''" class="modal-content">
              <v-render :vnode="modal.content" />
            </div>
          </div>
        </div>
        <div
          :[modal.scopeId]="''"
          v-if="modal.footer !== null"
          class="modal-footer"
        >
          <v-render :vnode="modal.footer" />
        </div>
      </div>
    </Overlay>
  </Teleport>
</template>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount, type PropType } from "vue";
import Overlay from "./components/overlay.vue";
import { ModalManager } from "./modalModel";
import closeIcon from "@/assets/icons/common/common_modal_close_icon.svg";
import type { CommonModalTemplate } from "./type";

const props = defineProps({
  templates: {
    type: Object as PropType<CommonModalTemplate>,
    default: {},
  },
  teleported: {
    type: Boolean,
    default: true,
  }
});

const modalManager = new ModalManager({ ...props.templates });
const modal = modalManager.modal;

//ANCHOR - 变量声明
const root = document.createElement("div");

//ANCHOR - 生命周期
onMounted(() => {
  document.body.appendChild(root);
});

onBeforeUnmount(() => {
  document.body.removeChild(root);
});

defineExpose({
  modalManager,
});
</script>

<style lang="scss" scoped>
.modal-container {
  width: 448px;
  max-height: min-content;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
}

.modal-close-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}

.modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.modal-content-container {
  width: 100%;
}

.modal-content {
  font-size: 14px;
  margin-top: 8px;
}

.modal-footer {
  margin-top: 24px;
}
</style>
