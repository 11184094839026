<template>
  <el-scrollbar>
    <div class="collection">
      <div class="header">
        <p class="flex w-20 cursor-pointer" @click="handleBack">
          <svg-icon
            name="icon_arrow_left_brandkit"
            color="#1C1B1E"
            :size="24"
          ></svg-icon>
          Back
        </p>
      </div>
      <div class="loading-mask" v-if="!list">
        <el-skeleton animated v-for="(_) in Array(5)">
          <template #template>
            <div class="skeleton-item-wrapper">
              <el-skeleton-item
                variant="image"
                style="width: 100px; height: 100px"
              />
              <div style="padding: 14px" class="flex-1">
                <el-skeleton :rows="1" animated />
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
      <div 
        v-else-if="list.length <= 0 && selectedType === 1"
        class="empty-user-collection"
      >
        <span>No media</span>
        <common-button @click="createCharacter">Upload media for character</common-button>
      </div>
      <div class="collection-item-box" v-else>
        <div v-for="item in list" :key="item.mediaId">
          <videoItem
            :muted="muted"
            :list="list"
            :selectedMediaId="selectedMediaId"
            :item="item"
            :selectedType="selectedType"
            :selectedStyleId="selectedStyleId"
            @refresh="$emit('refresh')"
            @onSelected="handleSelected"
            @onPlay="handlePlayVideo"
            @onPause="handlePauseVideo"
            @onCancelSelected="handleCancelSelected"
          />
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup>
import videoItem from './videoItem.vue';
import { useSimilarVideo } from '@/pages/similarVideo/store/index.js';
import { useModalManager } from '@/components/common/custom-modal/instance';

const modalManager = useModalManager();
const similarVideoStore = useSimilarVideo();

const emit = defineEmits(['back', 'onPlay', "refresh"]);

const props = defineProps({
  list: Array,
  muted: Boolean,
  selectedType: Number,
  selectedStyleId: Number,
  selectedMediaId: Number,
});

const handleSelected = (mediaId, voiceId) => {
  if(!mediaId && !voiceId) {
    return
  }

  similarVideoStore.setSelectedMediaId(mediaId);
  similarVideoStore.setSelectedVoiceId(voiceId);

  props.list.forEach((item) => {
    if (item.mediaId != mediaId) {
      item.isSelected = false;
      item.isPlay = false
    }
  });
};

const handleCancelSelected = (id) => {
  similarVideoStore.setSelectedMediaId(null);
  similarVideoStore.setSelectedVoiceId(null);
  props.list.forEach((item) => {
    if (item.mediaId == id) {
      item.isSelected = false;
    }
  });
};

const handlePlayVideo = (id) => {
  props.list.forEach((item) => {
    if (item.mediaId != id) {
      item.isPlay = false;
    }
  });

  emit('onPlay');
};

const handlePauseVideo = (id) => {
  props.list.forEach((item) => {
    if (item.mediaId == id) {
      item.isPlay = false;
    }
  });
};

const createCharacter = () => {
  window.open("/avatar", "_blank");
  
  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => emit("refresh"),
  });
};

const handleBack = () => {
  emit('back');
};
</script>

<style lang="scss" scoped>
.collection {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 12px 120px 15px;

  .collection-item-box {
    margin-top: 20px;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 4px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-auto-rows: minmax(124px, 124px);
  }

  .header {
    display: flex;
    padding-left: 9px;
    .title {
      color: #000;
      text-align: center;
      font-family: Inter-variant;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      margin-left: -80px;
      pointer-events: none;
    }
  }
}

.loading-mask {
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .el-skeleton__image {
    border-radius: 4px;
  }
}

.empty-user-collection {
  flex: 1 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-bottom: 24px;
  }

  & :deep(.el-button) {
    padding: 10px 36px;
    color: #060606;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.skeleton-item-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 11px 12px;
}
</style>
