<template>
  <div v-show="visible" ref="element" class="widget-tool" :style="style">
    <icon-button
      v-for="item in tools"
      :name="item.icon"
      :tip="item.tip"
      :tip-delay="0"
      :size="18"
      @mousedown.stop
      @click="item.onClick"
    />
    <div class="divider"></div>
    <icon-button
      class="widget-tool-more"
      name="editor_more"
      tip="More"
      :tip-delay="0"
      :size="18"
      @mousedown.stop="handleClickMore"
    />
  </div>
</template>
<script setup>
import { useTrackStore } from "@/store/modules/track";
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { getTranslate, getComputedSize } from "../../utils";

const { currentNode, widgetMenu, getXY, replaceData, showMaterialDialog } =
  useCreatorStore();
const { collectData, track } = useTrackStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const element = ref(null);
const left = ref(0);
const top = ref(0);
const visible = ref(false);
const mo = ref(null);
const style = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
}));
const tools = computed(() => {
  const node = currentNode.value;

  if (!node) {
    return [];
  }
  const fit = node.conf.fit;

  return [
    {
      icon: `editor_${fit === "contain" ? "cover" : "contain"}`,
      tip: fit === "contain" ? "Fill" : "Fit",
      onClick: () => {
        const { x, y } = getXY();

        if (fit === "contain") {
          node.conf.fit = "cover";
        } else {
          node.conf.fit = "contain";
        }
        node.conf.x = x;
        node.conf.y = y;
        submit();
      },
    },
    {
      icon: "icon_replace",
      tip: "Replace",
      onClick: () => {
        replaceData.node = node;
        showMaterialDialog.value = true;

        collectData("boolvideo_curtain_replace");
        track("boolvideo_curtain_replace");
      },
    },
  ];
});

onMounted(() => {
  mo.value = new MutationObserver(handleMutation);
  mo.value.observe(element.value.parentElement, {
    subtree: true,
    childList: true,
    attributeFilter: ["class"],
  });
});

function handleMutation(mutationsList) {
  for (const mutation of mutationsList) {
    const element = mutation.target;
    const cls = element.classList;

    if (
      cls.contains("active") &&
      !cls.contains("dragging") &&
      !cls.contains("resizing") &&
      !cls.contains("rotating")
    ) {
      const [x, y] = getTranslate(element);
      const [width] = getComputedSize(element);

      left.value = x + width / 2 - 57.75;
      top.value = Math.max(-44, y - 49);
      visible.value = true;
      break;
    } else {
      visible.value = false;
    }
  }
}

function submit() {
  commit();
  updateDraft();
}

function handleClickMore(e) {
  const { left, bottom } = e.currentTarget.getBoundingClientRect();

  setTimeout(() => {
    widgetMenu.visible = true;
    widgetMenu.left = left;
    widgetMenu.top = bottom + 10;
  });
}
</script>
<style scoped>
.widget-tool {
  position: absolute;
  background: #ffffff;
  padding: 7px 9px;
  border: 0.5px solid #e5e7eb;
  box-shadow: 0px 4px 8px 0px #0000001a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  z-index: 2;
}
.widget-tool .divider {
  width: 0.5px;
  height: 18px;
  background-color: #e5e7eb;
}
:deep(.widget-tool .icon-wapper) {
  padding: 3px;
}
</style>
