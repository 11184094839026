<template>
  <div class="card-item-container">
    <div class="card-info">
      <p class="title">{{ item.title }}</p>
      <p class="describe">{{ item.describe }}</p>
      <div class="card-button" @click.stop="handleNext(item.link, item.id)">
        <p>{{ item.buttonText }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const emit = defineEmits(['onClick']);
const router = useRouter();
const props = defineProps({
  item: Object,
});

const handleNext = (link, id) => {
  router.push(link);
  emit('onClick', id)
};

</script>

<style lang="scss" scoped>
.card-item-container {
  border-radius: 12px;
  background: #f1f5ff;
  display: flex;
  align-content: center;
  padding: 36px 37px 40px 54px;
}

.card-info {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; 
}

.describe {
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  height: 66px;
}

.card-button {
  margin-top: 24px;
  display: flex;
  width: 224px;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(289deg, #6f46f4 5.38%, #957aec 99.95%);
  }
}
</style>
