<template>
  <transition>
    <div class="overlay" v-show="open" :can-scroll="canScroll ? '' : null" :style="{ zIndex: zIndex }">
      <div v-if="canScroll" class="overlay-content">
        <slot></slot>
      </div>
      <slot v-else></slot>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { onUnmounted, watchEffect } from "vue";
import { useScopeId } from "@/utils/hook";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  zIndex: {
    type: Number,
    default: 3000,
  },
  canScroll: {
    type: Boolean,
    default: true,
  }
});

//ANCHOR - 变量声明
const scopeId = useScopeId();

//ANCHOR - 生命周期
watchEffect(() => {
  if (props.open) {
    document.body.setAttribute(scopeId, "");
  } else {
    document.body.removeAttribute(scopeId);
  }
});

onUnmounted(() => {
  document.body.removeAttribute(scopeId);
});
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}

.overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  
  &[can-scroll] {
    flex-direction: column;
    justify-content: normal;
    overflow-y: auto;
  }
}

.overlay-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-block: 50px;

  & > :deep(*) {
    height: fit-content;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 300ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
