<template>
  <div class="preset-container">
    <div class="header">
      <svg-icon name="icon_menu" :size="24" class="cursor-pointer" @click="handleToHistory"></svg-icon>
      <p class="tip">Powered by GPT-4 and DALL-E 3</p>
    </div>
    <div class="title">
      <p>Hey,</p>
      <p>How can I help you?</p>
    </div>
    <div class="prompt-container">
      <div
        class="prompt-box"
        v-for="prompt in prompts"
        @click="postMessage(prompt.value)"
      >
        <p class="name">{{ prompt.label }}</p>
        <p class="description">{{ prompt.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAssistStore } from '@/store/modules/assist';

const { postMessage, changeCurrentPanel, savePrePage } = useAssistStore();
const prompts = [
  {
    label: 'Create a video script',
    description: 'Create a 30-second video script for my product',
    value:
      'Generate a 30-second video script for my product based on the information below. \n \nProduct Information\nproduct name: Boolvideo;\nkey point: AI video genreator, turn everthing into stunning videos, easy but powerful online editor;\nbrand name: Boolv.\n \nDelete all the scene description and only include lines by the narrator.',
  },
  {
    label: 'Create a slogan',
    description: 'Create a slogan for a fashion brand',
    value:
      'Create a unique tagline for my fashion brand that appeals to luxury shoppers.',
  },
  {
    label: 'Create an image',
    description: 'Create an image of a man eating pizza',
    value: 'Create an image of a man eating pizza',
  },
  {
    label: 'Create a sticker',
    description: 'Create a sticker of a lovely cat',
    value: 'Create a sticker of a lovely colored cat.',
  },
];

const handleToHistory = () => {
  savePrePage('preset')
  changeCurrentPanel('history')
}
</script>

<style lang="scss" scoped>
.preset-container {
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  .tip {
    color: #8f959e;
    text-align: center;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.title {
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: 46px;
  margin-bottom: 24px;
}

.prompt-box {
  padding: 16px;
  border-radius: 6px;
  border: 0.5px solid #e5e7eb;
  cursor: pointer;
  background: #ffffff;
  transition: background 0.16s linear 0s;
  &:hover {
    background: rgba(31, 35, 41, 0.08);
  }
}

.prompt-box + .prompt-box {
  margin-top: 18px;
}

.name {
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 4px;
}

.description {
  color: #646a73;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
</style>
