<template>
  <el-dialog
    class="script-hook-voiceover-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-wrapper">
        <div class="header-container">
          <span>Choose a viral voiceover</span>
          <icon-button
            name="icon_close"
            color="#646A73"
            :size="20"
            @click="handleClose"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="title-container">
          <div class="label-rank">Rank</div>
          <div class="label-hooks">Hooks</div>
          <div class="label-likes text-center" style="min-width: 59px;">Likes</div>
        </div>
        <div class="scroll-wrapper">
          <el-scrollbar class="scroll-container">
            <el-skeleton animated :loading="loading && page === 0">
              <template #template>
                <el-skeleton-item v-for="(_, i) in Array(40)" variant="rect" :key="i" />
              </template>
              <div 
                class="voiceover-list"
                v-infinite-scroll="loadMore"
                :infinite-scroll-distance="50"
                :infinite-scroll-immediate="false"
                :style="{ paddingBottom: loadAll? '180px' : '0'}"
              >
                <div 
                  v-for="(item, vIndex) in voiceoverList"
                  class="voiceover-item"
                  :class="{active: voiceover == item}"
                  @click="voiceoverClick(item)"
                >
                  <div class="label-rank">
                    <img 
                      v-if="voiceover == item" 
                      :src="icon_selected" 
                      alt=""
                    >
                    <span v-else>{{ vIndex + 1 }}</span>
                  </div>
                  <div class="label-hooks">{{ item.text }}</div>
                  <div class="label-likes text-end">
                    <span v-if="voiceover != item">{{ formatNumber(item.likes) }}</span>
                    <span 
                      v-else
                      class="flex items-center" 
                    >
                      <div class="icon-play-wrapper">
                        <svg-icon
                          clickable
                          v-if="currentAudio.url === item.url && currentAudio.playing"
                          name="editor_pause"
                          :size="24"
                          @click.stop="pause"
                        />
                        <svg-icon
                          clickable
                          v-else
                          name="editor_play"
                          color="#1C1B1E"
                          :size="24"
                          @click.stop="play(item.url)"
                        />
                      </div>
                      <span>
                        {{ dayjs.duration(item.duration, "seconds").format("mm:ss") }}
                      </span>

                    </span>
                  </div>
                </div>
              </div>
            </el-skeleton>
            <Loading v-show="!loadAll && loading && page > 1"/>
          </el-scrollbar>
        </div>
      </div>
      <div class="footer-wrapper">
        <primary-button 
          size="small"
          :disabled="!voiceover" 
          @click="replaceClick"
        >
          <span style="width: 108px">
            Replace
          </span>
        </primary-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getSimilarViralHook } from '@/api/similarVideo';
import Loading from './loading.vue';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
dayjs.extend(duration);

const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  scene: {
    type: Object,
  }
});

const size = 20;
const page = ref(0);
const loadAll = ref(false);
const loading = ref(false);
const voiceover = ref(null);
const voiceoverList = ref([]);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  loading: true,
  currentTime: 0,
});

const formatNumber = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return (num / 1000000).toFixed(1) + 'M';
  }
};

const play = (url) => {
  if (currentAudio.url !== url) {
    destroyAudio();
    const audio = new Audio(url);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.oncanplay = () => { 
      currentAudio.loading = false;
    };
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.url = url;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
};

const pause = () => {
  if (!currentAudio.audio) return;
  currentAudio.playing = false;
  currentAudio.audio.pause();
};

const seekTo = (value) => {
  currentAudio.audio.currentTime = value;
};

const destroyAudio = () => {
  if (currentAudio.audio) {
    currentAudio.url = null;
    currentAudio.playing = false;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio.pause();
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.loading = true;
  }
}

const handleClose = () => {
  emits("update:modelValue", false);
  destroyAudio();
};

const voiceoverClick = (value) => {
  pause();
  voiceover.value = value;
};

const replaceClick = () => {
  emits("confirm", voiceover.value);
  handleClose();
}

const loadMore = async () => {
  if (loadAll.value) return;

  loading.value = true;
  const params = {
    size: size,
    page: page.value + 1,
  };
  
  const {code, data} = await getSimilarViralHook(params);
  if (code === 0) {
    if (data.records.length > 0) {
      voiceoverList.value = [...voiceoverList.value, ...data.records];
      page.value ++;
    }
    else if (data.records.length === 0) {
      loadAll.value = true;
    }
  }
  loading.value = false;
}

const setup = async () => {
  await loadMore();
};

onBeforeMount(() => {
  setup()
    .then(() => {
      if (voiceoverList.value.length > 0) {
        voiceover.value = voiceoverList.value[0];
      }
    });
});
</script>

<style>
.script-hook-voiceover-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.script-hook-voiceover-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-hook-voiceover-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-wrapper {
  flex: 0 0;
  padding: 20px 46px 24px;;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.footer-wrapper {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-container {
  width: 100%;
  display: flex;
  gap: 14px;
  padding: 0 66px;
  margin-bottom: 12px;
  color:  #646A73;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.label-rank {
  display: flex;
  justify-content: center;
  flex: 0 0 34px;
}

.label-hooks {
  flex: 1 1;
}

.label-likes {
  flex: 0 0;
}

.scroll-wrapper {
  height: 100%;
  padding: 0 5px;
}

.scroll-container {
  padding: 0 41px;
}

.voiceover-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.voiceover-item {
  width: 100%;
  min-height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 4px;
  background: #F3F5F7;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  cursor: pointer;

  &.active,
  &:hover {
    background: #F8F5FF;
  }
}

.icon-play-wrapper {
  margin-right: 4px;
}
</style>