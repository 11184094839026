<script lang="ts" setup>
import { watch, ref , onMounted, defineProps} from 'vue';
import { ArrowRight } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { breadcrumbList } from '@/router/routes';
import { getItem, sessionGetItem } from '@/utils/storage';

const props = defineProps({
  minWidth: Number
})

type menuType = {
  path: string;
  menuName: string;
  preMenu: string | null;
};

const router = useRouter();
const menuList = ref<menuType[]>([]);

const findMenuItem = (path: string, list) => {
  const item = breadcrumbList.find((menu) => menu?.path == path);
  list.unshift(item);
  if (item?.preMenu) {
    findMenuItem(item.preMenu?.path, list);
  }
};

const formateMenuList = (path: string) => {
  if (!path) {
    return;
  }

  const list: menuType[] = [];
  findMenuItem(path, list);
  menuList.value = list;
};

watch(
  () => router.currentRoute.value.path,
  (value) => {
    if (value) {
      formateMenuList(value);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

const handlePath = (path: string) => {
  let query = {};
  switch (path) {
    case '/product-to-video':
    case '/product-to-video/previewer':
      query = {
        // reqId: getItem("reqId"),
        // ratio: getItem("ratio"),
        type: 'feature',
      };
      router.replace({
        path: path,
        query,
      });
      break;
    case '/visuals-to-video':
    case '/visuals-to-video/previewer':
      query = {
        // reqId: getItem("reqId"),
        // ratio: getItem("ratio"),
        type: 'feature',
      };
      router.replace({
        path: path,
        query,
      });
      break;
    case '/template-to-video/previewer':
      query = {
        reqId: getItem('reqId'),
        ratio: getItem('ratio'),
        type: 'feature',
      };
      router.replace({
        path: path,
        query,
      });
      break;
    case '/template-to-video':
      const preParams = sessionGetItem('generateParams');
      query = {
        templateId: preParams.templateId,
        ratio: getItem('ratio'),
        type: 'feature',
      };
      router.replace({
        path: path,
        query,
      });
      break;
    case '/aiTools/upload':
      const aiToolType = getItem('aiToolType');
      query = {
        aiToolType: aiToolType,
      };
      router.replace({
        path: path,
        query,
      });
      break;
    default:
      router.replace({
        path: path,
      });
      break;
  }
};

</script>

<template>
  <el-breadcrumb
    :separator-icon="ArrowRight"
    v-if="menuList && menuList.length > 1"
    id="breadcrumb"
  >
    <el-breadcrumb-item
      @click="handlePath(item?.path)"
      v-for="item in menuList"
      class="cursor-pointer"
      >{{ item?.menuName }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>

<style lang="scss">
.el-breadcrumb__inner.is-link {
  color: #8f959e;
  font-size: 14px;
  font-weight: 400;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  .el-icon svg {
    color: #000 !important;
  }

  color: #000;

  &:hover {
    color: #000;
  }
}
</style>
