<template>
  <div class="h-full">
    <component :is="isMobileDevice ? MobileComponent : PCComponent"></component>
  </div>
</template>
<script setup>
import {ref} from 'vue';
import PCComponent from './pcIndex.vue';
import MobileComponent from './mobileIndex.vue';

const isMobileDevice = ref(false);

const judeIsMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};
onMounted(() => {
  isMobileDevice.value = judeIsMobileDevice();
});
</script>
