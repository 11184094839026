<template>
  <div>
    <Form></Form>
  </div>
</template>

<script setup lang="ts">
import Form from './components/form.vue';
import { sessionGetItem } from '@/utils/storage';
import { useTrackStore } from '@/store/modules/track';
const { clearEventData, collectData, track } = useTrackStore();

onMounted(() => {
  collectData('boolvideo_page_click_result', {
    video_type: 'blog_to_video',
    click_type: sessionGetItem('create_from'),
    result: 'success'
  });
  track('boolvideo_page_click_result');
});
</script>
