<template>
  <div class="discord-tip-container" @click.stop :style="`${rootStyle}`">
    <div class="close-box">
      <svg-icon
        name="icon_tip_close"
        :style="{
          width: '20px',
          height: '20px'
        }"
        @click.stop="handleClose"
      ></svg-icon>
    </div>
    <svg-icon name="icon_left_arrow"  class="absolute arrow" :style="{
      'width': '30px', height: '20px'
    }" ></svg-icon>

    <p class="title">Join our vibrant community!</p>
    <p class="detail">Get first access to insider info, enjoy free perks 🎁, share your ideas, and make valuable connections.</p>
    <div class="flex justify-end">
      <div class="button" @click.stop="handleConfirm">
      Join
    </div>
    </div>

  </div>
</template>

<script setup>
import { setItem } from '@/utils/storage'
const emit = defineEmits(['close', 'confirm'])

const props = defineProps({
  rootStyle: {
    type: String,
    default: '',
  },
});

const testStyle = props.rootStyle;

watch(() => props.rootStyle, (val) => {
  console.log(val);
});

const handleClose = () => {
  setItem('isShowCommunityTip', true)
  emit('close', true)
}

const handleConfirm = () => {
  setItem('isShowCommunityTip', true)
  emit('confirm')
  emit('close', true)
}

</script>

<style lang="scss" scoped>
.discord-tip-container {
  width: 324px;
  padding: 20px 24px 24px 24px;
  border-radius: 6px;
  background: #6741FF;

  .arrow {
    position: relative;
    top: 130px;
    left: -43px;
  }

  .close-box {
    position: absolute;
    right: 24px;
    top: 20px;
    padding: 4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #DEE0E399;
    }
  }

  .title {
    color: #FFF;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .detail {
    color: #FFF;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-bottom: 20px;
  }

  .button {
    width: 100px;
    height: 36px;
    display: flex;
    padding: 7px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    background: #FFF;
    cursor: pointer;

    &:hover {
      background: #EBEDEF;
    }
  }
}
</style>