<template>
  <div class="hook-container">
    <svg-icon name="hook_selected_triangle" class="decoration" :size="30" />
    <HookCover
      :key="mKey"
      :scene="scene"
      :node="materialNode"
      @click="materialClick"
    />
    <div class="hookinfo-container">
      <HookHeader :scene="scene" :canPlay="false" />
      <plain-button size="extra-small" @click="handleClick"
        >Need lip-syncing?</plain-button
      >
    </div>
  </div>
  <div class="tools-wrapper">
    <HookTools :scene="scene" />
  </div>
  <HookMaterialDialog
    v-model="materialVisble"
    :scene="scene"
    @confirm="handleReplaceHook"
  />
</template>

<script setup>
import { useMessage } from "@/utils";
import HookTools from "./hooktools.vue";
import HookCover from "./hookcover.vue";
import HookHeader from "./hookheader.vue";
import { replaceAllVoice, parseNode } from "../../utils/scene";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import HookMaterialDialog from "../dialog/hookmaterialdialog.vue";
import { useModalManager } from "@/components/common/custom-modal/instance";
import { useTrackStore } from "@/store/modules/track";
import { getSimilarVoiceList } from "@/api/similarVideo";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
});

const { collectData, track } = useTrackStore();
const { ratio, videoType, updateDraft, setAvatarType } = useDraftStore();
const {
  scenes,
  creator,
  totalFrame,
  defaultLanguage,
  defaultVoiceStyle,
  setDefaultVoice,
  updateSpeech,
  refresh,
  pause,
} = useScriptStore();

const message = useMessage();
const modalManager = useModalManager();
const showLipSync = ref(false);
const materialNode = ref(null);
const materialVisble = ref(false);
const editorLoading = inject("editorLoading");
const replaceHookStartTime = ref(0);

const mKey = computed(() => {
  let key = "";
  if (materialNode.value) {
    key = materialNode.value.id;
  }
  return key;
});

watch(
  () => props.scene.defaultVoiceName,
  async (defaultVoiceName) => {
    const response = await getSimilarVoiceList({
      page: 0,
      size: 20,
      style: 3,
      type: 1,
    });

    if (response.success) {
      showLipSync.value = response.data.records.some(
        (item) => item.voiceId === defaultVoiceName
      );
    }
  },
  { immediate: true }
);

watch(
  () => props.scene.nodes,
  (newNodes) => {
    if (!props.scene.sceneId || !newNodes) return;

    const { videos } = parseNode(newNodes);

    if (videos.length > 0) {
      materialNode.value = videos[0];
    }
  },
  {
    immediate: true,
  }
);

function handleClick() {
  collectData("similarvideo_need_click");
  track("similarvideo_need_click");
  modalManager.applyTemplate("confirm", {
    title: "Pay extra to unlock lip-syncing ($2 per minute)",
    key: "confirm-1",
    confirmText: "Buy it",
    showCancel: false,
    onConfirm: () => {
      collectData("similarvideo_buy_click");
      track("similarvideo_buy_click");
      setTimeout(() => {
        modalManager.applyTemplate("confirm", {
          showClose: false,
          showCancel: false,
          key: "confirm-2",
          content:
            "Lip syncing is coming soon, we will notify you as soon as it is launched",
          onConfirm: () => {
            collectData("similarvideo_ok_click");
            track("similarvideo_ok_click");
          },
        });
      }, 150);
    },
  });
}

const materialClick = () => {
  pause();
  materialVisble.value = true;
};

const replaceHook = (sticker) => {
  const { scene } = props;
  const { videos } = parseNode(scene.nodes);
  const newEnd = Math.min(Math.floor(sticker.duration * 30), totalFrame.value);
  const newConf = {
    name: sticker.name,
    src: sticker.preview480Url,
    hdUrl: sticker.preview1080Url,
    coverPic: sticker.coverPic,
    materialMeta: {
      width480: sticker.width480,
      width1080: sticker.width1080,
      url480: sticker.preview480Url,
      url1080: sticker.preview1080Url,
    },
    end: newEnd,
  };
  Object.assign(videos[0].conf, newConf);
};

const handleReplaceHook = (sticker, type) => {
  replaceHookStartTime.value = new Date();
  setAvatarType(type);
  pause();
  editorLoading.value = true;

  const newEnd = Math.min(Math.floor(sticker.duration * 30), totalFrame.value);
  const params = {
    language: defaultLanguage.value,
    voiceName: sticker.voiceId,
    voiceStyle: defaultVoiceStyle.value,
  };
  replaceHook(sticker);
  replaceAllVoice(params)
    .then(() => {
      Object.assign(props.scene, {
        hookName: sticker.name,
        end: newEnd,
      });
      triggerRef(creator);
      nextTick(refresh);
      updateDraft();
      message.success("Processing completed");
    })
    .catch((e) => {
      console.error(e);
      message.error("Processing failed");
    })
    .finally(() => {
      editorLoading.value = false;
      let currentTime = new Date();
      collectData("similarvideo_scene_edit_click_replace_avatar", {
        avatar_type: type == 0 ? "ordinary" : "self_built",
        replace_time: currentTime - replaceHookStartTime.value,
        replace_celebrity: media.name,
        replace_celebrity_fragment: media.mediaId,
      });
      track("similarvideo_scene_edit_click_replace_avatar");
    });
};
</script>

<style lang="scss" scoped>
.hook-container {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f8f5ff;
  display: flex;
  gap: 12px;

  & > .decoration {
    position: absolute;
    left: -1px;
    top: -1px;
  }
}

.hook-container.active {
  border-color: #a378ff;
}

.hookinfo-container {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
}
.bv-plain-button {
  margin-left: 10px;
}

.tools-wrapper {
  margin-top: 8px;
  opacity: 0;
  pointer-events: none;
}
</style>
