<template>
  <transition name="fade">
    <div v-show="visible" class="clip-dialog">
      <header class="modal-header">
        <div class="header-left">
          <icon-button
            v-show="showBack"
            name="icon_arrow_left_brandkit"
            color="#1C1B1E"
            :size="24"
            @click="back"
          />
          <span class="modal-title">Trim</span>
        </div>
        <svg-icon class="modal-close-btn" name="icon_close" @click="close" />
      </header>
      <div class="modal-content">
        <div ref="container" class="preview-container">
          <img v-show="!canPlay" :src="poster" />
        </div>
        <div class="play-container">
          <svg-icon
            clickable
            v-show="playing"
            name="editor_pause"
            :size="24"
            @click="pause"
          />
          <svg-icon
            clickable
            v-show="!playing"
            name="editor_play"
            color="#1C1B1E"
            :disabled="!canPlay"
            :size="24"
            @click="play"
          />
          <span class="time" :class="{ disabled: !canPlay }">
            <span class="current-time">{{ frameToHms(currentFrame) }}</span>
            <span class="duration">{{ ` / ${frameToHms(totalFrame)}` }}</span>
          </span>
        </div>
        <div class="control-container">
          <div ref="framesRef" class="frames">
            <div
              class="frame"
              v-for="i in frameNum"
              :key="i"
              :style="getStyle(i - 1)"
            ></div>
          </div>
          <div class="frame-mask">
            <div
              class="clip-container"
              :style="clipStyle"
              @mousedown.stop="cliperDown"
            >
              <div class="clip-mask"></div>
              <div class="clip-cursor" :style="cursorStyle"></div>
              <div class="clip-border"></div>
              <div class="clip-duration">
                {{ `${frame2Seconds(duration).toFixed(1)}s` }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <primary-button :disabled="disabled" @click="apply"
          >Apply</primary-button
        >
      </div>
    </div>
  </transition>
</template>

<script setup>
import { getVideoSpritesheet } from "@/api/material";
import PrimaryButton from "@/components/common/bv-button/components/primary-button.vue";
import { frameToHms, clamp } from "../../utils";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  showOverlay: {
    type: Boolean,
    default: true,
  },
  showBack: {
    type: Boolean,
    default: true,
  },
  src: {
    type: String,
    default: null,
  },
  poster: {
    type: String,
    default: null,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  duration: {
    type: Number,
    default: 0,
  },
  apply: {
    type: Function,
    default: () => {},
  },
});

const FPS = 30;
const videoSpritesheetMap = reactive({});

const dragStart = ref(false);
const dragging = ref(false);
const beforePosition = reactive({ pageX: 0 });
const ticker = ref(null);
const visible = ref(props.modelValue);
const currentFrame = ref(0);
const totalFrame = ref(0);
const playing = ref(false);
const container = ref(null);
const element = ref(null);
const canPlay = ref(false);
const canvas = ref(null);
const alphaCanvas = ref(null);
const spritesheet = ref(null);
const framesRef = ref(null);
const frameWidth = ref(0);
const frameHeight = ref(0);
const frameNum = ref(0);
const cliperLeft = ref(0);
const start = computed(() => cliperLeft.value / frameW.value);
const end = computed(() => start.value + props.duration);
const previewer = computed(() => alphaCanvas.value || element.value);
const disabled = computed(() => !canPlay.value || frameNum.value === 0);
const frameW = computed(() =>
  !framesRef.value || !totalFrame.value
    ? 0
    : framesRef.value.clientWidth / totalFrame.value
);
const cursorStyle = computed(() => ({
  left: `${currentFrame.value * frameW.value - cliperLeft.value}px`,
}));
const clipWidth = computed(() => frameW.value * props.duration);
const clipStyle = computed(() => ({
  left: `${cliperLeft.value}px`,
  width: `${clipWidth.value}px`,
}));

onMounted(() => {
  document.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("mouseup", handleMouseUp);
});
onUnmounted(() => {
  document.removeEventListener("mousemove", handleMouseMove);
  window.removeEventListener("mouseup", handleMouseUp);

  destroy();
});

watch(visible, (value) => {
  emit("update:modelValue", value);

  if (!value) {
    pause();
    seekTo(0);
    cliperLeft.value = 0;
  }
});
watch(
  () => props.modelValue,
  (value) => {
    visible.value = value;
  }
);
watch(
  () => props.src,
  (newSrc) => {
    if (newSrc) {
      destroy();
      getSpritesheet(newSrc);
      load(newSrc);
    }
  },
  { immediate: true }
);
watch([spritesheet, canPlay], () => nextTick(setup));

function close() {
  props.apply(-2);
  visible.value = false;
}

function back() {
  props.apply(-1);
  setTimeout(() => {
    visible.value = false;
  }, 150);
}

function apply() {
  props.apply(start.value);
  visible.value = false;
}

function handleMouseMove(e) {
  if (dragStart.value) {
    drag(e);
  }
}

function handleMouseUp() {
  beforePosition.pageX = 0;
  dragStart.value = false;

  if (dragging.value) {
    dragging.value = false;
  }
}

function drag(e) {
  if (!framesRef.value) {
    return;
  }
  dragging.value = true;

  const deltaX = snapToGrid(beforePosition.pageX - e.pageX);
  const maxLeft = framesRef.value.clientWidth - clipWidth.value;
  const newLeft = clamp(beforePosition.left - deltaX, 0, maxLeft);
  cliperLeft.value = newLeft;
  seekTo(newLeft / frameW.value);
}

function snapToGrid(delta) {
  return Math.round(delta / frameW.value) * frameW.value;
}

function cliperDown(e) {
  pause();

  dragStart.value = true;

  beforePosition.pageX = e.pageX;
  beforePosition.left = cliperLeft.value;
}

async function play() {
  if (playing.value || !element.value) {
    return;
  }
  if (currentFrame.value >= end.value) {
    await seekTo(start.value);
  }
  playing.value = true;
  await element.value.play();

  let lastTime = performance.now();
  let nextUpdateTime = 0;

  const tick = (now) => {
    if (currentFrame.value >= end.value) {
      pause();
    } else {
      const elapsed = now - lastTime;
      nextUpdateTime = Math.floor(nextUpdateTime - elapsed);

      if (nextUpdateTime <= 0) {
        currentFrame.value++;
        draw();
        nextUpdateTime = Math.floor(1000 / FPS);
      }
      if (playing.value) {
        ticker.value = requestAnimationFrame(tick);
      }
    }
    lastTime = now;
  };
  ticker.value = requestAnimationFrame(tick);
}

function pause() {
  playing.value = false;

  if (element.value) {
    element.value.pause();
  }
  if (ticker.value) {
    cancelAnimationFrame(ticker.value);
  }
  ticker.value = null;
}

function frame2Seconds(frame) {
  return Math.floor((frame / FPS) * 1000) / 1000;
}

function secondsToFrame(seconds) {
  return Math.floor(seconds * FPS);
}

function load(src) {
  element.value = document.createElement("video");

  if (props.transparent) {
    canvas.value = document.createElement("canvas");
    alphaCanvas.value = document.createElement("canvas");
  }
  const handleLoaded = () => {
    if (alphaCanvas.value) {
      canvas.value.width = element.value.videoWidth;
      canvas.value.height = alphaCanvas.value.height =
        element.value.videoHeight;
      alphaCanvas.value.width = element.value.videoWidth / 2;
    }
    totalFrame.value = secondsToFrame(element.value.duration);
    canPlay.value = true;
    container.value.appendChild(previewer.value);
    seekTo(0);
  };
  element.value.addEventListener("loadedmetadata", handleLoaded, {
    once: true,
  });
  element.value.crossOrigin = "anonymous";
  element.value.src = src;
  element.value.load();
}

function destroy() {
  spritesheet.value = 0;
  currentFrame.value = 0;
  totalFrame.value = 0;
  canPlay.value = false;
  playing.value = false;
  frameNum.value = 0;
  frameWidth.value = 0;
  frameHeight.value = 0;

  pause();

  if (element.value) {
    element.value.src = "";
    element.value.load();
  }
  if (previewer.value) {
    const parentElement = previewer.value.parentElement;

    if (parentElement) {
      parentElement.removeChild(previewer.value);
    }
  }
  element.value = null;
  canvas.value = null;
  alphaCanvas.value = null;
}

function seekTo(frame) {
  return new Promise((resolve) => {
    const handleSeeked = () => {
      element.value.removeEventListener("seeked", handleSeeked);
      currentFrame.value = Math.min(frame, totalFrame.value);
      draw();
      resolve();
    };
    element.value.addEventListener("seeked", handleSeeked);
    element.value.currentTime = frame2Seconds(frame);
  });
}

function draw() {
  if (!alphaCanvas.value) {
    return;
  }
  const context = canvas.value.getContext("2d");
  const alphaContext = alphaCanvas.value.getContext("2d");
  const w = canvas.value.width;
  const h = canvas.value.height;
  const hw = w / 2;

  context.drawImage(element.value, 0, 0, w, h);

  const mask = context.getImageData(0, 0, hw, h);
  const source = context.getImageData(hw, 0, hw, h);

  for (let i = 3; i < source.data.length; i += 4) {
    source.data[i] = mask.data[i - 1];
  }
  alphaContext.putImageData(source, 0, 0);
}

async function getSpritesheet(url) {
  if (!videoSpritesheetMap[url]) {
    const response = await getVideoSpritesheet({ url });

    if (response.success) {
      videoSpritesheetMap[url] = response.data;
    }
  }
  spritesheet.value = videoSpritesheetMap[url];
}

function setup() {
  if (!framesRef.value || !spritesheet.value || !canPlay.value) {
    return;
  }
  const width = framesRef.value.clientWidth;
  const height = framesRef.value.clientHeight;
  const transparent = props.transparent >> 0;
  const ratio =
    element.value.videoWidth / (transparent + 1) / element.value.videoHeight;
  const w = height * ratio;

  frameNum.value = Math.ceil(width / w);
  frameWidth.value = w;
  frameHeight.value = height;
}

function getStyle(i) {
  const { url, col, row, frames } = spritesheet.value;
  const width = frameWidth.value;
  const height = frameHeight.value;
  const transparent = props.transparent >> 0;
  const backgroundWidth = width * (transparent + 1) * col;
  const backgroundHeight = height * row;
  const secWidth = frameW.value * FPS;
  const index = Math.min(Math.floor((i * width) / secWidth), frames - 1);
  const x = width * (index % col) * (transparent + 1) + width * transparent;
  const y = height * Math.floor(index / col);

  return {
    width: `${width}px`,
    backgroundSize: `${backgroundWidth}px ${backgroundHeight}px`,
    backgroundImage: `url(${url})`,
    backgroundPosition: `${-x}px ${-y}px`,
  };
}
</script>
<style scoped>
.clip-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 80%;
  border-radius: 4px;
  background-color: #fff;
}
.frame-mask {
  position: absolute;
  inset: 0;
  background-color: #00000080;
}
.clip-container {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  cursor: grab;
}
.clip-mask {
  position: absolute;
  inset: 0;
  background-color: #fff;
  mix-blend-mode: overlay;
}
.clip-cursor {
  position: absolute;
  transform: translate(-50%);
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #333;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.clip-border {
  position: absolute;
  inset: 0;
  border: 2px solid #6741ff;
}
.clip-duration {
  position: absolute;
  bottom: 6px;
  right: 8px;
  padding-inline: 7px;
  font-size: 12px;
  border-radius: 2px;
  color: #fff;
  background-color: #000000b3;
}
.play-container svg {
  margin-right: 14px;
}

.play-container .time {
  font-size: 14px;
  height: 22px;
}

.play-container .time.disabled .current-time,
.play-container .time.disabled .duration {
  color: #bbbfc4;
}

.play-container .time .current-time {
  color: #000000;
  line-height: 22px;
  font-variant: tabular-nums;
}

.play-container .time .duration {
  color: #646a73;
  line-height: 22px;
}
.play-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-container {
  display: flex;
  justify-content: center;
  height: calc(100% - 140px);
  padding-top: 10px;
}
.control-container {
  width: 100%;
  height: 70px;
  position: relative;
}
.frames {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.frames .frame {
  height: 100%;
  background-repeat: no-repeat;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 26px 22px 18px;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 4px 4px 0 0;
}

.modal-header .header-left {
  display: flex;
  align-items: center;
}
:deep(.header-left .icon-wapper) {
  padding: 0;
}

.modal-title {
  font-size: 18px;
  font-weight: 500;
  margin-left: 12px;
}

.modal-content {
  height: calc(100% - 174px);
  padding: 0 24px;
}

.modal-footer {
  width: 100%;
  height: 104px;
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
}

.modal-close-btn {
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close-btn:hover {
  background-color: #eaeaea;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
