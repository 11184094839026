<template>
  <div class="script-edit-container">
    <div class="script-edit-header">
      <div 
        class="back" 
        @click="handleBack"
      >
        <svg-icon name="icon_arrow_left_brandkit" color="#1C1B1E" :size="24" />
        Back
      </div>
      <p>Choose your video script</p>
    </div>
    <div class="script-edit-content">
      <switcher 
        v-model="tabValue"  
        :tabs="tabs"
      />

    </div>

  </div>
</template>

<script setup>
import Switcher from './switcher.vue';
const tabs = [
  {
    value: "ai",
    label: "AI script",
  },
  {
    value: "user",
    label: "Write my own",
  },
];

const aiList = ref([]);
const userValue = ref("");
const tabValue = ref("ai");
</script>

<style lang="scss" scoped>
.script-edit-container {
  width: 65%;
  min-width: 800px;
  margin: 0 auto;
}

.script-edit-header {
  position: relative;
  width: 100%;
  margin-top: 42px; 
  display: flex;
  justify-content: center;

  & > p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; 
  }
}

.script-edit-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.switcher-wrapper {
  width: 40%;
  min-width: 299px;
}

.back {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #000000;
  cursor: pointer;

  & > svg {
    margin-right: 2px;
  }
}
</style>