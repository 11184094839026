<template>
  <Teleport to="body">
    <div
      v-show="widgetMenu.visible"
      v-click-outside:[excludes]="hide"
      class="widget-menu"
      ref="element"
      :style="style"
    >
      <div
        v-for="(option, i) in options"
        class="menu-item"
        :class="{ disabled: option.disabled }"
        :key="i"
        @click="handleClick(option.onClick)"
      >
        <div class="item-left">
          <svg-icon
            class="prefix"
            :disabled="option.disabled"
            :name="option.icon"
            :size="18"
          />
          {{ option.label }}
        </div>
        <div v-if="option.description" class="description">
          <div v-if="option.prefix" class="left-text">{{ keyCode + "+" }}</div>
          <div class="right-text">{{ option.description }}</div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import {
  useCreatorStore,
  useCopyStore,
  useDraftStore,
  useHistoryStore,
  useKeyboard,
} from "../../stores";

const { showCropper, widgetMenu, currentNode, removeActiveNodes } =
  useCreatorStore();
const { canPaste, copy, cut, paste, duplicate } = useCopyStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { keyCode } = useKeyboard();

const element = ref(null);
const style = computed(() => ({
  left: `${widgetMenu.left}px`,
  top: `${widgetMenu.top}px`,
}));
const excludes = computed(() => {
  return [element.value, ".widget-tool .widget-tool-more"];
});
const options = computed(() => {
  if (!currentNode.value) {
    return [
      {
        label: "Paste",
        icon: "icon_paste",
        prefix: true,
        description: "V",
        disabled: !canPaste.value,
        onClick: () => {
          canPaste.value && paste();
        },
      },
    ];
  }
  const baseMenu = [
    {
      label: "Copy",
      icon: "icon_copy",
      prefix: true,
      description: "C",
      onClick: copy,
    },
    {
      label: "Cut",
      icon: "icon_cut",
      prefix: true,
      description: "X",
      onClick: () => {
        cut();
        submit();
      },
    },
    {
      label: "Paste",
      icon: "icon_paste",
      prefix: true,
      description: "V",
      disabled: !canPaste.value,
      onClick: () => {
        if (!canPaste.value) {
          return;
        }
        paste();
        submit();
      },
    },
    {
      label: "Duplicate",
      prefix: true,
      icon: "editor_duplicate",
      description: "D",
      onClick: () => {
        duplicate();
        submit();
      },
    },
    {
      label: "Delete",
      icon: "icon_delete",
      description: "⌫",
      onClick: () => {
        removeActiveNodes();
        submit();
      },
    },
    {
      label: "Flip horizontal",
      icon: "editor_flip_x",
      onClick: () => {
        const node = currentNode.value;
        const [flipX, flipY] = node.getFlip();
        node.conf.flip = [-flipX, flipY];
        submit();
      },
    },
    {
      label: "Flip vertical",
      icon: "editor_flip_y",
      onClick: () => {
        const node = currentNode.value;
        const [flipX, flipY] = node.getFlip();
        node.conf.flip = [flipX, -flipY];
        submit();
      },
    },
  ];
  if (["image", "video"].includes(currentNode.value.type)) {
    baseMenu.splice(5, 0, {
      label: "Crop",
      icon: "editor_crop",
      onClick: () => {
        showCropper.value = true;
      },
    });
  }
  return baseMenu;
});

function submit() {
  commit();
  updateDraft();
}

function hide() {
  widgetMenu.visible = false;
}

function handleClick(callback) {
  callback();
  setTimeout(() => hide());
}
</script>
<style>
.widget-menu {
  position: fixed;
  width: 200px;
  z-index: 1000;
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
}
.widget-menu .menu-item {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px;
  color: #060606;
  font-size: 14px;
  line-height: 22px;
  user-select: none;
  cursor: pointer;
}
.widget-menu .menu-item:hover {
  background-color: rgba(31, 35, 41, 0.08);
}
.widget-menu .menu-item.disabled {
  color: #bbbfc4;
}
.widget-menu .menu-item.disabled:hover {
  background: none;
}

.widget-menu .prefix {
  display: inline;
  margin-right: 12px;
}
.widget-menu .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8f959e;
}
.widget-menu .right-text {
  padding: 0 2px;
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
