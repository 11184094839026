import { getUpdateSceneInfo, createNewVersion } from "@/api/script";
import { getDraftDetail } from "@/api/draft";
import { calcRate, getVideoDuration, getAudioDuration } from "./index";
let error = false;
let scenes = [];
let otherScenes = [];
let config = null;
const nodeMap = new Map();

export const translateAllScene = async (draftId, language) => {
  const route = useRoute();
  const { videoType } = route.query;

  const detailRes = await getDraftDetail(draftId);
  if (!detailRes.success) return;
  const { ratio, renderConfig } = detailRes.data;
  const { scriptScene, ...rest } = JSON.parse(renderConfig);
  const voiceType = scriptScene.find(
    scene => scene.type !== "default"
  )?.type || "default";
  otherScenes = scriptScene.filter(
    (item) => item.type !== "default" && item.type
  );
  scenes = scriptScene.filter((item) => item.type === "default" || !item.type);
  const defaultScenesStart = scenes[0].start;
  config = rest;
  parseConfig(config);

  const proList = [];
  for (const index in scenes) {
    const { text, speech } = parseNodes(scenes[index]);
    const lab11 = videoType.startsWith("similar_video");
    const preText = getPreText(scenes, index);
    const nextText = getNextText(scenes, index)
    const params = {
      preText,
      nextText,
      voiceType,
      language,
      size: ratio,
      text,
      autoTranslation: true,
      lab11,
    };
    if (lab11 && speech) {
      params.voiceName = speech.voiceName;
    }
    proList.push(updateSceneFunc(params, scenes[index]));
  }
  await Promise.all(proList)
    .then(async (res) => {
      const newScenes = res.map((item) => item.data);
      for (const i in newScenes) {
        const { voiceName, language } = newScenes[i];
        Object.assign(newScenes[i], {
          sceneId: scenes[i].sceneId,
          defaultVoiceStyle: "general",
          defaultVoiceName: voiceName,
          defaultLanguage: language,
        });
        const { start, end } = newScenes[i];
        const { speech } = parseNodes(newScenes[i]);
        const voiceSpeed = speech?.speed || 1;
        const duration = Math.round((end - start) / voiceSpeed);
        newScenes[i].start = newScenes[i - 1]?.end || defaultScenesStart;
        newScenes[i].end = newScenes[i].start + duration;
        const { voiceText } = newScenes[i];
        for (const ti in voiceText) {
          const { voiceTime } = voiceText[ti];
          const textDuration = Math.round(
            (voiceTime.end - voiceTime.start) / voiceSpeed
          );
          const textStart =
            voiceText[ti - 1]?.voiceTime.end ||
            newScenes[i].start;
          const textEnd = textStart + textDuration;
          voiceTime.start = textStart;
          voiceTime.end = textEnd;
        }
      }

      for (const i in newScenes) {
        await updateNodes(newScenes[i], config);
      }

      const defaultScenes = newScenes.map((item) => {
        const {
          start,
          end,
          sceneId,
          defaultLanguage,
          defaultVoiceName,
          defaultVoiceStyle,
        } = item;
        return {
          start,
          end,
          sceneId,
          defaultLanguage,
          defaultVoiceName,
          defaultVoiceStyle,
        };
      });
      Object.assign(config, {
        scriptScene: [...otherScenes, ...defaultScenes],
      });

      // for throughline audio
      const duration = newScenes[newScenes.length - 1].end - newScenes[0].start;
      await updateOverall(duration);
    })
    .catch((e) => {
      console.error(e);
      error = true;
    });

  if (error) return;
  const newScene = config.scriptScene;
  const { success, data } = await createNewVersion({
    draftId,
    language,
    renderConfig: JSON.stringify(config),
    duration: newScene[newScene.length - 1].end / 30,
  });
  if (!success) return;
  return data.draftId;
};

const updateSceneFunc = (params, oldScene) => {
  return new Promise((resolve, reject) => {
    const { text } = params;
    if (!text) {
      const res = {
        data: oldScene,
      };
      resolve(res);
    } else {
      getUpdateSceneInfo(params)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

const parseConfig = (config) => {
  for (const track of config.children) {
    for (const i of track.children) {
      const node = track.kind === "primary" ? i.children[0] : i;
      const sceneId = node.sceneId;
      if (nodeMap.has(sceneId)) {
        nodeMap.get(sceneId).push(node);
      } else {
        nodeMap.set(sceneId, [node]);
      }
    }
  }
};

export function getPreText(sceneArray, index) {
  if (index == 0) return "";
  else {
    return parseNodes(sceneArray[Number(index) - 1]).text;
  }
} 

export function getNextText(sceneArray, index) {
  if (index == (sceneArray.length - 1)) return "";
  else {
    return parseNodes(sceneArray[Number(index) + 1]).text;
  }
}


const parseNodes = (scene) => {
  const { sceneId } = scene;
  const nodes = nodeMap.get(sceneId) || [];
  let text = "";
  let speech = null;
  let material = null;
  let videos = [];
  let texts = [];
  for (const node of nodes) {
    if (node.type === "subtitle") {
      texts.push(node);
      text += node.text;
    }
    if (node.type === "speech") {
      speech = node;
    }
    if (["image", "video"].includes(node.type)) {
      material = node;
    }
    if (node.type === "video") {
      videos.push(node);
    }
  }
  return {
    text,
    speech,
    texts,
    material,
    videos,
  };
};

const getTrack = (type, config) => {
  // voice_sticker:
  // 0: primary
  // 2: subtitle
  // 3: speech
  // 4: bgm
  // normal:
  // 0: primary
  // 1: subtitle
  // 2: speech
  // 3: bgm
  const hasViralHook = otherScenes.some((item) => item.type === "voice_sticker");
  const tracks = config.children;
  if (hasViralHook) {
    switch(type) {
      case "image":
        return tracks[0];
      case "video":
        return tracks[0];
      case "subtitle":
        return tracks[2];
      case "speech":
        return tracks[3];
      case "audio":
        return tracks[4];
    }
  }
  else {
    switch(type) {
      case "image":
        return tracks[0];
      case "video":
        return tracks[0];
      case "subtitle":
        return tracks[1];
      case "speech":
        return tracks[2];
      case "audio":
        return tracks[3];
    }
  }
  for (const track of tracks) {
    if (track.children[0].type === type) {
      return track;
    }
  }
  return null;
};

const updateNodes = async (newScene, config) => {
  const { sceneId, start, end, voiceName, voiceStyle, voiceText, voiceUrl } =
    newScene;
  const nodes = nodeMap.get(sceneId) || [];
  for (const node of nodes) {
    //for primary
    if (["image", "video"].includes(node.type)) {
      if (node.type === "video" && node.active) {
        const oldDuration = node.end - node.start;
        const newDuration = end - start;
        if (newDuration > oldDuration) {
          node.speed = calcRate(oldDuration, newDuration);
          node.ss = Math.floor(node.ss / node.speed);
        }
      }
      Object.assign(node, {
        start,
        end,
      });
    }
    // for speech
    if (node.type === "speech") {
      Object.assign(node, {
        start,
        end,
        voiceName,
        voiceStyle,
        src: voiceUrl,
      });
    }
    // for sticker
    if (node.type === "sticker") {
      Object.assign(node, {
        start,
        end,
      });
    }

    // for effect
    if (node.type === "effect") {
      Object.assign(node, {
        start,
        end,
      });
    }
  }

  if (voiceText) {
    // for text
    let textTrack = getTrack("subtitle", config);
    const textConfig = { ...textTrack.children[0] };
    const newTextTrack = { ...textTrack };
    newTextTrack.children = textTrack.children.filter(
      (i) => i.sceneId !== sceneId
    );
    for (const i of voiceText) {
      const startTime = i.voiceTime.start;
      const endTime = i.voiceTime.end;
      const newNode = {
        ...textConfig,
        start: startTime,
        end: endTime,
        text: i.text,
        sceneId,
        wordBoundars: i.wordBoundars,
      };
      newTextTrack.children.push(newNode);
    }
    textTrack.children = newTextTrack.children;
  }
};

const updateOverall = async (duration) => {
  const updateBgm = async (duration) => {
    // for bgm
    const bgmTrack = getTrack("audio", config);
    const bgmNode = { ...bgmTrack.children[0] };
    const bgmStart = bgmTrack.children[0].start;
    const { src, ss } = bgmNode;
    const audioDuration = await getAudioDuration(src).then((res) =>
      Math.floor(res * 30 - ss)
    );
    const newChildren = [];
    const count = Math.floor(duration / audioDuration);
    for (let i = 0; i < count; i++) {
      const start = newChildren[i - 1]?.end || bgmStart;
      const end = start + audioDuration;
      newChildren.push({
        ...bgmNode,
        start,
        end,
      });
    }
    const rest = duration % audioDuration;
    const lastIndex = newChildren.length - 1;
    const start = newChildren[lastIndex]?.end || bgmStart;
    const end = start + rest;
    newChildren.push({
      ...bgmNode,
      start,
      end,
    });
    bgmTrack.children = newChildren;
  };
  const updateFilter = (duration) => {
    // for filter
    const filterTrack = getTrack("filter", config);
    if (filterTrack) {
      const filterNode = filterTrack.children[0];
      const newStart = filterTrack.children[0].start;
      const newEnd = filterTrack.children[0].start + duration;
      Object.assign(filterNode, {
        start: newStart,
        end: newEnd,
      });
    }
  };
  const updateOverallScene = async (total) => {
    const overallStickerScenes = otherScenes.filter(
      (item) => item.type === "voice_sticker"
    );
    if (overallStickerScenes.length === 0) return;
    for (const scene of overallStickerScenes) {
      let newEnd = scene.end;
      const { videos } = parseNodes(scene);
      if (videos.length === 0) return;
      for (const video of videos) {
        const duration = await getVideoDuration(video.src).then(
          (res) => res * 30
        );
        if (total > duration) {
          newEnd = duration;
        } else {
          newEnd = total;
        }
        video.end = newEnd;
      }
      scene.end = newEnd;
    }
  }

  await updateBgm(duration);
  await updateOverallScene(duration)
  updateFilter(duration);
};
