<template>
  <div class="voice-wrapper">
    <div class="voice-header">
      <p class="label"> 
        <span>
          Set a Voice for Your Avatar 
        </span>
        <bv-tip
          raw-content
          placement="bottom"
          :content="tip"
          :showAfter="0"
        >
          <svg-icon 
            name="script_help"
            :size="18"
          />
        </bv-tip>
      </p>
      <bv-uploader
        v-model="files"
        ref="uploader"
        :accept="accept"
        :preUploadOptions="{ toolName: 'similarCharacterVoice' }"
        :beforePreProcess="beforePreProcess"
        @success="refresh"
        @beforeAddFiles="handleBeforeAddFiles"
      />
      <div
        class="voice-upload"
        :class="files[0]?.state != 2 || files.length === 0 ? 'appear' : 'hide'"
        @click="clickUpload"
      >
        <svg-icon name="upload_music_icon" color="#6741FF" :size="18" />
        <span>{{ files.length === 0 ? 'Upload' : 'Replace' }}</span>
      </div>
    </div>
    <div
      class="voice-content"
      :class="{ error: files.length > 0 && files[0].state === 1 }"
    >
      <div class="blank-content" v-if="files.length === 0">
        Upload an audio file for the AI to clone.
      </div>
      <div class="processing-content" v-else-if="files[0].state === 2">
        <span>Voice cloning</span>
        <svg-icon name="icon_loading" :size="14" color="#646A73" />
      </div>
      <div class="success-content" v-else-if="files[0].state === 0">
        <span>{{ info.name || 'Unnamed' }}</span>
      </div>
      <div class="error-content" v-else-if="files[0].state === 1">
        <svg-icon name="icon_warn" :size="24" color="#FFFFFF" />
        <span>Voice cloning failed, please upload again</span>
      </div>
      <svg-icon
        name="editor_pause"
        clickable
        @click="pause"
        :size="24"
        v-show="currentAudio.playing"
      />
      <svg-icon
        name="editor_play"
        clickable
        @click="play"
        :size="24"
        :disabled="files.length === 0 || files[0]?.state !== 0"
        v-show="!currentAudio.playing"
      />
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';
import { AUDIOTYPE } from '@/utils/type.ts';
import { DataUnit } from '@/utils';
import { startAiProcess } from '@/api/character';
import { useSubscriptionInfo } from '@/store/modules/user';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useTrackStore } from '@/store/modules/track';
const { collectData, track } = useTrackStore();

const modalManager = useModalManager();
const subscriptionStore = useSubscriptionInfo();
const { refresh, getUserResource } = subscriptionStore;
const { subscriptionState } = storeToRefs(subscriptionStore);

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});

const accept = [
  {
    types: AUDIOTYPE.split(','),
    maxSize: 50 * DataUnit.MB,
  },
];
const tip = `
  <p style="font-size: 12px; font-weight: 600;">Recommended</p>
  <ul style='list-style: disc; padding: 7px 13px 7px 26px; width: 298px; font-size: 12px; font-weight: 400; lint-height: 22px;'>
    <li>Record in a noise-free environment</li>
    <li>Submit recordings longer than 30 seconds</li>
  </ul>
`;

const files = ref([]);
const uploader = ref(null);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  loading: true,
  currentTime: 0,
});

const play = () => {
  const url = files.value[0].url || files.value[0].previewUrl;
  if (currentAudio.url !== url) {
    destroyAudio();
    const audio = new Audio(url);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.oncanplay = () => {
      currentAudio.loading = false;
    };
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.url = url;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
};

const pause = () => {
  if (!currentAudio.audio) return;
  currentAudio.playing = false;
  currentAudio.audio.pause();
};

const destroyAudio = () => {
  if (currentAudio.audio) {
    currentAudio.url = null;
    currentAudio.playing = false;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio.pause();
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.loading = true;
  }
};

const handleBeforeAddFiles = (file) => {
  collectData('similarvideo_page_avatar_upload', {
    click: 'timbre',
    video_size: file[0].size,
    video_time:  files.value[1]?.duration,
    video_type: file[0]?.type
  });
  track('similarvideo_page_avatar_upload');
}

const beforePreProcess = async (file) => {

  return new Promise((resolve, reject) => {
    const params = {
      characterId: props.info.characterId,
      mid: file.mid,
      type: 2,
    };
    startAiProcess(params).then(({ success, data, code, msg }) => {
      if (success) {
        file.mid = data.aiMid;
        resolve();
      } else {
        if (code === 90014) {
          modalManager.applyTemplate('upgradeTips', {
            msg,
            code,
            onConfirm: () => {
              uploader.value?.openSubscriptionModal();
            },
          });
        }
        reject({ type: 'beforePreProcess' });
      }
    });
  });


};

const clickUpload = () => {
  if (files.value.length !== 0) {
    const refreshData = subscriptionState.value.nextRenewalDateTime
      ? dayjs(subscriptionState.value.nextRenewalDateTime).format(
          'MMM DD, YYYY, H:mm:ss'
        )
      : '-';
    const cloneTimes =
      getUserResource('SIMILAR_VIDEO_CLONE_VOICE_30')?.num || 0;

    modalManager.applyTemplate('confirm', {
      key: 'replace-character-voice-modal',
      title: 'The voice will be replaced',
      content: h('div', { style: { width: '100%' } }, [
        h('p', {}, 'Voice will be re-cloned. Continue uploading?'),
        h('div', { style: { color: '#8F959E' } }, [
          h('p', {}, `Voice clone: ${cloneTimes} remaining`),
          h('p', {}, `Refresh date: ${refreshData}`),
        ]),
      ]),
      confirmText: 'Continue',
      onConfirm: () => uploader.value?.handleClickUpload(),
    });
  } else {
    uploader.value?.handleClickUpload();
  }

  collectData('similarvideo_page_avatar_click', {
    click: 'timbre',
  });

  track('similarvideo_page_avatar_click');
};

const setup = () => {
  const { info } = props;

  if (info.voice) {
    files.value.push({ ...info.voice });
  }
};

onMounted(setup);
onBeforeUnmount(destroyAudio);
</script>

<style lang="scss" scoped>
.voice-wrapper {
  width: 60%;
  min-width: 430px;
  & :deep(.bv-tooltip-content-container) {
    width: fit-content;
    height: fit-content;
  }
}

.voice-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label {
  display: flex;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  & > span {
    margin-right: 6px;
  }
}

.voice-upload {
  padding: 4px 10px;
  border-radius: 4px;
  background: #f8f5ff;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  &.appear {
    opacity: 1;
    pointer-events: all;
  }

  & > span {
    color: #6741ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 8px;
  }
}

.voice-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  margin-top: 18px;
  border-radius: 6px;
  background: #ebedef;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &.error {
    background: #fff5f5;
  }
}

.blank-content {
  color: #646a73;
}

.processing-content {
  display: flex;
  align-items: center;

  & > span {
    margin-right: 8px;
    color: #646a73;
  }

  & > svg {
    animation: rotate 1s linear infinite;
  }
}

.success-content {
  color: #060606;
}

.error-content {
  display: flex;
  align-items: center;
  color: #ff5449;

  & > svg {
    margin-right: 6px;
  }
}
</style>
