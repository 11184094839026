<template>
  <div class="bubble">
    <div class="bubble-header">
      <slot name="avatar" class="avatar" />
    </div>
    <div class="bubble-content">
      <p class="nickname">
        <slot name="nickname" />
      </p>
      <slot name="context" />
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.bubble {
  display: flex;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  padding: 28px 20px 28px 20px;
}

.bubble + .bubble {
  padding-top: 16px;
}

.bubble-header {
  margin-right: 12px;
}

.bubble-content {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & img {
    width: 100%;
  }
}

.nickname {
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
