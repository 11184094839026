import warnIcon from "@/assets/icons/common/icon_warn.svg";
import successIcon from "@/assets/icons/common/icon_success.svg";
import { type DefaultModalTemplate } from "../type";

export default {
  warn(props) {
    this.$resetAndPatch({
      open: true,
      key: props.key || "",
      title: props.title,
      content: props.content,
      icon: props.icon || "warn",
      modalClass: props.modalClass,
      footer: props.footer || null,
      zIndex: props.zIndex
    });
  },
  success(props) {
    this.$resetAndPatch({
      open: true,
      key: props.key || "",
      title: props.title,
      content: props.content,
      icon: "success",
      footer: props.footer || null,
      zIndex: props.zIndex
    });
  },
  info(props) {
    this.$resetAndPatch({
      open: true,
      key: props.key || "",
      modalClass: props.modalClass,
      title: props.title,
      content: props.content,
      footer: props.footer || null,
      zIndex: props.zIndex,
    });
  },
} as DefaultModalTemplate;
