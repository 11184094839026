<template>
  <video
    id="videoId"
    :ref="videoRefHandler"
    :preload="lazy ? (preload === 'none' ? 'none' : 'metadata') : preload"
    :autoplay="lazy ? false : autoplay"
    :muted="muted"
    :playsinline="playsinline"
    :src="src"
    :poster="poster"
    @videofocus="handleVideoFocus"
    @videoblur="handleVideoBlur"
    @canplay="handleCanplay"
    @loadeddata="handleLoaded"
  >
    <slot></slot>
  </video>
</template>

<script setup lang="ts">
import { watch, onMounted, onBeforeUnmount } from 'vue';
import { createRef } from '@/utils';

const emit = defineEmits(['canplay', 'loaded']);

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  // 是否懒加载
  lazy: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String,
    default: 'metadata',
  },
  playsinline: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  // 是否重新加载 (当元素重新进入视窗时)
  reload: {
    type: Boolean,
    default: false,
  },
  // 是否重新开始 (当元素重新进入视窗时)
  restart: {
    type: Boolean,
    default: false,
  },
  poster: {
    type: String,
    default: '',
  },
  volumn: {
    type: Number,
    default: 0.5,
  },
});

const exposeData = {
  props,
  videoRef: null as null | HTMLVideoElement,
};

const videoRef = createRef(exposeData, 'videoRef');

function videoRefHandler(el: Element | ComponentPublicInstance | null) {
  exposeData.videoRef = el as HTMLVideoElement | null;
}

// 编写一个判断元素是否在视窗中的函数
function isInScreen(target: HTMLElement) {
  const clientRect = target.getBoundingClientRect();
  return clientRect.top <= innerHeight && clientRect.bottom >= 0;
}

let inScreen = false;
function handleEventListener() {
  if (isInScreen(videoRef!)) {
    if (!inScreen) {
      inScreen = true;
      videoRef!.dispatchEvent(new Event('videofocus'));
    }
  } else {
    if (inScreen) {
      inScreen = false;
      videoRef!.dispatchEvent(new Event('videoblur'));
    }
  }
}

let canplay = false;
function handleVideoFocus() {
  if (props.autoplay) {
    canplay = true;
  }

  if (props.reload) {
    videoRef!.load();
  } else if (props.restart) {
    videoRef!.currentTime = 0;
  }
}

function handleCanplay() {
  emit('canplay');
}

function handleLoaded() {
  emit('loaded');
}

function handleVideoBlur() {
  canplay = false;
}

function handleScroll() {
  const temp = canplay;

  handleEventListener();

  if (temp === canplay) {
    return;
  }

  if (canplay) {
    videoRef!.play();
  } else {
    videoRef!.pause();
  }
}



watch(
  () => props.volumn,
  (volumn) => {
    if (videoRef != null) {
      videoRef!.volume = volumn;
    }
  }
);

onMounted(() => {
  videoRef!.volume = props.volumn;
  scrollY === 0 && handleScroll();
  window.addEventListener('scroll', handleScroll);

});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

defineExpose(exposeData);
</script>
