import { reactive, h, type VNode } from "vue";
import warnIcon from "@/assets/icons/common/icon_warn.svg";
import successIcon from "@/assets/icons/common/icon_success.svg";
import errorIcon from "@/assets/icons/common/icon_error.svg";
import orangeWarnIcon from "@/assets/icons/common/icon_orange_warn.svg"

const defaultIcon = {
  warn: warnIcon,
  orangeWarn: orangeWarnIcon,
  success: successIcon,
  error: errorIcon,
} satisfies Record<string, string>;

export interface Modal {
  /** 使用key来决定是否复用模态框 */
  key: string;
  open: boolean;
  showClose: boolean;
  modalClass: string;
  content: VNode | string;
  title: VNode | string;
  icon: keyof typeof defaultIcon | VNode | null;
  footer: VNode | string | null;
  scopeId: string;
  zIndex: number,
  onClose: () => void;

  $reset(): void;
  /** 字段值为undefined时跳过赋值 */
  $patch(state: Partial<Modal>): void;
  $resetAndPatch(state: Partial<Modal>): void;
}

export class Modal {
  constructor() {
    this._init();

    return reactive(this);
  }

  _init() {
    this.key = "";
    this.open = false;
    this.showClose = true;
    this.modalClass = "";
    this.content = "";
    this.title = "";
    this.scopeId = "";
    this.icon = null;
    this.footer = null;
    this.zIndex = 2000;
    this.onClose = () => {
      this.open = false;
    };
  }

  $reset() {
    this._init();
  }

  $patch(state: Partial<Modal>) {
    for (const [key, value] of Object.entries(state)) {
      value !== undefined && Reflect.set(this, key, value);
    }

    if (typeof this.icon === "string") {
      if (this.icon in defaultIcon) {
        this.icon = h("img", { src: defaultIcon[this.icon] });
      } else {
        throw new Error("icon must be a valid icon name");
      }
    }
  }

  $resetAndPatch(state: Partial<Modal>) {
    this.$reset();
    this.$patch(state);
  }
}

export interface DefaultProps {
  /** 当不需要复用时，需添加key*/
  key?: string;
  title: string | VNode;
  icon?: VNode | null;
  content: string | VNode;
  footer?: string | VNode;
  modalClass?: string;
  zIndex?: number
}

export type CommonModalTemplate = Record<
  string & {},
  (this: Modal, props: any) => void
>;
export interface DefaultModalTemplate {
  warn: (this: Modal, props: DefaultProps) => void;
  success: (this: Modal, props: DefaultProps) => void;
  info: (this: Modal, props: DefaultProps) => void;
}

export type ModalTemplate = Partial<DefaultModalTemplate> & CommonModalTemplate;
