<template>
  <el-dialog
    :model-value="modelValue"
    modal-class="character-learning-modal"
    align-center
    :show-close="false"
    :append-to-body="true"
    @close="close"
  >
    <icon-button 
      class="close" 
      name="icon_close"
      :size="16"
      @click="close"
    />
    <div class="header">
      Quickly create your own characters for video generation
    </div>
    <div class="content">
      <div class="video-container">
        <video
          ref="videoRef"
          :controls="isPlaying"
          preload="metadata"
          :src="AvatarLearningVideo"
        />
      </div>
      <svg-icon
        v-show="!isPlaying"
        class="play"
        name="editor_play"
        :size="64"
        @click="play"
      />
    </div>
  </el-dialog>
</template>

<script setup>
import AvatarLearningVideo from "@/assets/videos/avatarLearning.mp4";

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  }
});
const emit = defineEmits(["update:modelValue"]);

const videoRef = ref(null);
const isPlaying = ref(false);

const close = () => {
  emit("update:modelValue", false);
};

const play = () => {
  const video = videoRef.value;
  if (!video) return;
  video.play();
  video.onended = () => {
    isPlaying.value = false;
    video.currentTime = 0;
  };
  isPlaying.value = true;
};

const pause = () => {
  isPlaying.value = false;
  videoRef.value?.pause();
};

watch(
  () => props.modelValue,
  (value) => {
    if (!value) pause();
  }
);
onBeforeUnmount(pause);
</script>

<style lang="scss">
.character-learning-modal {
  & .el-dialog {
    position: relative;
    width: 60%;
    border-radius: 4px;
  }

  & .el-dialog__header {
    padding: 0;
    margin: 0;
  }

  & .el-dialog__body {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.close {
  position: absolute;
  top: 14px;
  right: 24px;
}

.header {
  width: 100%;
  padding: 38px 34px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}

.content {
  position: relative;
  width: 100%;
  padding: 0 32px 32px; 

  & > .video-container {
    width: 100%;
    aspect-ratio: 1920 / 1080;
    background: #EBEDEF;

    & > video {
      width: 100%; 
      border-radius: 4px;
    }
  }



  & > .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
</style>