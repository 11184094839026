<script setup>
import BlurImage from "@/assets/images/common/blur.png";
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { maskOptions, blendOptions } from "@/constants/image";
import { useTrackStore } from "@/store/modules/track";
import Attr from "./attr.vue";
import Keyframe from "./keyframe.vue";
import Extra from "./extra.vue";
import VideoBgRemoveDialog from "@/pages/aiTools/views/bgRemove/videoBgRemoveDialog.vue";
import VideoEnhancerDialog from "@/pages/aiTools/views/enhancer/videoEnhancerDialog.vue";
import SubscribeDialog from "@/layout/components/workspace/subscribeDialog/index.vue";
import { useMessage } from "@/utils";
import { useModalManager } from "@/components/common/custom-modal/instance";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useAssetModal } from "../../utils/instance";

const toolOptions = [
  {
    id: 0,
    title: "Background Remover",
  },
  {
    id: 1,
    disabled: true,
    title: "Video Enhancer",
  },
];

const emit = defineEmits([
  "update:x",
  "update:y",
  "update:scale",
  "update:fit",
  "update:rotate",
  "update:opacity",
  "update:keyframes",
  "update:blend",
  "update:mask",
  "update:volume",
  "update:fadeIn",
  "update:fadeOut",
  "update:speed",
  "update:end",
]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  hdUrl: {
    type: String,
    default: null,
  },
  coverPic: {
    type: String,
    default: null,
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  scale: {
    type: Number,
    default: 1,
  },
  fit: {
    type: String,
    default: "none",
  },
  opacity: {
    type: Number,
    default: 1,
  },
  keyframes: {
    type: Object,
    default: null,
  },
  blend: {
    type: String,
    default: "normal",
  },
  mask: {
    type: Object,
    default: undefined,
  },
  volume: {
    type: Number,
    default: 1,
  },
  fadeIn: {
    type: Number,
    default: 0,
  },
  fadeOut: {
    type: Number,
    default: 0,
  },
  speed: {
    type: Number,
    default: 1,
  },
  end: {
    type: Number,
    default: 0,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
});

const bgTrackHelper = (ei) => {
  collectData("boolvideo_timeline_edit_click", {
    element_type: props.node.type,
    element_id: ei,
    click: "add_background",
  });
  track("boolvideo_timeline_edit_click");
  clearEventData("boolvideo_timeline_edit_click");
};

const backgroundOptions = [
  {
    title: "None",
    name: "editor_none",
    size: 18,
    style: {
      background: "#F3F5F7",
    },
    onClick: (title) => {
      if (title === activeBackground.value) return;
      bgTrackHelper("none");
      removeBackground(props.node.parent);
      submit();
    },
  },
  {
    title: "Upload",
    name: "editor_upload",
    size: 20,
    style: {
      borderColor: "#E5E7EB",
    },
    onClick: () => {
      assetModal.open(
        route.query.draftId,
        ({ url }) => {
          setBackgroundImage(props.node.parent, url);
          submit();
          bgTrackHelper("upload_media");
        },
        { mode: "background", sourceType: "image" }
      );
    },
  },
  {
    title: "Color fill",
    style: {},
  },
  {
    title: "Blur1",
    style: {},
    blur: 1,
    onClick: (title) => {
      if (title === activeBackground.value) return;
      setBackgroundBlur(props.node.parent, 0.02);
      submit();
      bgTrackHelper(title);
    },
  },
  {
    title: "Blur2",
    style: {},
    blur: 2,
    onClick: (title) => {
      if (title === activeBackground.value) return;
      setBackgroundBlur(props.node.parent, 0.05);
      submit();
      bgTrackHelper(title);
    },
  },
  {
    title: "Blur3",
    style: {},
    blur: 3,
    onClick: (title) => {
      if (title === activeBackground.value) return;
      setBackgroundBlur(props.node.parent, 0.1);
      submit();
      bgTrackHelper(title);
    },
  },
];

const {
  replaceData,
  showMaterialDialog,
  magnet,
  creator,
  materialTab,
  attrTabMap,
  updateProject,
  updateCloud,
  getTrack,
  refresh,
  adsorb,
} = useCreatorStore();
const { collectData, track, clearEventData } = useTrackStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const modalManager = useModalManager();
const subscriptionInfo = useSubscriptionInfo();
const assetModal = useAssetModal();
const message = useMessage();
const route = useRoute();
const showTool = ref(false);
const currentTool = ref(null);
const subscribeModalVisible = ref(false);
const globalDomain = inject("globalDomain");

const x = ref(props.x);
const y = ref(props.y);
const rotate = ref(props.rotate);
const scale = ref(props.scale);
const opacity = ref(props.opacity);
const speed = ref(props.speed);

const maskX = ref(0);
const maskY = ref(0);
const maskW = ref(0);
const maskH = ref(0);
const maskR = ref(0);
const radius = ref(0);

const dirty = reactive({
  x: 0,
  y: 0,
  rotate: 0,
  scale: 0,
  opacity: 0,
});
const dirtyMask = reactive({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotate: 0,
});

const activeBackground = computed(() => {
  const scene = props.node.parent;
  const conf = scene.conf;
  const { backgroundImage, backgroundColor, backgroundBlur } = conf;

  if (backgroundImage) {
    return "Upload";
  } else if (backgroundColor) {
    return "Color fill";
  } else if (backgroundBlur === 0.02) {
    return "Blur1";
  } else if (backgroundBlur === 0.05) {
    return "Blur2";
  } else if (backgroundBlur === 0.1) {
    return "Blur3";
  } else {
    return "None";
  }
});

onBeforeMount(() => {
  const { mask } = props;

  if (mask) {
    maskX.value = mask.x;
    maskY.value = mask.y;
    maskW.value = mask.width;
    maskH.value = mask.height;
    maskR.value = mask.rotate;
    radius.value = mask.radius;
  }
});

watch(
  () => props.node.updateId,
  () => {
    const node = props.node;
    const { mask } = node;
    const [nx, ny] = node.getXY();
    const ns = node.getScale();
    const nr = node.getRotation();
    const no = node.getOpacity();

    x.value = nx;
    y.value = ny;
    scale.value = ns;
    rotate.value = nr;
    opacity.value = no;

    if (mask) {
      const [mx, my] = mask.getXY();
      const [mw, mh] = mask.getWH();
      const mr = mask.getRotation();

      maskX.value = mx;
      maskY.value = my;
      maskW.value = mw;
      maskH.value = mh;
      maskR.value = mr;
    }
  },
  { immediate: true }
);
watch(
  () => props.x,
  (newX) => {
    updateX(newX, false, true);
  }
);
watch(
  () => props.y,
  (newY) => {
    updateY(newY, false, true);
  }
);
watch(
  () => props.scale,
  (newScale) => {
    updateScale(newScale, false, true);
  }
);
watch(
  () => props.rotate,
  (newRotation) => {
    updateRotate(newRotation, false, true);
  }
);
watch(
  () => props.opacity,
  (newOpacity) => {
    updateOpacity(newOpacity, false, true);
  }
);
watch(
  () => props.mask?.x,
  (newX) => {
    updateMaskX(newX, false, true);
  }
);
watch(
  () => props.mask?.y,
  (newY) => {
    updateMaskY(newY, false, true);
  }
);
watch(
  () => props.mask?.width,
  (newWidth) => {
    updateMaskWidth(newWidth, false, true);
  }
);
watch(
  () => props.mask?.height,
  (newHeight) => {
    updateMaskHeight(newHeight, false, true);
  }
);
watch(
  () => props.mask?.rotate,
  (newRotation) => {
    updateMaskRotation(newRotation, false, true);
  }
);

function submit() {
  commit();
  updateDraft();
}

function removeBackground(scene) {
  const conf = scene.conf;

  if (conf.backgroundImage) {
    delete conf.backgroundImage;
  }
  if (conf.backgroundColor) {
    delete conf.backgroundColor;
  }
  if (conf.backgroundOpacity) {
    delete conf.backgroundOpacity;
  }
  if (conf.backgroundBlur) {
    delete conf.backgroundBlur;
  }
}

function setBackgroundImage(scene, url) {
  removeBackground(scene);
  scene.conf.backgroundImage = url;
  scene.conf.backgroundOpacity = 0.3;
}

function setBackgroundColor(scene, color, shouldSubmit) {
  removeBackground(scene);
  scene.conf.backgroundColor = color;
  shouldSubmit && submit();
  shouldSubmit && bgTrackHelper("Color fill");
}

function setBackgroundBlur(scene, blur) {
  removeBackground(scene);
  scene.conf.backgroundBlur = blur;
}

async function ApplyAllBackground() {
  const trackHelper = () => {
    let ei = "none";
    const { backgroundImage, backgroundColor, backgroundBlur } = scene.conf;
    if (backgroundImage) {
      ei = "upload_media";
    } else if (backgroundColor) {
      ei = "Color fill";
    } else if (backgroundBlur) {
      switch (backgroundBlur) {
        case 0.1:
          ei = "Blur3";
          break;
        case 0.05:
          ei = "Blur2";
          break;
        case 0.02:
          ei = "Blur1";
          break;
      }
    }
    collectData("boolvideo_timeline_edit_click", {
      element_id: ei,
      click: "apply_all_background",
    });
    track("boolvideo_timeline_edit_click");
    clearEventData("boolvideo_timeline_edit_click");
  };
  const scene = props.node.parent;
  const { backgroundImage, backgroundColor, backgroundBlur } = scene.conf;
  const scenes = scene.parent.children.filter((s) => s !== scene);

  if (backgroundImage) {
    for (const scene of scenes) {
      setBackgroundImage(scene, backgroundImage);
    }
  } else if (backgroundColor) {
    for (const scene of scenes) {
      setBackgroundColor(scene, backgroundColor);
    }
  } else if (backgroundBlur) {
    for (const scene of scenes) {
      setBackgroundBlur(scene, backgroundBlur);
    }
  } else {
    for (const scene of scenes) {
      removeBackground(scene);
    }
  }
  submit();
  message.success("All backgrounds successfully applied");
  trackHelper();
}

function resetLayoutProperty() {
  const { initialState } = props;
  emit("update:x", initialState.x);
  emit("update:y", initialState.y);
  emit("update:rotate", initialState.rotation);
  emit("update:scale", initialState.scale);
  emit("update:opacity", initialState.opacity);
  emit("update:keyframes", initialState.keyframes);
  submit();
}

function resetBlendProperty() {
  const { initialState } = props;
  emit("update:blend", initialState.blend);
  submit();
}

function resetVolumeProperty() {
  const { initialState } = props;
  emit("update:volume", initialState.volume);
  emit("update:fadeIn", initialState.fadeIn);
  emit("update:fadeOut", initialState.fadeOut);
  submit();
}

function resetSpeedProperty() {
  const { node, initialState } = props;
  const duration = node.getDuration();
  const currentSpeed = node.getSpeed();
  const end =
    node.startFrame +
    Math.floor((duration * currentSpeed) / initialState.speed);

  speed.value = initialState.speed;
  emit("update:speed", initialState.speed);
  emit("update:end", end);
  submit();
  nextTick(refresh);
}

function resetMaskLayoutProperty() {
  const node = props.node;
  const { x, y, width, height, rotation, keyframes } = node.mask.initialState;
  emit("update:mask", {
    ...props.mask,
    x,
    y,
    width,
    height,
    rotate: rotation,
    keyframes,
  });
  submit();
}

function updateMedia() {
  updateProject.value++;
  updateCloud.value++;
  materialTab.value = "media";
}

function updateX(value, shouldSubmit, noEmit) {
  x.value = value;
  dirty.x++;
  !noEmit && emit("update:x", value);
  shouldSubmit && submit();
}

function updateY(value, shouldSubmit, noEmit) {
  y.value = value;
  dirty.y++;
  !noEmit && emit("update:y", value);
  shouldSubmit && submit();
}

function updateRotate(value, shouldSubmit, noEmit) {
  rotate.value = value;
  dirty.rotate++;
  !noEmit && emit("update:rotate", value);
  shouldSubmit && submit();
}

function updateScale(value, shouldSubmit, noEmit) {
  scale.value = value;
  dirty.scale++;
  !noEmit && emit("update:scale", value);
  if (shouldSubmit) {
    emit("update:fit", "none");
    submit();
  }
}

function updateOpacity(value, shouldSubmit, noEmit) {
  opacity.value = value;
  dirty.opacity++;
  !noEmit && emit("update:opacity", value);
  shouldSubmit && submit();
}

function updateKeyframes(value) {
  emit("update:keyframes", value);
}

function updateBlend(value) {
  emit("update:blend", value);
  submit();
}

function updateVolume(value, shouldSubmit) {
  emit("update:volume", value);
  shouldSubmit && submit();
}

function updateFadeIn(value, shouldSubmit) {
  emit("update:fadeIn", value);
  shouldSubmit && submit();
}

function updateFadeOut(value, shouldSubmit) {
  emit("update:fadeOut", value);
  shouldSubmit && submit();
}

function updateSpeed(value) {
  const node = toRaw(props.node);
  const duration = node.getDuration();
  const speed = node.getSpeed();
  const end = node.startFrame + Math.round((duration * speed) / value);
  const oldTrack = toRaw(node.getTrack());
  const zIndex = node.getZIndex();
  const parent = node.parent;

  node.setEnd(end);
  if (parent.type === "scene") {
    parent.annotate();
  }
  const { track, used } = getTrack(node, zIndex);

  emit("update:speed", value);
  emit("update:end", end);

  if (track === oldTrack) {
    if (magnet.value && track.kind === "primary") {
      adsorb();
    }
    nextTick(refresh);
  } else {
    track.addChild(node);

    if (!used) {
      track.start(false, false).then(refresh);
    }
    if (parent.children.length === 0) {
      parent.destroy();
    }
    triggerRef(creator);
  }
  submit();
}

function updateMaskX(value, shouldSubmit, noEmit) {
  maskX.value = value;
  dirtyMask.x++;
  !noEmit && emit("update:mask", { ...props.mask, x: value });
  shouldSubmit && submit();
}

function updateMaskY(value, shouldSubmit, noEmit) {
  maskY.value = value;
  dirtyMask.y++;
  !noEmit && emit("update:mask", { ...props.mask, y: value });
  shouldSubmit && submit();
}

function updateMaskWidth(value, shouldSubmit, noEmit) {
  maskW.value = value;
  dirtyMask.width++;
  !noEmit && emit("update:mask", { ...props.mask, width: value });
  shouldSubmit && submit();
}

function updateMaskHeight(value, shouldSubmit, noEmit) {
  maskH.value = value;
  dirtyMask.height++;
  !noEmit && emit("update:mask", { ...props.mask, height: value });
  shouldSubmit && submit();
}

function updateMaskRotation(value, shouldSubmit, noEmit) {
  maskR.value = value;
  dirtyMask.rotate++;
  !noEmit && emit("update:mask", { ...props.mask, rotate: value });
  shouldSubmit && submit();
}

function updateMaskRadius(value, shouldSubmit) {
  radius.value = value;
  emit("update:mask", { ...props.mask, radius: value });
  shouldSubmit && submit();
}

function updateMaskK(value) {
  emit("update:mask", { ...props.mask, keyframes: value });
}

function clickMask(option) {
  const { node, mask } = props;

  if (option.shape === mask?.shape) {
    return;
  }
  if (!option.shape) {
    emit("update:mask", null);
  } else {
    let [x, y] = node.getXY();
    const [width, height] = node.getWH();
    radius.value = 0;

    if (typeof mask?.x === "number") {
      x = mask.x;
    }
    if (typeof mask?.y === "number") {
      y = mask.y;
    }

    emit("update:mask", {
      mode: "graph",
      shape: option.shape,
      x,
      y,
      width: width / 2,
      height: option.shape === "rect" ? height / 2 : width / 2,
      radius: 0,
    });
  }
  submit();
}

function clickTool(tool) {
  if (tool.disabled) {
    return;
  }
  const userResource = subscriptionInfo.getUserResource(
    "boolvideo_tools_video"
  );
  const node = props.node;

  if (
    userResource === undefined ||
    (!userResource.unlimitedDuration && userResource.num < node.info.duration)
  ) {
    modalManager.applyTemplate("noCredits", {
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  currentTool.value = tool;
  nextTick(() => (showTool.value = true));
}

function handleReplace() {
  replaceData.node = props.node;
  showMaterialDialog.value = true;

  collectData("boolvideo_replace_media");
  track("boolvideo_replace_media");
}
</script>
<template>
  <Attr>
    <el-tabs v-model="attrTabMap[node.id]">
      <el-tab-pane label="Properties" name="props">
        <el-scrollbar>
          <div class="attr-content">
            <div class="form">
              <button class="plain" @click="handleReplace">
                Media replace
              </button>
            </div>
            <div class="form keyframe">
              <div class="form-header">
                <span>Layout</span>
                <div class="right">
                  <icon-button
                    name="editor_reset"
                    tip="Reset"
                    :size="14"
                    @click="resetLayoutProperty"
                  />
                  <Keyframe
                    :should-add="false"
                    :properties="{ x, y, rotate, scale, opacity }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
              </div>
              <div class="form-content">
                <div class="col col-input">
                  <input-number
                    rounded
                    :model-value="x"
                    @input="updateX"
                    @change="updateX($event, true)"
                  >
                    <template #prefix>X</template>
                  </input-number>
                  <input-number
                    rounded
                    :model-value="y"
                    @input="updateY"
                    @change="updateY($event, true)"
                  >
                    <template #prefix>Y</template>
                  </input-number>
                  <Keyframe
                    :properties="{ x, y }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Rotation">
                    <input-number
                      angle
                      :model-value="rotate"
                      :step="Math.PI / 180"
                      @input="updateRotate"
                      @change="updateRotate($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_angle" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ rotate }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Scale">
                    <input-number
                      percent
                      :model-value="scale"
                      :min="0.01"
                      :max="10"
                      :step="0.01"
                      @input="updateScale"
                      @change="updateScale($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_scale" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ scale }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Opacity">
                    <input-number
                      percent
                      :model-value="opacity"
                      :min="0"
                      :max="1"
                      :step="0.01"
                      @input="updateOpacity"
                      @change="updateOpacity($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_opacity" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ opacity }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
              </div>
            </div>
            <div class="form has-keyframe">
              <div class="form-header">
                <span>Blend</span>
                <icon-button
                  name="editor_reset"
                  tip="Reset"
                  :size="14"
                  @click="resetBlendProperty"
                />
              </div>
              <div class="form-content">
                <div class="col col-input col-small">
                  <bv-select
                    width="226px"
                    style="margin-right: 0"
                    :model-value="blend"
                    :options="blendOptions"
                    @change="updateBlend"
                  />
                </div>
              </div>
            </div>
            <div class="form has-keyframe">
              <div class="form-header">
                <span>Audio</span>
                <icon-button
                  name="editor_reset"
                  tip="Reset"
                  :size="14"
                  @click="resetVolumeProperty"
                />
              </div>
              <div class="form-content">
                <div class="col col-slider">
                  <svg-icon
                    clickable
                    v-show="volume === 0"
                    name="editor_muted"
                    :size="18"
                    @click="updateVolume(1, true)"
                  />
                  <svg-icon
                    clickable
                    v-show="volume > 0"
                    name="editor_unmuted"
                    :size="18"
                    @click="updateVolume(0, true)"
                  />
                  <bv-slider
                    :model-value="volume"
                    :min="0"
                    :max="1"
                    :step="0.01"
                    :show-tooltip="false"
                    @input="updateVolume"
                    @change="updateVolume($event, true)"
                  />
                  <input-number
                    align-center
                    percent
                    :model-value="volume"
                    :min="0"
                    :max="1"
                    :step="0.01"
                    @input="updateVolume"
                    @change="updateVolume($event, true)"
                  />
                </div>
                <div class="col col-slider">
                  <svg-icon name="editor_fade_in" :size="18" />
                  <bv-slider
                    :model-value="fadeIn"
                    :min="0"
                    :max="300"
                    :step="3"
                    :show-tooltip="false"
                    @input="updateFadeIn"
                    @change="updateFadeIn($event, true)"
                  />
                  <input-number
                    duration
                    align-center
                    :model-value="fadeIn"
                    :min="0"
                    :max="300"
                    :step="3"
                    @input="updateFadeIn"
                    @change="updateFadeIn($event, true)"
                  />
                </div>
                <div class="col col-slider">
                  <svg-icon name="editor_fade_out" :size="18" />
                  <bv-slider
                    :model-value="fadeOut"
                    :min="0"
                    :max="300"
                    :step="3"
                    :show-tooltip="false"
                    @input="updateFadeOut"
                    @change="updateFadeOut($event, true)"
                  />
                  <input-number
                    duration
                    align-center
                    :model-value="fadeOut"
                    :min="0"
                    :max="300"
                    :step="3"
                    @input="updateFadeOut"
                    @change="updateFadeOut($event, true)"
                  />
                </div>
              </div>
            </div>
            <div class="form has-keyframe">
              <div class="form-header">
                <span>Speed</span>
                <icon-button
                  name="editor_reset"
                  :size="14"
                  @click="resetSpeedProperty"
                />
              </div>
              <div class="form-content">
                <div class="col col-slider">
                  <svg-icon name="editor_speed" :size="18" />
                  <bv-slider
                    v-model="speed"
                    :min="0.1"
                    :max="4"
                    :step="0.1"
                    :marks="{ 0.1: '', 1: '', 2: '', 3: '', 4: '' }"
                    :show-tooltip="false"
                    @change="updateSpeed"
                  />
                  <input-number
                    speed
                    align-center
                    v-model="speed"
                    :min="0.1"
                    :max="4"
                    :step="0.1"
                    @change="updateSpeed"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="node.parent.type === 'scene'"
              class="form"
              style="padding: 24px 16px"
            >
              <div class="form-header">
                <span>Background</span>
                <span class="text-button" @click="ApplyAllBackground"
                  >Apply all</span
                >
              </div>
              <div class="form-content">
                <div class="grid-list">
                  <div
                    v-for="(option, i) in backgroundOptions"
                    class="grid-item"
                    :key="i"
                  >
                    <div
                      class="image-wrapper"
                      :style="option.style"
                      :class="{ active: option.title === activeBackground }"
                      @click="option.onClick(option.title)"
                    >
                      <div
                        class="background-image"
                        v-if="
                          option.title === 'Upload' &&
                          node.parent.conf.backgroundImage
                        "
                      >
                        <img
                          loading="lazy"
                          draggable="false"
                          :src="node.parent.conf.backgroundImage"
                        />
                        <div class="image-mask"></div>
                        <svg-icon
                          :name="option.name"
                          :size="option.size"
                          color="#FFFFFF"
                        />
                      </div>
                      <color-picker
                        v-else-if="option.title === 'Color fill'"
                        :value="node.parent.conf.backgroundColor || '#D9DFFF'"
                        :size="68"
                        @input="setBackgroundColor(node.parent, $event)"
                        @change="setBackgroundColor(node.parent, $event, true)"
                      />
                      <img
                        v-else-if="option.blur"
                        loading="lazy"
                        draggable="false"
                        :style="`filter: blur(${option.blur}px)`"
                        :src="coverPic || BlurImage"
                      />
                      <svg-icon
                        v-else-if="option.name"
                        :name="option.name"
                        :size="option.size"
                      />
                    </div>
                    <div class="title">{{ option.title }}</div>
                  </div>
                </div>
              </div>
            </div>
            <extra :config="node.conf" />
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="Mask" name="mask">
        <el-scrollbar>
          <div class="attr-content">
            <div class="form">
              <button class="plain" @click="handleReplace">
                Media replace
              </button>
            </div>
            <div class="form" :style="{ padding: '24px 16px' }">
              <div class="grid-list">
                <div
                  v-for="(option, i) in maskOptions"
                  class="grid-item"
                  :key="i"
                >
                  <div
                    class="image-wrapper"
                    :style="option.style"
                    :class="{ active: option.shape === mask?.shape }"
                    @click="clickMask(option)"
                  >
                    <svg-icon
                      :name="option.name"
                      :color="option.color"
                      :size="option.size"
                    />
                  </div>
                  <div class="title">{{ option.title }}</div>
                </div>
              </div>
              <div
                v-if="mask?.shape === 'rect'"
                class="col col-input col-single"
                :style="{ marginTop: '22px' }"
              >
                <bv-tip content="Radius">
                  <input-number
                    :model-value="radius"
                    :min="0"
                    @input="updateMaskRadius"
                    @change="updateMaskRadius($event, true)"
                  >
                    <template #prefix>
                      <svg-icon name="editor_radius" :size="18" />
                    </template>
                  </input-number>
                </bv-tip>
              </div>
            </div>
            <div class="form keyframe" v-if="mask">
              <div class="form-header">
                <span>Layout</span>
                <div class="right">
                  <icon-button
                    name="editor_reset"
                    tip="Reset"
                    :size="14"
                    @click="resetMaskLayoutProperty"
                  />
                  <Keyframe
                    :should-add="false"
                    :properties="{
                      x: maskX,
                      y: maskY,
                      width: maskW,
                      height: maskH,
                      rotate: maskR,
                    }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
              </div>
              <div class="form-content">
                <div class="col col-input">
                  <input-number
                    rounded
                    :model-value="maskX"
                    @input="updateMaskX"
                    @change="updateMaskX($event, true)"
                  >
                    <template #prefix>X</template>
                  </input-number>
                  <input-number
                    rounded
                    :model-value="maskY"
                    @input="updateMaskY"
                    @change="updateMaskY($event, true)"
                  >
                    <template #prefix>Y</template>
                  </input-number>
                  <Keyframe
                    :properties="{ x: maskX, y: maskY }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
                <div class="col col-input">
                  <input-number
                    rounded
                    :min="1"
                    :model-value="maskW"
                    @input="updateMaskWidth"
                    @change="updateMaskWidth($event, true)"
                  >
                    <template #prefix>W</template>
                  </input-number>
                  <input-number
                    rounded
                    :min="1"
                    :model-value="maskH"
                    @input="updateMaskHeight"
                    @change="updateMaskHeight($event, true)"
                  >
                    <template #prefix>H</template>
                  </input-number>
                  <Keyframe
                    :properties="{ width: maskW, height: maskH }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Rotation">
                    <input-number
                      angle
                      :model-value="maskR"
                      :step="Math.PI / 180"
                      @input="updateMaskRotation"
                      @change="updateMaskRotation($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_angle" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ rotate: maskR }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane v-if="!transparent && globalDomain === 1" name="aiTools">
        <template #label>
          <svg-icon
            name="editor_ai_tools"
            :size="16"
            :style="{ marginRight: '8px' }"
          />
          <span>Tools</span>
        </template>
        <el-scrollbar>
          <div class="attr-content">
            <div class="tool-container">
              <div
                v-for="(option, i) in toolOptions"
                class="tool-item"
                :class="{ disabled: option.disabled }"
                :key="i"
                @click="clickTool(option)"
              >
                <div class="flex">
                  <span>{{ option.title }}</span>
                  <span v-if="option.disabled" class="tip"
                    >(Feature Upgrading)</span
                  >
                </div>
                <svg-icon name="editor_arrow_forward" :size="18" />
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <video-bg-remove-dialog
      v-if="currentTool?.id === 0"
      v-model="showTool"
      :src="hdUrl"
      @complete="updateMedia"
    />
    <video-enhancer-dialog
      v-else-if="currentTool?.id === 1"
      v-model="showTool"
      :src="hdUrl"
      @complete="updateMedia"
    />
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </Attr>
</template>
<style scoped>
:deep(.color-picker canvas) {
  border: none !important;
}
.tool-container .tool-item {
  height: 56px;
  width: 100%;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e9ec;
  cursor: pointer;
  transition: background-color 250ms;
}

.tool-container .tool-item:hover {
  background-color: #e5e7eb;
}

.tool-item span {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.tool-item.disabled span {
  color: #646a73;
  cursor: auto;
}

.tool-container .tool-item.disabled:hover {
  background-color: #fff;
}

.tool-item.disabled .tip {
  /* color: red; */
  font-size: 10px;
  margin-left: 3px;
}
</style>
