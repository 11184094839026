import defaultTemplate from "./templates";
import { Modal } from "./type";
import type {
  DefaultModalTemplate,
  CommonModalTemplate,
  DefaultProps,
} from "./type";

export class ModalManager<
  T extends CommonModalTemplate,
  A extends CommonModalTemplate = T &
    DefaultModalTemplate &
    CommonModalTemplate,
> {
  modal: Modal;
  templates: CommonModalTemplate;

  constructor(templates: T) {
    this.modal = new Modal();
    this.templates = templates;
  }

  registerTemplate<K extends string & keyof A>(name: K, template: A[K]) {
    this.templates[name] = template;
  }

  hasTemplate(name: PropertyKey) {
    return name in this.templates;
  }

  _isDefaultTemplate(name: PropertyKey): name is keyof DefaultModalTemplate {
    return name in defaultTemplate;
  }

  applyTemplate<K extends keyof A>(name: K, prop: ExtractFirstParameter<A[K]>) {

    if (this.hasTemplate(name)) {
      this.templates[name as string].call(this.modal, prop);
      return true;
    } else if (this._isDefaultTemplate(name)) {
      defaultTemplate[name].call(this.modal, prop as DefaultProps);
      return true;
    }
    
    return false;
  }

  deleteTemplate(name: string) {
    return delete this.templates[name];
  }
}
