import PoppinsIcon from "@/assets/icons/common/editor_font_Poppins.svg";
import RobotoIcon from "@/assets/icons/common/editor_font_Roboto.svg";
import BodoniModaIcon from "@/assets/icons/common/editor_font_BodoniModa.svg";
import CormorantGaramondIcon from "@/assets/icons/common/editor_font_CormorantGaramond.svg";
import CormorantSCIcon from "@/assets/icons/common/editor_font_CormorantSC.svg";
import DarkerGrotesqueIcon from "@/assets/icons/common/editor_font_DarkerGrotesque.svg";
import InterIcon from "@/assets/icons/common/editor_font_Inter.svg";
import KaushanScriptIcon from "@/assets/icons/common/editor_font_KaushanScript.svg";
import LobsterIcon from "@/assets/icons/common/editor_font_Lobster.svg";
import PlayfairDisplayIcon from "@/assets/icons/common/editor_font_PlayfairDisplay.svg";
import ShadowsIntoLightIcon from "@/assets/icons/common/editor_font_ShadowsIntoLight.svg";
import WixMadeforDisplayIcon from "@/assets/icons/common/editor_font_WixMadeforDisplay.svg";
import ZeyadaIcon from "@/assets/icons/common/editor_font_Zeyada.svg";
import ArchivoNarrowIcon from "@/assets/icons/common/editor_font_ArchivoNarrow.svg";
import JostIcon from "@/assets/icons/common/editor_font_Jost.svg";
import LaBelleAuroreIcon from "@/assets/icons/common/editor_font_LaBelleAurore.svg";
import LoraIcon from "@/assets/icons/common/editor_font_Lora.svg";
import MsMadiIcon from "@/assets/icons/common/editor_font_MsMadi.svg";
import ParisienneIcon from "@/assets/icons/common/editor_font_Parisienne.svg";
import PetitFormalScriptIcon from "@/assets/icons/common/editor_font_PetitFormalScript.svg";
import WindSongIcon from "@/assets/icons/common/editor_font_WindSong.svg";
import BangersIcon from "@/assets/icons/common/editor_font_Bangers.svg";
import BungeeIcon from "@/assets/icons/common/editor_font_Bungee.svg";
import ChangaIcon from "@/assets/icons/common/editor_font_Changa.svg";
import LilitaOneIcon from "@/assets/icons/common/editor_font_LilitaOne.svg";
import MontserratIcon from "@/assets/icons/common/editor_font_Montserrat.svg";
import PacificoIcon from "@/assets/icons/common/editor_font_Pacifico.svg";
import RubikIcon from "@/assets/icons/common/editor_font_Rubik.svg";
import RubikWetPaintIcon from "@/assets/icons/common/editor_font_RubikWetPaint.svg";
import RyeIcon from "@/assets/icons/common/editor_font_Rye.svg";
import SecularOneIcon from "@/assets/icons/common/editor_font_SecularOne.svg";
import SilkscreenIcon from "@/assets/icons/common/editor_font_Silkscreen.svg";
import StaatlichesIcon from "@/assets/icons/common/editor_font_Staatliches.svg";
import TekoIcon from "@/assets/icons/common/editor_font_Teko.svg";
import BebasNeueIcon from "@/assets/icons/common/editor_font_BebasNeue.svg";
import todaySActivityPreview from "@/assets/images/text/todaySActivity.svg";
import newContentPreview from "@/assets/images/text/newContent.svg";
import bestLifeBlackPreview from "@/assets/images/text/bestLifeBlack.svg";
import bestLifeWhitePreview from "@/assets/images/text/bestLifeWhite.svg";
import loveItPreview from "@/assets/images/text/loveIt.svg";
import shopNowPreview from "@/assets/images/text/shopNow.svg";
import offPreview from "@/assets/images/text/off.svg";
import experiencePreview0 from "@/assets/images/text/experience.svg";
import experiencePreview1 from "@/assets/images/text/experience1.svg";
import experiencePreview2 from "@/assets/images/text/experience2.svg";
import travelTipsPreview1 from "@/assets/images/text/travelTips1.svg";
import dailyWhitePreview from "@/assets/images/text/dailyWhite.svg";
import dailyBlackPreview from "@/assets/images/text/dailyBlack.svg";
import couponPreview from "@/assets/images/text/coupon.svg";
import travelTipsPreview from "@/assets/images/text/travelTips.svg";
import todayOutfitPreview from "@/assets/images/text/todayOutfit.svg";
import brightSpotPreview from "@/assets/images/text/brightSpot.svg";
import flashSalePreview from "@/assets/images/text/flashSale.svg";
import vintagePreview from "@/assets/images/text/vintage.svg";
import amazingPreview from "@/assets/images/text/amazing.svg";
import retroVibePreview from "@/assets/images/text/retroVibe.svg";
import defaultPreview from "@/assets/images/text/default.svg";
import vintagePreview1 from "@/assets/images/text/vintage1.svg";
import bestLifePreview2 from "@/assets/images/text/bestLift2.svg"
export const tagOptions = [
  {
    label: "price",
    value: "price",
  },
  {
    label: "discount",
    value: "discount",
  },
  {
    label: "CTA",
    value: "CTA",
  },
  {
    label: "Slogan",
    value: "Slogan",
  },
  {
    label: "selling_point",
    value: "selling_point",
  },
  {
    label: "brand_name",
    value: "brand_name",
  },
  {
    label: "landing_page_link",
    value: "landing_page_link",
  },
  {
    label: "customize_text",
    value: "customize_text",
  },
  {
    label: "product_name",
    value: "product_name",
  },
  {
    label: "product_design",
    value: "product_design",
  },
  {
    label: "product_material",
    value: "product_material",
  },
  {
    label: "product_function",
    value: "product_function",
  },
  {
    label: "user_experience",
    value: "user_experience",
  },
  {
    label: "using_scenes",
    value: "using_scenes",
  },
];

export const fontSizeOptions = [
  {
    label: "16",
    value: 16,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "24",
    value: 24,
  },
  {
    label: "32",
    value: 32,
  },
  {
    label: "36",
    value: 36,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "48",
    value: 48,
  },
  {
    label: "64",
    value: 64,
  },
  {
    label: "96",
    value: 96,
  },
  {
    label: "128",
    value: 128,
  },
  {
    label: "160",
    value: 160,
  },
];

export const fontFamilyOptions = [
  {
    label: "Poppins",
    value: [
      {
        family: "Poppins",
        source: "https://cdn.boolv.video/pgc/font/Poppins-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Poppins",
        source: "https://cdn.boolv.video/pgc/font/Poppins-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "Poppins",
        source: "https://cdn.boolv.video/pgc/font/Poppins-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "Poppins",
        source: "https://cdn.boolv.video/pgc/font/Poppins-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "Roboto",
    value: [
      {
        family: "Roboto",
        source: "https://cdn.boolv.video/pgc/font/Roboto-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Roboto",
        source: "https://cdn.boolv.video/pgc/font/Roboto-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "Roboto",
        source: "https://cdn.boolv.video/pgc/font/Roboto-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "Roboto",
        source: "https://cdn.boolv.video/pgc/font/Roboto-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "BodoniModa",
    value: [
      {
        family: "BodoniModa",
        source: "https://cdn.boolv.video/pgc/font/BodoniModa_28pt-Regular.ttf",
        descriptors: {},
      },
      {
        family: "BodoniModa",
        source: "https://cdn.boolv.video/pgc/font/BodoniModa_28pt-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "BodoniModa",
        source: "https://cdn.boolv.video/pgc/font/BodoniModa_28pt-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "BodoniModa",
        source:
          "https://cdn.boolv.video/pgc/font/BodoniModa_28pt-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "CormorantGaramond",
    value: [
      {
        family: "CormorantGaramond",
        source:
          "https://cdn.boolv.video/pgc/font/CormorantGaramond-Regular.ttf",
        descriptors: {},
      },
      {
        family: "CormorantGaramond",
        source: "https://cdn.boolv.video/pgc/font/CormorantGaramond-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "CormorantGaramond",
        source: "https://cdn.boolv.video/pgc/font/CormorantGaramond-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "CormorantGaramond",
        source:
          "https://cdn.boolv.video/pgc/font/CormorantGaramond-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "CormorantSC",
    value: [
      {
        family: "CormorantSC",
        source: "https://cdn.boolv.video/pgc/font/CormorantSC-Regular.ttf",
        descriptors: {},
      },
      {
        family: "CormorantSC",
        source: "https://cdn.boolv.video/pgc/font/CormorantSC-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "DarkerGrotesque",
    value: [
      {
        family: "DarkerGrotesque",
        source: "https://cdn.boolv.video/pgc/font/DarkerGrotesque-Regular.ttf",
        descriptors: {},
      },
      {
        family: "DarkerGrotesque",
        source: "https://cdn.boolv.video/pgc/font/DarkerGrotesque-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "Inter",
    value: [
      {
        family: "Inter",
        source: "https://cdn.boolv.video/pgc/font/Inter-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Inter",
        source: "https://cdn.boolv.video/pgc/font/Inter-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "KaushanScript",
    value: [
      {
        family: "KaushanScript",
        source: "https://cdn.boolv.video/pgc/font/KaushanScript-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Lobster",
    value: [
      {
        family: "Lobster",
        source: "https://cdn.boolv.video/pgc/font/Lobster-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "PlayfairDisplay",
    value: [
      {
        family: "PlayfairDisplay",
        source: "https://cdn.boolv.video/pgc/font/PlayfairDisplay-Regular.ttf",
        descriptors: {},
      },
      {
        family: "PlayfairDisplay",
        source: "https://cdn.boolv.video/pgc/font/PlayfairDisplay-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "PlayfairDisplay",
        source: "https://cdn.boolv.video/pgc/font/PlayfairDisplay-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "PlayfairDisplay",
        source:
          "https://cdn.boolv.video/pgc/font/PlayfairDisplay-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "ShadowsIntoLight",
    value: [
      {
        family: "ShadowsIntoLight",
        source: "https://cdn.boolv.video/pgc/font/ShadowsIntoLight-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "WixMadeforDisplay",
    value: [
      {
        family: "WixMadeforDisplay",
        source:
          "https://cdn.boolv.video/pgc/font/WixMadeforDisplay-Regular.ttf",
        descriptors: {},
      },
      {
        family: "WixMadeforDisplay",
        source: "https://cdn.boolv.video/pgc/font/WixMadeforDisplay-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "Zeyada",
    value: [
      {
        family: "Zeyada",
        source: "https://cdn.boolv.video/pgc/font/Zeyada-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "ArchivoNarrow",
    value: [
      {
        family: "ArchivoNarrow",
        source: "https://cdn.boolv.video/pgc/font/ArchivoNarrow-Regular.ttf",
        descriptors: {},
      },
      {
        family: "ArchivoNarrow",
        source: "https://cdn.boolv.video/pgc/font/ArchivoNarrow-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "ArchivoNarrow",
        source: "https://cdn.boolv.video/pgc/font/ArchivoNarrow-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "ArchivoNarrow",
        source:
          "https://cdn.boolv.video/pgc/font/ArchivoNarrow-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "Jost",
    value: [
      {
        family: "Jost",
        source: "https://cdn.boolv.video/pgc/font/Jost-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Jost",
        source: "https://cdn.boolv.video/pgc/font/Jost-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "Jost",
        source: "https://cdn.boolv.video/pgc/font/Jost-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "Jost",
        source:
          "https://cdn.boolv.video/pgc/font/Jost-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "LaBelleAurore",
    value: [
      {
        family: "LaBelleAurore",
        source: "https://cdn.boolv.video/pgc/font/LaBelleAurore-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Lora",
    value: [
      {
        family: "Lora",
        source: "https://cdn.boolv.video/pgc/font/Lora-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Lora",
        source: "https://cdn.boolv.video/pgc/font/Lora-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "Lora",
        source: "https://cdn.boolv.video/pgc/font/Lora-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "Lora",
        source:
          "https://cdn.boolv.video/pgc/font/Lora-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "MsMadi",
    value: [
      {
        family: "MsMadi",
        source: "https://cdn.boolv.video/pgc/font/MsMadi-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Parisienne",
    value: [
      {
        family: "Parisienne",
        source: "https://cdn.boolv.video/pgc/font/Parisienne-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "PetitFormalScript",
    value: [
      {
        family: "PetitFormalScript",
        source: "https://cdn.boolv.video/pgc/font/PetitFormalScript-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "WindSong",
    value: [
      {
        family: "WindSong",
        source: "https://cdn.boolv.video/pgc/font/WindSong-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Bangers",
    value: [
      {
        family: "Bangers",
        source: "https://cdn.boolv.video/pgc/font/Bangers-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Bungee",
    value: [
      {
        family: "Bungee",
        source: "https://cdn.boolv.video/pgc/font/Bungee-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Changa",
    value: [
      {
        family: "Changa",
        source: "https://cdn.boolv.video/pgc/font/Changa-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Changa",
        source: "https://cdn.boolv.video/pgc/font/Changa-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "LilitaOne",
    value: [
      {
        family: "LilitaOne",
        source: "https://cdn.boolv.video/pgc/font/LilitaOne-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Montserrat",
    value: [
      {
        family: "Montserrat",
        source: "https://cdn.boolv.video/pgc/font/Montserrat-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Montserrat",
        source: "https://cdn.boolv.video/pgc/font/Montserrat-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "Montserrat",
        source: "https://cdn.boolv.video/pgc/font/Montserrat-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "Montserrat",
        source:
          "https://cdn.boolv.video/pgc/font/Montserrat-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "Pacifico",
    value: [
      {
        family: "Pacifico",
        source: "https://cdn.boolv.video/pgc/font/Pacifico-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Rubik",
    value: [
      {
        family: "Rubik",
        source: "https://cdn.boolv.video/pgc/font/Rubik-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Rubik",
        source: "https://cdn.boolv.video/pgc/font/Rubik-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
      {
        family: "Rubik",
        source: "https://cdn.boolv.video/pgc/font/Rubik-Italic.ttf",
        descriptors: {
          style: "italic",
        },
      },
      {
        family: "Rubik",
        source:
          "https://cdn.boolv.video/pgc/font/Rubik-BoldItalic.ttf",
        descriptors: {
          weight: "bold",
          style: "italic",
        },
      },
    ],
  },
  {
    label: "RubikWetPaint",
    value: [
      {
        family: "RubikWetPaint",
        source: "https://cdn.boolv.video/pgc/font/RubikWetPaint-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Rye",
    value: [
      {
        family: "Rye",
        source: "https://cdn.boolv.video/pgc/font/Rye-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "SecularOne",
    value: [
      {
        family: "SecularOne",
        source: "https://cdn.boolv.video/pgc/font/SecularOne-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Silkscreen",
    value: [
      {
        family: "Silkscreen",
        source: "https://cdn.boolv.video/pgc/font/Silkscreen-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Silkscreen",
        source: "https://cdn.boolv.video/pgc/font/Silkscreen-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "Staatliches",
    value: [
      {
        family: "Staatliches",
        source: "https://cdn.boolv.video/pgc/font/Staatliches-Regular.ttf",
        descriptors: {},
      },
    ],
  },
  {
    label: "Teko",
    value: [
      {
        family: "Teko",
        source: "https://cdn.boolv.video/pgc/font/Teko-Regular.ttf",
        descriptors: {},
      },
      {
        family: "Teko",
        source: "https://cdn.boolv.video/pgc/font/Teko-Bold.ttf",
        descriptors: {
          weight: "bold",
        },
      },
    ],
  },
  {
    label: "BebasNeue",
    value: [
      {
        family: "BebasNeue",
        source: "https://cdn.boolv.video/pgc/font/BebasNeue-Regular.ttf",
        descriptors: {},
      },
    ],
  },
];

export const voiceoverOptions = () => {
  const initData = {
    "English (United States)": {
      label: "English",
      accent: "United States",
      value: "en-US",
      voiceOptions: [
        {
          label: "Jenny",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Guy",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Newscast",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Davis",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Chat",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Jane",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Jason",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Nancy",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Sara",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
        {
          label: "Tony",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Excited",
            },
            {
              label: "Friendly",
            },
            {
              label: "Hopeful",
            },
            {
              label: "Sad",
            },
            {
              label: "Shouting",
            },
            {
              label: "Terrified",
            },
            {
              label: "Unfriendly",
            },
            {
              label: "Whispering",
            },
          ],
        },
      ]
    },
    "Chinese": {
      label: "Chinese",
      accent: "Mandarin, Simplified",
      value: "zh-CN",
      voiceOptions: [
        {
          label: "Xiaoxiao",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Affectionate",
            },
            {
              label: "Angry",
            },
            {
              label: "Assistant",
            },
            {
              label: "Calm",
            },
            {
              label: "Chat",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Customerservice",
            },
            {
              label: "Disgruntled",
            },
            {
              label: "Fearful",
            },
            {
              label: "Friendly",
            },
            {
              label: "Gentle",
            },
            {
              label: "Lyrical",
            },
            {
              label: "NewsCast",
            },
            {
              label: "Poetry-reading",
            },
            {
              label: "Sad",
            },
            {
              label: "Serious",
            },
          ],
        },
        {
          label: "Yunxi",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Assistant",
            },
            {
              label: "Chat",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Depressded",
            },
            {
              label: "Disgruntled",
            },
            {
              label: "Embarrassed",
            },
            {
              label: "Fearful",
            },
            {
              label: "Narration-relaxed",
            },
            {
              label: "NewsCast",
            },
            {
              label: "Sad",
            },
            {
              label: "Serious",
            },
          ],
        },
        {
          label: "Yunjian",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Depressed",
            },
            {
              label: "Disgruntled",
            },
            {
              label: "Documentary-narration",
            },
            {
              label: "Narration-relaxed",
            },
            {
              label: "Sad",
            },
            {
              label: "Serious",
            },
            {
              label: "Sport-commentary",
            },
            {
              label: "Sport-commentary-excited",
            },
          ],
        },
        {
          label: "Xiaoyi",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Affectionate",
            },
            {
              label: "Angry",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Disgruntled",
            },
            {
              label: "Embarrassed",
            },
            {
              label: "Fearful",
            },
            {
              label: "Gentle",
            },
            {
              label: "Sad",
            },
            {
              label: "Serious",
            },
          ],
        },
        {
          label: "Yunyang",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Customerservice",
            },
            {
              label: "Narration-professional",
            },
            {
              label: "NewsCast-casual",
            },
          ],
        },
        {
          label: "Xiaochen",
          styleOptions: [
            {
              label: "Default",
            },
          ],
        },
      ]
    },
    "French": {
      label: "French",
      accent: "France",
      value: "fr-FR",
      voiceOptions: [
        {
          label: "Denise",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Sad",
            },
          ],
        },
        {
          label: "Henri",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Sad",
            },
          ],
        },
      ],
    },
    "German": {
      label: "German",
      accent: "Germany",
      value: "de-DE",
      voiceOptions: [
        {
          label: "Conrad",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Cheerful",
            },
          ],
        },
      ],
    },
    "Japanese": {
      label: "Japanese",
      accent: "Japan",
      value: "ja-JP",
      voiceOptions: [
        {
          label: "Nanami",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Chat",
            },
            {
              label: "Cheerful",
            },
            {
              label: "Customerservice",
            },
          ]
        },
      ],
    },
    "Portuguese": {
      label: "Portuguese",
      accent: "Brazil",
      value: "pt-BR",
      voiceOptions: [
        {
          label: "Francisca",
          styleOptions: [
            {
              label: "Default",
            },
            {
              label: "Calm",
            },
          ],
        },
      ],
    },
  };

  const options = Object.values(initData).map((language) => {
    const newLanguage = { ...language };
    newLanguage.voiceOptions = language.voiceOptions.map((voice) => {
      const newVoice = { ...voice };
      newVoice.styleOptions = voice.styleOptions.map((style) => {
        // process style's value
        style.value = style.label.toLowerCase();
        return style;
      });
      // combine voice's value and url
      newVoice.value = `${language.value}-${newVoice.label}Neural`;
      newVoice.url = `https://cdn.boolv.video/pgc/tts_preview/${newVoice.value}.wav`;
      return newVoice;
    });
    return newLanguage;
  });
  return options;
};

export const defaultFontFamily = fontFamilyOptions.find(
  (f) => f.label === "Poppins",
).value;

export const getFontFamilyByLabel = (label) => fontFamilyOptions.find(
  (f) => f.label === label,
).value

export const textPreview = {
  default: defaultPreview,
  todaySActivity:todaySActivityPreview,
  newContent: newContentPreview,
  bestLifeBlack: bestLifeBlackPreview,
  bestLifeWhite: bestLifeWhitePreview,
  loveIt: loveItPreview,
  shopNow: shopNowPreview,
  off: offPreview,
  experience0: experiencePreview0,
  experience1: experiencePreview1,
  experience2: experiencePreview2,
  travelTips1: travelTipsPreview1,
  dailyWhite: dailyWhitePreview,
  dailyBlack: dailyBlackPreview,
  coupon: couponPreview,
  travelTips: travelTipsPreview,
  todayOutfit: todayOutfitPreview,
  brightSpot: brightSpotPreview,
  flashSale: flashSalePreview,
  vintage: vintagePreview,
  amazing: amazingPreview,
  retroVibe: retroVibePreview,
  vintage1: vintagePreview1,
  bestLife2: bestLifePreview2,
};

export const fontIcons = {
  Poppins: PoppinsIcon,
  Roboto: RobotoIcon,
  BodoniModa: BodoniModaIcon,
  CormorantGaramond: CormorantGaramondIcon,
  CormorantSC: CormorantSCIcon,
  DarkerGrotesque: DarkerGrotesqueIcon,
  Inter: InterIcon,
  KaushanScript: KaushanScriptIcon,
  Lobster: LobsterIcon,
  PlayfairDisplay: PlayfairDisplayIcon,
  ShadowsIntoLight: ShadowsIntoLightIcon,
  WixMadeforDisplay: WixMadeforDisplayIcon,
  Zeyada: ZeyadaIcon,
  ArchivoNarrow: ArchivoNarrowIcon,
  Jost: JostIcon,
  LaBelleAurore: LaBelleAuroreIcon,
  Lora: LoraIcon,
  MsMadi: MsMadiIcon,
  Parisienne: ParisienneIcon,
  PetitFormalScript: PetitFormalScriptIcon,
  WindSong: WindSongIcon,
  Bangers: BangersIcon,
  Bungee: BungeeIcon,
  Changa: ChangaIcon,
  LilitaOne: LilitaOneIcon,
  Montserrat: MontserratIcon,
  Pacifico: PacificoIcon,
  Rubik: RubikIcon,
  RubikWetPaint: RubikWetPaintIcon,
  Rye: RyeIcon,
  SecularOne: SecularOneIcon,
  Silkscreen: SilkscreenIcon,
  Staatliches: StaatlichesIcon,
  Teko: TekoIcon,
  BebasNeue: BebasNeueIcon,
};
