<template>
  <Introduction 
    v-if="_showIntroduction" 
    @onUpgrade="handleUpgrade" 
  />
  <Pricing
    v-else
    @close="$emit('close')"
    :currentPath="currentPath"
  />
</template>

<script setup>
import Pricing from './components/layout/index.vue';
import Introduction from './components/introduction/introduction.vue';

const props = defineProps({
  showIntroduction: {
    type: Boolean,
  }
});
const emit = defineEmits("close");
const currentPath = ref('pricing');
const _showIntroduction = ref(false);

provide("currentPath", currentPath);

watch(
  () => props.showIntroduction,
  () => {
    _showIntroduction.value = props.showIntroduction;
  },
  {
    immediate: true,
  }
);

const handleUpgrade = () => {
  _showIntroduction.value = false;
}
</script>

<style lang="scss" scoped>

</style>