type ListenerCallback = (...args: any[]) => void;
type Listeners = Record<string, ListenerCallback[]>;

export class EventListener<T extends Listeners> {
  listeners: T;

  constructor(listeners: T) {
    this.listeners = listeners;
  }

  on<K extends keyof T>(key: K, fn: ArrayItemType<T[K]>) {
    this.listeners[key].push(fn);
  }

  off<K extends keyof T>(key: K, fn?: ArrayItemType<T[K]>) {
    if (fn === undefined) {
      this.listeners[key] = [] as unknown as T[K];
      return;
    }

    const callbacks = this.listeners[key];
    const index = callbacks.indexOf(fn);

    if (index !== -1) {
      callbacks.splice(index, 1);
    }
  }

  emit<K extends keyof T>(key: K, ...args: Parameters<ArrayItemType<T[K]>>) {
    this.listeners[key].forEach((callback) => callback(...args));
  }

  once<K extends keyof T>(key: K, callback: ArrayItemType<T[K]>) {
    const wrapper = ((...args) => {
      callback(...args);
      this.off(key, wrapper);
    }) as ArrayItemType<T[K]>;

    this.on(key, wrapper);
  }

  clear<K extends keyof T>(key: K) {
    this.listeners[key].splice(0, this.listeners[key].length);
  }
}

export function inObject<T extends object>(
  key: PropertyKey,
  obj: T,
): key is keyof T {
  return key in obj;
}

export function toFixed(value: number, maxFractionDigits = 2) {
  let [integer, fraction] = value.toFixed(maxFractionDigits).split(".");
  fraction = fraction.replace(/0+$/, "") || "0";

  return fraction === "0" ? integer : `${integer}.${fraction}`;
}

export const generateGID = (() => {
  let count = 0;
  let date = 0;

  const toString62 = (num: number) => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let res = "";

    while (num > 0) {
      res = chars[num % 62] + res;
      num = Math.floor(num / 62);
    }

    return res;
  };

  // 雪花算法
  return () => {
    const temp = Date.now() % 2.592e9;

    if (date === temp) {
      count++;
    } else {
      count = 0;
      date = temp;
    }

    return toString62(date * 1000 + count).padStart(7, "0");
  };
})();
