<template>
  <transition name="fade">
    <div v-show="visible" class="material-dialog">
      <div class="header-wrapper">
        <Header
          :tabList="tabNames"
          v-model:currentTab="currentTab"
          @close="close"
        />
      </div>
      <div class="content-wrapper">
        <pexels-content
          v-show="currentTab === 'Library'"
          v-model="selectFile"
          :currentTab="currentTab"
          :keyword="keyword"
        />
        <project-content
          v-show="currentTab === 'Project'"
          v-model="selectFile"
          :currentTab="currentTab"
          @select="selectFile = $event"
        />
        <cloud-content
          v-show="currentTab === 'Cloud'"
          v-model="selectFile"
          :currentTab="currentTab"
        />
      </div>
      <div class="footer-wrapper">
        <Footer :selectFile="selectFile" @confirm="replace" />
      </div>
    </div>
  </transition>
</template>

<script setup>
import Header from "./materialheader.vue";
import Footer from "./materialfooter.vue";
import PexelsContent from "./pexelscontent.vue";
import AiContent from "./aicontent.vue";
import ProjectContent from "./projectcontent.vue";
import CloudContent from "./cloudcontent.vue";

import { useTrackStore } from "@/store/modules/track";
import { useDraftStore } from "../../stores/draft";

const { videoType } = useDraftStore();
const { collectData, track, clearEventData } = useTrackStore();

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  keyword: {
    type: String,
  },
});
const emits = defineEmits(["update:modelValue", "confirm"]);

const tabNames = ["Library", "Project", "Cloud"];
const visible = ref(props.modelValue);
const currentTab = ref("Library");
const selectFile = ref(null);

watch(visible, (value) => {
  emits("update:modelValue", value);
});
watch(
  () => props.modelValue,
  (value) => {
    visible.value = value;
  }
);
watch(
  () => props.keyword,
  () => (currentTab.value = "Library")
);
watch(currentTab, () => {
  selectFile.value = null;
});

const close = () => {
  visible.value = false;
  selectFile.value = null;
};

const replace = () => {
  const trackHelper = () => {
    collectData("boolvideo_scene_edit_click", {
      click: "replace_success",
      video_type: videoType.value,
      replace_type: `${currentTab.value.toLowerCase()}_${
        selectFile.value.type
      }`,
    });
    track("boolvideo_scene_edit_click");
    clearEventData("boolvideo_scene_edit_click");
  };
  trackHelper();
  emits("confirm", selectFile.value);
};
</script>
<style lang="scss" scoped>
.material-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 80%;
  border-radius: 4px;
  background-color: #fff;
}
.header-wrapper {
  height: 52px;
  padding: 24px 26px 0;
}
.content-wrapper {
  height: calc(100% - 132px);
}
.footer-wrapper {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px;
}
:deep(.el-progress) {
  width: 66px;
  height: 3px;
}
:deep(.el-progress-bar__outer) {
  height: 3px !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
:deep(.el-progress-bar__inner) {
  background-color: #ffffff;
  border-radius: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
