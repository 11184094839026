import Cor from "@boolv/creator";
import { clamp, courtship, getVideoDuration, getAudioDuration } from "../utils";
import { calcRate } from "../utils";
import { parseNode } from "../utils/scene";
import { validMaterial, normalizeConfig } from "../utils/conf";
import { useDraftStore } from "./draft";
import { useSettingStore } from "./setting";
import { ratio } from "./draft";

const replaceData = reactive({
  newData: null,
  src: null,
  poster: null,
  showVideoCliper: false,
  transparent: false,
  duration: 0,
  apply: null,
});
const showMaterialDialog = ref(false);
const resources = reactive(new Map());
const creator = shallowRef(null);
const currentFrame = ref(0);
const totalFrame = ref(0);
const scenePlaying = ref(false);
const playing = ref(false);
const nodes = ref([]);
const tracks = ref([]);
const scenes = ref([]);
const languageList = ref([]);
const activeScene = ref(null);
const loadingScene = ref(null);
const defaultLanguage = ref(null);
const defaultVoiceName = ref(null);
const defaultVoiceStyle = ref(null);
const canPlay = computed(() => totalFrame.value > 0);
const fps = computed(() => (creator.value ? creator.value.getConf("fps") : 30));
const blankTemplate = () => ({
  sceneId: Date.now(),
  start: 0,
  end: 60,
  type: "default",
  defaultVoiceName: defaultVoiceName.value,
  defaultVoiceStyle: defaultVoiceStyle.value,
  defaultLanguage: defaultLanguage.value,
  nodes: [],
});

watch(creator, (newCreator) => {
  const groupHelper = (ns) => {
    return ns.reduce((res, n) => {
      const id = n.conf.sceneId;
      if (!res[id]) {
        res[id] = [];
      }
      res[id].push(n);
      return res;
    }, {});
  };
  if (newCreator) {
    const newNodes = [];
    tracks.value = [...newCreator.children];
    resources.clear();
    for (const track of tracks.value) {
      for (const node of track.children) {
        const newNode = getNode(node);

        switch (newNode.type) {
          case "image":
          case "video":
          case "audio":
          case "sticker":
            mappingResource(newNode.conf.src, newNode);
            break;
        }

        newNode.isVisible = newNode.visible;
        newNode.isPrepared = newNode.prepared;
        newNode.on("visible", (visible) => (newNode.isVisible = visible));
        newNode.on("prepare", (prepare) => (newNode.isPrepared = prepare));
        newNode.on("render", () => newNode.updateId++);
        newNodes.push(newNode);
      }
    }
    nodes.value = newNodes;
    const sceneMap = groupHelper(nodes.value);
    for (const scene of scenes.value) {
      let temp = [];
      if (sceneMap[scene.sceneId]) temp = sceneMap[scene.sceneId];
      scene.nodes = [...temp];
    }
  }
});

function start(config) {
  return new Promise((resolve, reject) => {
    if (creator.value) {
      destroy();
    }
    validMaterial(JSON.parse(config))
      .then((validConfig) => {
        const cor = new Cor.Creator(validConfig, {
          container: document.getElementById("player"),
        });
        cor.on("durationchange", (frameNum) => (totalFrame.value = frameNum));
        cor.on("timeupdate", (frame) => (currentFrame.value = frame));
        cor.on("ended", () => setPlaying(false));
        cor.on("error", reject);
        cor.on("canplay", () => {
          scenes.value = validConfig.scriptScene.map((data) => ({
            ...data,
            nodes: [],
          }));
          const {
            defaultLanguage,
            defaultVoiceName,
            defaultVoiceStyle = "general",
          } = scenes.value
            .filter((item) => item.type === "default" || !item.type)
            .find((item) => item.defaultVoiceName);
          setDefaultVoice({
            language: defaultLanguage,
            voiceName: defaultVoiceName,
            voiceStyle: defaultVoiceStyle,
          });

          creator.value = cor;
          resolve();
        });
        cor.start();
      })
      .catch(reject);
  });
}

function destroy() {
  currentFrame.value = 0;
  totalFrame.value = 0;
  playing.value = false;
  scenes.value = [];
  activeScene.value = null;
  creator.value?.destroy();
  creator.value = null;
}

async function play() {
  if (creator.value) {
    setScenePlaying(false);
    setPlaying(true);
    await creator.value.play();
  }
}

async function pause() {
  if (creator.value) {
    setScenePlaying(false);
    setPlaying(false);
    await creator.value.pause();
  }
}

async function seekTo(value) {
  if (creator.value) {
    setScenePlaying(false);
    await creator.value.seekTo(clamp(value, 0, totalFrame.value));
  }
}

async function replay() {
  if (creator.value) {
    setPlaying(true);
    await seekTo(0);
    await play();
  }
}

function secondToFrame(duration) {
  return Math.floor(duration * fps.value);
}

function mappingResource(key, value) {
  const resource = resources.get(key);

  if (!resource) {
    resources.set(key, [value]);
  } else {
    resource.push(value);
  }
}

function materialChecker() {
  let active = true;
  for (const node of nodes.value) {
    if (node.parent.type !== "scene") {
      continue;
    }

    if (!node.active) {
      active = false;
      break;
    }
  }
  return active;
}

function getNode(node, deep = true) {
  if (!deep && node?.parent?.type === "scene") {
    return node.parent;
  } else if (deep && node?.type === "scene") {
    return node.children[0];
  } else {
    return node;
  }
}

async function addScene(scene) {
  const sceneStart = scene.end;
  const newScene = blankTemplate();
  const duration = newScene.end - newScene.start;
  const sceneEnd = sceneStart + duration;

  newScene.start = sceneStart;
  newScene.end = sceneEnd;

  const pos = scenes.value.findIndex((item) => item.sceneId === scene.sceneId);
  for (let i = pos + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], duration);
  }
  await updateOverallDuration(duration);

  scenes.value.splice(pos + 1, 0, newScene);
  return newScene;
}

async function updateSpeechSpeed(scene, params) {
  const { speed, volume } = params;
  const { speech, subtitles, primary, effect, transition } = parseNode(
    scene.nodes
  );
  const oldStart = scene.start;
  const oldEnd = scene.end;
  const oldSpeed = speech.conf.speed || 1;
  const step = oldSpeed / speed;
  const oldDuration = oldEnd - oldStart;
  const newDuration = Math.round(oldDuration * step);
  const newEnd = oldStart + newDuration;
  const diff = newDuration - oldDuration;
  const pos = scenes.value.findIndex((item) => item.sceneId === scene.sceneId);
  for (let i = pos + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], diff);
  }
  await updateOverallDuration(diff);

  for (const node of subtitles) {
    const { start, end } = node.conf;
    const newTextStart = Math.round((start - oldStart) * step) + oldStart;
    const newTextEnd = Math.round((end - oldStart) * step) + oldStart;
    node.conf.start = newTextStart;
    node.conf.end = newTextEnd;
  }

  speech.conf.volume = volume;
  speech.conf.speed = speed;
  speech.conf.end = newEnd;

  if (primary) {
    await updateMaterialSpeed(primary, oldStart, newEnd);
  }

  if (effect) {
    effect.conf.end = newEnd;
  }
  if (transition) {
    const maxTransDuration = courtship(newDuration / 2);
    const duration = transition.getDuration();

    if (newDuration < 4) {
      removeNode(transition);
    } else if (duration > maxTransDuration) {
      transition.conf.start = newStart - maxTransDuration / 2;
      transition.conf.end = newStart + maxTransDuration / 2;
    } else {
      const start = transition.conf.start + diff;
      transition.conf.start = start;
      transition.conf.end = start + duration;
    }
  }
  scene.end = newEnd;
  nextTick(refresh);
}

async function updateMaterialSpeed(node, start, end) {
  const newDuration = end - start;
  const { type } = node.conf;
  if (type === "video" && node.conf.active) {
    const oldDuration = node.conf.end - node.conf.start;
    if (oldDuration < newDuration) {
      const speed = calcRate(oldDuration, newDuration);
      node.conf.ss = Math.floor(node.conf.ss / speed);
      node.conf.speed = speed;
    }
  }
  node.conf.end = end;
}

async function addImageNode(conf) {
  await addNode({ kind: "primary", type: "image", fit: "cover", ...conf });
}

async function addVideoNode(conf) {
  await addNode({
    kind: "primary",
    type: "video",
    fit: "cover",
    volume: 0,
    ...conf,
  });
}

async function addSubtitleNode(conf) {
  await addNode({ kind: "text", type: "subtitle", ...conf });
}

async function addSpeechNode(conf) {
  await addNode({ kind: "audio", type: "speech", ...conf });
}

async function addAudioNode(conf) {
  await addNode({ kind: "audio", type: "audio", ...conf });
}

async function addTransitionNode(conf, frame = currentFrame.value, options) {
  const track = tracks.value[0];
  let current, sibling;

  for (const node of track.children) {
    if (node.type === "transition") continue;
    if (node.startFrame > frame) break;
    if (node.startFrame <= frame && frame <= node.endFrame) {
      const duration = node.getDuration();
      const prevSibling = node.prevSibling;
      const nextSibling = node.nextSibling;
      const isValidPrev =
        prevSibling &&
        (prevSibling.type === "transition" ||
          prevSibling.endFrame === node.startFrame);
      const isValidNext =
        nextSibling &&
        (nextSibling.type === "transition" ||
          nextSibling.startFrame === node.endFrame);

      current = node;

      if (frame <= node.startFrame + duration / 2) {
        if (isValidPrev) {
          sibling = prevSibling;
        } else if (isValidNext) {
          sibling = nextSibling;
        }
      } else {
        if (isValidNext) {
          sibling = nextSibling;
        } else if (isValidPrev) {
          sibling = prevSibling;
        }
      }
      break;
    }
  }
  if (current && sibling) {
    const currentIndex = track.getChildIndex(current);

    if (sibling.type === "transition") {
      const index = track.getChildIndex(sibling);
      const { start, end } = sibling.conf;
      removeNode(sibling);
      await addNode(
        { type: "transition", ...conf, start, end, index },
        options
      );
    } else {
      const minDuration = Math.min(
        current.getDuration(),
        sibling.getDuration(),
        60
      );

      if (minDuration < 4) {
        return null;
      } else {
        const siblingIndex = track.getChildIndex(sibling);
        const duration = courtship(minDuration / 2);
        const start =
          (currentIndex > siblingIndex
            ? current.startFrame
            : current.endFrame) -
          duration / 2;
        const end = start + duration;
        const index =
          currentIndex > siblingIndex ? siblingIndex + 1 : siblingIndex;
        await addNode(
          { type: "transition", ...conf, start, end, index },
          options
        );
      }
    }
  }
  return sibling;
}

async function addNode(conf, options = {}) {
  if (!conf.duration) {
    conf.duration = 90;
  }
  const {
    type,
    start = currentFrame.value,
    duration,
    index = -1,
    zIndex,
    sceneConf,
    ...restConf
  } = conf;
  const end = conf.end || start + duration;
  const { screen } = creator.value;
  let used = true;
  let track = toRaw(getTrack(type));
  if (!track) {
    track = createTrack(type);
    used = false;
  }
  const Node = Cor.NODE_MAP[type];
  const node = new Node({
    x: screen.width / 2,
    y: screen.height / 2,
    ...restConf,
    start,
    end,
  });

  if (track.kind === "primary") {
    if (["image", "video"].includes(node.type)) {
      const scene = new Cor.Scene(sceneConf);

      if (index >= 0) {
        track.addChildAt(scene, index);
      } else {
        track.addChild(scene);
      }
      scene.addChild(node);
      await scene.start();
    } else {
      if (index >= 0) {
        track.addChildAt(node, index);
      } else {
        track.addChild(node);
      }
      await node.start();
    }
  } else {
    track.addChild(node);
    await (used ? node : track).start();
  }
  if (options.shouldAnnotate || end > totalFrame.value || !used) {
    creator.value.annotate();
  }
  if (options.shouldRefresh) {
    creator.value.refresh();
  }
  if (options.shouldTrigger) {
    triggerRef(creator);
  }
  return node;
}

function getTrack(type) {
  // voice_sticker:
  // 0: primary
  // 2: subtitle
  // 3: speech
  // 4: bgm
  // normal:
  // 0: primary
  // 1: subtitle
  // 2: speech
  // 3: bgm
  const hasViralHook = scenes.value.some(
    (item) => item.type === "voice_sticker"
  );
  if (hasViralHook) {
    switch (type) {
      case "image":
      case "video":
      case "transition":
        return tracks.value[0];
      case "subtitle":
        return tracks.value[2];
      case "speech":
        return tracks.value[3];
      case "audio":
        return tracks.value[4];
    }
  } else {
    switch (type) {
      case "image":
      case "video":
      case "transition":
        return tracks.value[0];
      case "subtitle":
        return tracks.value[1];
      case "speech":
        return tracks.value[2];
      case "audio":
        return tracks.value[3];
    }
  }
  for (const track of tracks.value) {
    if (track.children[0]?.conf.type === type) {
      return track;
    }
  }
  return null;
}

function createTrack(kind, index = -1) {
  if (kind === "speech") kind = "audio";

  const track = new Cor.Track({ kind });

  if (index >= 0) {
    creator.value.addChildAt(track, index);
  } else {
    creator.value.addChild(track);
  }
  return track;
}

async function duplicateScene(scene, data) {
  const duration = scene.end - scene.start;
  const pos = scenes.value.findIndex((item) => item.sceneId === scene.sceneId);
  for (let i = pos + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], duration, false, duration);
  }
  const newId = data.sceneId;
  const cloneScene = {
    ...scene,
    start: scene.start + duration,
    end: scene.end + duration,
    sceneId: newId,
  };
  for (const node of scene.nodes) {
    const newStart = node.conf.start + duration;
    const newEnd = node.conf.end + duration;
    const newConf = {
      ...node.conf,
      sceneId: newId,
      start: newStart,
      end: newEnd,
    };
    if (node.parent.type === "scene") {
      const { videoType } = useDraftStore();
      if (["product_to_video", "visuals_to_video"].includes(videoType.value)){
        Object.assign(newConf, {
          fit: "contain",
          sceneConf: {
            backgroundBlur: 0.1,
          },
        });
      }
      else {
        Object.assign(newConf, {
          fit: "cover"
        });
      }
    }
    await addNode(newConf);
  }
  await updateOverallDuration(duration);

  scenes.value.splice(pos + 1, 0, cloneScene);
  triggerRef(creator);
  await nextTick(refresh);
}

async function addNodesAt(scene, data) {
  const { setting, getGlobalFontSetting } = useSettingStore();
  const confHelper = (conf) => {
    const { screen } = creator.value;
    return {
      video: normalizeConfig(
        screen,
        "video",
        conf.children[0].children[0].children[0]
      ),
      text: normalizeConfig(screen, "text", conf.children[1].children[0]),
    };
  };

  const start = scene.start;
  const oldDuration = scene.end - scene.start;
  const newDuration = data.end - data.start;
  const end = start + newDuration;

  const { voiceUrl, voiceName, voiceStyle } = data;
  await addSpeechNode({
    start,
    end,
    src: voiceUrl,
    voiceName,
    voiceStyle,
    volume: setting.value.speechVol,
    sceneId: scene.sceneId,
  });

  const { voiceText, config } = data;

  const textConf = {
    ...confHelper(JSON.parse(config)).text,
    ...getGlobalFontSetting(),
    y: setting.value.posY,
  };
  delete textConf.type;
  for (const item of voiceText) {
    const { start: textStart, end: textEnd } = item.voiceTime;
    await addSubtitleNode({
      ...textConf,
      start: start + textStart,
      end: start + textEnd,
      text: item.text,
      sceneId: scene.sceneId,
      wordBoundars: item.wordBoundars,
    });
  }

  const {
    materialUrl,
    materialCoverPic,
    url1080,
    width480,
    width1080,
    materialType,
    keyword,
  } = data;
  const materialConf = {
    start,
    end,
    src: materialUrl,
    hdUrl: url1080,
    sceneId: scene.sceneId,
    coverPic: materialCoverPic,
    sceneKeywords: keyword,
    materialMeta: {
      width480,
      width1080,
      url480: materialUrl,
      url1080,
    },
    fit: "cover",
  };
  const { videoType } = useDraftStore();
  if (["product_to_video", "visuals_to_video"].includes(videoType.value)){
    Object.assign(materialConf, {
      fit: "contain",
      sceneConf: {
        backgroundBlur: 0.1,
      },
    });
  }
  if (materialType === "video") {
    const oldDuration = scene.end - scene.start;
    const newDuration = end - start;
    if (oldDuration < newDuration) {
      const speed = calcRate(oldDuration, newDuration);
      materialConf.speed = speed;
      materialConf.ss = 0;
    }
    await addVideoNode(materialConf);
  } else if (materialType === "image") {
    await addImageNode(materialConf);
  }

  const diff = newDuration - oldDuration;
  const pos = scenes.value.findIndex((item) => item.sceneId === scene.sceneId);
  for (let i = pos + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], diff);
  }
  await updateOverallDuration(diff);

  scene.start = start;
  scene.end = end;
  triggerRef(creator);
  await nextTick(refresh);
}

async function swapScene(id1, id2) {
  // Ensure sequencei
  let preIndex = scenes.value.findIndex((i) => i.sceneId === id1);
  let nextIndex = scenes.value.findIndex((i) => i.sceneId === id2);
  if (preIndex > nextIndex) {
    [preIndex, nextIndex] = [nextIndex, preIndex];
  }
  const s1 = scenes.value[preIndex];
  const s2 = scenes.value[nextIndex];

  const s1Duration = getSceneDuration(s1);
  const s2Duration = getSceneDuration(s2);

  const midDiff = s2Duration - s1Duration;
  const s2Diff = s1.start - s2.start;
  const s1Diff = midDiff - s2Diff;

  // For Pre
  updateDuration(s2, s2Diff, false, 0);
  // For Mid Scene
  for (let i = preIndex + 1; i < nextIndex; i++) {
    updateDuration(scenes.value[i], midDiff);
  }
  // For Next
  updateDuration(s1, s1Diff, false, midDiff);
  const nodes1 = parseNode(s1.nodes);
  const nodes2 = parseNode(s2.nodes);
  const transition1 = nodes1.transition;
  const transition2 = nodes2.transition;

  if (transition1) {
    transition1.conf.sceneId = s2.sceneId;
    s1.nodes = s1.nodes.filter((node) => node !== transition1);
    s2.nodes.push(transition1);
  }
  if (transition2) {
    transition2.conf.sceneId = s1.sceneId;
    s2.nodes = s2.nodes.filter((node) => node !== transition2);
    s1.nodes.push(transition2);
  }

  [scenes.value[preIndex], scenes.value[nextIndex]] = [
    scenes.value[nextIndex],
    scenes.value[preIndex],
  ];

  await nextTick(refresh);
}

async function updateSpeech(scene, params, newConf) {
  const { setting, getGlobalFontSetting } = useSettingStore();

  const volume = params.volume || setting.value.speechVol;
  const speed = params.speed || 1;

  const confHelper = (conf) => {
    const { screen } = creator.value;
    return {
      video: normalizeConfig(
        screen,
        "video",
        conf.children[0].children[0].children[0]
      ),
      text: normalizeConfig(screen, "text", conf.children[1].children[0]),
    };
  };
  const { subtitles, speech, primary, effect, transition } = parseNode(
    scene.nodes
  );

  const { end } = newConf;
  const oldDuration = scene.end - scene.start;
  const oldSpeed = 1;
  const newStart = scene.start;
  const step = oldSpeed / speed;
  const newEnd = Math.round(end * step) + newStart;
  const newDuration = newEnd - newStart;
  const diff = newDuration - oldDuration;

  const { voiceText, config } = newConf;
  const textConf = {
    ...confHelper(JSON.parse(config)).text,
    ...getGlobalFontSetting(),
    y: setting.value.posY,
  };
  delete textConf.type;
  for (const item of voiceText) {
    const { start, end } = item.voiceTime;
    const conf = textConf;
    await addSubtitleNode({
      ...conf,
      speed,
      start: Math.round(start * step) + newStart,
      end: Math.round(end * step) + newStart,
      text: item.text,
      sceneId: scene.sceneId,
      wordBoundars: item.wordBoundars,
    });
  }

  if (primary) {
    await updateMaterialSpeed(primary, newStart, newEnd);
  }

  const { voiceUrl, voiceName, voiceStyle } = newConf;
  if (speech) {
    speech.conf.speed = speed;
    speech.conf.start = newStart;
    speech.conf.end = newEnd;
    speech.conf.src = voiceUrl;
    speech.conf.voiceName = voiceName;
    speech.conf.voiceStyle = voiceStyle;
  } else {
    await addSpeechNode({
      start: newStart,
      end: newEnd,
      src: voiceUrl,
      speed,
      volume,
      voiceName,
      voiceStyle,
      sceneId: scene.sceneId,
    });
  }

  if (effect) {
    effect.conf.start = newStart;
    effect.conf.end = newEnd;
  }
  if (transition) {
    const maxTransDuration = courtship(newDuration / 2);
    const duration = transition.getDuration();

    if (newDuration < 4) {
      removeNode(transition);
    } else if (duration > maxTransDuration) {
      transition.conf.start = newStart - maxTransDuration / 2;
      transition.conf.end = newStart + maxTransDuration / 2;
    } else {
      const start = transition.conf.start + diff;
      transition.conf.start = start;
      transition.conf.end = start + duration;
    }
  }
  // Clear captions
  for (const node of subtitles) {
    removeNode(node);
  }
  // Update duration
  const pos = scenes.value.findIndex((item) => item.sceneId === scene.sceneId);
  for (let i = pos + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], diff);
  }
  await updateOverallDuration(diff);
  scene.start = newStart;
  scene.end = newEnd;
}

function updateDuration(scene, diff, sceneOnly = false, diff2 = 0) {
  if (scene.type !== "default" && scene.type) return;
  if (!diff) return;
  scene.end += diff;
  scene.start += diff;

  if (sceneOnly) return;
  scene.nodes.forEach((node) => {
    if (node.conf.sceneId === scene.sceneId) {
      const delta = node.type === "transition" ? diff2 : diff;
      const newStart = node.conf.start + delta;
      const newEnd = node.conf.end + delta;
      node.conf.start = newStart;
      node.conf.end = newEnd;
    }
  });
}

async function updateBgmDuration(diff) {
  if (diff === 0) return;
  let rest = diff;
  const bgmTrack = getTrack("audio");
  const bgmNode = bgmTrack.children[bgmTrack.children.length - 1];
  const { ss, src } = bgmNode.conf;
  const audioDuration = await getAudioDuration(src).then((res) =>
    Math.floor(res * 30 - ss)
  );
  const newConf = { ...bgmNode.conf };
  let rearNode = bgmNode;
  while (rest !== 0) {
    const { end, start } = rearNode.conf;
    if (rest > 0) {
      if (end + rest - start <= audioDuration) {
        const newEnd = end + rest;
        rearNode.conf.end = newEnd;
        rearNode.setDuration(start, newEnd);
        rest -= rest;
      } else {
        const newEnd = start + audioDuration;
        rearNode.conf.end = newEnd;
        rearNode.setDuration(start, newEnd);
        rest -= newEnd - end;
        rearNode = await addNode({
          ...newConf,
          start: newEnd,
          end: newEnd,
        });
      }
    } else {
      if (end + rest > start) {
        const newEnd = end + rest;
        rearNode.conf.end = newEnd;
        rearNode.setDuration(start, newEnd);
        rest -= rest;
      } else {
        removeNode(rearNode);
        rest += end - start;
        // bgmTrack.children.pop();
        rearNode = bgmTrack.children[bgmTrack.children.length - 1];
      }
    }
  }
}

function updateFilterDuration(diff) {
  const filterTrack = getTrack("filter");
  if (!filterTrack) return;
  const filterNode = filterTrack.children[0];
  const newEnd = filterNode.conf.end + diff;
  const newStart = filterNode.conf.start;
  filterNode.conf.end = newEnd;
  filterNode.setDuration(newStart, newEnd);
}

async function updateVoiceStickerDuration(diff) {
  const voiceStickerScenes = scenes.value.filter(
    (item) => item.type === "voice_sticker"
  );
  if (voiceStickerScenes.length === 0) return;
  for (const scene of voiceStickerScenes) {
    let newEnd = scene.end;
    const { videos } = parseNode(scene.nodes);
    if (videos.length === 0) return;
    for (const video of videos) {
      const duration = await getVideoDuration(video.conf.src).then(
        (res) => res * 30
      );
      if (totalFrame.value + diff > duration) {
        newEnd = duration;
      } else {
        newEnd = totalFrame.value + diff;
      }
      video.conf.end = newEnd;
    }
    scene.end = newEnd;
  }
}

async function updateOverallDuration(diff) {
  await updateVoiceStickerDuration(diff);
  updateFilterDuration(diff);
  await updateBgmDuration(diff);
}

async function removeScene(scene) {
  activeScene.value = null;

  for (const node of scene.nodes) {
    removeNode(node, { skipRemoveTrans: true });
  }
  const diff = getSceneDuration(scene) * -1;
  const index = scenes.value.findIndex(
    (item) => item.sceneId === scene.sceneId
  );
  for (let i = index + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], diff);
  }
  await updateOverallDuration(diff);

  triggerRef(creator);
  await nextTick(refresh);
  scenes.value.splice(index, 1);
}

async function removeText(scene) {
  if (scene.nodes && scene.nodes.length > 0) {
    for (const node of scene.nodes) {
      const removeTypes = ["speech", "subtitle"];
      if (removeTypes.includes(node.type)) {
        removeNode(node);
      }
    }
    await nextTick(refresh);
  }
}

function getSceneDuration(scene) {
  const { start, end } = scene;
  return end - start;
}

function setScenePlaying(value) {
  scenePlaying.value = value;
}

function removeNode(node, options = {}) {
  const newNode = getNode(node);
  const shallowNode = getNode(node, false);
  const prevSibling = shallowNode.prevSibling;
  const nextSibling = shallowNode.nextSibling;
  const shouldRemoveTransPrev =
    !options.skipRemoveTrans &&
    prevSibling &&
    prevSibling.type === "transition";
  const shouldRemoveTransNext =
    !options.skipRemoveTrans &&
    nextSibling &&
    nextSibling.type === "transition";
  const parent = newNode.parent;

  newNode.destroy();
  if (shouldRemoveTransPrev) prevSibling.destroy();
  if (shouldRemoveTransNext) nextSibling.destroy();
  if (parent.children.length === 0) parent.destroy();
  if (options.shouldTrigger) triggerRef(creator);
  if (options.shouldRefresh) nextTick(refresh);
}

async function replaceNode(node, file) {
  let newNode;

  const oldConf = {
    start: node.conf.start,
    end: node.conf.end,
    x: node.conf.x,
    y: node.conf.y,
    rotate: node.conf.rotate,
    scale: node.conf.scale,
    opacity: node.conf.opacity,
    blend: node.conf.blend,
    keyframes: node.conf.keyframes,
    mask: node.conf.mask,
  };
  replaceData.newData = {
    fit: getFit(file),
    sceneId: node.conf.sceneId,
    sceneKeywords: node.conf.sceneKeywords,
    src: file.previewUrl,
    hdUrl: file.preview1080Url,
    coverPic: file.coverPic,
    materialMeta: {
      width480: file.width480,
      width1080: file.width1080,
      url1080: file.preview1080Url,
    },
  };
  if (file.type === "image") {
    if (node.type === "image") {
      Object.assign(node.conf, replaceData.newData);
    } else {
      newNode = new Cor.Image({ ...oldConf, ...replaceData.newData });
    }
  } else {
    const oldDuration = node.getDuration();
    const newDuration = secondToFrame(file.duration);
    const transparent = file.aiType === "videoBgRemove";

    replaceData.newData.transparent = transparent;

    if (newDuration > oldDuration) {
      const getSs = new Promise((resolve) => (replaceData.apply = resolve));
      replaceData.src = file.previewUrl;
      replaceData.poster = file.coverPic;
      replaceData.duration = oldDuration;
      replaceData.transparent = transparent;
      replaceData.showVideoCliper = true;

      const ss = await getSs;

      if (ss < 0) {
        if (ss === -1) {
          showMaterialDialog.value = true;
        }
        return false;
      }
      replaceData.newData.ss = ss;
    } else {
      replaceData.newData.speed = calcRate(newDuration, oldDuration);
      replaceData.newData.ss = 0;
    }
    if (node.type === "image") {
      newNode = new Cor.Video({
        ...oldConf,
        ...replaceData.newData,
        volume: 0,
      });
    } else {
      Object.assign(node.conf, replaceData.newData);
    }
  }
  if (newNode) {
    const parent = node.parent;
    parent.addChild(newNode);
    removeNode(node);
    await (parent.type === "scene" ? parent : newNode).start();
    await refresh();
  }
  return true;
}

function getFit(file) {
  let fit = "contain";
  const { width, height } = file;
  switch (ratio.value) {
    case "9:16":
      if (Math.abs(width / height - 9 / 16) <= 0.11) {
        fit = "cover";
      }
      break;
    case "1:1":
      if (Math.abs(width / height - 1) <= 0.19) {
        fit = "cover";
      }
      break;
    case "16:9":
      if (Math.abs(width / height - 16 / 9) <= 0.28) {
        fit = "cover";
      }
      break;
  }
  return fit;
}

function updateSceneId(scene, id) {
  for (const node of scene.nodes) {
    if (node.conf.sceneId === scene.sceneId) {
      node.conf.sceneId = id;
    }
  }
  scene.sceneId = id;
}

async function refresh() {
  creator.value.annotate();
  await creator.value.refresh();
}

function setPlaying(value) {
  playing.value = value;
}

function setActiveScene(value) {
  activeScene.value = value;
}

function setLoadingScene(value) {
  loadingScene.value = value;
}

function setLanguageList(value) {
  languageList.value = value;
}

function setDefaultVoice({ language, voiceName, voiceStyle = "general" }) {
  let temp = {};

  if (language) {
    defaultLanguage.value = language;
    temp.defaultLanguage = language;
  }
  if (voiceName) {
    defaultVoiceName.value = voiceName;
    temp.defaultVoiceName = voiceName;
  }
  if (voiceStyle) {
    defaultVoiceStyle.value = voiceStyle;
    temp.defaultVoiceStyle = voiceStyle;
  }

  for (const scene of scenes.value) {
    Object.assign(scene, temp);
  }

  temp = null;
}

export const useScriptStore = () => ({
  addNode,
  addTransitionNode,
  showMaterialDialog,
  replaceData,
  creator,
  defaultVoiceStyle,
  defaultVoiceName,
  defaultLanguage,
  languageList,
  currentFrame,
  totalFrame,
  loadingScene,
  activeScene,
  secondToFrame,
  scenePlaying,
  playing,
  canPlay,
  fps,
  nodes,
  tracks,
  scenes,
  start,
  destroy,
  replaceNode,
  removeScene,
  updateSceneId,
  materialChecker,
  setLoadingScene,
  setLanguageList,
  setDefaultVoice,
  updateOverallDuration,
  updateBgmDuration,
  updateSpeechSpeed,
  updateMaterialSpeed,
  updateDuration,
  duplicateScene,
  addSpeechNode,
  addAudioNode,
  addVideoNode,
  addImageNode,
  addSubtitleNode,
  updateSpeech,
  addNodesAt,
  removeNode,
  removeText,
  swapScene,
  addScene,
  play,
  pause,
  replay,
  seekTo,
  refresh,
  getTrack,
  setActiveScene,
  setScenePlaying,
  getFit,
});
