<template>
  <div id="premium-modal">
    <component :is="currentModal" :info="info" :closeCallback="closeCallback" :userOperationMap="userOperationMap"
      :subscriptionState="subscriptionState" v-if="modalArguments.visible" />
  </div>
</template>

<script setup lang="tsx">
import { ref, watch, defineProps } from "vue";
import { useRouter } from "vue-router";
import Space from "@/components/common/space/index.vue";
import Subscribing from "./subscribing.vue";
import { CommonButton, PrimaryButton } from "@/components/common";
import {
  modal_banner,
  Success,
  love_letter,
  gift_card,
} from "@/assets/images/premium";
import { useModalManager } from "@/components/common/custom-modal/instance";
import type { PropType, Ref, Component } from "vue";
import type {
  ModalArguments,
  ModalMap,
  CardItemInfo,
  ModalInfo,
} from "../../type";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import { grantCancelDiscount } from "@/api/premium";
import { debounce, useScopeId } from "@/utils";

const emit = defineEmits(['next', 'close'])

const router = useRouter();
const scopeId = useScopeId();
const modalManager = useModalManager();

const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { collectData, track, getTrackPlanName } = useTrackStore();
const info = ref(null);
const currentModal: Ref<Component | null> = ref(null);
const props = defineProps({
  modalArguments: {
    type: Object as PropType<ModalArguments>,
    required: true,
  },
  closeCallback: {
    type: Function,
    default: () => { },
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
});

// modalArguments驱动弹窗
watch(
  () => props.modalArguments,
  (newArguments: ModalArguments) => {
    const { card, type, visible, downgradeInfo, giftInfo, subscribingInfo } = newArguments;
    if (!visible) {
      clearModal();
      return;
    }
    if (type) {
      if (modalMap[type].component) {
        currentModal.value = modalMap[type].component;
        if (type === "subscribing") {
          info.value = subscribingInfo;
        }
      } else {
        let info = {};
        if (card) {
          info = { ...info, card };
        }
        if (downgradeInfo) {
          info = { ...info, downgradeInfo };
        }
        if (giftInfo) {
          info = { ...info, giftInfo };
        }
        if (info) {
          modalMap[type].handler(info);
        } else {
          modalMap[type].handler();
        }
      }
    }
  },
  { deep: true },
);

const modalMap: ModalMap = {
  subscribeSuccess: {
    name: "SubscribeSuccess",
    component: null,
    handler: () => {
      subscribeSuccessModal();
    },
  },
  upgradeSuccess: {
    name: "UpgradeSuccess",
    component: null,
    handler: () => {
      upgradeSuccessModal();
    },
  },
  upgrade: {
    name: "Upgrade",
    component: null,
    handler: () => {
      upgradeModal();
    },
  },
  downgrade: {
    name: "Downgrade",
    component: null,
    handler: (info) => {
      if (info && info.card && info.downgradeInfo) {
        downgradeModal(info.card, info.downgradeInfo);
      }
    },
  },
  recoverSuccess: {
    name: "RecoverSuccess",
    component: null,
    handler: () => {
      recoverSuccessModal();
    },
  },
  downgradeSuccess: {
    name: "DowngradeSuccess",
    component: null,
    handler: () => {
      downgradeSuccessModal();
    },
  },
  subscribing: {
    name: "Subscribing",
    component: Subscribing,
    handler: () => { },
  },
  contactUs: {
    name: "ContactUs",
    component: null,
    handler: () => {
      contactUsModal();
    },
  },
  yearToMonth: {
    name: "YearToMonth",
    component: null,
    handler: () => {
      yearToMonthTipsModal();
    },
  },
  ltdTips: {
    name: "LTDTips",
    component: null,
    handler: () => {
      ltdTipsModal();
    },
  },
};

const clearModal = () => {
  info.value = null;
  currentModal.value = null;
  modalManager.modal.open = false;
};

const subscribeSuccessModal = () => {
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Subscribed successfully !
            </p>
          </div>
        </div>
        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 pt-6 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p>As our esteemed member, you will :</p>
          <ul class="my-3 list-disc pl-5">
            <li>Enjoy exclusive features</li>
            <li>Priority support</li>
            <li>Regular updates</li>
            <li>Unique privileges</li>
          </ul>
          <p>
            We are always here to provide assistance and support to ensure you
            have a pleasant experience while using our services.
          </p>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

const upgradeSuccessModal = () => {
  const handleClose = () => {
  modalManager.modal.onClose()
}

  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Upgragde successfully !
            </p>
          </div>
        </div>

        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 pt-6 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p>As our esteemed member, you will :</p>
          <ul class="my-3 list-disc pl-5">
            <li>Enjoy exclusive features</li>
            <li>Priority support</li>
            <li>Regular updates</li>
            <li>Unique privileges</li>
          </ul>
          <p>
            We are always here to provide assistance and support to ensure you
            have a pleasant experience while using our services.
          </p>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

const downgradeSuccessModal = () => {
  const handleClose = () => {
  modalManager.modal.onClose()
}
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Downgrade successfully !
            </p>
          </div>
        </div>
        <div class="absolute z-10 top-5 right-5">
          <div
            class="w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p>
            As our esteemed member, we are always here to provide assistance and
            support to ensure you have a pleasant experience while using our
            services.
          </p>
          <div class="w-full text-center mt-9 flex justify-center">
            <PrimaryButton
              onclick={modalManager.modal.onClose}
              size="extra-small"
              color="#FF5449"
              fontColor="#FFF"
            >
              Continue my journey
            </PrimaryButton>
          </div>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

const downgradeModal = (
  card: CardItemInfo,
  info: ResData.BoolvideoUpdateSubPopMsg,
) => {
  const handleContinue = () => {

    collectData("boolvideo_subscribe_change", {
      click: "downgrade_tips",
      plan_name: getTrackPlanName(card.packageCode),
    });

    track("boolvideo_subscribe_change");
    emit('next', { path: 'payment', packageCode: card.packageCode, subscriptionType: 'DEGRADE_SUBSCRIPTION', state: 'view' })
    modalManager.modal.onClose();
  };

  modalManager.applyTemplate("info", {
    zIndex: 3000,
    title: (
      <div class="p-6 pb-4 w-full flex justify-between">
        <p>{`Downgrade to ${card.name.toLowerCase()}?`}</p>
        <div
          class="p-1 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor"
          onClick={() => modalManager.modal.onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
              fill="#646A73"
            />
          </svg>
        </div>
      </div>
    ),
    content: (
      <div class="w-full px-6">
        <p class="pb-4">
          Downgrading may limit your ability to upload or export if you exceed
          the cloud storage limit.
        </p>
        <div class="px-6 py-3 bg-primaryBgColor rounded-md">
          <div class="h-[102px] flex justify-between">
            <div class="flex flex-col">
              <p class="pb-4 text-transparent">1</p>
              <p class="pb-4 text-tipColor">Before</p>
              <p>After</p>
            </div>
            <div class="flex flex-col px-6 border-l border-l-uploadBg">
              <p class="text-tipColor pb-4">Cloud</p>
              <p class="text-tipColor pb-4 line-through">
                {info.beforeResource.cloud}
              </p>
              <p class="flex items-center ">
                {info.afterResource.cloud}
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 1.3125C7.24162 1.3125 7.4375 1.50838 7.4375 1.75V12.25C7.4375 12.4916 7.24162 12.6875 7 12.6875C6.75838 12.6875 6.5625 12.4916 6.5625 12.25V1.75C6.5625 1.50838 6.75838 1.3125 7 1.3125Z"
                      fill="#646A73"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.19064 8.44064C3.3615 8.26979 3.6385 8.26979 3.80936 8.44064L7 11.6313L10.1906 8.44064C10.3615 8.26979 10.6385 8.26979 10.8094 8.44064C10.9802 8.6115 10.9802 8.8885 10.8094 9.05936L7.30936 12.5594C7.1385 12.7302 6.8615 12.7302 6.69064 12.5594L3.19064 9.05936C3.01979 8.8885 3.01979 8.6115 3.19064 8.44064Z"
                      fill="#646A73"
                    />
                  </svg>
                </span>
              </p>
            </div>
            <div class="flex flex-col px-6 border-l border-l-uploadBg">
              <p class="text-tipColor pb-4">Export</p>
              <p class="text-tipColor pb-4 line-through">
                {info.beforeResource.export}
              </p>
              <p class="flex items-center ">
                {info.afterResource.export}
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 1.3125C7.24162 1.3125 7.4375 1.50838 7.4375 1.75V12.25C7.4375 12.4916 7.24162 12.6875 7 12.6875C6.75838 12.6875 6.5625 12.4916 6.5625 12.25V1.75C6.5625 1.50838 6.75838 1.3125 7 1.3125Z"
                      fill="#646A73"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.19064 8.44064C3.3615 8.26979 3.6385 8.26979 3.80936 8.44064L7 11.6313L10.1906 8.44064C10.3615 8.26979 10.6385 8.26979 10.8094 8.44064C10.9802 8.6115 10.9802 8.8885 10.8094 9.05936L7.30936 12.5594C7.1385 12.7302 6.8615 12.7302 6.69064 12.5594L3.19064 9.05936C3.01979 8.8885 3.01979 8.6115 3.19064 8.44064Z"
                      fill="#646A73"
                    />
                  </svg>
                </span>
              </p>
            </div>
            <div class="flex flex-col px-6 border-l border-l-uploadBg">
              <p class="text-tipColor pb-4">Templates</p>
              <p class="text-tipColor pb-4 line-through">
                {info.beforeResource.templates}
              </p>
              <p class="flex items-center">
                {info.afterResource.templates}
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 1.3125C7.24162 1.3125 7.4375 1.50838 7.4375 1.75V12.25C7.4375 12.4916 7.24162 12.6875 7 12.6875C6.75838 12.6875 6.5625 12.4916 6.5625 12.25V1.75C6.5625 1.50838 6.75838 1.3125 7 1.3125Z"
                      fill="#646A73"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.19064 8.44064C3.3615 8.26979 3.6385 8.26979 3.80936 8.44064L7 11.6313L10.1906 8.44064C10.3615 8.26979 10.6385 8.26979 10.8094 8.44064C10.9802 8.6115 10.9802 8.8885 10.8094 9.05936L7.30936 12.5594C7.1385 12.7302 6.8615 12.7302 6.69064 12.5594L3.19064 9.05936C3.01979 8.8885 3.01979 8.6115 3.19064 8.44064Z"
                      fill="#646A73"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    ),
    footer: (
      <Space size={12} reversed>
        <PrimaryButton
          size="extra-small"
          onclick={modalManager.modal.onClose}
          color="#FF5449"
          fontColor="#FFF"
        >
          No, keep my plan
        </PrimaryButton>
        <CommonButton size="extra-small" onclick={handleContinue}>
          Yes, continue
        </CommonButton>
      </Space>
    ),
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

const recoverSuccessModal = () => {
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Resume successfully !
            </p>
          </div>
        </div>

        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 pt-6 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p>As our esteemed member, you will :</p>
          <ul class="my-3 list-disc pl-5">
            <li>Enjoy exclusive features</li>
            <li>Priority support</li>
            <li>Regular updates</li>
            <li>Unique privileges</li>
          </ul>
          <p>
            We are always here to provide assistance and support to ensure you
            have a pleasant experience while using our services.
          </p>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

const upgradeModal = () => {
  modalManager.applyTemplate("info", {
    title: " ",
    content: (
      <div class="w-full">
        <p>
          Your quota will reduce, and unable to upload or export if cloud stored
          exceeds 10G.
        </p>
        <div></div>
      </div>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

// 年降月提示弹窗
const yearToMonthTipsModal = () => {
  modalManager.applyTemplate("info", {
    zIndex: 3000,
    title: (
      <div class="p-6 pb-4 w-full flex justify-between relative">
        <p class="flex">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
              fill="#FF8800"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z"
              fill="white"
            />
            <path
              d="M12 6V14"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="pl-4">Switch to Monthly</span>
        </p>
        <div
          class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor"
          onClick={() => modalManager.modal.onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
              fill="#646A73"
            />
          </svg>
        </div>
      </div>
    ),
    content: (
      <div class="w-full px-6">
        <p class="pl-10">
          You have already subscribed to a yearly plan. You can switch to
          monthly any time after your annual subscription ends. If you have any
          questions, feel free to contact us at{" "}
          <a href="mailto:support@boolvector.com" class="text-primaryColor">
            support@boolvector.com
          </a>
        </p>
      </div>
    ),
    footer: (
      <Space size={12} reversed>
        <CommonButton size="extra-small" onclick={modalManager.modal.onClose}>
          Got it
        </CommonButton>
      </Space>
    ),
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

// LTD提示弹窗
const ltdTipsModal = () => {
  modalManager.applyTemplate("info", {
    title: (
      <div class="p-6 pb-4 w-full flex justify-between relative">
        <p class="flex">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
              fill="#FF8800"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z"
              fill="white"
            />
            <path
              d="M12 6V14"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="pl-4">Update Plan</span>
        </p>
        <div
          class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor"
          onClick={() => modalManager.modal.onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
              fill="#646A73"
            />
          </svg>
        </div>
      </div>
    ),
    content: (
      <div class="w-full px-6">
        <p class="pl-10">
          You have already subscribed to a lifetime plan and are not eligible to
          subscribe to an additional subscription plan. If you have any
          questions, please contact:{" "}
          <a href="mailto:support@boolvector.com" class="text-primaryColor">
            support@boolvector.com
          </a>
        </p>
      </div>
    ),
    footer: (
      <Space size={12} reversed>
        <CommonButton size="extra-small" onclick={modalManager.modal.onClose}>
          Got it
        </CommonButton>
      </Space>
    ),
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};

const contactUsModal = () => {
  modalManager.applyTemplate("info", {
    zIndex: 3000,
    title: (
      <div class="p-6 pb-4 w-full flex justify-between z-999">
        <p>Contact us</p>
        <div
          class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor"
          onClick={() => modalManager.modal.onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
              fill="#646A73"
            />
          </svg>
        </div>
      </div>
    ),
    content: (
      <div class="w-full px-6">
        <p>
          Our Email:{" "}
          <a href="mailto:support@boolvector.com" class="text-primaryColor">
            support@boolvector.com
          </a>
        </p>
        <p>
          Email us if you have any questions. We will get in touch with you
          within 24 hours.
        </p>
      </div>
    ),
    footer: (
      <Space reversed>
        <CommonButton size="extra-small" onclick={modalManager.modal.onClose}>
          Got it
        </CommonButton>
      </Space>
    ),
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    props.closeCallback();
  };
};
</script>

<style lang="scss" scoped>
.modal-container {
  padding: 0;
  position: relative;
  z-index: 10;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 36%;
  max-width: 530px;
  min-width: 430px;
  border-radius: 8px;
}

.modal-header {
  width: 100%;

  p:first-child {
    width: 100%;
  }
}

.modal-footer {
  padding: 24px;
  margin-top: 0;
}

.modal-content {
  position: relative;
}

.modal-content {
  margin-top: 0;
}

.giftcard-button {
  :deep(.el-button) {
    width: 170px;
  }
}

.gift-card-content {
  position: absolute;
  left: 22px;
  bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contact-modal-index {
  z-index: 3000;
}
</style>
