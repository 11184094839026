<template>
  <el-scrollbar>
    <el-skeleton animated :loading="loading">
      <template #template>
        <div v-for="(_, i) in Array(8)" class="skeleton-item" :key="i">
          <el-skeleton-item variant="rect" />
          <el-skeleton-item variant="text" />
        </div>
      </template>
      <div class="file-list">
        <div class="file-item">
          <div
            class="image-wrapper"
            :class="{ active: !activeEffect }"
            @click.stop="remove"
          >
            <svg-icon name="script_null" color="#BBBFC4" :size="24" />
          </div>
          <div class="title">No effect</div>
        </div>
        <div
          v-for="(file, i) in files"
          class="file-item"
          :key="i"
          @mouseenter="hoverFile = file"
          @mouseleave="hoverFile = null"
        >
          <div
            class="image-wrapper"
            :class="{ active: file.name === activeEffect?.conf.name }"
            @click="add(file)"
          >
            <img
              loading="lazy"
              draggable="false"
              :src="hoverFile === file ? file.dynamicPic : file.coverPic"
            />
          </div>
          <div class="title">{{ file.name }}</div>
        </div>
      </div>
    </el-skeleton>
  </el-scrollbar>
</template>
<script setup>
import { getEffectList } from "@/api/resources";
import { useScriptStore } from "../../stores/script";
import { useDraftStore } from "../../stores/draft";
import { parseNode } from "../../utils/scene";
import { observeNode } from "../../utils/observe";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  scene: {
    type: Object,
    default: null,
  },
});
const { addNode, removeNode } = useScriptStore();
const { updateDraft } = useDraftStore();
const hoverFile = ref(null);
const files = ref([]);
const pendding = ref(props.modelValue);
const loading = ref(false);
const activeEffect = ref(null);

watch(pendding, (value) => {
  emit("update:modelValue", value);
});
watch(
  () => props.scene.nodes,
  (newNodes) => {
    if (!props.scene.sceneId || !newNodes) return;
    const { effect } = parseNode(newNodes);
    if (effect) {
      observeNode(effect);
    }
    activeEffect.value = effect;
  },
  {
    immediate: true,
  }
);
watch(
  () => props.visible,
  (value) => {
    if (value && files.value.length === 0) {
      getEffects();
    }
  }
);

function remove() {
  const { effect } = parseNode(props.scene.nodes);

  if (!effect) return;
  removeNode(effect, { shouldTrigger: true, shouldRefresh: true });
  updateDraft();
}

async function add(file) {
  if (pendding.value) {
    return;
  }
  pendding.value = true;

  const scene = props.scene;
  const { effect } = parseNode(props.scene.nodes);

  if (effect) {
    removeNode(effect);
  }
  await addNode(
    {
      type: "effect",
      fit: "cover",
      loop: true,
      name: file.name,
      src: file.url,
      blend: file.blend,
      sourceId: file.id,
      start: scene.start,
      end: scene.end,
      sceneId: scene.sceneId,
      materialMeta: {
        width480: file.width480,
        width1080: file.width1080,
        url1080: file.preview1080Url,
      },
    },
    { shouldTrigger: true, shouldRefresh: true }
  );
  pendding.value = false;
  updateDraft();
}

async function getEffects() {
  loading.value = true;
  const { success, data } = await getEffectList();

  if (success) {
    files.value = data;
  }
  loading.value = false;
}
</script>
<style scoped>
.file-list,
:deep(.el-skeleton) {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  padding: 24px 24px 40px 24px;
}
.skeleton-item {
  display: flex;
  flex-wrap: wrap;
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  width: 100%;
  height: 70px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background-color: #f3f5f7;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__text) {
  margin: 4px 0;
  height: 12px;
}
.image-wrapper.active,
.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title {
  padding: 0 2px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
</style>
