import { getNodeBoundingRect } from "./layout";

export const squareScaling = {
  /** Long side to long side */
  L2L(node, originalRect, targetRect, baseRect) {
    const resourceScale = node.getScale() / (Math.max(...originalRect) / Math.max(...baseRect));
    const ratio = Math.max(...targetRect) / Math.max(...baseRect);

    node.conf.scale = resourceScale * ratio;
  },

  /** Short side to short side */
  S2S(node, originalRect, targetRect, baseRect) {
    const resourceScale = node.getScale() / (Math.min(...originalRect) / Math.min(...baseRect));
    const ratio = Math.min(...targetRect) / Math.min(...baseRect);

    node.conf.scale = resourceScale * ratio;
  },

  /** Short side to long side */
  S2L(node, originalRect, targetRect, baseRect) {
    const resourceScale = node.getScale() / (Math.max(...originalRect) / Math.min(...baseRect));
    const ratio = Math.max(...targetRect) / Math.min(...baseRect);

    node.conf.scale = resourceScale * ratio;
  },
};

export const axisScaling = {
  toXY(node, originalRect, targetRect, baseRect) {
    const sourceRatio = originalRect[0] / originalRect[1];
    const targetRatio = targetRect[0] / targetRect[1];
    const baseRatio = baseRect[0] / baseRect[1];

    if (baseRatio < sourceRatio) {
      node.conf.scale = node.getScale() / (originalRect[0] / baseRect[0]);
    } else {
      node.conf.scale = node.getScale() / (originalRect[1] / baseRect[1]);
    }

    if (baseRatio < targetRatio) {
      node.conf.scale *= targetRect[0] / baseRect[0];
    } else {
      node.conf.scale *= targetRect[1] / baseRect[1];
    }
  },

  toX(node, originalRect, targetRect, baseRect) {
    node.conf.scale = node.getScale() / (originalRect[0] / baseRect[0]) * (targetRect[0] / baseRect[0]);
  },

  toY(node, originalRect, targetRect, baseRect) {
    node.conf.scale = node.getScale() / (originalRect[1] / baseRect[1]) * (targetRect[1] / baseRect[1]);
  }
};

export const paddingScaling = {
  toXY(node, originalRect, targetRect, baseRect) {
    const sourceRatio = originalRect[0] / originalRect[1];
    const targetRatio = targetRect[0] / targetRect[1];
    const baseRatio = baseRect[0] / baseRect[1];

    if (baseRatio < sourceRatio) {
      node.conf.scale = node.getScale() / (baseRect[0] / originalRect[0]);
    } else {
      node.conf.scale = node.getScale() / (baseRect[1] / originalRect[1]);
    }

    if (targetRatio < baseRatio || (targetRatio === 1 && baseRatio < 1)) {
      node.conf.scale *= targetRect[0] / baseRect[0];
    } else {
      node.conf.scale *= targetRect[1] / baseRect[1];
    }
  }
}
