<template>
  <el-dialog
    class="script-hook-material-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-wrapper">
        <div class="header-container">
          <span 
            v-if="!celebrity"
          >
            {{ dialogTitle }}
          </span>
          <span 
            class="stepback"
            @click="stepback"
            v-else
          >
            <svg-icon
              name="icon_arrow_left_brandkit"
              color="#1C1B1E"
              :size="24"
            />
            Back
          </span>
          <icon-button
            name="icon_close"
            color="#646A73"
            :size="20"
            @click="handleClose"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="switcher-wrapper">
          <Switcher 
            v-model="type"
            :tabs="tabs"
            @switch="(v) => type = v"
          />
        </div>
        <div class="scroll-wrapper">
          <el-scrollbar class="scroll-container" v-if="!celebrity">
            <el-skeleton animated :loading="currentPagination.loading && currentPagination.page === 0">
              <template #template>
                <div class="skeleton-list">
                  <div class="skeleton-item"  v-for="(_, i) in Array(20)" :key="i" >
                    <el-skeleton-item variant="image" style="width: 100px; height: 100px;border-radius: 4px;" />
                    <div style="display: flex; flex-direction:column; gap: 14px; margin-left: 12px;">
                      <el-skeleton-item variant="rect" style="width: 30px;"/>
                      <el-skeleton-item variant="rect" style="width: 100px;"/>
                    </div>
                  </div>
                </div>      
              </template>
              <div 
                class="celebrity-list"
                v-infinite-scroll="loadMore"
                :infinite-scroll-distance="50"
                :infinite-scroll-immediate="true"
                :style="{ paddingBottom: currentPagination.loadAll? '180px' : '0'}"
              >
                <div 
                  v-for="item in currentPagination.list"
                  :key="item.name"
                  class="celebrity-item"
                  @click="celebrityClick(item)"
                >
                  <div class="left">
                    <div class="lock-mask" v-if="item.lock">
                      <svg-icon
                        name="icon_lock"
                        :size="16"
                      />
                    </div>
                    <img 
                      v-if="item.coverPic"
                      :src="item.coverPic" 
                      :alt="item.name" 
                      class="celebrity-cover"
                    >
                  </div>
                  <div class="right">
                    <bv-tip
                      :content="item.name"
                      placement="top"
                    >
                      <p class="celebrity-name">{{ item.name || "Unnamed" }}</p>
                    </bv-tip>
                    <p class="celebrity-size">{{ `${item.size} video` }}</p>
                  </div>
                </div>
              </div>
              <div 
                v-if="type === 1 && currentPagination.list.length === 0" 
                class="character-empty-container" 
              >
                <span>No Avatar</span>
                <common-button @click="createCharacter">Create Avatar</common-button>
              </div>
            </el-skeleton>
            <Loading v-show="!currentPagination.loadAll && currentPagination.loading && currentPagination.page > 1"/>
          </el-scrollbar>
          <el-scrollbar class="scroll-container" v-else>
            <div class="hook-list">
              <el-skeleton  animated :loading="!celebrity.medias">
                <template #template>
                  <div style="width: 100%; display: flex; margin-bottom: 14px;"  v-for="(_, i) in Array(20)" :key="i">
                    <el-skeleton-item variant="image" style="width: 100px; height: 100px;border-radius: 4px;" />
                    <div style="width: 100%; display: flex; flex-direction: column; gap: 14px; margin-left: 14px;">
                      <el-skeleton-item variant="rect" style="width: 80%;" />
                      <el-skeleton-item variant="rect" style="width: 100%;" />
                    </div>
                  </div>
                </template>
                <div 
                  v-for="media in celebrity.medias"
                  class="hook-item"
                  :class="{ active: selectedHook == media}"
                  @click="hookClick(media)"
                > 
                  <div class="hook-detail">
                    <div class="left">
                      <div 
                        class="hook-cover-box"
                        @click.stop="playClick(media)"
                      > 
                        <div class="error-mask" v-if="media.state === 1">
                          <el-popover
                            popper-class="character-video-item-popover"
                            popper-style="padding: 12px 24px; background-color: #000; border: none; "
                            width="320"
                            placement="bottom" 
                            trigger="hover"
                          >
                            <template #reference>
                              <div class="error-tip-container">
                                <svg-icon 
                                  name="icon_warn" 
                                  :size="24" 
                                  color="#FFFFFF" 
                                  alt="warn icon" 
                                />
                                <span class="error-tip">Not Available</span>
                              </div>
                            </template>
                            <p class="popper-container">
                              {{`${ props.scene.type === "hook_start" ? 'Hook' : 'Sticker'} creation failed. Please check `}}
                              <span @click="createMedia">
                                here
                              </span>
                            </p>
                          </el-popover>

                        </div>
                        <div 
                          v-else-if="media.state === 2"
                          class="processing-mask">
                          <div class="progress-box">
                            <svg-icon
                              name="icon_loading"
                              :size="18"
                              color="#FFFFFF"
                            />
                            <span class="progress-tip">Generating</span>
                          </div>
                        </div>
                        <img
                          v-else
                          alt=""
                          :src="icon_play_video"
                          class="icon-play"
                          v-show="currentVideo.url !== media.url || !currentVideo.playing"
                        />
                        <video 
                          :src="media.url" 
                          :id="`celebrity_video_${media.url}`"
                          :muted="scene.type === 'voice_sticker'"
                          v-show="currentVideo.url == media.url"
                        />
                        <img class="cover" :src="media.coverPic" :alt="media.name" v-show="currentVideo.url !== media.url">
                      </div>
                    </div>
                    <div class="right">
                      <p class="hook-name">
                        {{ media.name || "Unnamed" }}
                      </p>
                      <div class="hook-control" v-if="currentVideo.url === media.url">
                        <p>
                          {{ `${secondsToMs(currentVideo.currentTime)} / ${secondsToMs(currentVideo.video.duration)}` }}
                        </p>
                        <el-slider
                          @click.stop
                          v-model="currentVideo.currentTime"
                          @change="seekTo"
                          :show-tooltip="false"
                          :step="0.1"
                          :min="0"
                          :max="currentVideo.video.duration"
                        />
                      </div>
                      <p class="hook-duration" v-else>
                        {{ secondsToMs(media.duration) }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      :src="icon_selected"
                      alt=""
                      v-if="selectedHook == media"
                    />
                    <img
                      :src="icon_select"
                      alt=""
                      v-else
                    />
                  </div>
                </div>
                <div 
                  v-if="type === 1 && celebrity.medias.length === 0" 
                  class="character-empty-container" 
                >
                  <span>No media</span>
                  <common-button @click="createMedia">Upload media for character</common-button>
                </div>
              </el-skeleton>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="footer-wrapper">
        <primary-button 
          size="small"
          :disabled="!selectedHook" 
          @click="replaceClick"
        >
          <span style="width: 108px">
            Replace
          </span>
        </primary-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import Loading from './loading.vue';
import Switcher from '../switcher/switcher.vue';
import { secondsToMs } from '@/utils/util';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';
import { getSimilarVoiceList, getSimilarHookDetail, getSimilarStickerDetail } from '@/api/similarVideo';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useMessage } from '@/utils';

const message = useMessage();
const modalManager = useModalManager();
const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  scene: {
    type: Object,
  }
});

const tabs = [
  { 
    label: "Library",
    value: 0,
  },
  {
    label: "My Avatars",
    value: 1,
  }
];
const lPagination = reactive({
  page: 0,
  size: 20,
  loading: false,
  loadAll: false,
  list: [],
});
const mPagination = reactive({
  page: 0,
  size: 20,
  loading: false,
  loadAll: false,
  list: [],
});
const type = ref(0);
const celebrity = ref(null);
const selectedHook = ref(null);
const currentVideo = reactive({
  url: null,
  video: null,
  playing: false,
  loading: true,
  currentTime: 0,
});
const dialogTitle = computed(() => {
  switch(props.scene.type) {
      case "hook_start":
        return "Choose a celebrity hook";
      case "voice_sticker":
        return "Choose a celebrity sticker";
    }
});
const currentPagination = computed(() => {
  if (type.value === 0){
    return lPagination;
  }
  else if (type.value === 1) {
    return mPagination;
  }
  return lPagination;
});

const play = (url) => {
  if (currentVideo.url !== url) {
    destroyVideo();
    const video = document.getElementById(`celebrity_video_${url}`);
    video.ontimeupdate = () => (currentVideo.currentTime = video.currentTime);
    video.oncanplay = () => { 
      currentVideo.loading = false;
    };
    video.onended = () => {
      currentVideo.playing = false;
      video.currentTime = 0;
    };

    currentVideo.url = url;
    currentVideo.video = video;
  }

  currentVideo.playing = true;
  currentVideo.video.play();
};

const pause = () => {
  if (!currentVideo.video) return;
  currentVideo.playing = false;
  currentVideo.video.pause();
};

const seekTo = (value) => {
  currentVideo.video.currentTime = value;
};

const destroyVideo = () => {
  if (currentVideo.video) {
    currentVideo.url = null;
    currentVideo.playing = false;
    currentVideo.video.ontimeupdate = null;
    currentVideo.video.onended = null;
    currentVideo.video.pause();
    currentVideo.video = null;
    currentVideo.currentTime = 0;
    currentVideo.loading = true;
  }
}

const handleClose = () => {
  emits("update:modelValue", false);
  celebrity.value = null;
  selectedHook.value = null;
  destroyVideo();
};

const stepback = () => {
  celebrity.value = null;
  selectedHook.value = null;
};

const loadMedia = async () => {
  celebrity.value.media = null;
  const { mediaId } = celebrity.value;
  const getDetail = async (id) => {
    if (props.scene.type === "hook_start") {
      return await getSimilarHookDetail(id);
    }
    else {
      return await getSimilarStickerDetail(id);
    }
  };
  await getDetail(mediaId).then(
    ({ success, data }) => {
      if (success) {
        celebrity.value.medias = data
      }
    }
  );
}

const refreshUserList = () => {
  mPagination.page = 0;
  mPagination.loadAll = false;
  mPagination.list = [];
  loadMore();
};

const celebrityClick = async (value) => {
  if (value.lock) {
    modalManager.applyTemplate("upgradeTips", {
      icon: "orangeWarn",
      title: "Upgrade to edit and use it",
      content: "The character is unavailable, upgrade to unlock this feature.",
      zIndex: 5000,
    });
    return;
  }

  if (type.value === 1 && !value.url && value.state === 0) {
    const goToEdit = () => {
      window.open(`/avatar/edit?characterId=${value.mediaId}`, "_blank");
      modalManager.applyTemplate("singleConfirm", {
        icon: "success",
        key: "refresh-character-modal",
        title: "Refresh to continue",
        content: "Once your avatar is created, refresh this page to continue your video creation",
        confirmText: "Refresh",
        onConfirm: () => refreshUserList(),
        zIndex: 5000,
      });
    };
    message.orangeWarn(
      h("p", { style: "font-size: 14px;font-style: normal;font-weight: 500;line-height: 22px;" },
      [
        h('span', { style: "color: #060606; margin-right: 24px;" }, 'No character voice'),
        h('button', { style: "color: #875EFF", onClick: () => goToEdit() }, 'Go to edit'),
      ]),
    );
    return;
  }

  celebrity.value = value

  if (!celebrity.value.medias || type.value === 1) {
    loadMedia();
  }
};

const hookClick = (value) => {
  if (type.value === 1 && value.state !== 0) return ;
  destroyVideo();
  if (selectedHook.value == value) {
    selectedHook.value = null;
  }
  else {
    selectedHook.value = value; 
  }
};

const playClick = (value) => {
  if (selectedHook.value != value) {
    selectedHook.value = value; 
  }
  play(value.url);
};

const replaceClick = () => {
  emits("confirm", selectedHook.value, type.value );
  handleClose();
};

const createCharacter = () => {
  window.open("/avatar", "_blank");

  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => refreshUserList(),
    zIndex: 5000,
  });
};

const createMedia = () => {
  window.open(`/avatar/edit?characterId=${celebrity.value.mediaId}`, "_blank");
  
  const confirm = () => {
    loadMedia();
  };

  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => confirm(),
    zIndex: 5000,
  });
}

const loadMore = async () => {
  if (currentPagination.value.loadAll || currentPagination.value.loading) return;
  currentPagination.value.loading = true;
  const getStyle = () => {
    const { type } = props.scene;
    switch(type) {
      case "hook_start":
        return 1;
      case "voice_sticker":
        return 3;
    }
  };
  const params = {
    size: currentPagination.value.size,
    page: currentPagination.value.page + 1,
    style: getStyle(),
    type: type.value,
  };
  
  const {code, data} = await getSimilarVoiceList(params);
  if (code === 0) {
    if (data.records.length > 0) {
      currentPagination.value.list = [...currentPagination.value.list, ...data.records];
      currentPagination.value.page ++;
    }
    else if (data.records.length === 0) {
      currentPagination.value.loadAll = true;
    }
  }
  currentPagination.value.loading = false;
};

const setup = async () => {
  await loadMore();
};

watch(
  type,
  () => {
    celebrity.value = null;
    const { list } = currentPagination.value;
    if (list.length === 0){
      setup();
    }
  },
  {
    immediate: true,
  }
);
</script>

<style>
.character-video-item-popover {
  background: #000;
  word-break: unset !important;
  word-wrap: break-word;
  text-align: start !important;
  
  & .popper-container {
    color: #FFF;
    /* Body/Medium-14px */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    width: 100%;
    word-wrap: break-word;

    & > span {
      color: #875EFF;
      text-decoration: underline;
      cursor: pointer; 
    }
  }
  & .el-popper__arrow::before {
    border: none !important;
    background: #000 !important;
  }
}

.script-hook-material-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.script-hook-material-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-hook-material-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & :deep(.bv-tooltip-content-container) {
    width: fit-content; 
    height: fit-content;
  }
}
.header-wrapper {
  flex: 0 0;
  padding: 20px 46px 24px;;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.switcher-wrapper {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.footer-wrapper {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .stepback {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.character-empty-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-bottom: 24px;
  }

  & :deep(.el-button) {
    padding: 10px 36px;
    color: #060606;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.scroll-wrapper {
  height: calc(100% - 52px);
  padding: 0 5px;
}

.scroll-container {
  padding: 0 24px;
}

.skeleton-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
  gap: 12px;
}

.skeleton-item {
  display: flex;
}

.celebrity-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
}

.celebrity-item {
  padding: 12px 17px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  cursor: pointer;

  &:hover {
    background: #F8F5FF;
  }

  & > .left {
    position: relative;
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background: #EBEDEF;
  }

  & .lock-mask {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.80);
    padding: 6px;
    border-radius: 4px;
    color: #ffe4ae;
  }

  & > .right {
    flex: 1 1;
  }

  & img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }

  & .celebrity-name {
    max-width: 124px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  & .celebrity-size {
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
  }
}

.hook-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 180px;
}

.hook-item {
  padding: 12px 18px 12px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
    
  &.active,
  &:hover {
    background: #F8F5FF;
  }

  & .hook-detail {
    display: flex;
    gap: 12px;

    & .right {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }


  & .hook-cover-box {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background: #ebedef;

    & > .cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    & > .icon-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
    }

    & > video {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .error-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.54);  
    z-index: 1;

    & > .error-tip-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    & .error-tip {
      color: #ffffff;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .processing-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.54);  
    z-index: 1;

    & .progress-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      inset: 0;
    }

    & .progress-tip {
      color: #ffffff;
      text-align: center;
      font-size: 14px;
    }

    & svg {
      animation: rotate 1s linear infinite;
    }
  }

  & :deep(.el-slider) {
    width: 187px;
  }

  & :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  & :deep(.el-slider__bar) {
    height: 4px;
  }

  & :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  & :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }

  & .hook-name {
    color: #000;
  }

  & .hook-control {
    margin-top: auto;
  }

  & .hook-duration {
    color: #646A73;
  }
}
</style>