<template>
  <el-dialog
    :model-value="modelValue"
    modal-class="tiktok-video-picker-modal"
    align-center
    :show-close="false"
    :append-to-body="true"
    @close="close"
  >
    <div class="header">
      <img :src="icon_tiktok_logo" alt="">
      <p>TikTok viral video trend</p>
      <icon-button 
        class="close" 
        name="icon_close"
        color="#646A73"
        :size="16"
        @click="close"
      />
    </div>
    <div class="content">
      <div class="select-container">
        <IndustrySelect 
          :options="industryOptions"
          @change="change"
        />
      </div>
      <div class="tiktok-video-list-wrapper">
        <el-scrollbar>
          <el-skeleton animated :loading="loading && page === 0">
            <template #template>
              <div class="skeleton-list">
                <div class="skeleton-item"  v-for="(_, i) in Array(20)" :key="i" >
                  <el-skeleton-item variant="rect" style="width: 100%; height: 100%; border-radius: 8px;" />
                </div>
              </div>    
            </template>  
            <div 
              class="tiktok-video-list-container"
              :style="{paddingBottom: loadAll? '150px': '0'}"
              v-infinite-scroll="loadMore"
              :infinite-scroll-distance="50"
              :infinite-scroll-immediate="false"
              :infinite-scroll-disabled="loadAll"
            >
              <TiktokItem
                v-for="(video, index) in videoList"
                :key="video.url"
                :data="video"
                :index="index"
                :currentVideo="currentVideo"
                :observer="observer"
                @play="(v) => play(v)"
                @seek="(v) => seekTo(v)"
                @setVolume="(v) => setVolume(v)"
                @pause="pause"
                @clone="clone"
              />
            </div>
            <Loading v-show="loading"/>
          </el-skeleton>
        </el-scrollbar>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import Loading from "./loading.vue";
import TiktokItem from "./tiktokItem.vue";
import IndustrySelect from "./industrySelect.vue";
import icon_tiktok_logo from "@/assets/similar-video/tiktokLogo.webp"
import { getTiktokLabels, queryTiktokVideo } from "@/api/similarVideo";
import { useTrackStore } from "@/store/modules/track";

const { collectData, track } = useTrackStore();
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  }
});
const emit = defineEmits(["update:modelValue", "confirm"]);

const size = 20;
const page = ref(0);
const timer = ref(null);
const loading = ref(false);
const loadAll = ref(false);
const videoList = ref([]);
const industryIds = ref([]);
const industryOptions = ref([]);
const currentVideo = reactive({
  url: null,
  video: null,
  playing: false,
  loading: true,
  volume: 1,
  duration: 0,
  currentTime: 0,
});
const observer = ref(null);

const play = (data) => {
  const { url, duration, industryKey } = data;
  collectData("similarvideo_paste_video_click",{
    url,
    second_label: industryKey,
  });
  track("similarvideo_paste_video_click");
  if (currentVideo.url !== url) {

    destroyVideo();
    const video = document.getElementById(`tiktok_video_${url}`);
    currentVideo.duration = duration;
    video.ontimeupdate = () => (
      currentVideo.currentTime = video.currentTime
    );
    video.onvolumechange = () => (
      currentVideo.volume = video.volume
    );
    video.onended = () => {
      currentVideo.playing = false;
      video.currentTime = 0;
      collectData("similarvideo_paste_playback", {
        url,
        result: "success"
      });
      track("similarvideo_paste_playback");
    };
    video.onplaying = () => {
      currentVideo.loading = false;
    };
    video.onwaiting = () => {
      currentVideo.loading = true;
    };
    video.onerror = () => {
      collectData("similarvideo_paste_playback", {
        url,
        result: "fail"
      });
      track("similarvideo_paste_playback");
    };
    currentVideo.url = url;
    currentVideo.video = video;
  }

  currentVideo.playing = true;
  currentVideo.video.play();
};

const pause = () => {
  if (!currentVideo.video) return;
  currentVideo.playing = false;
  currentVideo.video.pause();
};

const seekTo = (value) => {
  currentVideo.video.currentTime = value;
};

const setVolume = (value) => {
  currentVideo.video.volume = value;
};

const destroyVideo = () => {
  if (currentVideo.video) {
    currentVideo.url = null;
    currentVideo.loading = true;
    currentVideo.playing = false;
    currentVideo.onerror = null;
    currentVideo.video.onended = null;
    currentVideo.video.onplaying = null;
    currentVideo.video.onwaiting = null;
    currentVideo.video.ontimeupdate = null;
    currentVideo.video.onvolumechange = null;
    currentVideo.video.pause();
    currentVideo.video = null;
    currentVideo.volume = 1;
    currentVideo.duration = 0;
    currentVideo.currentTime = 0;
  }
};

const close = () => {
  emit("update:modelValue", false);
};

const clone = (data) => {
  emit("confirm", data);
};

const change = (value) => {
  industryIds.value = value;
  page.value = 0;
  videoList.value = [];
  loading.value = true;
  loadAll.value = false;
  const params = {
    size,
    page: page.value + 1,
    industryLabel: industryIds.value,
  };
  throttleQuery(params);
};

const loadMore = () => {
  // eventTack
  if(page.value !== 0) {
    track("similarvideo_paste_video_slip");
  }

  if(loading.value) return;
  loading.value = true;
  const params = {
    size,
    page: page.value + 1,
    industryLabel: industryIds.value,
  };
  query(params);
};

const query = (params) => {
  queryTiktokVideo(params)
    .then((res) => {
      if(res.success) {
        const { data } = res;
        videoList.value = videoList.value.concat(data.records);
        page.value ++;
        if (data.current * data.size > data.total) {
          loadAll.value = true;
        }
      }
    })
    .finally(() => {
      loading.value = false;
      timer.value = null;
  });
};

const throttleQuery = (params) => {
  if(timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(() => query(params), 1000)
};

const initObserver = () => {
  const handler = (entries) => {
    entries.forEach((entry) => {
      const video = entry.target;
      if(entry.isIntersecting) {
        if(!video.src) {
          video.src = video.dataset.src;
          video.load();
        }
      }
      else {
        if(video.dataset.src === currentVideo.url) pause();
        video.removeAttribute("src");
        video.load();
      }
    })
  };

  const root = document.getElementsByClassName("tiktok-video-list-wrapper")[0];
  const options = {
    root,
  };
  observer.value = new IntersectionObserver(handler, options);
};

const setup = () => {
  if(industryOptions.value.length === 0) {
    getTiktokLabels().then(res => {
      if (res.success) {
        industryOptions.value = res.data;
        loadMore();
      }
    });
  }
  initObserver();
};

const destroy = () => {
  if(observer.value) {
    observer.value.disconnect();
  }
};

onMounted(setup);
onBeforeUnmount(destroy);
</script>

<style lang="scss">
.tiktok-video-picker-modal {
  & .el-dialog {
    position: relative;
    width: 73%;
    height: 84%;
    border-radius: 4px;
  }

  & .el-dialog__header {
    padding: 0;
    margin: 0;
  }

  & .el-dialog__body {
    height: 100%;
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  padding: 25px 28px;
  & > img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  & > p {
    flex: 1 1;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.content {
  height: calc(100% - 74px);
}

.select-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 46px 34px;
}

.tiktok-video-list-wrapper {
  width: 100%;
  height: calc(100% - 86px);
}

.tiktok-video-list-container {
  display: grid;
  row-gap: 48px;
  column-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  padding: 0 46px;
}

.skeleton-list {
  display: grid;
  row-gap: 48px;
  column-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  padding: 0 46px;
}

.skeleton-item {
  width: 100%;
  aspect-ratio: 720 / 1280;
  border-radius: 8px;
}
</style>