<template>
  <div 
    class="tiktok-video-wrapper"
    ref="wrapperRef"
  >  
    <div class="tiktok-video-container">
      <video 
        :id="`tiktok_video_${data.url}`"
        :data-src="data.url"
        ref="videoRef"
        preload="none"
        loop
        playsinline
      />
      <template v-if="!hover">
        <div 
          class="tiktok-video-desc"
        >
          <p class="like">🔥Likes {{ like }}</p>
          <p class="title">{{ data.title }}</p>
        </div>
        <svg-icon 
          class="play"
          name="tiktok_play" 
          :size="80"
        />
      </template>
      <template v-else>
        <div
          v-if="loading"
          class="loading"
        >
          <svg-icon 
            name="icon_loading"
            color="#FFFFFF"
            :size="80"
          />
        </div>
        <div 
          class="tools-container"
        >
          <div class="tools">
            <svg-icon 
              clickable
              name="player_play" 
              v-show="!playing" 
              :size="24" 
              @click.stop="$emit('play', data)"
            />
            <svg-icon 
              clickable
              name="player_pause" 
              v-show="playing" 
              :size="24" 
              @click.stop="$emit('pause')"
            />
            <div class="volume-tools">
              <svg-icon 
                clickable
                name="player_volume" 
                color="#FFFFFF"
                v-show="!muted" 
                :size="20" 
                @click.stop="$emit('setVolume', 0)"
              />
              <svg-icon 
                clickable
                name="player_mute" 
                v-show="muted" 
                :size="20" 
                @click.stop="$emit('setVolume', 1)"
              />
              <div class="volume-slider-wrapper">
                <el-slider 
                  v-model="currentVideo.volume" 
                  @input="(v) => $emit('setVolume', v)"
                  vertical
                  height="76px"
                  :min="0" 
                  :max="1" 
                  :step="0.01" 
                  :showTooltip="false"
                />
              </div>
            </div>
          </div>
          <div class="control">
            <el-slider 
              :model-value="currentVideo.currentTime" 
              @input="(v) => $emit('seek', v)"
              :min="0" 
              :max="currentVideo.duration" 
              :step="1"
              :showTooltip="false"
            />
          </div>
        </div>
      </template>
      <icon-button 
        class="link"
        name="icon_link"
        tip="Copy URL"
        color="#00000099"
        placement
        :size="18"
        :tipDelay="0"
        @click="copy"
      />
    </div>
    <plain-button 
      class="clone"
      size="extra-small"
      @click="clone"
    >
      Clone script
    </plain-button>
  </div>
</template>


<script setup>
import { useMessage } from '@/utils';
import { useTrackStore } from '@/store/modules/track';

const { collectData, track } = useTrackStore();
const message = useMessage();
const emit = defineEmits([
  "clone", 
  "play", 
  "pause", 
  "seek", 
  "setVolume"
]);
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  currentVideo: {
    type: Object,
    required: true,
  },
  observer:{
    type: Object,
    required: true,
  }
});

const hover = ref(false);
const duration = ref(0);
const videoRef = ref(null);
const wrapperRef = ref(null);
const muted = computed(() => {
  const { currentVideo, data } = props;
  return currentVideo.url === data.url && currentVideo.volume === 0
});
const loading = computed(() => {
  const { currentVideo, data } = props;
  return currentVideo.url === data.url && currentVideo.loading
});
const playing = computed(() => {
  const { currentVideo, data } = props;
  return currentVideo.url === data.url && currentVideo.playing
});
const like = computed(() => {
  return formatNumberToK(props.data.likes);
});

const formatNumberToK = (number) => {
  if (number < 1000) {
      return number.toString();
  }
  const thousands = Math.floor(number / 1000);
  return `${thousands}K`;
};

const copy = () => {
  collectData("similarvideo_paste_copy", {
    url: props.data.url,
    second_label: props.data.industryKey,
  });
  track("similarvideo_paste_copy");
  navigator.clipboard.writeText(props.data.url)
    .then(() => {
      message.success("URL copied");
    });
};

const clone = () => {
  collectData("similarvideo_paste_clone_stript",{
    second_label: props.data.industryKey
  });
  track("similarvideo_paste_clone_stript");
  emit("clone", props.data);
}

const setup = () => {
  nextTick(() => {
    const wrapper = wrapperRef.value;
    const video = videoRef.value;
    duration.value = props.data.duration;
    wrapper.onmouseenter = () => {
      if (hover.value) return;
      hover.value = true;
      emit("play", props.data);
    };
    wrapper.onmouseleave = () => {
      emit("pause");
      hover.value = false;
      emit("seek", 0);
    };
    props.observer.observe(video);
  });
};

const destroy = () => {
  const wrapper = wrapperRef.value;
  wrapper.onmouseenter = null;
  wrapper.onmouseleave = null;
};

onMounted(setup);
onBeforeUnmount(destroy);
</script>

<style lang="scss" scoped>
.tiktok-video-wrapper {
  position: relative;
  width: 100%;
}

.tiktok-video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 720 / 1280;
  border-radius: 8px;

  & > video {
    width: 100%;
    aspect-ratio: 720 / 1280;
    background-color: #EBEDEF;
    border-radius: 8px;
  }
}

.tiktok-video-container:hover {
  & > .link {
    opacity: 1;
  }
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > svg {
    animation: rotate 2s linear infinite;
  }
}

.link {
  position: absolute;
  top: 14px;
  right: 14px;
  background: #00000099;
  opacity: 0;
  transition: opacity 0.2s;

  &:hover {
    background: #00000099 !important;
  }
}

.tiktok-video-desc {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0 14px 18px;
  color: #FFFFFF;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 54.75%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > .like {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  & > .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tools-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 14px 18px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 54.75%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > .tools {
    display: flex;
    align-items: center;
    gap: 14px;
  }
}

.volume-tools {
  position: relative;

  & > .volume-slider-wrapper{
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 28px;
    height: 92px;
    background: #1C1B1EE6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: -5px;
      height: 5px;
    }

    & :deep(.el-slider.is-vertical) {
      --el-slider-height: 3px;
      --el-slider-button-size: 8px;
      --el-slider-button-wrapper-size: 20px;
      --el-slider-button-wrapper-offset: -8.5px;
      --el-slider-main-bg-color: #FFFFFF;
      --el-slider-runway-bg-color: rgba(255, 255, 255, 0.30);
    }
  }
}

.volume-tools:hover {
  & > .volume-slider-wrapper{
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.2s;
  }
}

.control{
  & :deep(.el-slider) {
    --el-slider-main-bg-color: #FFFFFF;
    --el-slider-runway-bg-color: rgba(255, 255, 255, 0.30);
    --el-slider-height: 4px;
    --el-slider-button-size: 14px;
    --el-slider-button-wrapper-size: 20px;
    --el-slider-button-wrapper-offset: -8px;
  }
}

.clone {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
</style>