import { createApp } from "vue";
import Modal from "./index.vue";
import clickOutside from "@/directives/click-outside";
import { useScopeId } from "@/utils/hook";
import boolvTemplates from "./templates/boolv";
import type { FunctionalComponent, VNode, ComponentPublicInstance } from "vue";
import type { ModalManager } from "./modalModel";

const root = document.createElement("div");
const VRender: FunctionalComponent<{ vnode: VNode }> = ({ vnode }) => vnode;
document.body.appendChild(root);

const modalInstance: ComponentPublicInstance<
  {},
  { modalManager?: ModalManager<typeof boolvTemplates> }
> = createApp(Modal, {
  teleported: false,
  templates: boolvTemplates,
})
  .component("v-render", VRender)
  .directive("clickOutside", clickOutside)
  .mount(root);

export default modalInstance;
export function useModalManager() {
  const scopeId = useScopeId();
  
  const modalProxy = new Proxy(modalInstance.modalManager!.modal, {
    get(target, prop) {
      if (prop === "$reset") {
        return () => {
          target.$reset();
          target.scopeId = scopeId;
        };
      }

      return target[prop as keyof typeof target];
    },
  });

  return new Proxy(modalInstance.modalManager!, {
    get(target, prop) {
      if (prop === "modal") {
        return modalProxy;
      }

      return target[prop as keyof typeof target];
    },
  });
}
