<template>
  <div
    ref="segment"
    class="segment"
    :data-id="node.id"
    :data-left="beforePosition.left"
    :class="{ draggable, alignable, active, dragging, resizing }"
    :style="style"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @mousemove="handleMouseMove"
    @mousedown.stop="handleMouseDown"
    @mouseup.stop
    @contextmenu.stop.prevent="handleContextMenu"
  >
    <span
      v-for="(handler, i) in handlers"
      v-show="!dragging && active"
      class="handler"
      :key="i"
      :class="'handler-' + handler"
      @mousedown.stop="handlerDown($event, handler)"
      @mouseup.stop
    >
      <div class="stick"></div>
    </span>
    <div class="segment-content">
      <div v-if="!node.conf.active" class="segment-loading">
        <div class="icon-wapper">
          <svg-icon name="editor_segment_warning" :size="16" />
        </div>
        <span class="loading-text">Media lost</span>
      </div>
      <div v-else-if="!node.isPrepared" class="segment-loading">
        <div class="icon-wapper">
          <svg-icon
            class="loading-icon"
            name="editor_segment_loading"
            :size="16"
          />
        </div>
        <span class="loading-text">Loading</span>
      </div>
      <slot v-else></slot>
      <template v-if="keyframes">
        <keyframe
          v-for="frame in Object.keys(keyframes)"
          :key="frame"
          :visible="!dragging && active && !isMask"
          :left="getLeft(frame)"
          :easing="keyframes[frame].easing"
          @mousedown="keyframeDown($event, frame)"
          @keydown="keyDown($event, frame)"
          @apply="updateEasing($event, frame)"
        />
      </template>
      <template v-if="mask && mask.keyframes">
        <keyframe
          v-for="frame in Object.keys(mask.keyframes)"
          :key="frame"
          :visible="!dragging && active && isMask"
          :left="getLeft(frame)"
          :easing="mask.keyframes[frame].easing"
          @mousedown="keyframeDown($event, frame)"
          @keydown="keyDown($event, frame)"
          @apply="updateEasing($event, frame)"
        />
      </template>
      <div v-show="node === replaceData.node" class="segment-mask"></div>
    </div>
    <div
      v-show="showSplitLine"
      class="split-line"
      :style="splitLineStyle"
    ></div>
  </div>
</template>
<script setup>
import { render } from "vue";
import Cor from "@boolv/creator";
import {
  useCreatorStore,
  useNodeStore,
  useCopyStore,
  useDraftStore,
  useHistoryStore,
  useAttrTrackStore,
  useKeyboard,
  useDragStore,
} from "../../stores";
import { getComputedSize, getBounds, clamp, courtship } from "../../utils";
import { unrefElement } from "../../composables";
import { useTrackStore } from "@/store/modules/track";
import { throttled } from "@/utils";
import Keyframe from "./keyframe.vue";

const emit = defineEmits([
  "update:start",
  "update:end",
  "update:ss",
  "update:keyframes",
  "update:mask",
]);
const props = defineProps({
  y: {
    type: Number,
    default: 0,
  },
  start: {
    type: Number,
    default: 0,
  },
  end: {
    type: Number,
    default: 0,
  },
  ss: {
    type: Number,
    default: 0,
  },
  draggable: {
    type: Boolean,
    default: true,
  },
  alignable: {
    type: Boolean,
    default: true,
  },
  symmetry: {
    type: Boolean,
    default: false,
  },
  handlers: {
    type: Array,
    default: () => ["ml", "mr"],
  },
  style: {
    type: Object,
    default: {},
  },
  node: {
    type: Object,
    default: null,
  },
  keyframes: {
    type: Object,
    default: null,
  },
  mask: {
    type: Object,
    default: null,
  },
});

const {
  empty,
  magnet,
  timelineEnterd,
  segmentMenu,
  magnetMap,
  autoSnap,
  primaryNode,
  replaceData,
  attrTabMap,
  zIndexDelta,
  timeline,
  nodeMap,
  nodeSet,
  displayFrame,
  currentFrame,
  creator,
  activeNodeMap,
  frameToWidth,
  widthToFrame,
  secondToFrame,
  getTrack,
  createTrack,
  refresh,
  seekTo,
  tracks,
  setActiveNode,
  replaceNode,
  removeNode,
  getNode,
  addTransitionNode,
  adsorb,
  splitNode,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { setCopyedKeyframe } = useCopyStore();
const { commit } = useHistoryStore();
const dragData = useDragStore();
const { pressed } = useKeyboard();
const { collectData, track, clearEventData } = useTrackStore();
useNodeStore(props.node);
useAttrTrackStore(props.node);

const timelineRef = inject("timeline");
const scrollRef = inject("scrollRef");
const trackList = inject("trackList");
const refLineY = inject("refLineY");
const refLineLeft = inject("refLineLeft");
const refLineRight = inject("refLineRight");
const showRefLineY = inject("showRefLineY");
const showRefLineLeft = inject("showRefLineLeft");
const showRefLineRight = inject("showRefLineRight");

const mouseEntered = ref(false);
const splitLineLeft = ref(0);
const ticker = ref(null);
const scrollX = ref(0);
const scrollY = ref(0);
const frame = ref(-1);
const left = ref(frameToWidth(props.start));
const top = ref(props.y);
const right = ref(null);
const width = ref(frameToWidth(props.end - props.start));
const parentWidth = ref(null);
const moveStart = ref(false);
const moving = ref(false);
const dragStart = ref(false);
const dragging = ref(false);
const resizeStart = ref(false);
const resizing = ref(false);
const scrollStart = ref(false);
const scrolling = ref(false);
const segment = ref(null);
const currentHandler = ref(null);

const beforePosition = reactive({
  pageX: 0,
  pageY: 0,
  width: 0,
  height: 0,
  frame: -1,
  ss: 0,
});
const dragBounds = ref(null);
const scrollBounds = ref(null);
const resizeBounds = ref(null);

const shadow = ref(null);
const currentTrack = ref(null);
const trackIndex = ref(null);
const overlayElement = ref(null);

const isMask = computed(() => attrTabMap[props.node.id] === "mask");
const isDragTarget = computed(() => props.node.id === "dragTarget");
const isPrimary = computed(() => primaryNode.value === props.node);
const shouldMagnet = computed(
  () => magnet.value && props.node.parent && props.node.parent.type === "scene"
);
const draggable = computed(() => props.draggable && props.node.isPrepared);
const showSplitLine = computed(
  () => timeline.mode === "split" && mouseEntered.value
);
const active = computed(
  () => activeNodeMap.has(props.node.id) && props.node.isPrepared
);
const style = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
  width: `${width.value}px`,
  ...props.style,
}));
const splitLineStyle = computed(() => ({
  left: `${splitLineLeft.value}px`,
}));

onMounted(() => {
  const parentElement = segment.value.parentElement;
  const [pw] = getComputedSize(parentElement);

  parentWidth.value = pw;
  right.value = pw - width.value - left.value;

  resetPosition();
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp, true);
});

onBeforeUnmount(() => {
  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp, true);
  stopScroll();
});

watch(active, (active) => {
  if (active && nodeSet.has(props.node)) {
    segment.value.scrollIntoView({ block: "center" });
    nodeSet.delete(props.node);
  }
});
watch(
  [() => props.start, () => props.end],
  ([newStart, newEnd], [oldStart]) => {
    const { node, keyframes, mask } = props;
    const delta = newStart - oldStart;

    node.setDuration(newStart, newEnd);

    if (resizing.value) {
      return;
    }
    if (keyframes) {
      const newKeyframes = {};

      for (const key of Object.keys(keyframes)) {
        const frameString = (parseInt(key) + delta).toString();
        newKeyframes[frameString] = keyframes[key];
      }
      emit("update:keyframes", newKeyframes);
    }
    if (mask && mask.keyframes) {
      const newKeyframes = {};

      for (const key of Object.keys(mask.keyframes)) {
        const frameString = (parseInt(key) + delta).toString();
        newKeyframes[frameString] = mask.keyframes[key];
      }
      emit("update:mask", { ...mask, keyframes: newKeyframes });
    }
  }
);
watch(
  [() => props.start, () => props.end, () => timeline.frameWidth],
  ([newStart, newEnd]) => {
    if (dragging.value || resizing.value) {
      return;
    }
    left.value = frameToWidth(newStart);
    width.value = frameToWidth(newEnd - newStart);
    right.value = parentWidth.value - width.value - left.value;
  }
);

watch(
  () => timeline.frameWidth,
  () => nextTick(resetPosition)
);
watch(magnetMap, () => {
  const node = props.node;
  const parent = node.parent;
  const start = magnetMap.get(node.id);

  if (
    dragging.value ||
    start === undefined ||
    !parent ||
    (parent.type !== "scene" && node.type !== "transition")
  ) {
    return;
  }
  const newLeft = frameToWidth(start);
  beforePosition.left = newLeft;
  beforePosition.right = parentWidth.value - width.value - newLeft;
  magnetMap.delete(node.id);
});

function mouseMove(e) {
  if (moveStart.value) {
    move(e);
  } else if (dragStart.value) {
    drag(e);
  } else if (resizeStart.value) {
    resize(e);
  }
}

function mouseUp() {
  if (moveStart.value && !moving.value && frame.value >= 0) {
    seekTo(frame.value);
  }
  primaryNode.value = null;
  moveStart.value = false;
  dragStart.value = false;
  resizeStart.value = false;
  currentHandler.value = null;
  showRefLineLeft.value = false;
  showRefLineRight.value = false;
  showRefLineY.value = false;

  if (moving.value) {
    moving.value = false;
    updateKeyframes();
    frame.value = -1;
  }
  if (dragging.value) {
    if (shadow.value) {
      left.value = getBounds(shadow.value)[0];
    }
    if (empty.value) {
      left.value = 0;
    }
    top.value = 0;
    dragging.value = false;
    handleDragEnd();
  }
  if (resizing.value) {
    resizing.value = false;
    handleResizeEnd();
  }
  stopScroll();
  resetPosition();
}

function keyDown(e, frame) {
  switch (e.code) {
    case "Delete":
    case "Backspace":
      removeKeyframe(frame);
      break;
    case "KeyC":
      pressed.value && copyKeyframe(frame);
      break;
  }
}

function handleMouseEnter() {
  if (mouseEntered.value) {
    return;
  }
  mouseEntered.value = true;
}

function handleMouseLeave() {
  if (!mouseEntered.value) {
    return;
  }
  mouseEntered.value = false;
}

function handleMouseMove(e) {
  if (showSplitLine.value) {
    const bound = segment.value.getBoundingClientRect();
    splitLineLeft.value = e.pageX - bound.x;
  }
}

function handleMouseDown(e) {
  const node = props.node;

  if (showSplitLine.value) {
    const frame = widthToFrame(splitLineLeft.value) + widthToFrame(left.value);
    splitNode(frame, node);
  } else {
    window.removeEventListener("mouseup", mouseUp, true);
    window.addEventListener("mouseup", mouseUp, true);

    if (e.isTrusted || isDragTarget.value) {
      primaryNode.value = node;

      if (active.value) {
        const trackElements = document.querySelectorAll(".track");

        for (let i = trackElements.length - 1; i >= 0; i--) {
          const track = trackElements[i];
          const elements = track.querySelectorAll(".segment.active");

          for (const element of elements) {
            if (element === segment.value) continue;
            element.dispatchEvent(new MouseEvent("mousedown", e));
          }
        }
      } else {
        setTimeout(() => setActiveNode(node, !pressed.value));
      }
    }
    if (draggable.value) {
      dragStart.value = true;
    }
    savePosition(e);
    calcDragBounds();
    calcScrollBounds();
  }
}

function handlerDown(e, handler) {
  window.removeEventListener("mouseup", mouseUp, true);
  window.addEventListener("mouseup", mouseUp, true);

  resizeStart.value = true;
  currentHandler.value = handler;

  if (e.isTrusted) {
    primaryNode.value = props.node;
    const elements = document.querySelectorAll(
      `.segment.active .handler-${handler}`
    );

    for (const element of elements) {
      if (element.parentElement === segment.value) continue;
      element.dispatchEvent(new MouseEvent("mousedown", e));
    }
  }
  savePosition(e);
  calcResizeBounds();
}

function keyframeDown(e, frameString) {
  if (e.button !== 0) return;

  const currentFrame = parseInt(frameString);

  moveStart.value = true;
  frame.value = currentFrame;
  savePosition(e);
}

function move(e) {
  moving.value = true;

  const delta = widthToFrame(e.pageX - beforePosition.pageX);
  const newFrame = clamp(beforePosition.frame + delta, props.start, props.end);
  frame.value = newFrame;
}

function drag(e) {
  const scrollElement = scrollRef.value;
  const node = props.node;
  const bounds = dragBounds.value;
  const scrollLeft = beforePosition.scrollLeft - scrollElement.scrollLeft;
  const scrollTop = beforePosition.scrollTop - scrollElement.scrollTop;
  const deltaX = beforePosition.pageX - e.pageX + scrollLeft;
  const deltaY = beforePosition.pageY - e.pageY + scrollTop;

  if ((deltaX === 0 && deltaY === 0) || !primaryNode.value) {
    return;
  }
  if (!active.value) {
    setActiveNode(node);
  }
  if (!segment.value.classList.contains("active")) {
    return;
  }
  if (!dragging.value && isPrimary.value && shouldMagnet.value) {
    adsorb(true);
  }
  dragging.value = true;

  if (magnetMap.size > 0) {
    return;
  }
  let newLeft = Math.max(bounds.minLeft, beforePosition.left - deltaX);
  let newRight = parentWidth.value - width.value - newLeft;
  const newTop = beforePosition.top - deltaY;
  const snapValue = 10;

  if (isPrimary.value && timelineEnterd.value) {
    if (autoSnap.value && props.alignable && activeNodeMap.size <= 1) {
      [newLeft, newRight] = snap(newLeft, newRight, snapValue);
    }
    scroll(e);
  }
  if (
    props.alignable &&
    node.getTrack() === primaryNode.value.getTrack() &&
    timelineEnterd.value
  ) {
    const isDrag = isDragTarget.value;
    const timelineElement = unrefElement(timelineRef);
    const trackElements = document.querySelectorAll(".track");
    const timelineBox = timelineElement.getBoundingClientRect();
    let clientX = snapToGrid(newLeft);
    let overlay = null,
      replaced = null,
      valid = false,
      trackIndexRef = null,
      showRefLineYRef = false,
      prev = segment.value;

    while ((prev = prev.previousElementSibling)) {
      if (prev.classList.contains("dragging")) {
        break;
      }
    }
    for (let i = 0, j = trackElements.length - 1; j >= 0; i++, j--) {
      const trackElement = trackElements[i];
      const prevTrackElement = trackElements[i - 1];
      const track = toRaw(tracks.value[j]);
      const isPrimaryTrack = track.kind === "primary";
      const shouldMagnet = magnet.value && isPrimaryTrack;
      const trackBox = trackElement.getBoundingClientRect();
      const elements = trackElement.querySelectorAll(
        ".segment.alignable:not(.active)"
      );
      const shadows = trackElement.getElementsByClassName("segment-shadow");

      if (
        trackBox.top <= e.pageY &&
        e.pageY <= trackBox.bottom &&
        ((isPrimary.value && (track.kind !== "primary" || !magnet.value)) ||
          (shouldMagnet && (prev === null || shadows.length > 0)))
      ) {
        if (track.valid(node)) {
          valid = true;

          if (
            shadows.length > 0 &&
            shadows[0] !== shadow.value &&
            shouldMagnet
          ) {
            const element = shadow.value
              ? shadow.value.previousElementSibling
              : shadows[shadows.length - 1];
            const [sx] = getBounds(element);
            const [dx] = getBounds(prev);
            const [sw] = getComputedSize(element);
            const [dw] = getComputedSize(prev);

            newLeft = dx + dw;
            clientX = sx + sw;
          } else if (elements.length === 0 && shouldMagnet) {
            clientX = 0;
          } else {
            for (let i = 0; i < elements.length; i++) {
              const element = elements[i];
              const bounding = segment.value.getBoundingClientRect();
              const [w] = getComputedSize(element);
              const x = parseFloat(element.dataset.left);
              const r = x + w;
              const right = clientX + width.value;
              const cursorX = clientX + e.pageX - bounding.x;
              const centerX = x + w / 2;
              const replaceLeft = x + w * 0.1;
              const replaceRight = x + w * 0.9;

              if (shouldMagnet) {
                if (isDrag) {
                  if (replaceLeft <= cursorX && cursorX <= replaceRight) {
                    valid = false;
                    replaced = element;
                    break;
                  } else if (cursorX < replaceLeft) {
                    clientX = x;
                    overlay = element;
                    break;
                  } else if (i === elements.length - 1) {
                    clientX = r;
                  }
                } else {
                  if (cursorX <= centerX) {
                    clientX = x;
                    overlay = element;
                    break;
                  } else if (i === elements.length - 1) {
                    clientX = r;
                  }
                }
              } else {
                if (isDrag) {
                  if (
                    ["image", "video"].includes(node.type) &&
                    x + snapValue < cursorX &&
                    cursorX < r - snapValue
                  ) {
                    valid = false;
                    replaced = element;
                    break;
                  } else if (clientX < r && right > x) {
                    valid = false;
                    break;
                  } else if (right <= x) {
                    break;
                  }
                } else {
                  if (right <= x) {
                    break;
                  } else if (clientX < r && right > x) {
                    valid = false;
                    break;
                  }
                }
              }
            }
          }
          if (valid) {
            let overlayX = clientX + width.value;

            if (
              shouldMagnet &&
              shadow.value === shadows[0] &&
              activeNodeMap.size > 1
            ) {
              for (const activeNode of activeNodeMap.values()) {
                if (
                  activeNode.getTrack() === node.getTrack() &&
                  activeNode !== node
                ) {
                  overlayX += frameToWidth(activeNode.getDuration());
                }
              }
            }
            const oldOverlay = toRaw(overlayElement.value);

            if (oldOverlay && oldOverlay !== overlay) {
              setStart(oldOverlay, 0, true);
            }
            if (overlay) {
              const deltaX = overlayX - parseFloat(overlay.dataset.left);
              setStart(overlay, deltaX, true);
            }
            if (!shadow.value) {
              shadow.value = document.createElement("div");
              shadow.value.classList.add("segment-shadow");
              shadow.value.style.width = `${width.value}px`;
              trackElement.appendChild(shadow.value);
            }
            shadow.value.style.left = `${clientX}px`;
            overlayElement.value = overlay;
            currentTrack.value = track;
            zIndexDelta.value = track.getZIndex() - node.getZIndex();
          }
        }
        break;
      } else if (e.pageY <= trackBox.top) {
        if (prevTrackElement) {
          const prevTrackBox = prevTrackElement.getBoundingClientRect();

          if (prevTrackBox.bottom <= e.pageY) {
            showRefLineYRef = true;
            trackIndexRef = j + 1;
            refLineY.value =
              scrollElement.scrollTop +
              prevTrackBox.bottom -
              timelineBox.top +
              4;
          }
        } else {
          showRefLineYRef = true;
          trackIndexRef = j + 1;
          refLineY.value =
            scrollElement.scrollTop + trackBox.top - timelineBox.top - 4;
        }
        break;
      } else if (e.pageY > trackBox.bottom && track.kind === "primary") {
        showRefLineYRef = true;
        trackIndexRef = j + 1;
        refLineY.value =
          scrollElement.scrollTop + trackBox.top - timelineBox.top - 4;
        break;
      }
    }
    if (replaced) {
      const lastElement = dragData.target.lastElementChild;
      replaceData.node = nodeMap.get(replaced.dataset.id);
      dragData.segment.style.opacity = 0;
      dragData.target.style.opacity = 1;

      if (lastElement.classList.contains("replace-tip")) {
        lastElement.style.display = "block";
      }
    } else {
      replaceData.node = null;

      if (dragData.target) {
        const lastElement = dragData.target.lastElementChild;
        dragData.target.style.opacity = 0;
        dragData.segment.style.opacity = 1;

        if (lastElement.classList.contains("replace-tip")) {
          lastElement.style.display = "none";
        }
      }
    }
    if (!overlay) {
      removeOverlay();
    }
    if (!valid) {
      removeShadow();
    }
    trackIndex.value = trackIndexRef;
    showRefLineY.value = showRefLineYRef;
  }
  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
}

function resize(e) {
  const node = props.node;
  const deltaX = snapToGrid(beforePosition.pageX - e.pageX);

  if (deltaX === 0 || !primaryNode.value) {
    return;
  }
  resizing.value = true;

  const handler = currentHandler.value;
  const bounds = resizeBounds.value;

  let newLeft = left.value;
  let newRight = right.value;

  if (handler[1] === "l") {
    newLeft = clamp(
      beforePosition.left - deltaX,
      bounds.minLeft,
      bounds.maxLeft
    );
    if (props.symmetry) {
      newRight = right.value - (left.value - newLeft);
    }
  } else if (handler[1] === "r") {
    newRight = clamp(
      beforePosition.right + deltaX,
      bounds.minRight,
      bounds.maxRight
    );
    if (props.symmetry) {
      newLeft = left.value - (right.value - newRight);
    }
  }
  if (autoSnap.value && isPrimary.value && props.alignable) {
    [newLeft, newRight] = snap(newLeft, newRight, 5, handler[1]);
  }

  newLeft = clamp(newLeft, bounds.minLeft, bounds.maxLeft);
  newRight = clamp(newRight, bounds.minRight, bounds.maxRight);
  const newWidth = computeWidth(parentWidth.value, newLeft, newRight);

  left.value = newLeft;
  right.value = newRight;
  width.value = newWidth;

  const start = widthToFrame(newLeft);
  const duration = widthToFrame(newWidth);
  const end = start + duration;
  const deltaLeft = beforePosition.left - newLeft;
  const deltaRight = beforePosition.right - newRight;

  if (handler[1] === "l" && ["video", "audio", "speech"].includes(node.type)) {
    const info = node.getInfo();
    const speed = node.getSpeed();
    const newSs = clamp(
      beforePosition.ss - Math.floor(widthToFrame(deltaLeft) * speed),
      0,
      secondToFrame(info.duration / speed) - 1
    );

    emit("update:ss", newSs);
  }
  emit("update:start", start);
  emit("update:end", end);

  if (isPrimary.value) {
    if (shouldMagnet.value) {
      if (handler[1] === "l") {
        let prev = segment.value;

        while ((prev = prev.previousElementSibling)) {
          if (!prev.dataset.id) {
            continue;
          }
          const node = nodeMap.get(prev.dataset.id);
          const duration = node.getDuration();
          const start = widthToFrame(parseFloat(prev.dataset.left) - deltaLeft);

          node.conf.start = start;
          node.conf.end = start + duration;
        }
      } else if (handler[1] === "r") {
        let next = segment.value;

        while ((next = next.nextElementSibling)) {
          if (!next.dataset.id) {
            continue;
          }
          const node = nodeMap.get(next.dataset.id);
          const duration = node.getDuration();
          const start = widthToFrame(
            parseFloat(next.dataset.left) + deltaRight
          );

          node.conf.start = start;
          node.conf.end = start + duration;
        }
      }
    }
    displayFrame.value = handler[1] === "l" ? start : end;
  }
}

async function handleDragEnd() {
  if (replaceData.node) {
    replaceData.showBack = false;
    replaceNode(replaceData.node, dragData.file).then((success) => {
      if (success) {
        submit();
        collectData("boolvideo_timeline_edit_click", {
          click: "replace_media",
        });
        track("boolvideo_timeline_edit_click");
      }
    });
    dragData.segment = null;
    dragData.data = null;
    dragData.file = null;
    replaceData.node = null;
    render(null, trackList.value);
  } else {
    const elements = document.querySelectorAll(".segment.dragging");
    const start = widthToFrame(left.value);
    const duration = props.end - props.start;
    const end = start + duration;
    let node;

    if (isDragTarget.value) {
      const { type, conf } = props.node;
      const Node = Cor.NODE_MAP[type];
      node = new Node({ ...conf, start, end });

      dragData.data = null;
      dragData.file = null;
      render(null, trackList.value);
      dragData.segment = null;

      if (!timelineEnterd.value) {
        return;
      }
    } else {
      node = toRaw(props.node);
      emit("update:start", start);
      emit("update:end", end);
      await nextTick();
    }
    const parent = node.parent;
    const { track, used } = getOrCreateTrack(node);

    removeShadow();
    overlayElement.value = null;
    showRefLineY.value = false;
    removeTransitionIfNeeded(node, start, end, track);

    if (node.type === "transition") {
      if (!isDragTarget.value) {
        removeNode(node, { skipTrigger: true, skipRefresh: true });
      }
      await addTransitionNode(node.conf, start, { skipRender: true });
    } else if (track.kind === "primary") {
      if (parent && parent.type === "scene") {
        track.addChild(parent);
      } else {
        const scene = new Cor.Scene();
        track.addChild(scene);
        scene.addChild(node);
        await scene.start(false, false);
      }
    } else {
      track.addChild(node);
    }
    if (parent && parent.children.length === 0) {
      parent.destroy();
    }
    removeTransitionIfNeeded(node, start, end);
    triggerRef(creator);

    if (!used) {
      await track.start(false, false);
    }
    if (isDragTarget.value && node.type !== "transition") {
      await node.start();
    }
    setActiveNode(node);
    if (elements.length <= 1) {
      zIndexDelta.value = 0;
      creator.value.annotate();
      triggerRef(creator); // Sort the elements of track
      creator.value.refresh();
      submit();
    }
    trackTimeChangeHelper();
  }
}

function removeTransitionIfNeeded(node, start, end, track) {
  const shallowNode = getNode(node, false);
  const prevSibling = shallowNode.prevSibling;
  const nextSibling = shallowNode.nextSibling;

  if (prevSibling && prevSibling.type === "transition") {
    if (
      (track && track !== shallowNode.parent) ||
      start !== prevSibling.prevSibling.endFrame
    ) {
      removeNode(prevSibling, { skipTrigger: true, skipRefresh: true });
    }
  }
  if (nextSibling && nextSibling.type === "transition") {
    if (
      (track && track !== shallowNode.parent) ||
      end !== nextSibling.nextSibling.startFrame
    ) {
      removeNode(nextSibling, { skipTrigger: true, skipRefresh: true });
    }
  }
}

function handleResizeEnd() {
  const { node, keyframes, mask, start, end } = props;
  const elements = document.querySelectorAll(".segment.resizing");
  const shallowNode = getNode(node, false);
  const prevSibling = shallowNode.prevSibling;
  const nextSibling = shallowNode.nextSibling;
  const duration = end - start;
  const maxTransDuration = courtship(duration / 2);

  if (keyframes) {
    const newKeyframes = { ...keyframes };

    for (const key of Object.keys(keyframes)) {
      const frame = parseInt(key);

      if (frame < props.start || frame > props.end) {
        delete newKeyframes[frame];
      }
    }
    emit("update:keyframes", newKeyframes);
  }
  if (mask && mask.keyframes) {
    const newKeyframes = { ...mask.keyframes };

    for (const key of Object.keys(mask.keyframes)) {
      const frame = parseInt(key);

      if (frame < props.start || frame > props.end) {
        delete newKeyframes[frame];
      }
    }
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  }
  if (prevSibling && prevSibling.type === "transition") {
    if (
      (!magnet.value && start > prevSibling.prevSibling.endFrame) ||
      duration < 4
    ) {
      removeNode(prevSibling, { skipRefresh: true });
    } else if (prevSibling.getDuration() > maxTransDuration) {
      prevSibling.conf.start = start - maxTransDuration / 2;
      prevSibling.conf.end = start + maxTransDuration / 2;
    }
  }
  if (nextSibling && nextSibling.type === "transition") {
    if (
      (!magnet.value && end < nextSibling.nextSibling.startFrame) ||
      duration < 4
    ) {
      removeNode(nextSibling, { skipRefresh: true });
    } else if (nextSibling.getDuration() > maxTransDuration) {
      nextSibling.conf.start = start - maxTransDuration / 2;
      nextSibling.conf.end = start + maxTransDuration / 2;
    }
  }
  if (elements.length <= 1) {
    if (shouldMagnet.value) {
      adsorb();
    }
    nextTick(refresh);
    submit();
  }
  displayFrame.value = currentFrame.value;
}

function snap(l, r, asp, alignment = "lr") {
  const parentElement = segment.value.parentElement;
  const unactiveElements = document.querySelectorAll(".segment:not(.active)");
  const alignLeft = alignment.includes("l");
  const alignRight = alignment.includes("r");
  const justify = alignLeft && alignRight;
  const width = computeWidth(parentWidth.value, l, r);

  let newLeft = l;
  let newRight = r;
  let isAdsorptionLeft = false;
  let isAdsorptionRight = false;
  let lineLeft = 0;
  let lineRight = 0;

  for (const element of unactiveElements) {
    if (
      (magnet.value && currentTrack.value?.kind === "primary") ||
      replaceData.node
    ) {
      break;
    }
    if (
      shouldMagnet.value &&
      element.parentElement === parentElement &&
      !justify
    ) {
      continue;
    }
    const [x] = getBounds(element);
    const [w] = getComputedSize(element);
    const r1 = l + width;
    const r2 = x + w;
    const rl = x - r1;
    const lr = l - r2;
    const ll = l - x;
    const rr = r1 - r2;

    if (alignLeft) {
      if (-asp <= lr && lr <= asp) {
        isAdsorptionLeft = true;
        newLeft = lineLeft = r2;
        if (justify) {
          newRight = parentWidth.value - width - newLeft;
        }
        break;
      } else if (-asp <= ll && ll <= asp) {
        isAdsorptionLeft = true;
        newLeft = lineLeft = x;
        if (justify) {
          newRight = parentWidth.value - width - newLeft;
        }
        break;
        // console.log('[ll]', { ll, rr, newLeft, newRight })
      }
    }
    if (alignRight) {
      if (-asp <= rl && rl <= asp) {
        isAdsorptionRight = true;
        newRight = parentWidth.value - x;
        lineRight = x;
        if (justify) {
          newLeft = parentWidth.value - width - newRight;
        }
        break;
      } else if (-asp <= rr && rr <= asp) {
        isAdsorptionRight = true;
        newRight = parentWidth.value - r2;
        lineRight = r2;
        if (justify) {
          newLeft = parentWidth.value - width - newRight;
        }
        break;
        // console.log('[rr]', { ll, rr, newLeft, newRight, isAdsorptionLeft, isAdsorptionRight })
      }
    }
  }
  showRefLineLeft.value = isAdsorptionLeft;
  showRefLineRight.value = isAdsorptionRight;
  refLineLeft.value = lineLeft;
  refLineRight.value = lineRight;

  return [newLeft, newRight];
}

function scroll(e) {
  const scrollElement = scrollRef.value;
  const bounds = scrollBounds.value;

  if (e.pageX <= bounds.minLeft && scrollElement.scrollLeft > 0) {
    scrollX.value = -10;
  } else if (
    e.pageX >= bounds.minRight &&
    scrollElement.scrollLeft < bounds.maxLeft
  ) {
    scrollX.value = 10;
  } else {
    scrollX.value = 0;
  }
  if (e.pageY <= bounds.minTop && scrollElement.scrollTop > 0) {
    scrollY.value = -5;
  } else if (
    e.pageY >= bounds.minBottom &&
    scrollElement.scrollTop < bounds.maxTop
  ) {
    scrollY.value = 5;
  } else {
    scrollY.value = 0;
  }
  if (scrollX.value || scrollY.value) {
    startScroll();
  } else {
    stopScroll();
  }
}

function tick() {
  const scrollElement = scrollRef.value;
  const bounds = scrollBounds.value;
  scrolling.value = true;

  if (scrollX.value) {
    if (
      (scrollX.value < 0 && scrollElement.scrollLeft <= 0) ||
      (scrollX.value > 0 && scrollElement.scrollLeft >= bounds.maxLeft)
    ) {
      scrollX.value = 0;
    }
    let newLeft = left.value;
    let newRight = right.value;

    newLeft = left.value + scrollX.value;
    newRight = right.value - scrollX.value;

    left.value = newLeft;
    right.value = newRight;
    width.value = computeWidth(parentWidth.value, newLeft, newRight);
    scrollElement.scrollLeft = clamp(
      scrollElement.scrollLeft + scrollX.value,
      0,
      bounds.maxLeft
    );
  }
  if (scrollY.value) {
    if (
      (scrollY.value < 0 && scrollElement.scrollTop <= 0) ||
      (scrollY.value > 0 && scrollElement.scrollTop >= bounds.maxTop)
    ) {
      scrollY.value = 0;
    }
    let newTop = top.value;

    newTop = top.value + scrollY.value;

    top.value = newTop;
    scrollElement.scrollTop = clamp(
      scrollElement.scrollTop + scrollY.value,
      0,
      bounds.maxTop
    );
  }

  if (scrollX.value || scrollY.value) {
    ticker.value = requestAnimationFrame(tick);
  } else {
    stopScroll();
  }
}

function startScroll() {
  showRefLineLeft.value = false;
  showRefLineRight.value = false;

  if (!scrollStart.value) {
    scrollStart.value = true;
  }
  if (!ticker.value) {
    ticker.value = requestAnimationFrame(tick);
  }
}

function stopScroll() {
  scrollStart.value = false;

  if (scrolling.value) {
    scrolling.value = false;
  }
  if (ticker.value) {
    cancelAnimationFrame(ticker.value);
  }
  ticker.value = null;
}

function calcDragBounds() {
  const bounds = dragBounds.value;
  const elements = document.querySelectorAll(".segment.active");
  let min = left.value;

  if (active.value) {
    for (const element of elements) {
      const [left] = getBounds(element);
      min = Math.min(min, left);
    }
  }
  bounds.minLeft = left.value - min;
}

function calcScrollBounds() {
  const scrollElement = scrollRef.value;
  const bounds = scrollBounds.value;
  const scrollBox = scrollElement.getBoundingClientRect();

  bounds.minLeft = scrollBox.left + 150;
  bounds.maxLeft = Math.floor(
    scrollElement.scrollWidth - scrollElement.clientWidth
  );
  bounds.minRight = scrollBox.right - 150;
  bounds.minTop = scrollBox.top + 50;
  bounds.maxTop = Math.floor(
    scrollElement.scrollHeight - scrollElement.clientHeight
  );
  bounds.minBottom = scrollBox.bottom - 50;
}

function calcResizeBounds() {
  const node = props.node;
  const symmetry = props.symmetry >> 0;
  const bounds = resizeBounds.value;

  bounds.minLeft = 0;
  bounds.maxLeft =
    left.value + (width.value / (symmetry + 1) - timeline.frameWidth);
  bounds.minRight = 0;
  bounds.maxRight =
    right.value + (width.value / (symmetry + 1) - timeline.frameWidth);

  let prev = segment.value.previousElementSibling;
  let next = segment.value.nextElementSibling;

  if (!shouldMagnet.value) {
    while (prev && !prev.classList.contains("alignable")) {
      prev = prev.previousElementSibling;
    }
    while (next && !next.classList.contains("alignable")) {
      next = next.nextElementSibling;
    }
    if (prev) {
      const [x] = getBounds(prev);
      const [w] = getComputedSize(prev);
      bounds.minLeft = x + w * (1 - symmetry);
    }
    if (next) {
      const [x] = getBounds(next);
      const [w] = getComputedSize(next);
      bounds.minRight = parentWidth.value - x - w * symmetry;
    }
  }
  switch (node.type) {
    case "transition": {
      const { prevSibling, nextSibling } = props.node;
      const minDuration = Math.min(
        prevSibling.getDuration(),
        nextSibling.getDuration()
      );
      const maxDuration = courtship(minDuration / 2);
      const maxWidth = frameToWidth(maxDuration);
      const minLeft = left.value - (maxWidth - width.value) / 2;
      const minRight = right.value - (maxWidth - width.value) / 2;

      bounds.minLeft = minLeft;
      bounds.minRight = minRight;
      break;
    }
    case "video":
    case "audio":
    case "speech": {
      const info = node.getInfo();
      const ss = node.getSs();
      const speed = node.getSpeed();
      const ssWidth = frameToWidth(ss / speed);
      const durationWidth = frameToWidth(secondToFrame(info.duration / speed));
      const minLeft = left.value - ssWidth;
      const minRight = parentWidth.value - minLeft - durationWidth;

      bounds.minLeft = Math.max(bounds.minLeft, minLeft);
      bounds.minRight = Math.max(bounds.minRight, minRight);
      break;
    }
  }
}

function savePosition(e) {
  beforePosition.pageX = e.pageX;
  beforePosition.pageY = e.pageY;
  beforePosition.scrollLeft = scrollRef.value.scrollLeft;
  beforePosition.scrollTop = scrollRef.value.scrollTop;
  beforePosition.frame = frame.value;
  beforePosition.ss = props.ss;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.pageY = 0;
  beforePosition.scrollLeft = 0;
  beforePosition.scrollTop = 0;
  beforePosition.frame = -1;
  beforePosition.ss = 0;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;

  dragBounds.value = {
    minLeft: null,
    maxLeft: null,
    minRight: null,
    maxRight: null,
  };
  scrollBounds.value = {
    maxLeft: null,
    maxRight: null,
    maxTop: null,
    maxBottom: null,
  };
  resizeBounds.value = {
    minLeft: null,
    maxLeft: null,
    minRight: null,
    maxRight: null,
  };
}

function computeWidth(parentWidth, left, right) {
  return parentWidth - left - right;
}

function snapToGrid(delta) {
  return Math.round(delta / timeline.frameWidth) * timeline.frameWidth;
}

function submit() {
  commit();
  updateDraft();
}

const trackTimeChangeHelper = throttled(() => {
  const node = props.node;
  const id = node.conf.sourceId || node.id;
  collectData("boolvideo_timeline_edit_click", {
    click: "time_change",
    element_type: node.type,
    element_id: id,
    with_mask: Boolean(node.conf.mask),
  });
  track("boolvideo_timeline_edit_click");
  clearEventData("boolvideo_timeline_edit_click");
}, 10000);

function getOrCreateTrack(node) {
  const trackRef = currentTrack.value;
  const trackIndexRef = trackIndex.value;
  const result = { used: false };
  let zIndex = node.parent && node.getZIndex();

  if (!isPrimary.value) {
    zIndex = Math.max(1, zIndex + zIndexDelta.value);
  }
  if (node.type === "transition") {
    result.track = toRaw(tracks.value[0]);
    result.used = true;
  }
  if (trackRef) {
    result.track = toRaw(trackRef);
    result.used = true;
  } else if (trackIndexRef) {
    result.track = createTrack(node.type, trackIndexRef);
  } else {
    Object.assign(result, getTrack(node, zIndex));
  }
  return result;
}

function setStart(element, deltaX = 0, deep) {
  const helper = (e) => {
    if (!e || !e.dataset.id) return;
    const node = nodeMap.get(e.dataset.id);
    const left = parseFloat(e.dataset.left) + deltaX;
    const start = widthToFrame(left);
    const duration = node.getDuration();
    const end = start + duration;

    node.conf.start = start;
    node.conf.end = end;
  };
  helper(element);

  if (element && deep) {
    let next = element;

    while ((next = next.nextElementSibling)) {
      helper(next);
    }
  }
}

function removeOverlay() {
  if (overlayElement.value) {
    setStart(overlayElement.value, 0, true);
  }
  overlayElement.value = null;
}

function removeShadow() {
  const shadowRef = shadow.value;

  if (shadowRef) {
    shadowRef.parentElement.removeChild(shadowRef);
  }
  shadow.value = null;
  currentTrack.value = null;
}

function updateKeyframes() {
  const { keyframes, mask } = props;
  const oldFrame = beforePosition.frame;
  const newFrame = frame.value;

  if (isMask.value) {
    const newKeyframes = { ...mask.keyframes };

    newKeyframes[newFrame] = { ...mask.keyframes[oldFrame] };
    delete newKeyframes[oldFrame];
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  } else {
    const newKeyframes = { ...keyframes };

    newKeyframes[newFrame] = { ...keyframes[oldFrame] };
    delete newKeyframes[oldFrame];
    emit("update:keyframes", newKeyframes);
  }
  submit();
}

function copyKeyframe(frame) {
  const { keyframes, mask } = props;

  if (isMask.value) {
    const keyframe = { ...mask.keyframes[frame] };
    setCopyedKeyframe(keyframe);
  } else {
    const keyframe = { ...keyframes[frame] };
    setCopyedKeyframe(keyframe);
  }
}

function removeKeyframe(frame) {
  const { keyframes, mask } = props;

  if (isMask.value) {
    const newKeyframes = { ...mask.keyframes };
    delete newKeyframes[frame];
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  } else {
    const newKeyframes = { ...keyframes };
    delete newKeyframes[frame];
    emit("update:keyframes", newKeyframes);
  }
  submit();
}

function updateEasing(easing, frame) {
  const { keyframes, mask } = props;

  if (isMask.value) {
    const newKeyframes = { ...mask.keyframes };
    newKeyframes[frame].easing = easing;
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  } else {
    const newKeyframes = { ...keyframes };
    newKeyframes[frame].easing = easing;
    emit("update:keyframes", newKeyframes);
  }
  submit();
}

function handleContextMenu(e) {
  const node = props.node;

  setTimeout(() => {
    segmentMenu.visible = true;
    segmentMenu.left = e.pageX;
    segmentMenu.top = e.pageY;
  });
  if (activeNodeMap.has(node.id)) {
    return;
  }
  setTimeout(() => setActiveNode(node));
}

function getLeft(frameString) {
  const currentFrame = parseInt(frameString);
  const newFrame =
    currentFrame === beforePosition.frame ? frame.value : currentFrame;
  return frameToWidth(newFrame - props.start);
}
</script>
<style scoped>
.segment {
  position: absolute;
  height: 100%;
}
.segment:not(.alignable) {
  z-index: 2;
}
.segment.active:not(.dragging):before {
  content: "";
  position: absolute;
  inset: 0;
  outline: 2px solid #6741ff;
  outline-offset: -2px;
  z-index: 3;
  pointer-events: none;
}
.segment.dragging {
  z-index: 6;
}
.segment.dragging:not(.alignable) {
  z-index: 7;
}
.handler {
  width: 7px;
  height: 100%;
  position: absolute;
  cursor: ew-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.handler-ml {
  left: 0;
}
.handler-mr {
  right: 0;
}
.stick {
  width: 100%;
  height: 16px;
  border: 2px solid #6741ff;
  background-color: #fff;
}
.handler-ml .stick {
  border-radius: 0 4px 4px 0;
}
.handler-mr .stick {
  border-radius: 4px 0 0 4px;
}
.segment-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.segment-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.segment-loading {
  display: flex;
  align-items: center;
}
.icon-wapper {
  margin-left: 18px;
  margin-right: 8px;
}
.segment-loading svg {
  margin: 0;
}
.segment-loading .loading-icon {
  animation: rotate 1s linear infinite;
}
.loading-text {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.split-line {
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px dashed #875eff;
  z-index: 9;
  pointer-events: none;
}
:deep(.segment-content > svg) {
  margin: 0 8px;
  flex: 0 0 18px;
}
:deep(.segment-content .segment-title) {
  width: calc(100% - 18px);
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-right: 5px;
}
</style>
