<template>
  <div class="editpart-container">
    <div class="switcher-wrapper">
      <div v-if="showBack" class="back" @click="handleBack">
        <svg-icon name="icon_arrow_left_brandkit" color="#1C1B1E" :size="24" />
        Back
      </div>
      <ul class="switcher">
        <li
          v-for="item in tabs"
          :key="item.name"
          :class="{ active: item.name === activeName }"
          @click="handleSwitch(item.name)"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>
    <div class="editpart-content" v-if="setting">
      <SceneList v-show="activeName === 'scene'" />
      <Setting v-show="activeName === 'setting'" />
    </div>
  </div>
</template>

<script setup>
import { useSettingStore } from "../../stores/setting";
import { useTrackStore } from "@/store/modules/track";
import { useDraftStore } from "../../stores/draft";
import SceneList from "../scene/scenelist.vue";
import Setting from "../setting/setting.vue";

const { collectData, track } = useTrackStore();
const { videoType } = useDraftStore();
const { setting } = useSettingStore();
const router = useRouter();

const tabs = [
  {
    name: "scene",
    text: "Scene",
  },
  {
    name: "setting",
    text: "Setting",
  },
];
const activeName = ref("scene");
const showBack = computed(() => window.history.length > 1);

const handleSwitch = (tabName) => {
  const trackHelper = () => {
    if (activeName.value !== "setting") return;
    collectData("boolvideo_scene_edit_click", {
      click: "setting",
      video_type: videoType.value,
    });
    track("boolvideo_scene_edit_click");
  };
  activeName.value = tabName;
  trackHelper();
};

const handleBack = () => {
  const trackHelper = () => {
    collectData("boolvideo_scene_edit_click", {
      click: "scene_back",
      video_type: videoType.value,
    });
    track("boolvideo_scene_edit_click");
  };
  trackHelper();
  router.back();
};
</script>

<style lang="scss" scoped>
.editpart-container {
  height: 100%;
}

.editpart-content {
  height: calc(100% - 70px);
}

.switcher-wrapper {
  position: relative;
  margin-top: 19px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switcher {
  display: flex;
  align-items: center;
  padding: 1px;
  border-radius: 6px;
  color: #000;
  background: #f3f5f7;
  border: 0.5px solid #e5e7eb;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  & > li {
    min-width: 88px;
    height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition:
      background-color 0.2s,
      box-shadow 0.2s;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    background-color: transparent;
    color: #000000;

    &.active {
      box-shadow:
        0px 0.25px 0.25px 0px #e5e7eb,
        0px 1px 0.75px 0px #e5e7eb;
      background-color: #fff;
      color: #6741ff;
    }
  }
}

.back {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #000000;
  cursor: pointer;

  & > svg {
    margin-right: 2px;
  }
}
</style>
