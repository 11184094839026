<template>
  <div class="input-container">
    <el-input 
      type="textarea" 
      placeholder="Ask anything..."
      v-model="prompt"
      ref="elInputRef"
      :autosize="{ minRows: 1, maxRows: 11 }"
      @keyup.stop
    />
    <bv-tip 
      placement="top"
      :content="`Send`"
    >
      <svg-icon 
        name="assistance_arrow"
        class="assistance_arrow"
        :size="36"
        :disabled="disabled"
        @click="send"
      />
    </bv-tip>
  </div>
</template>

<script setup>
import { getOS } from "@/utils/platform";
import { useAssistStore } from "@/store/modules/assist";
import { setItem, getItem } from '@/utils/storage';
import { set } from "lodash-es";

const { messages } = storeToRefs(useAssistStore());
const { postMessage } = useAssistStore();
const os = getOS();

const prompt = ref("");
const elInputRef = ref(null);
const isMacOS = computed(() => os === "Mac OS");
const disabled = computed(() => {
  const disabledArray = ["loading", "typing"];
  const pending = messages.value.some((item) => disabledArray.includes(item.state));
  return pending || !containContent(prompt.value);
});

const keydown = (e) => {
  e.stopPropagation();
  const { code, isComposing, shiftKey } = e;
  if (isComposing) return;
  if (code === "Enter") {
    e.preventDefault();
    if(shiftKey){
      prompt.value += "\n";
    }
    else {
      !disabled.value && send();
    }
  }
};

function containContent(str) {
  // 使用正则表达式匹配只有回车和空格的情况
  return str && !(/^[ \n\r]+$/.test(str));
}

const send = () => {
  postMessage(prompt.value);
  clearPrompt();
};

const clearPrompt = () => {
  prompt.value = "";
};

onMounted(() => {
  const inputEl = elInputRef.value.ref;
  inputEl.addEventListener("keydown", keydown);
});

onBeforeUnmount(() => {
  const inputEl = elInputRef.value.ref;
  inputEl .removeEventListener("keydown", keydown);
});
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 20px;
}

:deep(.bv-tooltip-content-container) {
  position: absolute;
  right: 28px;
  bottom: 28px;
  width: fit-content;
  height: fit-content;
}

.assistance_arrow {
  padding: 9px;
  border-radius: 8px;
  color: #FFFFFF;
  background: #6EABA0;
  cursor: pointer;
  transition: background 0.16s linear 0s;
  &.disabled {
    color: #BBBFC4;
    background: #EBEDEF;
    cursor: auto;
    pointer-events: none;
  }
} 

:deep(.el-textarea) {
  width: 100%;
  height: 100%;
}

:deep(.el-textarea__inner) {
  padding: 13px 46px 13px 20px;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border: 1px solid #E5E7EB;
  box-shadow: none;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: transparent;
    transition: background-color 0.3s linear 0s;
  }
  
  &::placeholder {
    color: #8F959E;
  }
}

:deep(.el-textarea__inner):hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

:deep(.el-textarea__inner:focus) {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
</style>