<template>
  <footer>
    <nav class="content-container">
      <ul class="footer-menu">
        <li v-for="(item, i) of FeaturesMenuList" :data-i="i">
          <span class="footer-menu-title">{{ item.name }}</span>
          <div class="footer-sub-menu feature-sub-menu">
            <div v-for="(subItem, i) of item.items" class="">
              <a
                v-if="/^[a-z]+:/.test(subItem.path)"
                :href="subItem.path"
                target="_blank"
                >{{ subItem.name }}</a
              >
              <router-link
                v-else-if="subItem.link"
                :to="{ path: subItem.path, query: subItem.query }"
                >{{ subItem.name }}</router-link
              >
              <p :data-i="i" @click="handlePath(item)" v-else>
                {{ subItem.name }}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <ul class="footer-menu grid-list">
        <li v-for="(item, i) of ResourcesMenuList" :data-i="i">
          <span class="footer-menu-title">{{ item.name }}</span>
          <div class="footer-sub-menu">
            <div v-for="(subItem, i) of item.items" class="">
              <a
                v-if="/^[a-z]+:/.test(subItem.path)"
                :href="subItem.path"
                target="_blank"
                >{{ subItem.name }}</a
              >
              <router-link
                v-else-if="subItem.link"
                :to="{ path: subItem.path, query: subItem.query }"
                >{{ subItem.name }}</router-link
              >
              <p :data-i="i" @click="handlePath(item)" v-else>
                {{ subItem.name }}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <div>
        <img class="boolvideo-icon" :src="boolvideoIcon" alt="Boolvideo" />
        <ul class="social-media-list">
          <li v-for="item of socialMediaList">
            <img :src="item.icon" :alt="`${item.name} icon`" />
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </footer>
</template>

<script setup lang="ts">
import { FeaturesMenuList, ResourcesMenuList, socialMediaList } from './data.js';
import boolvideoIcon from '@/assets/icons/home/boolvideo_text.svg';
import { useRouter } from 'vue-router';

const router = useRouter();

const handlePath = (item: any) => {
  const path = item.path;
  if (item.handler) {
    item.handler();
  } else {
    if (/^[a-z]+:/.test(path)) {
      window.open(path);
    } else {
      router.push({ path, query: item.query });
    }
  }
};

</script>

<style lang="scss" scoped>
footer {
  width: 100%;

  background-color: #000;
}

.content-container {
  padding: 46px 16px 129px 16px;
  color: #ffffff;
}

.boolvideo-icon {
  width: 129px;
  height: 27px;
  margin-bottom: 34px;
}

.social-media-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15.29px;
  row-gap: 8px;
  margin-top: 11px;
  & > li {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }
  }
}

.footer-menu {
  display: flex;
  margin-bottom: 43px;
  gap: 25px;
  cursor: default;
}

.footer-menu-title {
  font-size: 12px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-sub-menu {
  margin-top: 15px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  & > div {
    width: fit-content;
    padding-block: 9px;
    cursor: pointer;
    &:hover {
      color: #875eff;
    }
  }
}

.feature-sub-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 8px;
}

.grid-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
}
</style>
