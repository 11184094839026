<template>
  <Teleport to="body">
    <div 
      v-show="visible"
      ref="maskNode"
      class="scene-loading-mask-container"
    >
    </div>
  </Teleport>
</template>

<script setup>
import { useScriptStore } from "../../stores/script";

const { 
  scenes,
  loadingScene
} = useScriptStore();

const maskNode = ref(null);
const cloneNode = ref(null);
const visible = computed(() => 
  loadingScene.value !== null
);

watch(
  loadingScene,
  (scene) => {
    if (!scene) {
      cloneNode.value = null;
      return;
    }
    const sceneNodeList = document.querySelectorAll(".scene-wrapper.default");
    const index = scenes.value
      .filter(i => i.type ===  "default" || !i.type)
      .findIndex(i => i.sceneId === scene.sceneId);
    const oNode = sceneNodeList[index];
    const { top, left, width, height } = oNode.getBoundingClientRect();
    const node = oNode.cloneNode(true);

    Object.assign(node.style, {
      position: "fixed",
      zIndex: "5",
      top: top + "px",
      left: left + "px",
      width: width + "px",
      height: height + "px",
      margin: 0,
    });
    node.style.setProperty("pointer-events", "none", "important");
    cloneNode.value = node;
  },
  {
    flush: "post",
  },
);

watch(
  cloneNode,
  (value, oldValue) => {
    oldValue && maskNode.value.removeChild(oldValue);
    value && maskNode.value.appendChild(value);
  },
);
</script>

<style lang="scss" scoped>
.scene-loading-mask-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.80);

  & > .scene-wrapper {
    margin: 0;
  }
}
</style>