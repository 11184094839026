<script setup lang="ts">
import type { VNodeArrayChildren } from "vue";

const elRef = ref(null as unknown as HTMLElement);
const emit = defineEmits(["click", "contextmenu"]);
const instance = getCurrentInstance()!;
const props = defineProps({
  trigger: {
    type: String,
    default: "contextmenu",
  },
  width: {
    type: Number,
    default: null,
  },
  options: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
});
const visible = ref(false);
const hover = ref(false);
const left = ref(0);
const top = ref(0);
const style = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
  width: `${props.width}px`,
}));

const vnode = computed(() => {
  const target = instance.slots.default!()[0];

  if (typeof target.type === "symbol") {
    if (target.children!.length !== 1) {
      throw new Error(
        "[bv-context-menu] Only one child is allowed in the default slot."
      );
    }

    return (target.children as VNodeArrayChildren)[0];
  }

  return target;
});

async function contextmenu(e: MouseEvent) {
  visible.value = true;
  left.value = e.pageX;
  top.value = e.pageY;
  await nextTick();
  // auto adjust position
  const height = elRef.value.offsetHeight;
  const width = elRef.value.offsetWidth;
  if (height + e.pageY > document.body.offsetHeight) {
    top.value = e.pageY - height;
  }
  if (width + e.pageX > document.body.offsetWidth) {
    left.value = e.pageX - width;
  }
  emit("contextmenu");
}

function mouseDown() {
  if (hover.value) {
    return;
  }
  hide();
}

function hide() {
  visible.value = false;
  left.value = 0;
  top.value = 0;
}

function itemClick(option: any) {
  if (option.disabled) {
    return;
  }
  hide();
  option?.onClick(option);
  emit("click", option);
}

onMounted(() => {
  document.addEventListener("mousedown", mouseDown, { capture: true });
});
onBeforeUnmount(() => {
  document.removeEventListener("mousedown", mouseDown, { capture: true });
});

defineExpose({
  el: elRef,
  top,
  left,
  visible,
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <v-render :vnode="vnode" @[trigger].stop.prevent="contextmenu" />
  <teleport v-if="visible" to="body">
    <div
      class="contentmenu"
      ref="elRef"
      :style="style"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <div
        v-for="(option, i) in options"
        class="menu-item"
        :class="{ disabled: option.disabled }"
        :key="i"
        @click="itemClick(option)"
      >
        <div class="item-left">
          <svg-icon
            v-if="option.icon"
            class="prefix"
            :disabled="option.disabled"
            :name="option.icon"
            :size="18"
          />
          <v-render :vnode="option.label || option" />
        </div>
        <span v-if="option.description" class="description">{{ option.description }}</span>
      </div>
    </div>
  </teleport>
</template>
<style scoped>
.contentmenu {
  z-index: 1000;
  padding: 8px 0;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  background: #fff;
  min-width: 30px;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
}
.menu-item {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px;
  color: #060606;
  font-size: 14px;
  line-height: 22px;
  user-select: none;
}
.menu-item:hover {
  background-color: rgba(31, 35, 41, 0.08);
}
.menu-item.disabled {
  color: #bbbfc4;
}
.menu-item.disabled:hover {
  background: none;
}

.prefix {
  display: inline;
  margin-right: 12px;
}
.description {
  color: #8F959E;
}
</style>
