<template>
  <div class="relative w-full h-full overflow-hidden">
    <div
      class="w-full flex justify-center items-center image-box"
      ref="imageContainer"
    >
      <div
        :style="{ height: boxWidth + '%', width: '100%' }"
        ref="imageBox"
      >
        <drag :isDrag="isDrag" :isRest="isRest" @click="handleTest">
          <div
            class="w-full h-full flex justify-center items-center absolute top-0 left-0"
            v-if="isShowBrush"
          >
            <div
              class="cursor-icon"
              :style="{
                width: 2 * brushSize + 'px',
                height: 2 * brushSize + 'px',
              }"
            ></div>
          </div>

          <div
            class="w-full h-full flex justify-center items-center -z-1"
            :class="isCompare || !removeComplete? 'relative': 'absolute'"
            :style="{ opacity: isCompare || !removeComplete ? 1 : 0 }"
          >
            <img class="image" :src="originImage" v-if="!removeComplete" />
            <img
              v-else
              class="image"
              :src="originImage"
              :style="{
                width: (imageWidth / 2) * zoomRatio + 'px',
                height: (imageHeight / 2) * zoomRatio + 'px',
              }"
            />
          </div>

          <context-menu :operations="operations">
            <matting-board
              :style="{ opacity: isCompare || !removeComplete ? 0 : 1 }"
              :class="isCompare || !removeComplete? 'absolute': 'relative'"
              ref="mattingBoard"
              :isDrag="isDrag"
              :rawImage="rawImage"
              :mattingImage="mattingImage"
              :radius="brushSize"
              :isErasing="isErasing"
              :zoomRatio="zoomRatio"
              @update="handleMattingUpdate"
              @onMouseEnterCanvas="handleMouseEnter"
              @onInit="handleMattingInit"
            />
          </context-menu>
        </drag>
      </div>
    </div>

    <!-- button -->
    <div class="process-btn-box" v-if="mode == 'editor' && waitProcess">
      <primary-button
        :disabled="loading"
        :showDefaultIcon="false"
        padding="20px 65px"
        @click="handleProcess"
      >
        <template #preIcon>
          <svg-icon
            name="icon_magic"
            :style="{ width: '20.7px', height: '20.7px', color: '#fff' }"
          ></svg-icon>
        </template>
        <p
          class="text-base font-normal flex items-center justify-center leading-22"
          :style="{ marginLeft: '10px' }"
        >
          Remove BG
        </p>
      </primary-button>
    </div>

    <div class="tool-bar-box" :style="{ bottom: positionBottom }" v-else>
      <tool-bar
        class="tool-bar"
        :disable="loading"
        :undoDisable="undoDisable"
        :redoDisable="redoDisable"
        @onScale="handleScale"
        @onCompare="handleCompare"
        @onReset="handleReset"
        @onDrag="handleDrag"
        @onSubmit="handleSubmit"
        @onApply="handleApply"
        @onBrushSize="handleBrushSize"
        @onIsErasing="handleErase"
        @onBack="handleUndoClick"
        @onPre="handleRedoClick"
        :brushMoveList="brushMoveList"
        :buttonText="buttonText"
        :loading="isSubmitting"
      />
    </div>

    <loading-mask v-if="loading" />
  </div>
</template>

<script setup lang="jsx">
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import ToolBar from "../../components/toolBar/index.vue";
import Drag from "../../components/drag/index.vue";
import LoadingMask from "../../components/loading.vue";
import MattingBoard from "../../components/matting-board/index.vue";
import ContextMenu from "../../components/contextMenu/index.vue";
import { useSingleMessage, getImageType, generateGID } from "@/utils";
import { createMenuItem, useProcessRequestHandler } from "../helper";
import cases from '../../assets/cases/background-remover';

import {
  imageToImageData,
  downloadFn,
  getCanvasFromImage,
  getBase64FromUrl,
} from "../../utils/index.js";

import { getImageBgRemove } from "@/api";
import { fileUploadPre, uploadFile, fileUploadState } from "@/api/upload";

const emit = defineEmits(["onApply"]);
const props = defineProps({
  buttonText: String,
  positionBottom: {
    type: String,
    default: "41px",
  },
  draftId: String,
  originUrl: String,
  mode: String,
});

const mattingBoard = ref(null);
const undoDisable = ref(true);
const redoDisable = ref(true);
const isCancelTask = ref(false);

const route = useRoute();
const router = useRouter();
const Message = useSingleMessage();
const handleProcessRequest = useProcessRequestHandler();

const timer = ref(null);
const boxWidth = ref(80);
const originImage = ref("");
const isCompare = ref(false);
const imageBox = ref(null);
const imageContainer = ref(null);
const removeComplete = ref(false);
const isDrag = ref(false);
const loading = ref(false);
const isShowBrush = ref(false);

// 后端抠图结果
const resultUrl = ref("");

// 最终结果
const rawImage = ref(null);
const mattingImage = ref(null);
const waitProcess = ref(true);

const brushMoveList = ref([]);
const brushSize = ref(10);
const isErasing = ref(true);
const zoomRatio = ref(1);
const isRest = ref(false);
const isSubmitting = ref(false);
const imageWidth = ref(0);
const imageHeight = ref(0);
const usedCase = cases.find(item => item.input === route.query.url) ?? null;

const operations = [
  {
    label: createMenuItem("icon_download", "Download"),
    handler: () => downloadUrl(),
  },
];

const setImageBoxSize = () => {
  imageWidth.value = mattingBoard.value.width;
  imageHeight.value = mattingBoard.value.height;
};

const handleScale = (value) => {
  isRest.value = false;
  boxWidth.value = boxWidth.value * value;
  zoomRatio.value = value;
  isShowBrush.value = false;
  setImageBoxSize();
};

const handleCompare = (value) => {
  isCompare.value = value;
  isShowBrush.value = false;
};

const handleReset = () => {
  boxWidth.value = 80;
  zoomRatio.value = 1;
  isRest.value = true;
  isShowBrush.value = false;
  setTimeout(() => {
    isRest.value = false;
  }, 200)
};

const handleDrag = (value) => {
  isDrag.value = value;
};

const handleErase = (value) => {
  isErasing.value = value;
  isDrag.value = false;
  isShowBrush.value = false;
};

const handleBrushSize = (value) => {
  brushSize.value = value;
  isShowBrush.value = true;
};

const handleUndoClick = () => {
  mattingBoard.value?.undoMatting();
  isShowBrush.value = false;
};

const handleRedoClick = () => {
  mattingBoard.value?.redoMatting();
  isShowBrush.value = false;
};

const handleMouseEnter = () => {
  isShowBrush.value = false;
};

const handleMattingInit = () => {
  setImageBoxSize();
};

const handleMattingUpdate = ({ canUndo, canRedo }) => {
  undoDisable.value = !canUndo;
  redoDisable.value = !canRedo;
  isShowBrush.value = false;
};

const handleClose = () => {
  isCancelTask.value = true;
  loading.value = false;
  removeComplete.value = false;
};

// 请求抠图结果
const getRemoveResult = async () => {
  loading.value = true;
  Message.loading("Processing, it will take a while", {
    duration: 0,
    onClose: handleClose,
  });

  if (usedCase === null) {
    const result = await handleProcessRequest(getImageBgRemove, {
      imgUrl: originImage.value,
    }).catch(() => {});
  
    loading.value = false;
  
    if (result === undefined) return;
  
    if (isCancelTask.value) {
      return;
    }
  
    resultUrl.value = "data:image/png;base64," + result.imgBase64;
  } else {
    resultUrl.value = await getBase64FromUrl(usedCase.output);
  }

  Message.close();

  try {
    rawImage.value = await imageToImageData(originImage.value);
    mattingImage.value = await imageToImageData(resultUrl.value);
  } catch (e) {
    console.log("e=====", e);
  }

  removeComplete.value = true;
  Message.success("Processing completed", { duration: 1000 });
};

const handleToSpace = () => {
  const href = router.resolve({
    path: "/space",
    query: {
      tab: "media",
    },
  });
  // 点击事件
  window.open(href.href, "_blank");
};

const canvasToBlob = (canvas, type, quality) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      type,
      quality,
    );
  });
};

const getUploadState = async (id) => {
  const res = await fileUploadState(id);
  const { code, data } = res;
  const { mid, preview480Url, preview1080Url, width480, width1080, state } = data;

  if (state === 0) {
    // 成功
    clearInterval(timer.value);
    timer.value = null;
    isSubmitting.value = false;
    emit("onApply", preview480Url, preview1080Url, width480, width1080);
  } else if (state === 1) {
    isSubmitting.value = false;
    clearInterval(timer.value);
    timer.value = null;
    Message.error("error, save failed", { duration: 2000 });
  }
};

const getSuffix = (type) => {
  const typeArr = type.split("/");
  if (typeArr[typeArr.length - 1]) {
    return "." + typeArr[typeArr.length - 1];
  }
  return ".png";
};

// 上传函数
const uploadFn = async (file) => {
  const uploadParams = {
    suffix: getSuffix(file.type),
    size: file.size,
    type: getImageType(file.type),
    draftId: props.draftId || 0,
    toolName: "imageBgRemove",
  };
  // 获取预签名
  const data = await fileUploadPre(uploadParams);
  if (data.code == 401) {
    router.push("/workspace");
  }
  if (data.code != 0) {
    console.log("err====", data);
    return;
  }
  const { preSignUrl, mid } = data.data;
  const { code } = await uploadFile(preSignUrl, file);
  if (code !== 0) {
    Message.error("error, save failed", { duration: 2000 });
    return;
  }
};

// 上传函数
const uploadAndGet480Material = async (file) => {
  const uploadParams = {
    suffix: getSuffix(file.type),
    size: file.size,
    type: getImageType(file.type),
    draftId: props.draftId || 0,
    toolName: "imageBgRemove",
  };

  const data = await fileUploadPre(uploadParams);
  if (data.code == 401) {
    router.push("/workspace");
  }
  if (data.code != 0) {
    return;
  }

  const { preSignUrl, mid } = data.data;
  try {
    const { code } = await uploadFile(preSignUrl, file);
    if (code !== 0) {
      Message.error("error, save failed", { duration: 2000 });
      return;
    }
    // 定时器查询预处理状态
    timer.value = setInterval(() => {
      getUploadState(mid);
    }, 2000);
  } catch (e) {
    Message.error("error, save failed", { duration: 2000 });
    isSubmitting.value = false;
  }
};

// 保存结果
const handleSubmit = async () => {
  isSubmitting.value = true;
  const resultImage = mattingBoard.value.getMattingResult();
  const canvas = getCanvasFromImage(resultImage);
  const result = await canvasToBlob(canvas, "image/png", 1);
  result.name = `background-remove-${Date.now()}`;
  await uploadFn(result);

  // 上传aws 成功之后跳转到space页面
  setTimeout(() => {
    isSubmitting.value = false;
    handleToSpace();
  }, 2000);
};

const handleApply = async () => {
  // 处理之后的图片应用
  isSubmitting.value = true;
  const resultImage = mattingBoard.value.getMattingResult();
  const canvas = getCanvasFromImage(resultImage);
  const result = await canvasToBlob(canvas, "image/jpg", 1);
  result.name = `background-remove-${Date.now()}`;
  uploadAndGet480Material(result);
};

const downloadUrl = () => {
  // 下载
  loading.value = true;
  const resultImage = mattingBoard.value.getMattingResult();
  const canvas = getCanvasFromImage(resultImage);
  downloadFn(canvas, `background-remove-${generateGID()}`);
  loading.value = false;
};

const handleProcess = () => {
  waitProcess.value = false;
  getRemoveResult();
};

const handleTest = () => {};

onMounted(async () => {
  if (props.mode === "editor") {
    originImage.value = props.originUrl;
    boxWidth.value = 90;
  } else {
    originImage.value = route.query?.url;
    getRemoveResult();
  }
});
</script>

<style lang="scss" scoped>
.tool-bar-box {
  width: 100%;
  height: 114px;
  position: absolute;
}

.process-btn-box {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 36px;
}

.tool-bar {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image-box {
  height: 80%;
}

.image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  -webkit-user-drag: none;
}

.cursor-icon {
  border-radius: 1000px;
  background-color: #ccff00e9;
  opacity: 0.65;
  z-index: 1;
}
</style>
