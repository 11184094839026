<template>
  <div class="history-container" @click="handleHiddenDelete">
    <div class="btn-back" @click="handleToHome">
      <svg-icon
        name="icon_arrow_left_brandkit"
        color="#1C1B1E"
        :size="24"
      ></svg-icon>
      <p>Back</p>
    </div>
    <el-scrollbar>
      <div
        class="history-content"
        :infinite-scroll-immediate="false"
        v-infinite-scroll="getHistoryList"
        :infinite-scroll-disabled="disabledLoad"
      >
        <div v-if="loading" v-loading="loading" class="flex justify-center items-center empty">
        </div>

        <div
          v-else-if="!historyList.length"
          class="flex justify-center items-center empty"
        >
          <div>
            <svg-icon name="assistant_empty" color="#875EFF"></svg-icon>
            <p class="text-center">You don’t have any history</p>
          </div>
        </div>

        <div
          v-else
          class="history-item"
          :class="{ active: item.showDelete }"
          v-for="item in innerList"
          :key="item.sessionId"
          @click="handleSelect(item)"
          @contextmenu.prevent="(e) => showContextMenu(e, item)"
        >
          <p>{{ item.name }}</p>
          <div class="delete-box" v-if="item.showDelete" :style="currentStyle">
            <div
              class="delete-btn"
              :class="{ disabled: removing }"
              @click.stop="handleRemove(item.sessionId)"
            >
              <svg-icon
                name="assistant_icon_delete"
                class="mr-3"
                :color="removing ? '#BBBFC4' : '#FF5449'"
                :size="18"
              ></svg-icon>
              Delete
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>
<script setup>
import { onMounted, ref, onUnmounted } from 'vue';
import { useAssistStore } from '@/store/modules/assist';

const { historyList, prePage, loading } = storeToRefs(useAssistStore());
const {
  changeCurrentPanel,
  getHistoryList,
  removeHistory,
  disabledLoad,
  resetHistoryList,
} = useAssistStore();

const innerList = ref(historyList);
const removing = ref(false);
const currentStyle = reactive({
  left: 0,
});

const handleToHome = () => {
  changeCurrentPanel(prePage.value);
};

const handleSelect = (item) => {
  handleHiddenDelete(item);
  changeCurrentPanel('chat', item);
};

const handleRemove = async (id) => {
  if (removing.value) {
    return;
  }
  removing.value = true;
  await removeHistory(id);
  removing.value = false;
};

const handleShowDelete = (e, item) => {
  const historyItem = e.currentTarget;
  const historyItemRect = historyItem.getBoundingClientRect();
  const width = historyItemRect.width;
  const left = e.clientX - historyItemRect.left;

  if (width - left > 187) {
    currentStyle.left = left + 'px';
  } else {
    currentStyle.left = width - 187 + 'px';
  }

  innerList.value.forEach((ele) => {
    if (ele.sessionId == item.sessionId) {
      if (ele.showDelete == true) {
        ele.showDelete = false;
      } else {
        ele.showDelete = true;
      }
    } else {
      ele.showDelete = false;
    }
  });
};

const handleHiddenDelete = (item) => {
  innerList.value.forEach((ele) => {
    ele.showDelete = false;
  });
};

const showContextMenu = (e, item) => {
  handleShowDelete(e, item);
};

const fetchList = () => {
  console.log('2');
};

onUnmounted(() => {
  resetHistoryList();
});

onMounted(() => {
  getHistoryList();
  window.addEventListener('click', () => {
    handleHiddenDelete();
  });
});
</script>

<style>
.history-container .el-scrollbar__view {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.history-container {
  padding: 18px 6px 18px 12px;
  height: 100%;
  padding-bottom: 50px;
  .btn-back {
    padding-left: 12px;
    cursor: pointer;
    align-items: center;
    width: 80px;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .history-content {
    height: 100%;
    min-height: 300px;
  }

  .history-item {
    margin-right: 6px;
    position: relative;
    width: 100%;
    color: #060606;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    p {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .history-item.active {
    background: #ebedef;
  }
  .empty {
    height: 80%;
    p {
      color: #8f959e;
      font-size: 14px;
    }
  }

  .delete-box {
    position: absolute;
    z-index: 1;
    width: 187px;
    top: 39px;
    padding-top: 13px;
  }

  .delete-btn {
    border-radius: 4px;
    border: 1px solid #dee0e3;
    display: flex;
    align-items: flex-start;
    line-height: 22px;
    color: #ff5449;
    font-size: 14px;
    padding: 8px 1px;
    font-style: normal;
    font-weight: 400;
    padding-left: 12px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

    &:hover {
      background: #ebedef;
    }
  }

  .delete-btn.disabled {
    background: #fff;
    color: #bbbfc4;
  }
}
</style>
