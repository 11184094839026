import { awsFileUpload, _post, _get, _delete } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

type PreUploadParams = {
  suffix: string;
  fileType: string;
  draftId: string | number;
  toolName?: string;
  partCount?: number;
  multipartUpload?: boolean;
};

type PreUploadRes = {
  preSignUrl: string;
  presignedUrls: string[];
  preview480Url: string;
  cropUrl?: string;
  mid: string;
};

type PreUploadStateRes = {
  mid: string;
  coverPic?: string;
  duration?: number;
  preview480Url: string;
  preview1080Url: string;
  width480: number;
  width1080: number;
  originUrl: string;
  state: number;
  init: boolean;
};

interface CompleteMultipartUploadParams {
  parts: Array<{
    ETag: string;
    PartNumber: number;
  }>;
}

interface CommonParams {
  key: string;
}

//文件上传前置
export function fileUploadPre(params: PreUploadParams, signal: AbortSignal) {
  return _get<PreUploadRes>(`${VITE_API_BASE}/material/upload`, params, { signal });
}

export function getListParts(uploadId: string, params: CommonParams, signal: AbortSignal) {
  return _get(`${VITE_API_BASE}/s3/multipart/${uploadId}`, params, { signal });
}

export function deleteMultipartUpload(uploadId: string, params: CommonParams, signal: AbortSignal) {
  return _delete(`${VITE_API_BASE}/s3/multipart/${uploadId}`, params, { signal });
}

export function completeMultipartUpload(
  uploadId: string,
  key: string,
  params: CompleteMultipartUploadParams,
  signal: AbortSignal
) {
  return _post(
    `${VITE_API_BASE}/s3/multipart/${uploadId}/complete?key=${key}`,
    params,
    { signal }
  );
}

//文件上传
export function uploadFile(url: string, file: File, config?: Object) {
  return awsFileUpload(url, file, config);
}

// 查询上传状态
export function fileUploadState(mid: string) {
  return _get<PreUploadStateRes>(`${VITE_API_BASE}/material/upload/${mid}/state`);
}
