<template>
  <div class="tools-container">
    <AutoTools :scene="scene" :index="index" />
    <icon-button
      name="script_add"
      tip="Add a blank scene"
      :size="18"
      :tipDelay="0"
      @click="addBlankSceneFunc"
    />
    <icon-button
      v-if="primaryNode"
      :name="`script_${fit === 'contain' ? 'cover' : 'contain'}`"
      :tip="fit === 'contain' ? 'Fill' : 'Fit'"
      :size="18"
      :tipDelay="0"
      @click="updateFit"
    />
    <EffectTools
      :scene="scene"
      :is-last="!canDown"
    />
    <icon-button
      name="script_down"
      tip="Move this scene down"
      :size="18"
      :tipDelay="0"
      :color="canDown ? '#646A73' : '#BBBFC4'"
      :disabled="!canDown"
      @click="down"
    />
    <icon-button
      name="script_up"
      tip="Move this scene up"
      :size="18"
      :tipDelay="0"
      :color="canUp ? '#646A73' : '#BBBFC4'"
      :disabled="!canUp"
      @click="up"
    />
    <!-- <icon-button
      v-if="parseNode(scene.nodes).text !== ''"
      name="script_text"
      tip="Adjust text style"
      :size="18"
      :tipDelay="0"
      @click="textStyleVisible = true"
    /> -->
    <el-dropdown
      trigger="hover"
      :teleported="false"
      popper-class="script-tools-popper"
      placement="bottom-end"
      :popper-options="{
        modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
      }"
      @command="moreConmmand"
      @visibleChange="(v) => (moreActive = v)"
    >
      <icon-button
        name="script_more"
        class="more"
        :class="{ active: moreActive }"
        :size="18"
      />
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item in moreOption"
            :command="item.value"
            :disabled="item.value === 'delete' && defaultScenes.length === 1"
          >
            <svg-icon :name="item.prefix" :size="18" />
            <span style="margin-left: 12px">
              {{ item.label }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import EffectTools from "../tools/effecttool.vue";
import AutoTools from "../tools/autotool.vue";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import { useTrackStore } from "@/store/modules/track";
import { parseNode } from "../../utils/scene";
import { useMessage, throttled } from "@/utils";
import { getNewSceneInfo } from "@/api/script";
import { useModalManager } from "@/components/common/custom-modal/instance";

const {
  loadingScene,
  scenes,
  pause,
  seekTo,
  refresh,
  addScene,
  swapScene,
  removeScene,
  updateSceneId,
  duplicateScene,
  setLoadingScene,
} = useScriptStore();
const { collectData, track } = useTrackStore();
const { videoType, updateDraft } = useDraftStore();
const modalManager = useModalManager();
const message = useMessage();

const props = defineProps({
  index: {
    type: Number,
  },
  scene: {
    type: Object,
    default: {},
  },
});

const defaultScenes = computed(() => {
  return scenes.value.filter((item) => item.type === "default" || !item.type);
});
const primaryNode = computed(() => {
  const { primary } = parseNode(props.scene.nodes);
  return primary;
});
const fit = computed(() => primaryNode.value?.conf.fit);
const canUp = computed(() => props.index > 0);
const canDown = computed(() => props.index < defaultScenes.value.length - 1);
const moreActive = ref(false);
const moreOption = [
  {
    label: "Duplicate this scene",
    value: "duplicate",
    prefix: "icon_copy",
  },
  {
    label: "Delete",
    value: "delete",
    prefix: "icon_delete",
  },
];
const duplicating = ref(false);

const updateFit = () => {
  const node = primaryNode.value;

  if (fit.value === "contain") {
    node.conf.fit = "cover";
  } else {
    node.conf.fit = "contain";
  }
  updateDraft();
};

const moreConmmand = async (command) => {
  pause();
  switch (command) {
    case "delete":
      const confirm = throttled(() => {
        collectData("boolvideo_scene_edit_click", {
          click: "delete",
          video_type: videoType.value,
        });
        track("boolvideo_scene_edit_click");
        removeScene(props.scene).then(() => {
          modalManager.modal.open = false;
          updateDraft();
          seekTo(0);
        });
      }, 5000);
      modalManager.applyTemplate("warning", {
        icon: null,
        title: "Delete selected clip?",
        key: "delete-clip-modal",
        content: "Unable to recover after deletion.",
        confirmText: "Delete",
        closeAfterConfirm: false,
        onConfirm: confirm,
      });
      break;
    case "duplicate":
      await duplicate();
      break;
  }
};

const addBlankSceneFunc = async () => {
  if (loadingScene.value) return;
  collectData("boolvideo_scene_edit_click", {
    click: "add_scene",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
  pause();
  const newScene = await addScene(props.scene);
  setLoadingScene(newScene);

  const { success, data } = await getNewSceneInfo();
  if (!success) {
    message.error("Processing failed");
  } else {
    updateSceneId(newScene, data.sceneId);
    refresh();
    updateDraft();
    message.success("Processing completed");
  }
  setLoadingScene(null);
};

const duplicate = async () => {
  if (duplicating.value) return;
  duplicating.value = true;
  const { success, data } = await getNewSceneInfo();

  if (!success) return;
  collectData("boolvideo_scene_edit_click", {
    click: "duplicate",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
  duplicateScene(props.scene, data)
    .then(() => {
      updateDraft();
      message.success("Processing completed");
    })
    .finally(() => {
      duplicating.value = false;
    });
};

const up = () => {
  collectData("boolvideo_scene_edit_click", {
    click: "move_up",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
  pause();
  const { scene } = props;
  const index = scenes.value.findIndex(
    (item) => item.sceneId === scene.sceneId
  );
  const id1 = scenes.value[index - 1].sceneId;
  const id2 = scenes.value[index].sceneId;
  swapScene(id1, id2).then(() => {
    updateDraft();
    seekTo(scenes.value[index - 1].start);
  });
};

const down = () => {
  collectData("boolvideo_scene_edit_click", {
    click: "move_down",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
  pause();
  const { scene } = props;
  const index = scenes.value.findIndex(
    (item) => item.sceneId === scene.sceneId
  );
  const id1 = scenes.value[index].sceneId;
  const id2 = scenes.value[index + 1].sceneId;
  swapScene(id1, id2).then(() => {
    updateDraft();
    seekTo(scenes.value[index + 1].start);
  });
};
</script>

<style lang="scss">
.script-tools-popper .el-popper__arrow {
  display: none;
}

.script-tools-popper .el-dropdown-menu__item {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.script-tools-popper .el-dropdown-menu__item.is-disabled {
  pointer-events: none;
  color: #bbbfc4;
}
</style>
<style lang="scss" scoped>
.tools-container {
  display: flex;
  justify-content: end;

  & > :not(:first-child) {
    margin-left: 12px;
  }
}

.more {
  &.active {
    background-color: rgba(222, 224, 227, 0.6);
  }
}

:deep(.icon-wapper) {
  padding: 3px;
}
</style>
