<template>
  <div v-click-outside:[excludes]="onClosePickerShow" class="color-picker" ref="colorPicker" @click.stop="handleClick">
    <el-tooltip
      class="box-item"
      effect="light"
      placement="bottom-start"
      :show-arrow="false"
      :visible="isPickerShow"
      :popper-class="['custom-tooltip', popperClass]"
    >
      <color-item
        class="color-item"
        :style="colorItemStyle"
        :value="value"
        :draggable="valueList.length > 1"
      />
      <template #content>
        <transition>
          <picker
            class="picker"
            ref="picker"
            :value="selectedColor"
            :colors="colors"
            v-show="true"
            @input="onPickChange($event, 'input')"
            @change="onPickChange($event, 'change')"
          />
        </transition>
      </template>
    </el-tooltip>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  watch,
  unref,
} from "vue";
import type { PropType } from "vue";
import Picker from "./picker";
import ColorItem from "./color-item";
export default defineComponent({
  name: "ColorPicker",
  components: {
    ColorItem,
    Picker,
  },
  props: {
    excludes: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String as PropType<string>,
    },
    size: {
      type: Number,
      default: 24,
    },
    popperClass: {
      type: String as PropType<string>,
      default: null,
    },
    colors: {
      type: Array,
      default: () => [
        "#FFFFFF",
        "#000000",
        "#7F3BF5",
        "#3075FF",
        "#34C724",
        "#FFBA2E",
        "#FF922E",
        "#F54A45",
      ],
    },
  },
  emits: ["input", "change", "update:visible", "update:value", "close", "click"],
  setup(props, { emit }) {
    let valueList: any = [];
    const picker = ref();
    const selectedIndex = ref<number>(0);
    const isPickerShow = ref(false);
    const isInitPickColor = ref(false);
    const colorPicker = ref<HTMLElement>();
    const oldColorValue = ref<string | undefined>(undefined);
    // 设置添加初始值
    const selectedColor = computed<undefined | string>(
      () => unref(valueList)[unref(selectedIndex)],
    );
    // 色块
    const colorItemStyle = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
    }));

    const excludes = computed(() => [picker, ...props.excludes]);

    const colorItemSelected = (index: number) => {
      return unref(valueList).length > 0 && unref(selectedIndex) === index;
    };

    // 初始化valueList
    const initValueList = () => {
      if (props.value == null) {
        return ref([""]);
      } else {
        return computed(() =>
          Array.isArray(props.value) ? props.value : [props.value],
        );
      }
    };

    watch(
      () => props.value,
      (w) => {
        valueList = initValueList();
      },
      {
        immediate: true,
      },
    );

    watch(
      () => props.visible,
      (value) => {
        isPickerShow.value = value;
      },
    );

    watch(isPickerShow, (value) => {
      emit("update:visible", value);
    });

    watch(
      () => selectedIndex.value,
      () => {
        isInitPickColor.value = true;
      },
    );

    const handleClick = () => {
      isPickerShow.value = !isPickerShow.value;
      if (!isPickerShow.value) {
        emit("close");
      }
      emit("click");
    };
    const onClosePickerShow = () => {
      selectedIndex.value = 0;
      isPickerShow.value = false;
      picker.value?.resetValue();
      emit("close");
    };

    const onPickChange = (color: string, event: "input" | "change") => {
      if (unref(isInitPickColor) || unref(oldColorValue) === color) {
        return;
      }
      if (event === "change") {
        oldColorValue.value = color;
      }
      const index = unref(selectedIndex);
      // 改变数值
      let value: string | string[] = "";
      const isArr = Array.isArray(props.value);
      if (isArr) {
        const temp = unref(valueList).slice();
        temp[index] = color;
        value = temp;
      } else {
        value = color;
      }
      const valueArr = (isArr ? value : [value]) as string[];
      if (props.value == null) {
        valueList.value = valueArr;
      }
      emit("update:value", value);
      emit(event, value, color, index);
    };

    const handleClose = () => {
      emit("close");
    };

    return {
      valueList,
      colorItemStyle,
      colorItemSelected,
      selectedColor,
      selectedIndex,
      isPickerShow,
      onPickChange,
      handleClose,
      handleClick,
      colorPicker,
      onClosePickerShow,
      excludes,
      picker,
    };
  },
});
</script>

<style lang="scss">
.custom-tooltip.el-popper.is-light {
  background: none !important;
  border: none !important;
  padding: 0;
}
</style>

<style lang="scss" scoped>
:deep(.custom-tooltip.el-popper.is-light) {
  background: none !important;
  border: none !important;
}

:deep(.custom-tooltip.el-popper) {
  padding: 0;
}

.color-picker {
  display: inline-block;
  cursor: pointer;
  z-index: 999;
}

.color-item {
  margin: 5px;
}

.add-color-item {
  margin: 5px;
}

.picker {
  will-change: transform;
  z-index: 9;
  transition:
    left 60ms ease-in-out,
    top 60ms ease-in-out;
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: center top;
}

[data-popper-placement="top"] {
  transform-origin: center bottom;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
</style>
<style>
.el-popper.is-custom {
  padding: 0;
}
</style>
