<template>
  <el-popover
    trigger="click"
    placement="bottom-end"
    popper-class="timeline-mode-popover"
    :visible="visible"
    :width="187"
    :offset="4"
    :show-arrow="false"
  >
    <template #reference>
      <div
        class="select-wrapper"
        :class="{ active: visible }"
        @click="visible = !visible"
        v-click-outside="close"
      >
        <bv-tip content="Select or split" :show-after="0">
          <div class="select-container">
            <svg-icon
              :name="
                timeline.mode === 'select' ? 'editor_select' : 'editor_split'
              "
              :size="18"
            />
            <div class="suffix">
              <div class="suffix-icon">
                <svg-icon name="editor_timeline_down" :size="14" />
              </div>
            </div>
          </div>
        </bv-tip>
      </div>
    </template>
    <div class="select-content">
      <div
        v-for="(option, i) in options"
        :key="i"
        class="select-item"
        @click="option.onClick"
      >
        <div class="left">
          <svg-icon :name="option.icon" :size="18" />
          <span class="label">{{ option.label }}</span>
        </div>
        <span class="description" v-if="option.description">{{
          option.description
        }}</span>
      </div>
    </div>
  </el-popover>
</template>
<script setup>
import { useCreatorStore } from "../../stores";
const { timeline } = useCreatorStore();

const visible = ref(false);

const options = [
  {
    icon: "editor_select",
    label: "Select",
    description: "A",
    onClick: function () {
      timeline.mode = "select";
    },
  },
  {
    icon: "editor_split",
    label: "Split",
    description: "B",
    onClick: function () {
      timeline.mode = "split";
    },
  },
];

function close() {
  visible.value = false;
}
</script>
<style scoped>
.select-wrapper {
  height: 30px;
  transition: background-color 200ms;
  border-radius: 4px;
  padding: 1px 3px 1px 6px;
  cursor: pointer;
  margin-right: 24px;
}
.select-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.select-wrapper.active,
.select-wrapper:hover {
  background-color: #e8e9ec;
}
.select-wrapper > svg {
  margin-right: 2px;
}
.suffix-icon {
  transition: all 200ms;
}
.select-wrapper.active .suffix-icon {
  transform: rotate(180deg);
}
</style>
<style>
.el-popover.timeline-mode-popover {
  padding: 8px 0;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
}

.el-popover.timeline-mode-popover .select-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}
.el-popover.timeline-mode-popover .left {
  display: flex;
  align-items: center;
}
.el-popover.timeline-mode-popover .left > svg {
  margin-right: 12px;
}
.el-popover.timeline-mode-popover .select-item:hover {
  background-color: rgba(31, 35, 41, 0.08);
}
.el-popover.timeline-mode-popover .label {
  color: #060606;
}
.el-popover.timeline-mode-popover .description {
  font-size: 12px;
  color: #8f959e;
}
</style>
