<template>
  <div>
    <div
      class="collection-item"
      :class="{ 'active-item': innerItem.isSelected || innerItem.isPlay }"
      @click="handleSelected(innerItem)"
    >
      <div class="image-box" >
        <div class="lock-mask" v-if="innerItem.lock">
          <svg-icon
            name="icon_lock"
            :size="14"
          />
        </div>
        <div class="error-mask" v-else-if="!innerItem.url && innerItem.state === 0">
          <el-popover
            popper-class="character-video-item-popover"
            popper-style="padding: 12px 24px; background-color: #000; border: none; "
            :popper-options="{ modifiers: [{ name: 'offset', options: { offset: [0, -20] } }]}"
            width="320"
            placement="bottom" 
            trigger="hover"
          >
            <template #reference>
              <div class="error-tip-container">
                <svg-icon 
                  name="icon_warn" 
                  :size="24" 
                  color="#FFFFFF" 
                  alt="warn icon" 
                />
                <span class="error-tip">Not Available</span>
              </div>
            </template>
            <p class="popper-container">
              No voice available. Please check 
              <span @click="goToEdit">
                here
              </span>
            </p>
          </el-popover>
        </div>
        <img
          :src="innerItem.coverPic"
          v-if="innerItem.coverPic"
          class="coverPic"
        />
        <template v-if="innerItem.url">
          <audio
            ref="audioRef"
            :src="innerItem.url"
            loop
          ></audio>
          <img
            :src="icon_play_video"
            class="icon-play"
            v-if="!innerItem.isPlay"
            @click.stop="handlePlay(innerItem)"
          />
          <img
            :src="icon_playing"
            class="icon_playing"
            v-if="innerItem.isPlay"
            @click.stop="handlePlay(innerItem)"
          />
        </template>
      </div>
      <div class="detail">
        <div class="flex items-start">
          <p class="video-name">{{ innerItem.name || "Unnamed" }}</p>
          <div class="image-select">
            <img
              :src="icon_selected"
              v-if="innerItem.isSelected"
              @click.stop="handleCancelSelected(innerItem)"
            />
            <img
              :src="icon_select"
              v-else
              @click.stop="handleSelected(innerItem)"
            />
          </div>
        </div>
        <p class="time" v-if="innerItem.isPlay">
          <span class="time-number">{{ formatDuration(currentFrame) }}</span> /
          <span class="time-number">{{ formatDuration(duration) }}</span>
        </p>

        <p class="time" v-else>{{ formatDuration(duration) }}</p>
        <div @click.stop="(e) => {}">
          <el-slider
            v-model="currentFrame"
            v-if="innerItem.isPlay"
            @input="handleSeekTo"
            :show-tooltip="false"
            :step="1"
            :min="0"
            :max="Math.ceil(duration)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import header from '@/assets/similar-video/header_portrait.png';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';
import icon_playing from '@/assets/similar-video/icon_playing.svg';
import { useMessage } from '@/utils';
import { useModalManager } from '@/components/common/custom-modal/instance';

const modalManager = useModalManager();
const message = useMessage();
const props = defineProps({
  item: Object,
  list: Array,
  selectedMediaId: Number,
});

const emit = defineEmits([
  'onSelected',
  'onPlay',
  'onPause',
  'onCancelSelected',
  
]);

const previewerRoot = document.createElement('div');
document.body.appendChild(previewerRoot);

const audioRef = ref(null);
const currentFrame = ref(0);
const innerItem = ref(props.item);

const previewList = ref([]);
const previewData = ref(null);
const previewerRef = ref(null);

const duration = ref(0);

watch(
  () => props.list,
  (list) => {
    previewList.value = list
      .filter((item) => item.url)
      .map((image) => {
        return {
          mid: image.mediaId,
          coverPic: image.coverPic,
          preview1080Url: image.url,
          type: image.type || 'video',
        };
      });
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  props.item,
  (value) => {
    previewData.value = {
      mid: value.mediaId,
      coverPic: value.coverPic,
      preview1080Url: value.url,
      type: value.type || 'video',
      duration: value.duration,
    };
  },
  {
    immediate: true,
  }
);

const handleCancelSelected = (item) => {
  emit('onCancelSelected', item.mediaId);
};

const goToEdit = () => {
  window.open(`/avatar/edit?characterId=${props.item.mediaId}`, "_blank");
  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => emit("refresh"),
  });
};

const handleSelected = (item) => {
  if (props.item.lock) {
    modalManager.applyTemplate("upgradeTips", {
      icon: "orangeWarn",
      title: "Upgrade to edit and use it",
      content: "The character is unavailable, upgrade to unlock this feature.",
    });
    return ;
  };
  if (!item.url && item.state === 0) {
    return ;
  }
  item.isSelected = !item.isSelected;

  if (!item.isSelected) {
    emit('onCancelSelected', item.mediaId);
    emit('onPause', item.mediaId);
    return;
  }
  emit('onSelected', item.mediaId, item.voiceId);
  emit('onPlay', item.mediaId);
};

const handleScale = (item) => {};

function formatDuration(duration) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${(minutes | 0).toString().padStart(2, '0')}:${(seconds | 0)
    .toString()
    .padStart(2, '0')}`;
}

const handlePlay = async (item) => {
  item.isPlay = !item.isPlay;

  if (item.isPlay == false) {
    currentFrame.value = 0;
    emit('onPause', item.mediaId);
    audioRef.value.pause();
    return;
  }
  emit('onPlay', item.mediaId);
  emit('onSelected', item.mediaId, item.voiceId);
  audioRef.value.play();
};

const handleSeekTo = (value) => {
  audioRef.value.currentTime = value;
};

watch(
  () => props.item.isSelected,
  (isSelected) => {
    if (!isSelected && audioRef.value) {
      audioRef.value.pause();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.item.isPlay,
  (isPlay) => {
    if (!isPlay && audioRef.value) {
      audioRef.value.pause();
    }

    if (isPlay) {
      innerItem.value.isSelected = true;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.selectedMediaId,
  () => {
    if (props.item.mediaId == props.selectedMediaId) {
      innerItem.isSelected = true;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => audioRef.value,
  (audioRef) => {
    if (audioRef) {
      addAudioEvents();
    }
  },
  {
    immediate: true,
  }
);

function addAudioEvents() {
  const audio = audioRef.value;

  audio.addEventListener('loadedmetadata', handleAudioLoadMetadata);
  audio.addEventListener('timeupdate', handleAudioTimeupdate);
};

function handleAudioLoadMetadata() {
  duration.value = audioRef.value.duration;
}

function handleAudioTimeupdate() {
  currentFrame.value = audioRef.value.currentTime;
}

onBeforeUnmount(() => {
  const audio = audioRef.value;

  if (audioRef.value) {
    audio.removeEventListener('loadedmetadata', handleAudioLoadMetadata);
    audio.removeEventListener('timeupdate', handleAudioTimeupdate);
  }
  audioRef.value = null;
})
</script>
<style lang="scss" scoped>
.collection-item {
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 124px;
  padding: 12px;
  display: flex;
  border-radius: 8px;

  &:hover {
    background: #f8f5ff;
  }

  :deep(.el-slider) {
    max-width: 187px;
  }

  :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  :deep(.el-slider__bar) {
    height: 4px;
  }

  :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }

  .video-name {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 36px;
    flex: 1;
  }

  .image-select {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.active-item {
  background: #f8f5ff;
}

.detail {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  flex: 1;
}

.time {
  color: #646a73;
  margin-top: 6px;
  line-height: 22px;
  display: flex;
}

.time-number {
  display: block;
  width: 45px;
  text-align: center;
}

.novoice-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.image-box {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 4px;
  position: relative;
  margin-right: 12px;
  background: #ebedef;

  & > .lock-mask {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.80);
    padding: 6px;
    border-radius: 4px;
    color: #ffe4ae;
  }

  & > .error-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.54);  
    z-index: 1;

    & > .error-tip-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    & .error-tip {
      color: #ffffff;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .coverPic {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    object-fit: cover;
  }
  audio {
    width: 100px;
    height: 100px;
    border-radius: 4px;
  }
}

.icon-play {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  width: 36px;
  height: 36px;
}

.icon_playing {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  width: 36px;
  height: 36px;
}
</style>
