/**
 * return a debounced version of the function
 * @param fn
 * @param delay
 */
// eslint-disable-next-line no-unused-vars
export function debounce(
  fn: (...args: any[]) => unknown,
  delay: number,
): typeof fn {
  let timerId: ReturnType<typeof setTimeout> | null;
  return function (...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export function asyncDebounce<T extends (...args: any[]) => Promise<any>>(
  fn: T,
) {
  let task: null | ReturnType<T> = null;

  return function (...args: Parameters<T>) {
    if (task === null) {
      task = fn(...args) as ReturnType<T>;
      task.then(() => (task = null));
    }

    return task;
  };
}
