<template>
  <div class="script-write-container">
    <div class="header">
      <p class="flex w-20 cursor-pointer" @click="handleBack">
        <svg-icon
          name="icon_arrow_left_brandkit"
          color="#1C1B1E"
          :size="24"
        ></svg-icon>
        Back
      </p>

      <p class="flex-1 title">Rewriting Script</p>
    </div>

    <div class="content">
      <div class="left-part">
        <div
          class="text-box shadow-box"
          :class="{
            'active-border': editOriginScript,
            'error-border': showErrorTip,
          }"
        >
          <div class="box-header">
            <p>Input Video Extracted Script</p>
            <div
              class="button"
              @click="handleSaveOrigin"
              v-if="editOriginScript"
            >
              Save and regenerate
            </div>
            <div class="button" @click="handleEditOrigin" v-else>Edit</div>
          </div>

          <el-input
            @input="handleInputOriginScript"
            v-if="editOriginScript"
            v-model="originScript"
            type="textarea"
            placeholder="Please input video script"
          />

          <div class="script-content perfect-scrollbar" v-else>
            {{ originScript }}
          </div>
        </div>
      </div>
      <div class="right-part">
        <div
          class="text-box shadow-box"
          :class="{
            'active-border': editSimilarScript,
            'shadow-box': !editSimilarScript,
          }"
        >
          <div class="box-header">
            <p class="flex items-center">
              <img
                v-if="selectedNumber == currentSimilarScriptId"
                :src="icon_selected"
                class="select-icon"
              />

              <el-tooltip
                v-else
                placement="bottom"
                effect="dark"
              >
                <template #content> Use this rewrite script </template>

                <img
                  :src="icon_select"
                  class="select-icon"
                  @click="handleSelected"
                />
              </el-tooltip>

              Customized script {{ currentStep }}
            </p>
            <div
              class="button"
              @click="handleSaveSimilar"
              v-if="editSimilarScript"
            >
              Save
            </div>
            <div class="button" @click="handleEditSimilar" v-else>Edit</div>
          </div>

          <el-input
            v-if="editSimilarScript"
            v-model="currentNewSimilarScript"
            autosize
            type="textarea"
            placeholder="Please input video script"
          />

          <div class="script-content perfect-scrollbar" v-else>
            {{ currentOriginSimilarScript }}
          </div>

          <div class="indicator-container">
            <p
              class="text-button"
              @click="handleGenerateMore"
              v-if="currentStep == scriptList.length"
            >
              Generate more
            </p>
            <Pagination
              @onPre="handlePre"
              @onNext="handleNext"
              :length="scriptList.length"
              :step="currentStep"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="next-button" @click="handleNextStep">Next</div>
  </div>
</template>

<script setup>
import { watch, onBeforeMount, computed , onMounted} from 'vue';
import { useRouter } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';

import svgIcon from '@/components/common/bv-svgIcon/svgIcon.vue';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import Pagination from './components/pagination.vue';

import { similarScriptRewrite } from '@/api/similarVideo/index.js';
import { useSingleMessage } from '@/utils';
import { sessionGetItem, sessionSetItem } from '@/utils/storage';

import { useSimilarVideo } from './store/index.js';
import { useTrackStore } from '@/store/modules/track';

const similarVideoStore = useSimilarVideo();
const { generateScript } = storeToRefs(similarVideoStore);
const { collectData, track } = useTrackStore();

const cacheScript = ref(null);
const originScript = ref('');
const hasEditOrigin = ref(false);

const scriptList = ref([]);
const currentStep = ref(1);
const currentSimilarScriptId = ref(null);
const currentSimilarScript = ref(null);
const currentOriginSimilarScript = ref('');
const currentNewSimilarScript = ref('');

const editOriginScript = ref(false);
const editSimilarScript = ref(false);
const showErrorTip = ref(false);

const selectedNumber = ref(null);
const Message = useSingleMessage();
const router = useRouter();

watch(
  () => [currentStep.value, scriptList.value],
  () => {
    currentSimilarScript.value = scriptList.value[currentStep.value - 1];
    if (currentSimilarScript.value) {
      currentOriginSimilarScript.value = currentSimilarScript.value.origin;
      currentNewSimilarScript.value = currentSimilarScript.value.new;
      currentSimilarScriptId.value = currentSimilarScript.value.id;
    }
  },
  {
    immediate: true,
  }
);
collectData("similarvideo_similar_script_edit", {
  video_type: "similar_video",
});

const handleBack = () => {
  router.push('/similar-video');
};

const handleInputOriginScript = (inputValue) => {
  showErrorTip.value = false;
  if (inputValue.trim() === '') {
    originScript.value = '';
  } else {
    originScript.value = inputValue;
    hasEditOrigin.value = true;
  }
};

const handleEditOrigin = () => {
  hasEditOrigin.value = false;
  showErrorTip.value = false;
  editOriginScript.value = true;
  collectData("similarvideo_similar_script_edit", {
    click: "edit_origin"
  });
  track("similarvideo_similar_script_edit");
};

const regenerateScript = async (script) => {
  const originParams = sessionGetItem('similarVideo');
  const params = {
    original: script,
    name: originParams.name,
    description: originParams.description,
  };

  Message.loading('Regenerating, it will take a while');
  const { data, code, msg } = await similarScriptRewrite(params);

  Message.success('Processing completed');

  scriptList.value = data.scripts.map((item, index) => {
    return {
      id: uuidv4(),
      origin: item,
      new: item,
    };
  });

  cacheScript.value = data;
  similarVideoStore.setGenerateScript(cacheScript.value);
  currentStep.value = 1;
  selectedNumber.value = scriptList.value[0]?.id;
};

const handleGenerateMore = async (script) => {
  if (scriptList.value.length >= 12) {
    Message.error('Generate up to 12 script lines.');
    return;
  }

  const originParams = sessionGetItem('similarVideo');
  const params = {
    original: originScript.value,
    name: originParams.name,
    description: originParams.description,
  };

  Message.loading('Regenerating, it will take a while');
  const { data, code, msg } = await similarScriptRewrite(params);
  Message.success('Processing completed');
  collectData("similarvideo_similar_script_edit", {
    click: "generate_more"
  });
  track("similarvideo_similar_script_edit");
  
  const list = data.scripts.map((item) => {
    return {
      id: uuidv4(),
      origin: item,
      new: item,
    };
  });

  cacheScript.value.scripts = [...cacheScript.value.scripts, ...data.scripts];
  similarVideoStore.setGenerateScript(cacheScript.value);
  scriptList.value = [...scriptList.value, ...list];
};

const handleSaveOrigin = () => {
  if (!originScript.value) {
    Message.error('Please input original video script');
    return;
  }

  if (!hasEditOrigin.value) {
    hasEditOrigin.value = false;
    editOriginScript.value = false;
    return
  }

  hasEditOrigin.value = false;
  editOriginScript.value = false;
  // 重新生成
  regenerateScript(originScript.value);
};

const handleEditSimilar = () => {
  editSimilarScript.value = true;
  collectData("similarvideo_similar_script_edit", {
    click: "edit_script"
  });
  track("similarvideo_similar_script_edit");
};

// 保存生成编辑
const handleSaveSimilar = () => {
  editSimilarScript.value = false;

  currentOriginSimilarScript.value = currentNewSimilarScript.value;
  currentSimilarScript.value.origin = currentNewSimilarScript.value;
  currentSimilarScript.value.new = currentNewSimilarScript.value;
  const list = scriptList.value.map((item) => item.new);
  cacheScript.value.scripts = list;
  similarVideoStore.setGenerateScript(cacheScript.value);
};

const handleSelected = () => {
  selectedNumber.value = currentSimilarScript.value?.id;
};

const handlePre = () => {
  currentStep.value = currentStep.value - 1;
};

const handleNext = () => {
  currentStep.value = currentStep.value + 1;
};

const handleNextStep = () => {
  if (editSimilarScript.value) {
    // 自动保存一波
    handleSaveSimilar();
    // Message.error('Please save the changes to prevent data loss');
    // return;
  }

  if (!selectedNumber.value) {
    Message.error('Please select a script to generate');
    return;
  }

  const selectedScript = scriptList.value.find(
    (item) => item.id == selectedNumber.value
  );

  if (!selectedScript.new) {
    Message.error('Please input selected similar video scripts');
    return;
  }

  collectData("similarvideo_similar_script_edit", {
    click: "next",
  });
  track("similarvideo_similar_script_edit");
  similarVideoStore.setSelectedScript(selectedScript);
  router.push('/similar-video/setting');
};

onBeforeMount(() => {
  if (generateScript.value) {
    cacheScript.value = generateScript.value;
    originScript.value = generateScript.value.original;
    scriptList.value = generateScript.value.scripts?.map((item, index) => {
      return {
        id: uuidv4(),
        origin: item,
        new: item,
      };
    });

    currentSimilarScript.value = scriptList.value[0];
    selectedNumber.value = currentSimilarScript.value.id;
  }
});

onMounted(() => {

  collectData('similarvideo_similar_input_result', {
    result: 'success ',
    video_type: 'similar_video',
  });

  track('similarvideo_similar_input_result');
})
</script>

<style lang="scss" scoped>
.script-write-container {
  padding: 31px 42px;
  height: 100%;
  position: relative;
}

.header {
  display: flex;
  .title {
    color: #000;
    text-align: center;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-left: -80px;
    pointer-events: none;
  }
}

.content {
  padding-top: 30px;
  padding-bottom: 54px;
  height: 100%;
  display: flex;

  .left-part {
    flex: 1;
    height: 100%;
    margin-right: 20px;
  }

  .right-part {
    flex: 1;
    height: 100%;
  }

  .script-content {
    margin-top: 22px;
    color: #646a73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    white-space: pre-wrap;
    height: calc(100% - 16px);
    padding-bottom: 160px;
    overflow: scroll;
  }

  .text-box {
    padding: 20px 25px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    background: #fff;
    overflow: hidden;

    .box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000;
      font-family: Inter-variant;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .button {
      color: #6741ff;
      text-align: right;
      font-family: Inter-variant;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      width: 160px;
    }

    :deep(.el-textarea) {
      height: 96%;
      box-shadow: none;
      margin-top: 22px;
    }
    :deep(.el-textarea__inner) {
      height: 100% !important;
      box-shadow: none;
      padding: 0px 0px 160px 0px;
      line-height: 30px;
      color: #060606;
      --sb-track-color: transparent;
      --sb-thumb-color: rgba(0, 0, 0, 0.25);
      --sb-size: 9px;

      &::-webkit-scrollbar {
        width: var(--sb-size);
        padding: 0px;
      }

      &::-webkit-scrollbar-track {
        background: var(--sb-track-color);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 7px;
        background-color: var(--sb-thumb-color);
      }
    }
  }
}

.select-icon {
  width: 22px;
  margin-right: 12px;
  cursor: pointer;
}

.next-button {
  z-index: 3;
  display: flex;
  width: 220px;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  margin-left: -110px;
  left: 50%;
  bottom: 80px;
  cursor: pointer;
}

.content .active-border {
  border: 1px solid #875eff;
}

.content .error-border {
  border: 1px solid #ff5449;
}

.script-content.perfect-scrollbar {
  --sb-track-color: transparent;
  --sb-thumb-color: rgba(0, 0, 0, 0.25);
  --sb-size: 9px;

  &::-webkit-scrollbar {
    width: var(--sb-size);
    padding: 0px;
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--sb-thumb-color);
  }
}

.shadow-box {
  position: relative;
  padding: 20px 25px 100px 25px !important;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    background-image: linear-gradient(
      0deg,
      #fff 23.72%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
  }
}

.indicator-container {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-button {
    color: #6741ff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}
</style>
