<template>
  <div class="switcher-wrapper">
    <div class="switcher-container">
      <div 
        v-for="tab in tabs"
        class="tab" 
        :class="{active: tab.id === value}"
        @click="handleClick(tab.id)"
      >
        {{ tab.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits('switch');

const tabs = [
  { 
    id: 0,
    name: "Library" 
  },
  {
    id: 1,
    name: "My Avatars"
  }
];

const selectId = ref(0);

const handleClick = (id) => {
  if (props.value === id) return;
  emit('switch', id);
};

const setup = () => {
  selectId.value = props.value;
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.switcher-wrapper {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 20px;
}

.switcher-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(117px, 1fr));
  padding: 1px;
  border-radius: 6px;
  border: 0.5px solid #E5E7EB;
  background: #F3F5F7;
}

.tab {
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid transparent;
  background: transparent;
  box-shadow: none;
  text-align: center;
  color: #060606;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  transition: all 0.2s;
  cursor: pointer;

  &.active {
    border: 0.5px solid rgba(0, 0, 0, 0.02);
    background: #FFF;
    box-shadow: 0px 0.25px 0.25px 0px #E5E7EB, 0px 1px 0.75px 0px #E5E7EB;
    color: #6741FF;
  }
}
</style>