<template>
  <div class="h-full w-full flex justify-center iterms-center">
    <div class="text-sm font-normal payment-info-container">
      <!-- 套餐信息 -->
      <el-skeleton :loading="isLoading">
        <template #template>
          <div class="flex flex-col">
            <el-skeleton-item
              variant="circle"
              style="width: 24px; height: 24px; margin-bottom: 20px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 30%; margin-bottom: 20px"
            />

            <el-skeleton-item
              variant="text"
              style="width: 50%; height: 36px; margin-bottom: 50px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 240px; height: 24px; margin-bottom: 90px"
            />
            <el-skeleton-item
              variant="rect"
              style="width: 30%; height: 0; padding: 0; padding-bottom: 30%"
            />
          </div>
        </template>
        <template #default>
          <p class="w-full text-3xl font-bold text-center mb-10">
            Confirm your update
          </p>

          <p class="mt-5 mb-6 flex justify-between items-end text-base">
            <span class="text-end font-bold text-xl">{{
              productSummary.newProductName
            }}</span>
            <span>{{ productSummary.newProductPrice }}</span>
          </p>
          <p
            class="text-base font-medium"
            v-if="productSummary.newProductPriceDescription"
          >
            {{ productSummary.newProductPriceDescription }}
          </p>
          <hr />

          <div
            :class="`-mx-5  bg-corpItemBg border-0 rounded-lg font-sm ${
              isDetailsShow || coupon.couponValue
                ? 'visible max-h-[350px] my-5 opacity-1'
                : 'max-h-0 invisible m-0 opacity-0'
            } transition-[max-height] ease-out duration-500`"
          >
            <div class="p-5 pt-0">
              <p
                class="pt-5 flex justify-between"
                v-for="(item, index) in subscriptionOrderItemDetailList"
              >
                <span class="font-medium">
                  {{ item.description }}
                  <el-tooltip 
                    popper-class="price-addition-popper"
                  >
                    <template #content>
                      {{ detailAdditionList[index] }}
                    </template>
                    <svg-icon 
                      name="icon_info_filled" 
                      :size="18"
                      :style="{ display: 'inline' }"
                    />
                  </el-tooltip>
                </span>
                <span>{{ item.price }}</span>
              </p>

              <!-- 折扣信息 -->
              <p
                v-if="discountDetails?.discountName"
                class="my-5 flex justify-between"
              >
                <span>{{ discountDetails?.discountName }}</span
                ><span>{{ "-" + discountDetails.discountPrice }}</span>
              </p>
              <!-- 账单信息 -->
              <p class="my-5 flex justify-between font-semibold">
                <span>
                  Total
                  <el-tooltip 
                    popper-class="price-addition-popper"
                  >
                    <template #content>
                      {{ totalAddition }}
                    </template>
                    <svg-icon 
                      name="icon_info_filled" 
                      :size="18"
                      :style="{ display: 'inline' }"
                    />
                  </el-tooltip>
                </span>
                <span>
                  {{ total }}
                </span>
              </p>
              <hr />
              <p class="mt-5 flex justify-between font-medium flex">
                <span>
                  Account balance credit
                  <el-tooltip 
                    popper-class="price-addition-popper"
                  >
                    <template #content>
                      {{ balanceAddition }}
                    </template>
                    <svg-icon 
                      name="icon_info_filled" 
                      :size="18"
                      :style="{ display: 'inline' }"
                    />
                  </el-tooltip>
                </span>
                <span>{{ accountBalanceCredit }}</span>
              </p>
              <p class="mt-5 flex justify-between font-semibold flex">
                <span>Amount due today</span>
                <span>{{ amountDueToday }}</span>
              </p>
            </div>
          </div>

          <p class="my-5 flex justify-between text-base font-semibold flex">
            <span>Amount due today</span>
            <span>{{ amountDueToday }}</span>
          </p>
          <p
            class="text-xs hover:text-tipColor cursor-pointer mb-5"
            @click="() => (isDetailsShow = !isDetailsShow)"
            v-if="!coupon.couponValue"
          >
            {{ (isDetailsShow ? "Hide" : "Show") + " Details" }}
          </p>
          <hr />
          <!-- 优惠券信息 -->
          <coupon
            :data="coupon"
            :discount="coupon.description"
            :update="(coupon) => updatePriceData(false, coupon)"
          />
          <PrimaryButton
            class="mt-5 box-border text-center w-full rounded-full h-12 payment-button"
            @click="handleComfirm"
            :disabled="isLoading"
            :loading="btnDisabled"
          >
            <span class="text-sm">Confirm</span>
          </PrimaryButton>
          <p
            class="confirm-error transition-all"
            :style="{ minHeight: errorMessage ? '30px' : 0 }"
          >
            {{ errorMessage }}
          </p>
        </template>
      </el-skeleton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalManager } from "@/components/common/custom-modal/instance";
import { updateChangingPriceData, updateSubscription } from "@/api/svPremium";
import { usePricingStore } from "../../stores";
import { useTrackStore } from "@/store/modules/track";
import { getToken } from "@/utils/auth";
import { useMessage } from "@/utils";
import { paymentPolling } from "@/utils/premium";
import Coupon from "../coupon/coupon.vue";

const emit = defineEmits(['next', 'close'])
const message = useMessage();
const modalManager = useModalManager();

const { getCardInfo } = usePricingStore();
const { collectData, track, getTrackPlanName } = useTrackStore();
const isDetailsShow = ref(false);
const isLoading = ref(false);
const btnDisabled = ref(false);
const errorMessage = ref("");
const productSummary = ref({
  newProductPriceDescription: "Server error",
  newProductName: "Booltool Pro",
  newProductPrice: "$9.90",
});
const accountBalanceCredit = ref("$403.65");
const amountDueToday = ref("$0.00");
// 优惠券
const coupon = reactive({
  couponCheckFailMsg: "",
  couponCheckSuccessBoolean: true,
  couponValue: "",
  description: "",
});
const discountDetails = ref({
  discountName: "",
  discountPrice: "",
});
const subscriptionOrderItemDetailList = ref([]);
const total = ref("$22.5");

const detailAdditionList = [
  "Prorated charge is a partial fee charged to a customer based on the remaining time in the billing period for the new plan.",
  "Prorated credit is a partial credit issued to a customer based on the unused portion of the previous plan.",
];
const totalAddition = "Total is the net amount owed or credited to the customer (Total = Prorated Charge - Prorated Credit). If the credit is larger than the charge, the net amount will be added to your account balance as a credit, which can be used to offset future charges.";
const balanceAddition = "Account balance credit is the positive balance on your Stripe account, which can be used to offset future charges.";

interface Props {
  packageCode: number;
}
const props = defineProps<Props>();

onBeforeMount(async () => {
  isLoading.value = true;
  await updatePriceData(true);
  isLoading.value = false;
  collectData("boolvideo_subscribe_change", {
    plan_name: getTrackPlanName(props.packageCode),
  });
});

const updatePriceData = async (initFlag: boolean, couponCode: string = "") => {
  const payload = {
    packageCode: props.packageCode,
    couponCode: couponCode || null,
    initFlag,
  };
  const res = await updateChangingPriceData(payload);
  if (res.code === 0) {
    const {
      newProductName,
      newProductPrice,
      newProductPriceDescription,
      orderDetails,
      couponCheckSuccessBoolean,
      couponCheckFailMsg,
      couponDetails,
    } = res.data;

    if (couponCheckSuccessBoolean) {
      productSummary.value = {
        newProductPriceDescription,
        newProductPrice,
        newProductName,
      };
      updateOrderDetails(orderDetails);
    }
    updateCouponData({
      couponCheckFailMsg,
      couponCheckSuccessBoolean,
      description: couponDetails?.description || "",
      couponValue: couponDetails?.couponExistBoolean
        ? couponDetails.couponCode
        : "",
    });
    return res;
  } else {
    message.error(res.msg);
    return;
  }
};

const updateOrderDetails = (_value: any) => {
  discountDetails.value = _value.discountDetails;
  accountBalanceCredit.value = _value.accountBalanceCredit;
  amountDueToday.value = _value.amountDueToday;
  subscriptionOrderItemDetailList.value =
    _value.subscriptionOrderItemDetailList.reverse();
  total.value = _value.total;
};
const updateCouponData = (_value: any) => {
  coupon.couponCheckFailMsg = _value.couponCheckFailMsg;
  coupon.couponCheckSuccessBoolean = _value.couponCheckSuccessBoolean;
  coupon.couponValue = _value.couponValue;
  coupon.description = _value.description;
};

const handleComfirm = async () => {
  if (isLoading.value) {
    return;
  }
  btnDisabled.value = true;
  errorMessage.value = "";
  const couponApplied = coupon.couponValue;
  // confirm update
  await updateSubscription({
    couponCode: couponApplied ? coupon.couponValue : null,
    packageCode: props.packageCode,
  })
    .then(async (res) => {
      const { code, data, msg } = res;
      if (code === 0) {
        const {
          couponCheckSuccessBoolean,
          couponCheckFailMsg,
          orderId,
          subscriptionOperType,
        } = data;
        if (couponCheckSuccessBoolean) {
          const operMap = {
            UPGRADE_SUBSCRIPTION: "upgrade_comfirm",
            DEGRADE_SUBSCRIPTION: "downgrade_confirm",
          };
          collectData("boolvideo_subscribe_change", {
            click: operMap[subscriptionOperType as keyof typeof operMap],
          });

          await paymentPolling(orderId).then(async (orderData) => {
            if (subscriptionOperType === "UPGRADE_SUBSCRIPTION") {
              collectData("boolvideo_subscribe_upgrade_confirm", {
                stripeCustomerId: orderData.stripeCustomerId,
              });
              track("boolvideo_subscribe_upgrade_confirm");
            }
            else if (subscriptionOperType === "DEGRADE_SUBSCRIPTION") {
              collectData("boolvideo_subscribe_downgrade_confirm", {
                stripeCustomerId: orderData.stripeCustomerId,
              });
              track("boolvideo_subscribe_downgrade_confirm");
            }
            collectData("boolvideo_subscribe_change", {
              stripeCustomerId: orderData.stripeCustomerId,
            });
            track("boolvideo_subscribe_change");
            const email = localStorage.getItem("user_email");
            // window.fpr("referral", { email: email });
            // update订阅成功
            const type = getCardInfo(props.packageCode)?.subscriptionCycle;
            const pricingArr = getCardInfo(props.packageCode)?.monthlyPrice?.split('$') || []
            gtag("event", `subscribe_update_success`, {
              type: type == "MONTH" ? "month" : type == "YEAR" ? "year" : "",
              package_code: props.packageCode,
              value: Number(pricingArr[1]),
               currency: 'USD'
            });
            emit('next', {path: 'premium', packageCode:props.packageCode,  subscriptionType: subscriptionOperType , state: 'success'})
            emit('close')
          })
          .catch(error => {
              console.log(error);
              const { state, hostedInvoiceUrl } = error;
              const doneCallback = () => {
                emit('next', {path: 'premium', packageCode:'',  subscriptionType: '' , state: ''})
              };
              switch(state) {
                case "PAYMENT_ANOMALY":
                  modalManager.applyTemplate("paymentFail", { 
                    zIndex: 3000, 
                    doneCallback ,
                    repaySuccessCallback: doneCallback,
                    redirectUrl: hostedInvoiceUrl,
                  });
                  break;
                case "PAYMENT_ERROR":
                  modalManager.applyTemplate("paymentFail", { 
                    zIndex: 3000, 
                    doneCallback ,
                    repaySuccessCallback: doneCallback,
                  });
                  break;
              }
              throw { msg: "Please check your credit card limit and availability." };
            });
        } else {
          throw { msg: couponCheckFailMsg };
        }
      } else {
        throw { msg };
      }
    })
    .catch((error) => {
      message.error(error.msg);
      errorMessage.value = error.msg;
    });
  btnDisabled.value = false;
};
</script>
<style lang="scss">
.price-addition-popper {
  width: 300px;
}
</style>
<style lang="scss" scoped>
.payment-info-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 24px 16px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 44px;
  color: #060606;
}

.confirm-error {
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-top: 12px;
  padding: 8px;
  color: #f54a45;
  text-align: left;
}

.payment-button {
  :deep(.el-button) {
    width: 100%;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
