<template>
  <teleport to="body">
    <transition>
      <div 
        v-show="modelValue"
        class="bv-upgrade-modal-overlay" 
        :style="{zIndex}"
      >
        <div 
          class="bv-upgrade-modal-wrapper" 
          v-click-outside="close"
        >
          <div class="bv-upgrade-modal-container">
            <div class="bv-upgrade-modal-header">
              <svg-icon
                name="icon_orange_warn"
                color="#FFFFFF"
                :size="24"
              />
              <p>{{ title }}</p>
              <icon-button
                name="icon_close"
                :size="16"
                @click="close"
              />
            </div>
            <div class="bv-upgrade-modal-content">
              {{ content }}
            </div>
            <div class="bv-upgrade-modal-footer">
              <primary-button 
                size="extra-small"
                @click="confirm">
                Upgrade
              </primary-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  </teleport>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Upgrade to use this feature",
  },
  content: {
    type: String,
    default: "Upgrade now to unlock all amazing features and take your experience to the next level.",
  },
  zIndex: {
    type: Number,
    default: 2000,
  },
});
const emit = defineEmits([
  "update:modelValue",
  "confirm",
]);

const close = () => {
  emit("update:modelValue", false);
};

const confirm = () => {
  emit("confirm");
  close();
};
</script>

<style lang="scss" scoped>
.bv-upgrade-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bv-upgrade-modal-wrapper {
  width: 448px;
  padding: 24px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1)
}

.bv-upgrade-modal-container {
  display: flex;
  flex-direction: column;
}

.bv-upgrade-modal-header {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

  & > svg {
    margin-right: 16px;
  }

  & > p {
    flex: 1 1;
  }
}

.bv-upgrade-modal-content {
  font-size: 14px;
  margin-top: 8px;
  padding-left: 40px;
}

.bv-upgrade-modal-footer {
  margin-top: 24px; 
  display: flex;
  flex-direction: row-reverse;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 300ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>