import { useCreatorStore } from "./creator";
import { clamp, getComputedSize } from "../utils";

const { creator } = useCreatorStore();

const autoResize = ref(true);
const left = ref(0);
const top = ref(0);
const scale = ref(1);
const mode = ref(0);
const playerContainer = ref(null);
const playerContent = ref(null);
const player = ref(null);
const modifier = reactive({ alt: false, keyZ: false });
const beforePosition = reactive({ left: 0, top: 0, width: 0, height: 0 });
const bounds = reactive({ minLeft: 0, minTop: 0, maxLeft: 0, maxTop: 0 });

function updateCursorStyle() {
  if (!playerContainer.value) {
    return;
  }
  if (mode.value === 0) {
    playerContainer.value.style.removeProperty("cursor");
  } else {
    playerContainer.value.style.cursor = "grab";
  }
  if (modifier.keyZ) {
    playerContainer.value.style.cursor = modifier.alt ? "zoom-out" : "zoom-in";
  }
}

function resetSize(scale = 1) {
  if (creator.value) {
    const { view } = creator.value;
    const { clientWidth, clientHeight } = playerContent.value;
    const width = creator.value.getConf("width");
    const height = creator.value.getConf("height");
    const ratio = Math.min(clientWidth / width, clientHeight / height);
    const newWidth = width * ratio;
    const newHeight = height * ratio;

    beforePosition.width = newWidth;
    beforePosition.height = newHeight;
    view.style.width = `${newWidth * scale}px`;
    view.style.height = `${newHeight * scale}px`;

    calcDragBounds();
  }
}

function resetPosition() {
  const { x, y, width, height } = playerContent.value.getBoundingClientRect();
  const { clientWidth, clientHeight } = player.value;
  const newLeft = x + width / 2 - clientWidth / 2;
  const newTop = y + height / 2 - clientHeight / 2;
  
  beforePosition.left = newLeft;
  beforePosition.top = newTop;
  left.value = newLeft;
  top.value = newTop;
}

function zoom(delta, newScale) {
  const y = clamp(delta, -10, 10) * 0.01;
  const { view } = creator.value;

  newScale = clamp(newScale || scale.value - y, 0.1, 5);
  view.style.width = `${beforePosition.width * newScale}px`;
  view.style.height = `${beforePosition.height * newScale}px`;
  scale.value = newScale;
  autoResize.value = false;

  calcDragBounds();
}

function fit() {
  scale.value = 1;
  resetSize();
  resetPosition();
  calcDragBounds();
  autoResize.value = true;
}

function calcDragBounds() {
  if (creator.value) {
    const { x, y, width, height } = playerContainer.value.getBoundingClientRect();
    const { clientWidth, clientHeight } = player.value;
    const [w, h] = getComputedSize(creator.value.view);
    const padding = 20;

    bounds.minLeft = x - clientWidth / 2 - w / 2 + padding;
    bounds.minTop = y - clientHeight / 2 - h / 2 + padding;
    bounds.maxLeft = x + width - clientWidth / 2 + w / 2 - padding;
    bounds.maxTop = y + height - clientHeight / 2 + h / 2 - padding;
  }
}

export function useToolStore() {
  return {
    autoResize,
    left,
    top,
    mode,
    scale,
    playerContainer,
    playerContent,
    player,
    modifier,
    beforePosition,
    bounds,
    updateCursorStyle,
    resetSize,
    resetPosition,
    calcDragBounds,
    zoom,
    fit,
  }
}