interface IdentifyUserInfo {
  username?: string;
  package_code?: string;
  email?: string;
  signup_time?: Date;
}

type TrackedData = {
  device_id: string;
  session_id: string;
  app_name: string;
  device_type: string;
  session_source: string;
  timestamp: number;
  user_id: string;
  subscribed_plan: string;
} & Record<string, any>;

type Task = () => Promise<any>;

export const {
  identifyUser,
  trackEvent,
  setTrackApp,
  showTrackApp,
  trackAppLoadedSignal,
} = (() => {
  // 此部分逻辑用于适配track app，如需迁移到其它平台，需重写此部分逻辑

  let trackApp: any = null;
  let triggerSignal: PlainFunction<void>;
  const loadedSignal = new Promise<void>(res => triggerSignal = res);
  const tasks: Task[] = [];
  const resumeTask = () => {
    const task = tasks[0];
    
    if (task) {
      task().then(() => {
        tasks.shift();
        resumeTask();
      });
    }
  };

  const addTask = ((task: Task) => {
    tasks.push(task);

    if (tasks.length === 1) {
      resumeTask();
    }
  });

  return {
    trackAppLoadedSignal: loadedSignal,
    async identifyUser(userId: string | null | undefined, userInfo: IdentifyUserInfo) {
      await loadedSignal;

      const { username, signup_time: signupTime, ...usedUserInfo } = userInfo;
      const usedUserId = userId ?? trackApp.getIdentity().gleapId;

      addTask(async () => {
        return await trackApp.identify(usedUserId, username ? {
          name: username,
          ...usedUserInfo,
          ...(signupTime === undefined ? {} : {
            signup_time: signupTime.getTime(),
            signup_date: signupTime.toISOString().split('T')[0],
          }),
        } : usedUserInfo);
      });
    },

    async trackEvent(eventName: string, trackedData: TrackedData) {
      await loadedSignal;

      addTask(async () => {
        return await trackApp.trackEvent(eventName, trackedData)
      });
    },
    
    setTrackApp(app: any) {
      trackApp = app;
      triggerSignal();
    },

    async showTrackApp(state: boolean) {
      await loadedSignal;
      trackApp.showFeedbackButton(state);
    },
  }
})();
