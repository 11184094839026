import { render } from 'vue';
import AssetModal from '@/components/asset-modal/index.vue';

export const useAssetModal = (() => {
  let root = null;

  return () => {
    const context = getCurrentInstance().appContext;

    return {
      destroy() {
        if (root === null) return;

        render(null, root);
        document.body.removeChild(root);
        root = null;
      },
      open(draftId, handler, options = {}) {
        if (root !== null) {
          this.destroy();
        }

        root = document.createElement('div');
        document.body.appendChild(root);

        const vnode = h(AssetModal, {
          mode: 'director',
          draftId: draftId,
          visible: true,
          onReplace: handler,
          onClose: this.destroy,
          ...options,
        });

        vnode.appContext = {
          ...context,
        };

        render(vnode, root);
      }
    }
  };
})();

