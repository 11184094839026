<template>
  <div class="content-container">
    <el-scrollbar wrap-class="content-scroll">
      <History
        v-if="showPanel == 'history'"
      />
      <preset
        v-else-if="showPanel == 'preset'"
      />
      <chat v-else-if="showPanel == 'chat'" />
    </el-scrollbar>
    <inputline v-if="showPanel == 'preset' || (showPanel == 'chat' && messages.length)" />
  </div>
</template>

<script setup>
import { watch, onMounted, onBeforeUnmount } from 'vue';
import Chat from './chat.vue';
import Preset from './preset.vue';
import History from './history.vue';
import Inputline from './inputline.vue';
import { useAssistStore } from '@/store/modules/assist';
const { messages, showPanel } = storeToRefs(useAssistStore());
const { getHistoryList, resetPanel, resetHistoryList, changeCurrentPanel } = useAssistStore();

onMounted(() => {
  getHistoryList();
});

onBeforeUnmount(() => {
  changeCurrentPanel('preset');
  resetHistoryList()
});
</script>

<style lang="scss" scoped>
.content-container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:deep(.el-scrollbar) {
  flex: 1;
  height: 100%;
}

:deep(.content-scroll) {
  scroll-behavior: smooth;
}
</style>
