<template>
  <div class="discord-tip-container" @click.stop :style="`${rootStyle}`">
    <svg-icon name="icon_left_arrow"  class="absolute arrow" :style="{
      'width': '30px', height: '20px'
    }" ></svg-icon>

    <p class="title">🎉 Free upgrade to standard</p>
    <p class="detail">Free upgrade to experience more advanced features</p>
    <div class="flex justify-end">
      <div class="button" @click.stop="handleConfirm">
        Learn more
    </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['confirm'])

const props = defineProps({
  rootStyle: {
    type: String,
    default: '',
  },
});

watch(() => props.rootStyle, (val) => {
  console.log(val);
});

const handleConfirm = () => {
  emit('confirm')
}

</script>

<style lang="scss" scoped>
.discord-tip-container {
  width: 324px;
  height: 178px;
  padding: 20px 24px 24px 24px;
  border-radius: 6px;
  background: #6741FF;
  position: relative;

  .arrow {
    transform: rotate(90deg);
    position: absolute;
    top: -14px;
    right: 35px;
  }

  .close-box {
    position: absolute;
    right: 24px;
    top: 20px;
    padding: 4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #DEE0E399;
    }
  }

  .title {
    color: #FFF;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .detail {
    color: #FFF;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-bottom: 20px;
  }

  .button {
    width: 147px;
    height: 36px;
    display: flex;
    padding: 7px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    background: #FFF;
    cursor: pointer;
    color: #060606;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    &:hover {
      background: #EBEDEF;
    }
  }
}
</style>