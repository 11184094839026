<template>
  <el-scrollbar v-if="isMounted">
    <div class="loading-mask" v-if="!list">
      <el-skeleton animated v-for="(_) in Array(5)">
        <template #template>
          <div class="skeleton-item-wrapper">
            <el-skeleton-item
              variant="image"
              style="width: 100px; height: 100px"
            />
            <div style="padding: 14px" class="flex-1">
              <el-skeleton :rows="1" animated />
            </div>
          </div>
        </template>
      </el-skeleton>
    </div>
    <div 
      v-else-if="selectedType === 1 && list.length <= 0"
      class="empty-user-collection"
    >
      <span>No Avatar</span>
      <common-button @click="createCharacter">Create Avatar</common-button>
    </div>
    <div v-else-if="selectedType === 0 &&  list.length <= 0 " class="h-full flex justify-center items-center">
      <svg-icon
        name="icon_empty"
        style="color: #bbbfc4; height: 200px"
      ></svg-icon>
    </div>
    <div
      class="collection"
      v-else-if="list.length > 0"
      v-infinite-scroll="handleLoadData"
      :infinite-scroll-disabled="pagination.loadAll"
      :infinite-scroll-distance="10"
      :infinite-scroll-delay="200"
    >
      <template
        v-for="item in list"
        :key="item.name"
      >
        <LibraryCollectionItem 
          v-if="selectedType === 0"
          :item="item"
          @select="handleClick"
          :selectedStyleId="selectedStyleId"
        />
        <UserCollectionItem 
          v-else-if="selectedType === 1" 
          :item="item"
          @refresh="$emit('refresh')"
          @select="handleClick"
          :selectedStyleId="selectedStyleId"
        />
      </template>
    </div>
  </el-scrollbar>
</template>

<script setup>
import { ref } from 'vue';
import UserCollectionItem from './userCollectionItem.vue';
import LibraryCollectionItem from './libraryCollectionItem.vue';
import { useSimilarVideo } from '@/pages/similarVideo/store/index.js';
import { useModalManager } from '@/components/common/custom-modal/instance';

const modalManager = useModalManager();

const similarVideoStore = useSimilarVideo();
const { pagination } = storeToRefs(similarVideoStore);
const props = defineProps({
  list: Array,
  selectedType: Number,
  selectedStyleId: Number,
});

const emit = defineEmits('selected', 'request', 'refresh');
const isMounted = ref(false)
const handleClick = (item) => {
  emit('selected', item);
};

const handleLoadData = () => {
  emit('request', props.selectedStyleId);
};

const createCharacter = () => {
  window.open("/avatar", "_blank");
  
  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => emit("refresh"),
  });
};

onMounted(async () => {
  await nextTick();
  isMounted.value = true
});
</script>

<style lang="scss" scoped>
.collection {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 16px 120px 16px;
  display: grid;


  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
}

.loading-mask {
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .el-skeleton__image {
    border-radius: 4px;
  }
}

.empty-user-collection {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-bottom: 24px;
  }

  & :deep(.el-button) {
    padding: 10px 36px;
    color: #060606;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>
