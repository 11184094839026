export function isObject(val) {
  return val !== null && typeof val === "object";
}

export function setItem(key, val) {
  if (isObject(val)) {
    localStorage.setItem(key, JSON.stringify(val));
  } else {
    localStorage.setItem(key, val);
  }
}

export function getItem(key) {
  const val = localStorage.getItem(key);
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
}


export function sessionSetItem(key, val) {
  if (isObject(val)) {
    sessionStorage.setItem(key, JSON.stringify(val));
  } else {
    sessionStorage.setItem(key, val);
  }
}

export function sessionGetItem(key) {
  const val = sessionStorage.getItem(key);
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
}



export function removeItem(key) {
  localStorage.removeItem(key);
}

export function sessionRemoveItem(key) {
  sessionStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}

export const userConstant = {
  USER_TOKEN: "user_token",
  USER_NAME: "user_name",
  USER_INFO: "user_info",
  USER_EMAIL: "user_email",
  USER_AVATAR: "user_avatar",
};

export default {
  isObject,
  setItem,
  getItem,
  removeItem,
  clear,
  userConstant,
  sessionSetItem,
  sessionGetItem,
  sessionRemoveItem
};
