<template>
  <el-scrollbar>
    <div class="params-edit-container perfect-scrollbar h-full w-full">
      <div class="feature-edit-form">
        <Prompt :value="formState.productDesc" @update:value="formState.productDesc = $event" />
        <Ratio :value="formState.size" @update:value="formState.size = $event" />
        <Purpose v-if="formState.type == 0" :size="formState.size" :value="formState.purposeIds" @update:value="formState.purposeIds = $event"
          @update:purposeId="formState.purposeId = $event" :purposeList="purposeList"
          :subPurposeList="subPurposeList" />
        <Language :value="formState.language" @update:value="formState.language = $event" />
        <Effect :value="formState.effectId" @update:value="formState.effectId = $event"
          @update:customizeIds="formState.customize = $event" :effectList="effectList" :subEffectList="subEffectList" />
        <BrandKit :value="formState.bkId" @update:value="formState.bkId = $event" :brandKitList="brandKitList"
          style="margin-bottom: 350px;" />
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { ref, reactive, watch, onBeforeMount } from 'vue'

import Prompt from '../../../commonComponents/featureEditForm/prompt.vue'
import Ratio from '../../../commonComponents/featureEditForm/ratio.vue'
import Purpose from '../../../commonComponents/featureEditForm/purpose.vue'
import Effect from '../../../commonComponents/featureEditForm/effect.vue'
import Language from '../../../commonComponents/featureEditForm/language.vue'
import BrandKit from '../../../commonComponents/featureEditForm/brandkit.vue'

import { useFeatureEdit } from "./store/index.js";

const featureEditStore = useFeatureEdit();
const { subEffectList, effectList, purposeList, subPurposeList, brandKitList } = storeToRefs(featureEditStore)

const props = defineProps({
  productInfo: Object,
  editMode: String
})

const formState = reactive({
  productDesc: '',
  type: '0',
  duration: 0.5,
  size: '9:16',
  purposeId: '1',
  purposeIds: ['General showcase'],
  effectId: '',
  customize: [],
  language: 'en-US',
  bkId: '',
})

watch(() => props.productInfo, (productInfo) => {
  formState.productDesc = productInfo.productDesc
  formState.url = productInfo.url
  formState.bkId = productInfo.bkId
}, {
  immediate: true
})

watch(() => formState, () => {
  featureEditStore.updateFormState(formState);
}, {
  immediate: true,
  deep: true
})

const getFormState = () => {
  return formState
}

defineExpose({
  getFormState
})

onBeforeMount(async() => {
  await featureEditStore?.getVideoPurpose()
  featureEditStore.updateFormState(formState)
  featureEditStore.getBrandKitListFn()
  console.log(purposeList.value);
})

</script>

<style lang="scss">
.params-edit-container {
  font-family: Inter-variant;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 40px;

}
</style>

<style lang="scss" scoped>
.params-edit-container {
  .voice-time {
    width: 100%;
    height: 76px;
    border-radius: 6px;
    background: #F7F7F8;
    margin-top: 18px;
    padding: 16px 21px;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    .title {
      width: 123px;
      margin-right: 30px;
    }

    .time-num {
      color: #060606
    }

    .time-tip {
      color: #646A73
    }

    .voice-slider {
      flex: 1
    }
  }

  .checkbox-group {
    padding-left: 21px
  }

}

.search-box {
  display: flex;
  align-items: center;
  padding: 0px 15px 5px 18px;

  .icon_search {
    margin-right: 8px;
  }

  .el-input {
    box-shadow: none;
  }
}

.empty-data {
  padding: 48px 0px;
  color: #8F959E;
  text-align: center;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .link {
    color: #6741FF;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>