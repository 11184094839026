<template>
  <div class="carousel" ref="carousel">
    <div
      class="carousel-inner"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div
        class="carousel-item"
        v-for="(item, index) in items"
        :key="item.src"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <player
          :posterUrl="item.poster"
          :resourceUrl="item.src"
          v-model:isMute="isMute"
          :videoId="index"
          :playingId="currentIndex"
          :init-play="index === 0"
        />
      </div>
    </div>
  </div>
  <div class="indicators-container">
    <div class="indicators">
      <span
        v-for="(item, index) in items"
        :key="index"
        :class="{ active: index === currentIndex }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script setup>
import { ref,watch } from 'vue';
import Player from './player.vue';

const props = defineProps({
  list: Array,
});

const items = ref(props.list);
const currentIndex = ref(0);
const touchStartX = ref(0);
const isScrollVideo = ref(0);
const isMute = ref(true)

watch(
  () => currentIndex.value,
  () => {
    isScrollVideo.value += 1;
  }
);
const goToSlide = (index) => {
  currentIndex.value = index;
};

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % items.value.length;
};

const prevSlide = () => {
  currentIndex.value =
    (currentIndex.value - 1 + items.value.length) % items.value.length;
};

const handleTouchStart = (event) => {
  touchStartX.value = event.touches[0].clientX;
  event.stopPropagation();
};

const handleTouchEnd = (event) => {
  const touchEndX = event.changedTouches[0].clientX;
  if (touchStartX.value - touchEndX > 50) {
    nextSlide();
  } else if (touchEndX - touchStartX.value > 50) {
    prevSlide();
  }
  event.stopPropagation();
};
</script>

<style lang="scss" scoped>
.carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 42px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
  background-color: #f0f0f0;
  touch-action: pan-y;
}

.indicators-container {
  display: flex;
  justify-content: center;
}

.indicators {
  margin-top: 15px;
  display: flex;
  gap: 5px;
}

.indicators span {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
}

.indicators .active {
  background-color: #632cff;
}
</style>
