<template>
  <!-- Card container -->
  <div class="card-box">
    <div class="card-container" :class="{'active-border': currentHightLightCard == card.packageCode}" v-for="card in displayCardList" :key="card.name">
      <PricingCardItem
        :active="currentHightLightCard == card.packageCode" 
        :card="card"
        :subscriptionState="subscriptionState"
        :isLoading="isLoading"
        :userOperationMap="userOperationMap"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref , watch} from 'vue'
import PricingCardItem from "./pricing-card-item.vue";
import type { CardItemInfo } from "../../type";
import { useSubscriptionInfo } from '@/store/modules/user';

const { subscriptionState } = storeToRefs(useSubscriptionInfo());

const props = defineProps({
  cardList: {
    type: Array<CardItemInfo>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
});

const currentPlan =  ref('')
const currentPackageCode = ref(-1)
const starterCodes = [300401, 300402];

const subscribeList = [
  {
    packageCode: -1,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300401,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300402,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300201,
    highLightPackageCode: 300301,
    highLightCardName: "Pro",
    cycleType: "month"
  },
  {
    packageCode: 300301,
    highLightPackageCode: 300202,
    highLightCardName: "Standard",
    cycleType: "year"
  },
  {
    packageCode: 300202,
    highLightPackageCode: 300302,
    highLightCardName: "Pro",
    cycleType: "year"
  },
  {
    packageCode: 300302,
    highLightPackageCode: 10,
    highLightCardName: "Pro",
    cycleType: "year"
  }, 
  {
    packageCode: 300401,
    highLightPackageCode: 300201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCode: 300402,
    highLightPackageCode: 300202,
    highLightCardName: "Standard",
    cycleType: "year"
  }
]

const currentHightLightCard = computed(() => {
  if(currentPackageCode.value == 300402) {
    return 300202
  }

  if(currentPackageCode.value == 300401) {
    return 300201
  }
  return subscribeList.find(item => item.packageCode == currentPackageCode.value)?.highLightPackageCode
})

const displayCardList = computed(() => {
  const { currentPlanName, packageCode } = subscriptionState.value;
  currentPlan.value = currentPlanName
  currentPackageCode.value = packageCode

if (starterCodes.includes(packageCode)) {
  const list = [props.cardList[0], props.cardList[1], props.cardList[2]]
    return list;
  }else if (currentPlanName === "Starter") {
    return props.cardList;
  }else {
    return props.cardList.filter(item => 
      item.name !== "Starter"
    );
  }
});
</script>

<style lang="scss" scoped>
.card-box {
  display: grid;
  max-width: 1672px;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-top: 36px;
  margin-bottom: 104px;
  padding: 0 1.5rem;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 318px;
  padding: 0 20px 36px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dee0e3;
  border-radius: 8px;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08);
}

.active-border {
  border: 2px solid #6741FF;
}

.card-box {
    grid-template-columns: repeat(auto-fit, minmax(318px, 318px));
    padding: 0 24px;
  }
</style>
