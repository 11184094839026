import { putDraft, putDraftName } from "@/api/draft";
import { useCreatorStore } from "./creator";

const title = ref("Untitled draft");
const ratio = ref("9:16");
const state = ref("saved");
const lastUpdate = ref(0);
const timer = ref(null);
const videoType = ref("director");
const videoSubtype = ref("no_ai_voiceover");
const draftId = ref("");
const reqId = ref("");
const rid = ref("");

export const ratioMap = new Map([
  ["480*480", "1:1"],
  ["480*854", "9:16"],
  ["854*480", "16:9"],
  ["480*600", "4:5"],
  ["480*720", "2:3"],
]);

function setRatio(value) {
  ratio.value = value;
}

export function useDraftStore() {
  const route = useRoute();
  const { creator, totalFrame } = useCreatorStore();

  async function updateDraftFunc() {
    state.value = "saving";

    const { draftId } = route.query;
    const renderConfig = JSON.stringify(creator.value.conf);
    const data = {
      draftId,
      renderConfig,
      ratio: ratio.value,
      duration: totalFrame.value / 30,
    };
    const response = await putDraft(data);

    if (response.success) {
      const { lastUpdateTime } = response.data;
      state.value = "saved";
      lastUpdate.value = lastUpdateTime;
    } else {
      state.value = "failed";
    }
    timer.value = null;
  }

  async function updateDraft() {
    if (timer.value) {
      clearTimeout(timer.value);
    }
    timer.value = setTimeout(updateDraftFunc, 1000);
  }

  async function renameDraft() {
    if (title.value === "") {
      title.value = "Untitled draft";
    }
    const { draftId } = route.query;
    const params = { name: title.value };
    await putDraftName(draftId, params);
  }

  function setVideoSubtype(value) {
    switch (value) {
      case 1:
      case 3:
        videoSubtype.value = "no_ai_voiceover";
        break;
      case 2:
      case 4:
        videoSubtype.value = "with_ai_voiceover";
        break;
    }
  }

  function setVideoType(value) {
    let type = "director";
    switch (value) {
      case 1:
      case 2:
        type = "product_to_video";
        break;
      case 3:
      case 4:
        type = "visuals_to_video";
        break;
      case 5:
        type = "idea_to_video";
        break;
      case 6:
        type = "script_to_video";
        break;
      case 7:
        type = "blog_to_video";
        break;
    }
    videoType.value = type;
  }

  function setDraftId(value) {
    draftId.value = value;
  }

  function setReqId(value) {
    reqId.value = value;
  }

  function setRid(value) {
    rid.value = value;
  }

  return {
    title,
    ratio,
    state,
    reqId,
    rid,
    draftId,
    lastUpdate,
    videoType,
    videoSubtype,
    setVideoType,
    setVideoSubtype,
    updateDraft,
    renameDraft,
    setRatio,
    setDraftId,
    setReqId,
    setRid,
  };
}
