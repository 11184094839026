<template>
  <el-tooltip
    :visible="selectOpen"
    :show-arrow="false"
    effect="customized"
    :show-after="200"
    :placement="placement"
    :offset="offset"
    :popper-options="popperOptions"
    :teleported="teleported"
  >
    <template #content>
      <transition name="select">
        <div
          ref="selectDropdown"
          class="bv-select-dropdown rounded pt-2"
          :class="popperClass"
          :style="{ width: width, ...popperStyle }"
        >
          <div v-if="showSearch">
            <el-input
              class="select-search-input"
              v-model="searchContent"
              :placeholder="searchPlaceholder"
              @input="handleInputSearch"
            >
              <template #prefix>
                <svg-icon
                  name="icon_search"
                  :style="{ width: '18px', height: '18px' }"
                />
              </template>
            </el-input>
          </div>
          <div 
            v-if="noMatch"
            class="search-empty"
          >
            No matching data
          </div>
          <ul class="perfect-scrollbar" ref="ulRef">
            <selectItem
              class="select-option"
              v-for="(option, i) in displayOptions"
              :key="i"
              :active="deepEqual(option.value, currentOption?.value)"
              :showSelectIcon="showSelectIcon"
              :style="{ width }"
              @click="handleChooseSelectItem(option)"
            >
              <slot v-if="$slots.option" name="option" :option="option" />
              <span v-else>{{ option.label }}</span>
            </selectItem>

            <!-- empty -->
            <div
              class="flex justify-center items-center text-sm text-defaultColor flex-col py-5"
              v-if="!options.length"
            >
              <svg-icon
                name="icon_empty"
                :style="{ width: '175px', height: '71px', color: '#875EFF' }"
              ></svg-icon>
              {{ noDataText }}
            </div>
          </ul>
        </div>
      </transition>
    </template>
    <div
      v-click-outside:[selectDropdown]="close"
      ref="selectButton"
      class="bv-select-button"
      :class="{ disabled, showBg: selectOpen && hignlightBackground }"
      :style="{ width, ...style }"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      @click="handleClick"
    >
      <div
        class="select-wapper"
        :class="{
          'show-box-shadow': showBoxShadow,
          border: showBorder,
          'is-focus': selectOpen,
          'bg-primaryBgColor': selectOpen && hignlightBackground,
        }"
      >
        <div v-if="$slots.prefix" class="prefix">
          <slot name="prefix"></slot>
        </div>
        <div class="select-content">
          <el-input
            v-if="inputable"
            :model-value="currentValue"
            @input="handleChange"
            :class="{ 'custom-input': selectOpen }"
          />
          <input-number
            v-if="numberInput"
            class="select-number-input"
            :model-value="Number(currentValue)"
            :min="1"
            :max="160"
            @blur="selectOpen = false"
            @input="handleInput"
            @change="handleChange"
            @click.stop
          />
          <!-- 选中内容 -->
          <span
            v-else-if="currentOption || currentValue"
            class="font-normal select-value"
            :class="{
              'text-primaryColor': selectOpen,
              'text-tipColor': !selectOpen,
            }"
            :style="{ ...textStyle }"
          >
            {{ currentOption?.label || currentValue }}
          </span>
          <span v-else class="placeholder">{{ placeholder }}</span>
        </div>
        <div class="suffix">
          <svg-icon v-if="clearable && hover && currentOption" name="icon_clear" :size="18" @click.stop="clear" />
          <div v-else class="select-icon" :class="{ 'select-open': selectOpen }">
            <slot v-if="$slots.suffix" name="suffix"></slot>
            <svg-icon
              v-else
              name="icon_select_arrow"
              :size="18"
              :disabled="disabled"
              :color="selectOpen ? '#875EFF' : '#646A73'"
            />
          </div>
        </div>
      </div>
    </div>
  </el-tooltip>
</template>
<script setup lang="ts">
import { ref, watch, type PropType } from "vue";
import selectItem from "./select-item.vue";
import { deepEqual } from "@/utils";

const emits = defineEmits([
  "search",
  "update:modelValue",
  "input",
  "change",
  "changeId",
]);
const props = defineProps({
  justifyAlign: String,
  spaceBetween: String,
  teleported: {
    type: Boolean,
    default: true
  },
  name: String,
  id: String,
  width: String,
  offset: {
    type: Number,
    default: 4,
  },
  style: {
    type: Object,
    default: {},
  },
  textStyle: {
    type: Object,
    default: {},
  },
  popperClass: {
    type: String,
    default: "",
  },
  popperStyle: {
    type: Object,
    default: {},
  },
  showBoxShadow: {
    type: Boolean,
    default: false,
  },
  showBorder: {
    type: Boolean,
    default: true,
  },
  inputable: {
    type: Boolean,
    default: false,
  },
  numberInput: {
    type: Boolean,
    default: false,
  },
  hignlightBackground: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number, Array],
    default: undefined,
  },
  autocomplete: {
    type: String,
    default: "off",
  },
  size: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "Select",
  },
  noDataText: {
    type: String,
    default: "No data",
  },
  searchPlaceholder: {
    type: String,
    default: "",
  },
  disabled: Boolean,
  clearable: Boolean,
  filterable: Boolean,
  allowCreate: Boolean,
  loading: Boolean,
  options: {
    type: Array as PropType<ItemType[]>,
    default: () => [],
  },
  remote: Boolean,
  loadingText: String,
  noMatchText: String,
  remoteMethod: Function,
  filterMethod: Function,
  multiple: Boolean,
  defaultFirstOption: Boolean,
  placement: {
    type: String,
    default: "bottom-start",
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
  showSelectIcon: {
    type: Boolean,
    default: true,
  },
  popperOptions: Object
});

type ItemType = {
  value: number | string | Array<any>;
  label: string;
};

const ulRef = ref(null);
const selectDropdown = ref(null);
const selectButton = ref<HTMLDivElement | null>(null);
const currentValue = ref<string | number | Array<any> | null>(null);
const searchContent = ref("");
const selectOpen = ref(false);
const hover = ref(false);
const currentOption = ref<ItemType | null>(null);
const noMatch = computed(() => {
  return props.showSearch && 
  displayOptions.value.length === 0 &&
  props.options.length !== 0
});
const displayOptions = computed(() => {
  if (props.showSearch && searchContent.value !== "") {
    return props.options.filter(i => {
      const lowerLabel = i.label.toLowerCase();
      const lowerSearch = searchContent.value.toLowerCase();
      return lowerLabel.includes(lowerSearch);
    });
  }
  else {
    return props.options;
  }
});

watch(selectOpen, (val) => {
  if (!val) {
    searchContent.value = "";
  }
  else {
    scrollToSelected();
  }
});

watch(
  () => props.modelValue,
  (newVal: any) => {
    if ((newVal || newVal === 0) && !props.defaultFirstOption) {
      const target = props.options.find((item) =>
        deepEqual(newVal, item.value),
      );
      if (target) {
        currentOption.value = target;
        currentValue.value = target.value;
      } else {
        currentOption.value = null;
        currentValue.value = newVal;
      }
    } else {
      currentOption.value = null;
      currentValue.value = null;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.defaultFirstOption,
  () => {
    const target = props.options[0];
    if (target) {
      currentOption.value = target;
      currentValue.value = target.value;
    }
  },
);

function scrollToSelected() {
  nextTick(() => {
    const index = displayOptions.value.findIndex(item => 
      item.value === currentValue.value
    );
    
    if (index === - 1) return;
    if (!ulRef.value) return;
    const children = ulRef.value.querySelectorAll("li");
    const selectedItem = children[index];
    const containerHeight = ulRef.value.clientHeight;
    ulRef.value.scrollTop = selectedItem.offsetTop - containerHeight / 2;
  });
};

function clear() {
  currentOption.value = null;
  currentValue.value = null;

  emits("update:modelValue", currentValue.value);
  emits("change", currentValue.value);
}

const close = () => {
  selectOpen.value = false;
}

const handleClick = () => {
  if (props.disabled) {
    return;
  }
  selectOpen.value = !selectOpen.value;
};

const handleInput = (value: string) => {
  currentValue.value = value;

  emits("update:modelValue", value);
  emits("input", value);
};

const handleChange = (value: string) => {
  currentValue.value = value;

  emits("update:modelValue", value);
  emits("change", value);
};

const handleChooseSelectItem = (option: ItemType) => {
  currentOption.value = option;
  currentValue.value = option.value;
  close();

  emits("update:modelValue", option.value);
  emits("change", option.value);
};

const handleInputSearch = () => {
  if (props.remoteMethod) {
    props.remoteMethod();
  }
  emits("search", searchContent.value);
};
</script>
<style lang="scss" scoped>
@import "./style/index.scss";

// 下拉框
.bv-select-button {
  width: 100%;
  height: 100%;
  font-size: 14px;
  @include border(generalBorderColor, 1px solid);
  @include background-color(mainBackground);
  cursor: pointer;
}

.select-wapper {
  padding: 0 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 200ms;
}

.bv-select-button.disabled .select-wapper .select-value {
  color: #bbbfc4;
}

.select-wapper.border {
  border: 1px solid #d5d6d7;
  box-shadow: none;
  border-radius: 4px;
}

.bv-select-button .select-wapper.border.is-focus {
  border-color: #875eff;
  box-shadow: none;
}


.select-wapper.show-box-shadow {
  box-shadow: 0 0 0 1px #d5d6d7 inset;
  border-radius: 4px;
  border:none;
}

.bv-select-button .select-wapper.show-box-shadow.is-focus {
  box-shadow: 0 0 0 1px #875eff inset;
  border:none;
}

.select-content {
  min-width: 0;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.select-value {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:deep(.select-content .el-input .el-input__wrapper) {
  padding: 0px !important;
  border: none !important;
}

.prefix {
  margin-right: 6px;
}

.suffix {
  flex-shrink: 0;
  margin-left: 4px;
  display: flex;
  align-items: center;
}

// icon
.select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  @include border(generalBorderColor, 2px solid);
  transition: all 0.2s;
}

.select-icon.select-open {
  transform: rotate(180deg);
}

.bv-select-dropdown {
  padding: 8px 0;
  border-width: 1px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

  :deep(.el-input__wrapper) {
    box-shadow: none !important;
  }
  
  & > ul{
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }


}

.bv-select-dropdown ul {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 345px;
  @include border(generalBorderColor, 1px solid);
}

.el-input.select-number-input {
  height: 100%;
}

.placeholder {
  color: #8f959e;
  font-size: 12px;
}

.search-empty {
  text-align: center;
  color: #8F959E;
}
</style>
<style lang="scss">
.el-popper.is-customized {
  padding: 0;
}
</style>
<style lang="scss">
.el-input.select-number-input .el-input__wrapper,
.el-input.select-number-input .el-input__wrapper:hover,
.el-input.select-number-input .el-input__wrapper.is-focus {
  outline: none !important;
  border: none !important;
}

.custom-input {
  .el-input__wrapper,
  .el-input__inner {
    background-color: #f8f5ff;
    height: 16px;
    color: #875eff;
    text-align: center;
  }
}

.bv-select-button {
  .el-input__wrapper {
    padding: 0px;
  }

  .el-input__inner {
    text-align: center;
  }
}

.bv-select-button.showBg {
  background-color: #f8f5ff;
  padding: 2px 4px;
}

.select-search-input {
  .el-input__wrapper {
    padding: 5px 11px;
    min-width: 50px;
    width: 100%;
  }

  .el-input__inner { 
    padding: 0;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;

    &::placeholder {
      color: #8F959E;
    }
  }

}
</style>
