<script setup>
const emit = defineEmits(["click"]);
const props = defineProps({
  tip: {
    type: String,
    default: "",
  },
  tipDelay: {
    type: Number,
    default: 1000,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  round: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: "icon",
  },
  name: {
    type: String,
    required: true,
  },
  backgroundColor: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: "none",
  },
  size: {
    type: Number,
    default: null,
  },
  stroke: {
    type: String,
    default: "none",
  },
  style: {
    type: Object,
    default: {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

function clickButton() {
  if (props.disabled || props.loading) {
    return;
  }
  emit("click");
}
</script>
<template>
  <span
    class="icon-button"
    :class="{ primary, active, disabled, border, round, loading }"
    @click="clickButton"
  >
    <div v-if="loading" class="icon-wapper">
      <svg-icon
        name="icon_loading"
        class="animate-spin duration-300 loading"
        :color="color"
        :size="size"
      />
    </div>
    <template v-else>
      <bv-tip v-if="tip" :content="tip" :showAfter="tipDelay">
        <div class="icon-wapper">
          <svg-icon
            :prefix="prefix"
            :name="name"
            :color="color"
            :disabled="disabled"
            :size="size"
            :stroke="stroke"
            :style="style"
          />
        </div>
      </bv-tip>
      <div v-else class="icon-wapper">
        <svg-icon
          :prefix="prefix"
          :name="name"
          :color="color"
          :disabled="disabled"
          :size="size"
          :stroke="stroke"
          :style="style"
        />
      </div>
    </template>
  </span>
</template>
<style scoped>
.icon-button {
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms;
}
.icon-button.round {
  border-radius: 50%;
}
.icon-button.primary {
  background-color: #875eff;
}
.icon-button.border {
  border-radius: 2px;
  border: 0.5px solid #d5d6d7;
}
.icon-button.active {
  background-color: #f8f5ff;
}
.icon-button:hover {
  background-color: rgba(222, 224, 227, 0.6) !important;
}
.icon-button.primary:hover {
  background-color: #6741ff !important;
}
.icon-button.disabled:hover {
  background: none !important;
}
.icon-button.loading:hover {
  background: none !important;
}
.icon-button.loading {
  cursor: default;
}
.icon-button.disabled {
  cursor: not-allowed;
}
.icon-wapper {
  padding: 4px;
}
.icon-button.round .icon-wapper {
  padding: 3px;
}
</style>
