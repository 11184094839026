<template>
  <div 
    class="card-item-container"
    :class="{locked: data.lock}"
  >
    <div class="preview" @click="goToEdit">
      <div class="loading-mask" v-if="data.state === 2">
        <div class="progress-box">
          <span class="progress-tip">{{`Creating ${0.5 * 100}%`}}</span>
          <div class="progress-bar">
            <div class="progress" :style="{ width: `${0.5 * 100}%` }"></div>
          </div>
        </div>
      </div>
      <div class="error-mask" v-else-if="data.state === 1">
        <div class="error-tip-container">
          <svg-icon name="icon_warn" :size="24" color="#FFFFFF" alt="warn icon" />
          <span class="error-tip">Avatar creation failed</span>
        </div>
      </div>
      <div class="lock-mask" v-if="data.lock">
        Locked
        <svg-icon
          name="icon_lock"
          :size="16"
        />
      </div>
      <img 
        v-if="data.coverPic"
        class="preview-img" 
        :src="data.coverPic" 
        alt=""
      >
    </div>
    <div class="card-footer">
      <p class="name">{{ data.name || "Unnamed" }}</p>
      <div class="more">
        <span class="time">{{ lastEditTime }}</span>
        <el-dropdown
          trigger="click"
          :teleported="true"
          placement="bottom-end"
          popper-class="character-card-item-popper"
          @command="handleCommand"
          @visibleChange="(v) => moreActive = v"
          :popper-options="{
            modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
          }"
        >
          <icon-button 
            class="more-icon"
            :class="{active: moreActive}"
            name="icon_more"
            :size="16"
          />
          <template #dropdown>
            <el-dropdown-menu class="character-card-menu">
              <el-dropdown-item
                v-for="option in Options"
                :key="option.text"
                :command="option.value"
                :disabled="option.value === 'edit' && data.lock"
              >
                <svg-icon :size="18" :name="option.prefix" />
                <span style="margin-left: 12px;">{{ option.label }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  <SubscribeDialog
    :visible="subscribeModalVisible"
    @close="subscribeModalVisible = false"
    :showIntroduction="false"
  />
</template>

<script setup>
import { formatDate } from "@/utils";
import { deleteCharacter, getCharacterDetail } from "@/api/character";
import { useModalManager } from "@/components/common/custom-modal/instance";
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

const router = useRouter();
const modalManager = useModalManager();

const props = defineProps({
  data: {
    type: Object,
    required: true,
  }
});
const emits = defineEmits(["delete", "update"]);

const timer = ref(null);
const moreActive = ref(false);
const subscribeModalVisible = ref(false);
const lastEditTime = computed(() => (
  props.data?.updateTime === -1 ? "00:00" : formatDate(props.data?.updateTime)
));

const Options = [
  {
    label: "Edit",
    value: "edit",
    prefix: "icon_rename",
  },
  {
    label: "Delete",
    value: "delete",
    prefix: "icon_delete",
  }
];

const goToEdit = () => {
  if (props.data.lock) {
    modalManager.applyTemplate("upgradeTips", {
      icon: "orangeWarn",
      title: "Upgrade to edit and use it",
      content: "The character is unavailable, upgrade to unlock this feature.",
      onConfirm: () => {
        subscribeModalVisible.value = true;
      }
    });
  }
  router.push({
    path: "/avatar/edit",
    query: {
      characterId: props.data.mediaId,
    }
  });
};

const handleCommand = (command) => {
  switch(command) {
    case "edit":
      goToEdit();
      break;
    case "delete":
      let deleteLoading = false;
      const confirm = async () => {
        if(deleteLoading) return;
        deleteLoading = true;
        const { success } = await deleteCharacter(props.data.mediaId);
        if (success) {
          emits("delete");
        }
        modalManager.modal.open = false;
        deleteLoading = null;
      };
      modalManager.applyTemplate("warning", {
        icon: "orangeWarn",
        key: "delete-character-modal",
        title: "Deleted avatars cannot be restored",
        content: "The hooks, stickers, and sounds in video drafts made with this avatar are no longer available.Continue deleting?",
        confirmText: "Delete",
        closeAfterConfirm: false,
        onConfirm: confirm,
      });
      break;
  }
};

const setup = async () => {
  const getState = async () => {
    const { success, data } = await getCharacterDetail(props.data.mediaId);
    if (success) {
      if (data.state !== 2) {
        emits("update", data);
      }
      else {
        timer.value = setTimeout(getState, 1000);
      }
    }
    else {
      timer.value = null;
    }
  };
  
  if (props.data.state === 2) {
    getState();
  }
};

const destroy = () => {
  if (timer.value) {
    clearTimeout(timer.value);
    timer.value = null;
  }
};

onMounted(setup);
onBeforeUnmount(destroy);
onDeactivated(destroy);
</script>

<style lang="scss">
.character-card-item-popper {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

  & > .el-popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.card-item-container {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}

.card-item-container.locked > .preview{
  cursor: default;
  pointer-events: none;
}

.preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  cursor: pointer;

  & > .loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.54);  
    z-index: 1;
  }

  & > .error-mask {
    position:absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.54);
    z-index: 1;
  }

  & > .lock-mask {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.80);
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    color: #ffe4ae;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    & > svg {
      margin-left: 6px;
    }
  }

  & > .preview-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 0;
    -webkit-user-drag: none;
  }
}

.progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
}

.progress-bar {
  position: relative;
  width: 160px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow-x: hidden;
}

.progress {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s;
}

.progress-tip {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.error-tip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error-tip {
  color: #ffffff;
  font-size: 14px;
  margin-top: 4px;
}

.card-footer {
  padding: 14px;
  border-top: 1px solid #dee0e3;
}

.name {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
}

.card-item-container:hover .more-icon,
.more-icon.active {
  opacity: 1;
}

.more-icon {
  opacity: 0;
  transition: opacity 0.2s;
  &:hover,
  &.active {
    background-color: #eaeaea;
  }
}


.time {
  font-size: 12px;
  color: #646a73;
}

.character-card-menu {
  min-width: 150px;

  & > :deep(li) {
    color: #1f2329;
    padding-inline: 12px;

    &.is-disabled {
      color: #c0c4cc;
    }

    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    & > span {
      font-size: 14px;
    }
  }
}
</style>