import { _get, _post, _singleGet } from "@/utils/https";
const urlList = {
  templateList: "/template/page",
  templateTags: "/template/tags",
  audioList: '/library/audio'
};

const { VITE_API_BASE } = import.meta.env;

// 获取渲染信息
export function getSingleRenderInfo(rid: string | number) {
  return _singleGet<CreateVideoResData.RenderVideoType>(
    VITE_API_BASE + `/video/render/${rid}/info`,
  );
}

// 获取模版视频列表
export function getTemplateList(
  params: TemplateVideoEditReqParams.TemplateListParams,
) {
  return _post<TemplateVideoEditResData.TemplateListType>(
    VITE_API_BASE + urlList.templateList,
    params,
  );
}

// 获取模版标签列表
export function getTemplateTagList() {
  return _get<[]>(VITE_API_BASE + urlList.templateTags);
}

export function getAudioList(params) {
  return _get(VITE_API_BASE + urlList.audioList, params);
}
