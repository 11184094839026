<template>
  <teleport to="body">
    <transition name="fade">
      <div v-show="visible" class="bv-dialog-overlay" @click.self="close">
        <slot></slot>
      </div>
    </transition>
  </teleport>
</template>
<script setup>
const emit = defineEmits(["close"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const visible = ref(props.modelValue);
watch(
  () => props.modelValue,
  (value) => {
    visible.value = value;
  }
);
function close() {
  visible.value = false;
  emit("close");
}
</script>
<style scoped>
.bv-dialog-overlay {
  position: fixed;
  inset: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
