<template>
  <svg aria-hidden="true" :style="style" :class="{ clickable, disabled }">
    <use :xlink:href="symbolId" :fill="color" :stroke="stroke" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "SvgIcon",
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: "icon",
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "none",
    },
    size: {
      type: Number,
      default: null,
    },
    stroke: {
      type: String,
      default: "none",
    },
    style: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const symbolId = computed(() => `#${props.name}`);
    const style = computed(() => {
      const newStyle = { ...props.style };

      if (typeof newStyle.color === "undefined" && props.color !== "none") {
        newStyle.color = props.color;
      }
      if (props.disabled) {
        newStyle.color = "#BBBFC4";
      }
      if (typeof props.size === "number") {
        newStyle.width = `${props.size}px`;
        newStyle.height = `${props.size}px`;
      }
      return newStyle;
    });
    return { style, symbolId };
  },
});
</script>
<style scoped>
svg.clickable {
  cursor: pointer;
}
svg.disabled {
  cursor: not-allowed;
}
</style>
