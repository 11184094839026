<template>
  <div class="video-info">
    <div
      class="video-wrap"
      @click.stop="handleControlSelect"
      @mousemove="handleMouseMove"
      @mouseleave="handleMouseLeave"
    >
      <div style="padding-top: 177.777%;">
        <div class="video-container">
          <video
            ref="videoRef"
            :src="item.url"
            :poster="item.coverPic"
          ></video>

          <svg-icon
            v-if="isStop"
            @click="handleControlPlay"
            class="icon-play-large"
            name="icon_big_play"
            :size="60"
          />

          <div class="time" v-if="isStop">{{ formatDuration(duration) }}</div>

          <div
            class="controls"
            v-if="(played && showControl && !isStop) || (isPause && !isStop)"
            @click.stop="(e) => {}"
          >
            <bv-slider
              :model-value="currentFrame"
              :step="1"
              :min="0"
              :max="Number(duration)"
              :show-tooltip="false"
              @input="handleSeekTo"
            />

            <div class="control-btns">
              <div class="flex">
                <svg-icon
                  class="icon-play"
                  v-show="!played"
                  name="player_play"
                  :size="24"
                  @click.stop="handlePlay"
                />
                <svg-icon
                  class="icon-play"
                  v-show="played"
                  name="player_pause"
                  :size="24"
                  @click.stop="handlePause"
                />

                <span
                  >{{ formatDuration(currentFrame) }}/
                  {{ formatDuration(duration) }}</span
                >
              </div>

              <svg-icon
                class="icon-volume"
                v-show="!muted"
                name="player_volume"
                :size="20"
                @click.stop="handleSetVolume(0)"
              />
              <svg-icon
                class="icon-volume"
                v-show="muted"
                name="player_mute"
                :size="20"
                @click.stop="handleSetVolume(1)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="item-name cursor-pointer"
        @click="handleSelectedStyle(item.id)"
      >
        <img :src="icon_selected" v-if="item.id == currentStyleId" />
        <img :src="icon_select" v-else />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';

const props = defineProps({
  item: Object,
  currentStyleId: Number,
});

const emit = defineEmits(['onSelected', 'onPlay', 'onPause']);

const route = useRoute();
const videoName = ref('');
const videoUrl = ref('');
const draftId = ref('');
const ratio = ref('9:16');
const played = ref(false);

const muted = ref(false);
const visible = ref(true);

const duration = ref(0);
const isResizeHeight = ref(false);
const isResizeWidth = ref(false);
const currentFrame = ref(0);
const videoRef = ref(null);
const infoList = ref([]);
const showControl = ref(false);
const isStop = ref(true);
const isPause = ref();

const handleSelectedStyle = (id) => {
  emit('onSelected', id);
};

function formatDuration(duration) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${(minutes | 0).toString().padStart(2, '0')}:${(seconds | 0)
    .toString()
    .padStart(2, '0')}`;
}

const handleControlVideo = () => {
  const video = videoRef.value;

  video.addEventListener('loadedmetadata', handleVideoLoadedMetata);
  video.addEventListener('play', handleVideoPlay);
  video.addEventListener('pause', handleVideoPause);
  video.addEventListener('timeupdate', handleVideoTimeUpdate);
};

function handleVideoLoadedMetata() {
  duration.value = videoRef.value.duration;
}

function handleVideoPlay() {
  played.value = true;
  isPause.value = false;
  visible.value = false;

  emit('onPlay', props.item.id);
}

function handleVideoPause() {
  played.value = false;
  visible.value = true;
  isPause.value = true;
  
  emit('onPause', props.item.id);
}

function handleVideoTimeUpdate() {
  currentFrame.value = videoRef.value.currentTime;
}

const handlePlay = () => {
  videoRef.value?.play();
  emit('onSelected', props.item.id);
};

const handlePause = () => {
  videoRef.value?.pause();
  played.value = false;
  visible.value = true;
};

const handleSetVolume = (value) => {
  if (!videoRef.value) {
    return;
  }

  videoRef.value.muted = value ? false : true;
  muted.value = value ? false : true;
};

const handleSeekTo = (value) => {
  if (!videoRef.value) {
    return;
  }

  videoRef.value.currentTime = value;
};

const handleControlSelect = () => {
  if (!videoRef.value) {
    return;
  }

  emit('onSelected', props.item.id);
};

const handleControlPlay = () => {
  if (!videoRef.value) {
    return;
  }

  if (played.value == true) {
    resetVideo();
  } else {
    videoRef.value?.play();
    isStop.value = false;
    emit('onSelected', props.item.id);
  }
};

const handleMouseMove = () => {
  showControl.value = true;
};

const handleMouseLeave = () => {
  showControl.value = false;
};
const resetVideo = () => {
  handlePause();
  isStop.value = true;
  if (videoRef.value) {
    videoRef.value.currentTime = 0;
  }
};

watch(
  () => props.item.isPlaying,
  (isPlaying) => {
    if (!isPlaying) {
      resetVideo();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.item.isSelected,
  (isSelected) => {
    if (!isSelected) {
      resetVideo();
    }
  },
  {
    immediate: true,
  }
);

onMounted(async () => {
  await nextTick();
  handleControlVideo();
});
onBeforeUnmount(() => {
  const video = videoRef.value;

  if (video) {
    video.removeEventListener('loadedmetadata', handleVideoLoadedMetata);
    video.removeEventListener('play', handleVideoPlay);
    video.removeEventListener('pause', handleVideoPause);
    video.removeEventListener('timeupdate', handleVideoTimeUpdate);
  }
  videoRef.value = null;
})
</script>

<style lang="scss" scoped>
.video-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-wrap {
  position: relative;
  background: #ebedef;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
}
.video-container {
  position: absolute;
  inset: 0;
}

.item-name {
  width: 100%;
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 24px;
    margin-right: 10px;
    cursor: pointer;
  }
}

video {
  width: 100%;
  height: auto;
}

.time {
  position: absolute;
  left: 24px;
  bottom: 24px;
  height: 30px;
  padding: 4px 14px;
  border-radius: 200px;
  z-index: 1;
  color: #fff;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 200px;
  background: rgba(0, 0, 0, 0.54);
}

.icon-play-large {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.controls {
  height: 66px;
  width: 100%;
  padding: 8.5px 16px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
}

.controls .control-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.controls svg {
  cursor: pointer;
}

.controls .icon-play {
  margin-right: 8px;
  margin-left: -6px;
}

.controls .icon-volume {
  margin-right: -3px;
}

:deep(.controls .el-slider) {
  margin: 0 12px;
  flex: 1 1;
}

:deep(.controls .el-slider__runway) {
  background: rgba(255, 255, 255, 0.4);
  height: 4px;
  border-radius: 40px;
  overflow: hidden;
}

:deep(.controls .el-slider__bar) {
  background-color: #ffffff;
  height: 4px;
}

:deep(.controls .el-slider__button-wrapper) {
  display: none;
}

:deep(.el-loading-spinner .path) {
  stroke: #646a73 !important;
}
</style>
