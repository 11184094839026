<script setup>
import Material from "./material.vue";
import ImageMaterial from "./libimagematerial.vue";
import VideoMaterial from "./libvideomaterial.vue";

const currentTab = ref("image");
</script>
<template>
  <Material>
    <el-tabs v-model="currentTab">
      <el-tab-pane label="Image" name="image" lazy>
        <image-material :current-tab="currentTab" />
      </el-tab-pane>
      <el-tab-pane label="Video" name="video" lazy>
        <video-material :current-tab="currentTab" />
      </el-tab-pane>
    </el-tabs>
  </Material>
</template>
<style scoped>
:deep(.el-tab-pane) {
  display: flex;
  flex-direction: column;
}
:deep(.el-scrollbar) {
  flex-grow: 1;
}
</style>
