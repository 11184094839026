<template>
  <div class="setting-container">
    <div class="header">
      <p class="flex w-20 cursor-pointer" @click="handleBack">
        <svg-icon
          name="icon_arrow_left_brandkit"
          color="#1C1B1E"
          :size="24"
        ></svg-icon>
        Back
      </p>
    </div>

    <div class="content">
      <div class="right-part">
        <p class="part-title">Select a visual style</p>
        <el-scrollbar>
          <div class="style-video-container">
            <StyleVideoItem
              v-for="item in videoList"
              :key="item.id"
              :item="item"
              :currentStyleId="selectedStyleId"
              class="item-box"
              @onSelected="handleSelectedStyle"
              @onPlay="handlePlayStyleVideo"
            />
          </div>
        </el-scrollbar>
      </div>
      <div class="left-part">
        <p class="part-title">
          <span :class="{ 'error-border': errorStatus }">{{
            currentTitle
          }}</span>
          <span class="sub-title">{{ showStyleTip }}</span>
        </p>
        <div class="list-wrapper" :class="{ 'error-border': errorStatus }">
          <Switcher
            v-if="selectedStyleId !== 4"
            :value="selectedType"
            @switch="switchSelectedType"
          />
          <div class="list-container">
            <viral-hooks
              v-if="showComponents === 'viralHooks'"
              @onPlay="handleViralHooks"
              :list="collectionList"
              @request="handleLoadData"
              :selectedMediaId="selectedMediaId"
              :selectedStyleId="selectedStyleId"
            />
            <voiceList
              v-show="showComponents === 'voiceList'"
              @onPlay="handlePlayVoice"
              :list="collectionList"
              :selectedMediaId="selectedMediaId"
              :selectedStyleId="selectedStyleId"
              @request="handleLoadData"
              @refresh="setCurrentStylePanel(selectedStyleId)"
            />

            <CollectionItems
              :muted="selectedStyleId == 3 ? true : false"
              v-show="showComponents === 'collectionItems'"
              @refresh="() => loadMedias(currentCollection)"
              @onPlay="handlePlayCollection"
              @back="handleBackToCollection"
              :list="currentItemsList"
              :selectedType="selectedType"
              :selectedMediaId="selectedMediaId"
            />
            <Collection
              v-show="showComponents === 'collection'"
              :selectedType="selectedType"
              :selectedStyleId="selectedStyleId"
              @selected="handleSelectedCollection"
              :list="collectionList"
              @request="handleLoadData"
              @refresh="setCurrentStylePanel(selectedStyleId)"
            />

            <div class="loading-mask h-full" v-if="fetchLoading">
              <el-skeleton animated v-for="item in skeletonList">
                <template #template>
                  <div class="flex w-full">
                    <el-skeleton-item
                      variant="image"
                      style="width: 100px; height: 100px"
                    />
                    <div style="padding: 14px" class="flex-1">
                      <el-skeleton :rows="1" animated />
                    </div>
                  </div>
                </template>
              </el-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="next-button" @click.stop="handleGenerate">
      <img :src="icon_generate" class="icon_generate" />
      Generate
    </div>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { checkResource } from '@/api/script';
import { fileUploadState } from '@/api';
import { useModalManager } from '@/components/common/custom-modal/instance';
import icon_generate from '@/assets/similar-video/icon_generate.svg';

import Switcher from '../components/collection/switcher.vue';
import Collection from '../components/collection/index.vue';
import CollectionItems from '../components/collection/collectionItems.vue';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import voiceList from '../components/collection/voiceList.vue';
import ViralHooks from '../components/collection/viralHooks.vue';
import StyleVideoItem from '../components/styleVideoItem.vue';

import hooks from '@/assets/similar-video/hooks.mp4';
import roll from '@/assets/similar-video/b-roll.mp4';
import stickers from '@/assets/similar-video/stickers.mp4';
import voiceover from '@/assets/similar-video/voiceover.mp4';

import {
  getSimilarHookDetail,
  getSimilarStickerDetail,
} from '@/api/similarVideo';
import { useTrackStore } from '@/store/modules/track';
import { useSimilarVideo } from '../store/index.js';
import { useSingleMessage } from '@/utils';

const Message = useSingleMessage();
const similarVideoStore = useSimilarVideo();
const { collectData, track } = useTrackStore();
const {
  formParams,
  checkSource,
  currentCollection,
  collectionList,
  selectedScriptItem,
  fetchLoading,
  selectedType,
  selectedMediaId,
  selectedVoiceId,
  selectedStyleId,
} = storeToRefs(similarVideoStore);

const modalManager = useModalManager();
const showComponents = ref(false);
const skeletonList = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const subscribeModalVisible = ref(false);
const timers = reactive(new Map());

const router = useRouter();
const route = useRoute();
const videoList = ref([
  {
    id: 1,
    url: hooks,
    name: 'Begin with a Hook',
    isSelected: false,
    trackName: 'hook',
  },
  {
    id: 2,
    url: roll,
    name: 'B-roll Footage',
    isSelected: false,
    trackName: 'b_roll',
  },
  {
    id: 3,
    url: stickers,
    name: 'Celebrity Sticker',
    isSelected: false,
    trackName: 'sticker',
  },
  {
    id: 4,
    url: voiceover,
    name: 'Begin with viral voiceover',
    isSelected: false,
    trackName: 'voiceover',
  },
]);

const showStyleTip = ref(
  'Both celebrity hook and voice will be applied in the video'
);

const currentItemsList = ref([]);

const errorStatus = ref(false);

const currentTitle = ref('Choose a celebrity hook');

const setCurrentStylePanel = (id) => {
  switch (id) {
    case 1:
      currentTitle.value = 'Choose a celebrity hook';
      showStyleTip.value =
        'Both celebrity hook and voice will be applied in the video';
      similarVideoStore.resetPagination();
      similarVideoStore.getSimilarHookListFn();

      if (selectedMediaId.value && currentCollection.value) {
        errorStatus.value = false;
        showComponents.value = 'collectionItems';
        currentItemsList.value?.forEach((e) => {
          if (e.mediaId == selectedMediaId.value) {
            e.isSelected = true;
          }
        });
      } else {
        showComponents.value = 'collection';
      }
      break;
    case 2:
      currentTitle.value = 'Choose a voice';
      showStyleTip.value = '';
      showComponents.value = 'voiceList';
      similarVideoStore.resetPagination();
      similarVideoStore.getSimilarVoiceListFn();
      break;
    case 3:
      currentTitle.value = 'Choose a celebrity sticker';
      showStyleTip.value =
        'Both celebrity voice and sticker will be applied in the video';

      showComponents.value = 'collection';
      similarVideoStore.resetPagination();
      similarVideoStore.getSimilarStickersListFn();

      if (selectedMediaId.value && currentCollection.value) {
        errorStatus.value = false;
        showComponents.value = 'collectionItems';
        currentItemsList.value.forEach((e) => {
          if (e.mediaId == selectedMediaId.value) {
            e.isSelected = true;
          }
        });
      } else {
        showComponents.value = 'collection';
      }

      break;
    case 4:
      currentTitle.value = 'Choose a viral voiceover';
      showStyleTip.value = '';

      showComponents.value = 'viralHooks';
      similarVideoStore.resetPagination();
      similarVideoStore.getSimilarViralHookFn();

      break;
    default:
      break;
  }
};

const handleLoadData = (id) => {
  switch (id) {
    case 1:
      similarVideoStore.getSimilarHookListFn();
      break;
    case 2:
      similarVideoStore.getSimilarVoiceListFn();
      break;
    case 3:
      similarVideoStore.getSimilarStickersListFn();
      break;
    case 4:
      similarVideoStore.getSimilarViralHookFn();
      break;
    default:
      break;
  }
};

const loadMedias = async (collections) => {
  const getDetailFunc = async (mediaId) => {
    if (selectedStyleId.value === 1) {
      return await getSimilarHookDetail(mediaId);
    } else if (selectedStyleId.value === 3) {
      return await getSimilarStickerDetail(mediaId);
    }
  };

  currentItemsList.value = null;
  const { mediaId } = collections;
  await getDetailFunc(mediaId).then(({ success, data }) => {
    if (success) {
      collections.medias = data;
      if (selectedType.value === 1) {
        for (const file of data) {
          if (file.state === 2) {
            getUploadState(file)
              .then((info) => Object.assign(file, info))
              .catch((e) => Object.assign(file, e));
          }
        }
      }
    }
  });
  currentItemsList.value = collections.medias || [];
};

// 参数回显
watch(
  () => collectionList.value,
  () => {
    if (
      selectedMediaId.value &&
      collectionList.value.length &&
      selectedStyleId.value == 2
    ) {
      collectionList.value.forEach((e) => {
        if (e.mediaId == selectedMediaId.value) {
          e.isSelected = true;
        }
      });
    }
  },
  {
    immediate: true,
  }
);

watch(selectedStyleId, (value) => {
  similarVideoStore.setSelectedType(0);
  setCurrentStylePanel(value);
});

watch(
  () => currentCollection.value,
  async (newCollection) => {
    if (newCollection) {
      const { medias } = newCollection;
      if (medias) {
        currentItemsList.value = newCollection.medias || [];
      } else {
        await loadMedias(newCollection);
      }
    }
  },
  {
    immediate: true,
  }
);

const switchSelectedType = (value) => {
  similarVideoStore.setSelectedType(value);
  similarVideoStore.setSelectedMediaId(null);
  similarVideoStore.setSelectedVoiceId(null);
  similarVideoStore.setCurrentCollection(null);
  setCurrentStylePanel(selectedStyleId.value);
};

const getUploadState = async (file) => {
  return new Promise((resolve, reject) => {
    file.progress = 50;
    let increment = 10;
    const getState = async () => {
      const { success, data } = await fileUploadState(file.mid);

      if (success) {
        if (data.state === 0) {
          timers.delete(file.mid);
          file.progress = 100;
          resolve(data);
        } else if (data.state === 1) {
          timers.delete(file.mid);
          reject();
        } else {
          const timer = setTimeout(getState, 1000);
          file.progress = Math.min(90, file.progress + increment);
          increment = Math.max(1, Math.round(increment / 2));
          timers.set(file.mid, timer);
        }
      } else {
        timers.delete(file.mid);
        reject();
      }
    };
    getState();
  });
};

const handleSelectedStyle = (id) => {
  similarVideoStore.setSelectedMediaId(null);
  similarVideoStore.setSelectedVoiceId(null);
  similarVideoStore.setCurrentCollection(null);

  videoList.value.forEach((item) => {
    if (item.id == id) {
      item.isSelected = true;
    } else {
      item.isSelected = false;
    }
  });

  currentItemsList.value.forEach((e) => {
    e.isSelected = false;
  });

  similarVideoStore.setSelectedStyleId(id);
};

const handlePlayStyleVideo = (id) => {
  videoList.value.forEach((item) => {
    if (item.id == id) {
      item.isPlaying = true;
    } else {
      item.isPlaying = false;
    }
  });

  if (
    showComponents.value == 'voiceList' ||
    showComponents.value == 'viralHooks'
  ) {
    collectionList.value.forEach((item) => {
      item.isPlay = false;
    });
  } else {
    currentItemsList.value.forEach((item) => {
      item.isPlay = false;
    });
  }
};

const handleBack = () => {
  similarVideoStore.setCurrentCollection(null);
  similarVideoStore.setSelectedType(0);
  router.push('/similar-script-to-video');
};

const handleBackToCollection = () => {
  showComponents.value = 'collection';
  currentItemsList.value.forEach((e) => {
    e.isSelected = false;
  });

  similarVideoStore.setSelectedMediaId(null);
  similarVideoStore.setSelectedVoiceId(null);
  similarVideoStore.setCurrentCollection(null);
};

const handleSelectedCollection = (item) => {
  showComponents.value = 'collectionItems';
  similarVideoStore.setCurrentCollection(item);
};

const checkSimilarVideoSource = () => {
  return new Promise(async (resolve, reject) => {
    const { msg, code } = await checkResource({
      resource: [90011],
    });

    if (code == 101011) {
      modalManager.applyTemplate('upgradeTips', {});
      reject();
    }

    if (code == 90010 || code == 90011) {
      modalManager.applyTemplate('upgradeTips', {
        msg,
        code,
        onConfirm: () => {
          subscribeModalVisible.value = true;
        },
      });
      reject();
    }

    resolve();
  });
};

const handleGenerate = async () => {
  if (
    !selectedMediaId.value ||
    (selectedStyleId.value !== 2 && !selectedVoiceId.value)
  ) {
    errorStatus.value = true;
    return;
  }

  try {
    await checkSimilarVideoSource();
  } catch (e) {
    return;
  }

  const trackHelper = () => {
    let celebrity = formatCollectionData();
    collectData('similarvideo_script_generate', {
      name: currentItemsList.value[0]?.name,
      id: selectedMediaId.value,
      avatar_type: selectedType.value == 0 ? 'ordinary' : 'self_built',
      video_style: videoList.value.find(
        (item) => item.id === selectedStyleId.value
      ).trackName,
      celebrity,
      celebrity_fragment: selectedMediaId.value,
      video_type: 'similar_video_script_to_video',
    });
    track('similarvideo_script_generate');
  };
  trackHelper();

  router.push('/similar-video/script-generating');
};

const handlePlayVoice = () => {
  videoList.value.forEach((item) => {
    item.isPlaying = false;
  });
};

const handleViralHooks = () => {
  console.log(222);
  videoList.value.forEach((item) => {
    item.isPlaying = false;
  });
};

const formatCollectionData = () => {
  let celebrity = 'none';
  if (selectedStyleId.value === 2) {
    celebrity = collectionList.value.find(
      (item) => item.mediaId === selectedMediaId.value
    )?.name;
  } else {
    celebrity = collectionList.value.find((item) =>
      item.medias?.some((i) => i.mediaId === selectedMediaId.value)
    )?.name;
  }

  return celebrity;
};

const handlePlayCollection = () => {
  videoList.value.forEach((item) => {
    item.isPlaying = false;
  });

  let celebrity = formatCollectionData();
  collectData('similarvideo_script_celebrity_click', {
    video_type: 'similar_video_script_to_video',
    name: currentItemsList.value[0]?.name,
    id: selectedMediaId.value,
    video_type: 'similar_video',
    avatar_type: selectedType.value == 0 ? 'ordinary' : 'self_built',
    video_style: videoList.value.find(
      (item) => item.id === selectedStyleId.value
    ).trackName,
    celebrity,
    celebrity_fragment: selectedMediaId.value,
  });
  track('similarvideo_script_celebrity_click');
};

const handleDocumentClick = () => {
  errorStatus.value = false;
};

const checkParams = () => {
  if (!formParams.value?.prompt) {
    // Message.error('Please input your params first');
    const path = route.path;

    if (path == '/similar-video/script-setting') {
      router.replace('/similar-script-to-video');
      return;
    }
  }
};

onMounted(() => {
  checkParams();
  document.addEventListener('click', handleDocumentClick);
  setCurrentStylePanel(selectedStyleId.value);
  collectData('similarvideo_script_input_result', {
    result: 'success ',
    video_type: 'similar_script_video',
  });

  track('similarvideo_script_input_result');
});

onUnmounted(() => {
  document.removeEventListener('click', handleDocumentClick);
});
</script>
<style lang="scss" scoped>
.setting-container {
  padding: 31px 42px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  margin-bottom: 25px;

  .title {
    color: #000;
    text-align: center;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-left: -80px;
    pointer-events: none;
  }
}

.content {
  height: calc(100% - 49px);
  display: flex;
}

.right-part {
  flex: 2 1 40%;
  height: 100%;
  padding-right: 40px;
}

.right-part .el-scrollbar {
  height: calc(100% - 52px);
}

.left-part {
  flex: 1 1 50%;
  height: 100%;
}

.part-title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.sub-title {
  margin-left: 20px;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.next-button {
  z-index: 10;
  display: flex;
  width: 220px;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  margin-left: -110px;
  left: 50%;
  bottom: 80px;
  cursor: pointer;

  .icon_generate {
    margin-right: 6px;
  }
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  border-radius: 6px;
  border: 1px solid #ebedef;
}

.list-container {
  position: relative;
  flex: 1 1;
  min-height: 0;
}

.loading-mask {
  padding: 21px 12px 120px 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
  overflow-y: auto;
  overflow-x: hidden;

  .el-skeleton__image {
    border-radius: 4px;
  }
}

.list-wrapper.error-border {
  border: 1px solid #ff4a40;
}

.part-title .error-border {
  color: #ff4a40;
}

.style-video-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 40px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.custom-scrollbar {
  --sb-track-color: transparent;
  --sb-thumb-color: rgba(0, 0, 0, 0.25);
  --sb-size: 9px;

  &::-webkit-scrollbar {
    width: var(--sb-size);
    padding: 0px;
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--sb-thumb-color);
  }
}
</style>
