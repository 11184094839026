<template>
  <card-list
    ref="cardListRef"
    :items="items"
    :isFirstLoad="page === 1"
    :isLoading="isLoading"
    :isLoaded="isLoaded"
    @loadMore="handleLoadMore"
  />
</template>

<script setup lang="tsx">
import CardItem from "../components/card-item.vue";
import CardList from "../components/card-list.vue";
import { wrapperCardOperations } from "../utils/helper";
import { toFixed, apiCall, createRef, useScopeId } from "@/utils";
import videoIcon from "@/assets/icons/space/icon_video.svg";
import timelineTypeIcon from "@/assets/icons/space/draft_timeline.svg";
import templateTypeIcon from "@/assets/icons/space/draft_template.svg";
import sceneTypeIcon from "@/assets/icons/space/draft_scene.svg";
import modalInstance from "@/components/common/custom-modal/instance";
import { useTrackStore } from "@/store/modules/track";
import { getDraftList, deleteDraft, renameDraft, copyDraft } from "@/api/space";
import type { CardItemData, CardOperation, CardItemVNode } from "../utils/type";

// ANCHOR - 变量声明
const size = 30;
const page = ref(1);
const scopeId = useScopeId();
const router = useRouter();
const isLoading = ref(false);
const isLoaded = ref(false);
const { collectData, track, clearEventData } = useTrackStore();
const renameInputRef = ref<null | HTMLInputElement>(null);
const cardListRef = ref(null as unknown as InstanceType<typeof CardList>);
const modalManager = modalInstance.modalManager!;
const batchDelete = inject<Set<string>>("batchDelete")!;
const items = reactive<CardItemData<SpaceResData.DraftRecordDTO>[]>([]);
const cardOperations: CardOperation[] =
  wrapperCardOperations<SpaceResData.DraftRecordDTO>(
    [
      {
        text: "Edit",
        iconName: "icon_edit",
        handler: (item) => {
          const { type, draftId, rid } = item.record;
          clearEventData("boolvideo_export_click");
          if (type === 0) {
            router.push({ path: "/space/editVideo", query: { rid } });
          } else if (type === 1) {
            router.push({ path: "/editor", query: { draftId } });
          } else if (type === 2) {
            router.push({ path: "/scene-editor", query: { draftId } });
          }
        },
      },
      {
        text: "Rename",
        iconName: "icon_rename",
        handler: (item) =>
          modalManager.applyTemplate("rename_card_item", {
            inputRef: renameInputRef,
            value: item.record.name,
            async onRename(name: string) {
              if (name === item.record.name || name === "") return;

              await apiCall(renameDraft, item.key, name.replace(/&/g, "%26"));
              item.vnode.props!.info.title = item.record.name = name;
              item.vnode.component!.update();
            },
          }),
      },
      {
        text: "Duplicate",
        iconName: "icon_duplicate",
        handler: (item) =>
          modalManager.applyTemplate("duplicate_card_item", {
            async onDuplicate() {
              const res = await apiCall(copyDraft, item.key);
              const record: CardItemData<SpaceResData.DraftRecordDTO> = {
                key: res.draftId,
                record: res,
                vnode: renderCardItem(res),
              };
              items.splice(0, 0, record);
            },
          }),
      },
      {
        text: "Delete",
        iconName: "icon_delete",
        handler: (item, index) =>
          modalManager.applyTemplate("delete_card_item", {
            async onDelete() {
              await apiCall(deleteDraft, item.key);
              index !== -1 && items.splice(index, 1);
            },
          }),
      },
    ],
    items
  );

const draftTypeMap: Record<
  number,
  {
    name: string;
    icon: string;
    tip: string;
  }
> = {
  0: {
    name: "template",
    icon: templateTypeIcon,
    tip: "Template editor",
  },
  1: {
    name: "timeline",
    icon: timelineTypeIcon,
    tip: "Timeline editor",
  },
  2: {
    name: "scene",
    icon: sceneTypeIcon,
    tip: "Scene editor",
  },
};

// ANCHOR - 事件处理
async function handleLoadMore() {
  isLoading.value = true;

  const { records, total } = await apiCall(getDraftList, {
    page: page.value,
    size,
  }).finally(() => {
    isLoading.value = false;
  });

  if (total <= page.value++ * size) {
    isLoaded.value = true;
  }

  items.push(
    ...records.map((record) => ({
      key: record.draftId,
      record,
      vnode: renderCardItem(record),
    }))
  );

  nextTick(() => {
    cardListRef.value.canLoadMore() && nextTick(handleLoadMore);
  });
}

batchDelete.delete = ((originalDelete) => {
  let lock = false;

  onBeforeUnmount(() => {
    batchDelete.delete = originalDelete;
  });

  return function (id: string) {
    if (lock) return Set.prototype.delete.call(toRaw(batchDelete), id);

    const index = items.findIndex((item) => item.key === id)!;
    index !== -1 && items.splice(index, 1);
    lock = true;

    const reslut = originalDelete.call(batchDelete, id);
    lock = false;

    return reslut;
  };
})(batchDelete.delete);

function renderCardItem(record: SpaceResData.DraftRecordDTO) {
  const size = (record.ratio || "9:16").split(":").map(Number);
  const posterStyle = ref("cursor: pointer");
  const draftType = draftTypeMap[record.type];
  const info = reactive({
    id: record.draftId,
    title: record.name,
    poster: record.coverPic,
    duration: record.duration,
    updateTime: record.lastUpdateTime,
    selected: false,
  });

  const handlePosterLoadFail = () => {
    info.poster = videoIcon;
    if (size.length === 2) {
      posterStyle.value += `; background-color: #FFF; aspect-ratio: ${size[0]}/${size[1]};`;
      if (size[0] > size[1]) {
        posterStyle.value += `width: min(100%, ${toFixed(
          (size[0] / size[1]) * 250,
          3
        )}px); height: auto`;
      } else {
        posterStyle.value += "width: auto";
      }
    } else {
      posterStyle.value += "; width: 100%; background-color: #FFF";
    }
  };

  const handleClickPoster = () => {
    let url = "";

    collectData("boolvideo_space_editor_page_view", {
      draftId: record.draftId,
      rid: record.rid,
      editor_type:
        record.type === 0
          ? "template_editor"
          : record.type === 1
          ? "timline_editor"
          : "scene-editor",
    });
    track("boolvideo_space_editor_page_view");
    if (record.type === 0) {
      url = router.resolve({
        path: "/space/editVideo",
        query: { rid: record.rid },
      }).href;
    } else if (record.type === 1) {
      url = router.resolve({
        path: "/editor",
        query: { draftId: record.draftId, enterFrom: 'space'},
      }).href;
    } else if (record.type === 2) {
      url = router.resolve({
        path: "/scene-editor",
        query: { draftId: record.draftId, enterFrom: 'space'  },
      }).href;
    }

    window.open(url);
  };

  return h(
    CardItem,
    {
      info,
      handleClickPoster,
      handlePosterLoadFail,
      operations: cardOperations,
      posterStyle: createRef(posterStyle, "value"),
    },
    {
      title() {
        return (
          <>
            <el-tooltip
              popper-class="draft-type-tip"
              content={draftType.tip}
              effect="dark"
            >
              {h("img", {
                [scopeId]: "",
                class: "draft-type",
                src: draftType.icon,
              })}
            </el-tooltip>
            {info.title}
          </>
        );
      },
    }
  ) as CardItemVNode;
}

watch(renameInputRef, (input) => {
  if (input !== null) {
    setTimeout(() => {
      input.focus();
      input.select();
    });
  }
});
</script>

<style scoped lang="scss">
:deep(.video-name) {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

:deep(.bottom-part) {
  padding-left: 36px;
}

.draft-type {
  width: 24px;
  margin-right: 12px;
}
</style>

<style lang="scss">
.draft-type-tip {
  --el-text-color-primary: #1f2329;

  padding: 8px 12px;

  & > .el-popper__arrow {
    width: 8px;
    height: 8px;

    &::before {
      width: 100%;
      height: 100%;
      border-top-left-radius: 0 !important;
    }
  }
}
</style>
