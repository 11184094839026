<template>
  <div class="step-container">
    <div class="avatar">
      <img :src="icon_avatar" />
      <div class="info">
        <p class="name">Sophia Anderson </p>
        <p class="tip">Social Media Manager</p>
      </div>
    </div>

    <div class="des">
      "Boolvideo Standard has been incredibly useful for us. Easily create high-quality videos without the need for extensive video editing training."
    </div>

    <div v-for="(item, index) in stepList" class="step-box" :class="{'hidden-border': index == stepList.length - 1}">
      <img :src="item.icon" class="icon" />
      <p class="title">
        {{ item.title }}
      </p>
      <p class="content">{{ item.content }}</p>
    </div>

    <div class="upgrade-button" @click="handleUpgrade">Free upgrade</div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import icon_avatar from '../../assets/icon_avatar.png'
import icon_clock from '../../assets/icon_clock.svg'
import icon_gift from '../../assets/icon_gift.svg'
import icon_diamond from '../../assets/icon_diamond.svg'

const emit = defineEmits(['onUpgrade'])
const currentTime = ref('')
const stepList = ref([
  {
    id: 1,
    icon: icon_gift,
    title: 'Today - Free upgrade to Standard, cancel anytime',
    content: 'Free upgrade to access more features! 80% of users have chosen to upgrade.'

  }, {
    id: 2,
    icon: icon_diamond,
    title: 'May 3, 2024',
    content: 'Your Standard subscription will become active immediately. Enjoy your new journey!'

  }, {
    id: 3,
    icon: icon_clock,
    title: 'May 10, 2024',
    content: `Your subscription starts at $29 monthly, unless you've canceled it.`
  }
])

const handleUpgrade = () => {
  emit('onUpgrade')
}

const getCurrentTime = () => {
  // 获取当地当前时间戳
const currentTimestamp = Math.floor(new Date().getTime() / 1000);

// 将时间戳转换为特定日期格式
const date = new Date(currentTimestamp * 1000);
const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = date.toLocaleDateString('en-US', options);
return formattedDate
}

const getNextTime = () => {

  // 获取当地当前时间戳
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  // 加30天
  const nextMonthTimestamp = new Date(currentTimestamp * 1000);
  nextMonthTimestamp.setDate(nextMonthTimestamp.getDate() + 30);

  // 将时间戳转换为特定日期格式
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = nextMonthTimestamp.toLocaleDateString('en-US', options);
  return formattedDate

}

onMounted(() => {
  stepList.value[1].title = getCurrentTime()
  stepList.value[2].title = getNextTime()
})
</script>

<style lang="scss" scoped>
.step-container {
  padding: 24px 12px 24px 24px;
  font-family: Inter-variant;
  height: 100%;
  position: relative;

  .avatar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 48;
      height: 48px;
      margin-right: 14px;
    }

    .info .name {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .info .tip {
      color: #060606;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 4px;
    }

  }

  .des {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 38px;
  }

  .step-box {
    min-height: 120px;
    border-left: 1px solid #DCDFE4;
    margin-left: 15px;
    position: relative;
    padding-left: 27px;

    .title {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px;
      margin-top: -4px;
      display: inline-block;

      vertical-align: top;
    }

    .icon {
      position: absolute;
      left: -15px;
    }

    .content {
      color: #060606;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .hidden-border {
    border: none;
  }

  .upgrade-button {
    position: absolute;
    bottom: 24px;
    display: flex;
    width: 274px;
    height: 54px;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      background: linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%);
    }
  }
}
</style>