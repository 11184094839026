<template>
  <div class="video-player">
    <advanced-video
      loop
      ref="advancedVideo"
      class="preview-video"
      :src="resourceUrl"
      :poster="posterUrl"
      :volumn="0.5"
      :muted="isMute"
      @click="handleClickVideo"
      @timeupdate="handleTimeUpdate"
      @loadedmetadata="handleLoadedMetaData"
      @ended="isPlay = false"
    />
    <div v-if="showControl" class="video-control">
      <progress
        class="progress-bar"
        :value="progress"
        :ref="pointerMove.ref"
        min="0"
        max="1"
      ></progress>
      <div class="control-box">
        <SvgIcon
          :name="isPlay ? 'icon_edit_pause' : 'icon_edit_play'"
          @click="handlePlay"
        />
        <span class="time">{{ formatTime(videoDuration * progress) }}</span>
        <SvgIcon
          style="margin-inline: auto 0"
          :name="isMute ? 'icon_edit_mute' : 'icon_edit_unmute'"
          @click="handleMute"
        />
      </div>
    </div>
    <template v-else>
      <span class="video-duration">
        {{ formatTime(videoDuration) }}
      </span>
      <SvgIcon class="play-icon" name="icon_big_play"  />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { usePointerMove } from '@/utils/hook';
import { createDynamicRef } from '@/utils/type';
import AdvancedVideo from '@/components/common/advanced-video.vue';

const emit = defineEmits(['update:isMute']);
const props = defineProps({
  resourceUrl: {
    type: String,
    required: true,
  },
  posterUrl: {
    type: String,
    required: true,
  },
  initPlay: {
    type: Boolean,
    default: false,
  },
  isMute: {
    type: Boolean,
    default: false,
  },
  playingId: {
    type: Boolean,
    default: 0,
  },
  videoId: {
    type: Boolean,
    default: 0,
  }
});

let isUnmount = false;
const mutexSpace = inject<{ ref: PlainFunction<Ref<boolean>> }>('mutexSpace')!;
const isPlay = mutexSpace.ref();
const progress = ref(0);
const showControl = ref(true);
const videoDuration = ref(0);

const advancedVideo = ref(
  null as unknown as InstanceType<typeof AdvancedVideo>
);

const activeVideo = createDynamicRef(() => advancedVideo.value.videoRef!);
const pointerMove = usePointerMove({
  suppressY: true,
  handler(e) {
    if (e.state === 'start') {
      activeVideo.pause();
    } else if (e.state === 'end') {
      activeVideo.play();
    }

    if (e.state !== 'end') {
      progress.value = Math.min(1, Math.max(0, e.x / e.self.clientWidth));
      activeVideo.currentTime = videoDuration.value * progress.value;
    }
  },
});

const formatTime = (time: number) => {
  return `${((time / 60) | 0).toString().padStart(2, '0')}:${(time % 60 | 0)
    .toString()
    .padStart(2, '0')}`;
};

function handlePlay() {
  isPlay.value = !isPlay.value;
  showControl.value = !showControl.value;

  if (isPlay.value) {
    activeVideo.play();
  } else {
    activeVideo.pause();
  }
}

function handleClickVideo() {
  handlePlay();
}

function handleMute() {
  emit('update:isMute', !props.isMute);
}

function handleLoadedMetaData(e: Event) {
  videoDuration.value = (e.target as HTMLVideoElement).duration;
}

function handleTimeUpdate() {
  if (isUnmount) return;
  progress.value = activeVideo.currentTime / activeVideo.duration;
}

watch(
  () => isPlay.value,
  () => {
    isPlay.value ? activeVideo.play() : activeVideo.pause();
    showControl.value = isPlay.value;
  }
);

watch(
  () => props.playingId,
  (playingId) => {
    isPlay.value = playingId == props.videoId;
  }
);

watch(
  () => props.isMute,
  (value) => {
    activeVideo.muted = value;
  }
);

onBeforeUnmount(() => {
  isUnmount = true;
});

onMounted(() => {
  if (props.initPlay) {
    isPlay.value = true;
  }
});
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;

  &:hover > .video-control {
    opacity: 1;
  }
}

.preview-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border-radius: 11.593px;
}

.video-control {
  position: absolute;
  inset: auto 0 0;
  padding: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.4s;

  & > .control-box {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;

    & > svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}

.progress-bar {
  display: block;
  width: 100%;
  height: 4px;
  margin-bottom: 10px;
  cursor: pointer;

  &::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 9999px;
  }

  &::-webkit-progress-value {
    background-color: #ffffff;
    border-radius: 9999px;
  }
}

.play-icon {
  position: absolute;
  inset: 0;
  width: 80px;
  height: 80px;
  margin: auto;
  pointer-events: none;
}

.video-duration {
  position: absolute;
  bottom: 18px;
  left: 18px;
  padding: 4px 12px;
  color: white;
  font-size: 12px;
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
