import { _get, _post, _delete, _put } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

/** 获取云空间明细 */
export function getCloudDetail() {
  return _get<SpaceResData.GetCloudDetail>(`${VITE_API_BASE}/cloud/detail`);
}

/** 云素材列表分页 */
export function getCloudMaterialList(
  params: SpaceReqParams.GetCloudMaterialList,
) {
  return _post<SpaceResData.GetCloudMaterialList>(
    `${VITE_API_BASE}/material/cloud/page`,
    params,
  );
}

/** 删除云素材 */
export function deleteCloudMaterial(mid: string) {
  return _delete<SpaceResData.DeleteMaterial>(
    `${VITE_API_BASE}/material/cloud/${mid}`,
  );
}

/** 素材重命名 */
export function renameMaterial(mid: string, name: string) {
  return _put<SpaceResData.RenameMaterial>(
    `${VITE_API_BASE}/material/rename/${mid}?name=${name}`,
  );
}

/** 获取素材上传地址 */
export function getMaterialUploadUrl(
  params: SpaceReqParams.GetMaterialUploadUrl,
) {
  return _get<SpaceResData.GetMaterialUploadUrl>(
    `${VITE_API_BASE}/material/upload`,
    params,
  );
}

/** 获取素材上传状态 */
export function getMaterialUploadState(mid: string) {
  return _get<SpaceResData.GetMaterialUploadState>(
    `${VITE_API_BASE}/material/upload/${mid}/state`,
  );
}

/** 批量删除素材 */
export function deleteCloudMaterials(params: SpaceReqParams.DeleteMaterials) {
  return _post<SpaceResData.DeleteMaterials>(
    `${VITE_API_BASE}/material/cloud/batch`,
    params,
  );
}

/** 草稿列表分页 */
export function getDraftList(params: SpaceReqParams.GetDraftList) {
  return _get<SpaceResData.GetDraftList>(`${VITE_API_BASE}/draft/page`, params);
}

/** 草稿重命名 */
export function renameDraft(draftId: string, name: string) {
  return _put<SpaceResData.RenameDraft>(
    `${VITE_API_BASE}/draft/rename/${draftId}?name=${name}`,
  );
}

/** 删除草稿 */
export function deleteDraft(draftId: string) {
  return _delete<SpaceResData.DeleteDraft>(`${VITE_API_BASE}/draft/${draftId}`);
}

/** 复制草稿 */
export function copyDraft(draftId: string) {
  return _post<SpaceResData.DraftRecordDTO>(
    `${VITE_API_BASE}/draft/${draftId}/copy`,
  );
}

/** 导出列表分页 */
export function getExportVideoList(params: SpaceReqParams.GetExportVideoList) {
  return _get<SpaceResData.GetExportVideoList>(
    `${VITE_API_BASE}/video/export/page`,
    params,
  );
}

/** 重命名导出视频 */
export function renameExportVideo(exportId: string, name: string) {
  return _put<SpaceResData.RenameExportVideo>(
    `${VITE_API_BASE}/video/export/rename/${exportId}?name=${name}`,
  );
}

/** 删除导出视频 */
export function deleteExportVideo(exportId: string) {
  return _delete<SpaceResData.DeleteExportVideo>(
    `${VITE_API_BASE}/video/export/${exportId}`,
  );
}

/** 获取视频导出状态 */
export function getVideoExportState(exportId: string) {
  return _get<SpaceResData.GetVideoExportState>(
    `${VITE_API_BASE}/video/export/${exportId}/state`,
  );
}
