<template>
  <div class="timeline-tools">
    <div class="timeline-tools-left">
      <timeline-mode />
      <icon-button
        name="editor_undo"
        :tip="`Undo(${keyCode}+Z)`"
        :tip-delay="0"
        :disabled="!canUndo"
        :size="18"
        @click="undoFunc"
      />
      <icon-button
        name="editor_redo"
        :tip="`Redo(${keyCode}+Shift+Z)`"
        :tip-delay="0"
        :disabled="!canRedo"
        :size="18"
        @click="redoFunc"
      />
      <icon-button
        name="editor_split"
        :tip="`Split(${keyCode}+B)`"
        :tip-delay="0"
        :disabled="!canSplit"
        :size="18"
        @click="splitFunc"
      />
      <icon-button
        name="editor_delete_left"
        tip="Delete left(Q)"
        :tip-delay="0"
        :disabled="!canSplit"
        :size="18"
        @click="deleteLeftFunc"
      />
      <icon-button
        name="editor_delete_right"
        tip="Delete right(W)"
        :tip-delay="0"
        :disabled="!canSplit"
        :size="18"
        @click="deleteRightFunc"
      />
      <icon-button
        v-show="canRemove"
        name="editor_delete"
        tip="Delete(⌫)"
        :tip-delay="0"
        :size="18"
        @click="removeFunc"
      />
      <icon-button
        v-show="canMirror"
        name="editor_flip_x"
        tip="Mirror"
        :tip-delay="0"
        :size="18"
        @click="handleMirror"
      />
      <icon-button
        v-show="canRotate"
        name="editor_crop_rotate"
        tip="rotate"
        :tip-delay="0"
        :size="18"
        @click="handleRotate"
      />
      <icon-button
        v-show="isImage"
        name="editor_crop"
        tip="Crop"
        :tip-delay="0"
        :size="18"
        @click="showCropper = true"
      />
    </div>
    <div class="timeline-tools-center">
      <svg-icon
        clickable
        v-show="playing"
        name="editor_pause"
        :size="24"
        @click="pause"
      />
      <svg-icon
        clickable
        v-show="!playing"
        name="editor_play"
        color="#1C1B1E"
        :disabled="!canPlay"
        :size="24"
        @click="play"
      />
      <span class="time" :class="{ disabled: !canPlay }">
        <span class="current-time">{{ frameToHmss(displayFrame) }}</span>
        <span class="duration">{{ ` / ${frameToHmss(totalFrame)}` }}</span>
      </span>
    </div>
    <div class="timeline-tools-right">
      <icon-button
        name="editor_magnet"
        :tip="`Turn ${magnet ? 'off' : 'on'} main track magnet(P)`"
        :tip-delay="0"
        :color="magnet ? '#6741FF' : '#1C1B1E'"
        :active="magnet"
        :disabled="!canPlay"
        :size="18"
        @click="toggleMagnet"
      />
      <icon-button
        name="editor_auto_snap"
        :tip="`Turn ${autoSnap ? 'off' : 'on'} auto snapping(N)`"
        :tip-delay="0"
        :color="autoSnap ? '#6741FF' : '#1C1B1E'"
        :active="autoSnap"
        :disabled="!canPlay"
        :size="18"
        @click="toggleAutoSnap"
      />
      <icon-button
        name="editor_preview"
        :tip="`Turn ${previewAxis ? 'off' : 'on'} preview axis(S)`"
        :tip-delay="0"
        :color="previewAxis ? '#6741FF' : '#1C1B1E'"
        :active="previewAxis"
        :disabled="!canPlay"
        :size="18"
        @click="togglePreviewAxis"
      />
      <icon-button
        name="editor_timeline_fit"
        tip="Zoom to fit timeline(Shift+Z)"
        :tip-delay="0"
        :disabled="!canPlay"
        :size="18"
        @click="scaleTofit"
      />
      <div class="timeline-scale-group">
        <icon-button
          name="editor_scale_down"
          :tip="`Zoom out(${keyCode}+-)`"
          :disabled="!canScaleDown"
          :size="18"
          @click="scaleDown(10)"
        />
        <bv-slider
          :model-value="timeline.scale"
          :disabled="!canPlay"
          :min="timeline.minScale"
          :max="timeline.maxScale"
          :step="1"
          :show-tooltip="false"
          @update:model-value="scaleTimeline"
        />
        <icon-button
          name="editor_scale_up"
          :tip="`Zoom in(${keyCode}++)`"
          :disabled="!canScaleUp"
          :size="18"
          @click="scaleUp(10)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  useCreatorStore,
  useHistoryStore,
  useDraftStore,
  useCopyStore,
  useKeyboard,
} from "../../stores";
import { clamp, frameToHmss } from "../../utils";
import TimelineMode from "./timeline-mode.vue";

const {
  primaryTrack,
  showCropper,
  magnet,
  autoSnap,
  previewAxis,
  canPlay,
  activeNodeMap,
  displayFrame,
  currentFrame,
  playing,
  timeline,
  totalFrame,
  splitNode,
  deleteLeft,
  deleteRight,
  removeActiveNodes,
  play,
  pause,
  seekTo,
  frameToWidth,
  adsorb,
  refresh,
} = useCreatorStore();

const { canUndo, canRedo, undo, redo, commit } = useHistoryStore();
const {
  canCut,
  canPaste,
  canPasteKeyframe,
  copy,
  paste,
  cut,
  duplicate,
  pasteKeyframe,
} = useCopyStore();
const { updateDraft } = useDraftStore();
const { keyCode, pressed } = useKeyboard();
const timelineRef = inject("timeline");
const imageNode = computed(() => {
  if (activeNodeMap.size === 1) {
    for (const node of activeNodeMap.values()) {
      if (["image", "video"].includes(node.type)) {
        return node;
      }
      return null;
    }
  }
  return null;
});
const canSplit = computed(() => {
  for (const node of [
    ...activeNodeMap.values(),
    ...(primaryTrack.value?.children || []),
  ]) {
    if (node.type === "transition") {
      continue;
    }
    if (
      node.startFrame < currentFrame.value &&
      currentFrame.value < node.endFrame
    ) {
      return true;
    }
  }
  return false;
});
const canMirror = computed(() => {
  if (activeNodeMap.size <= 0) {
    return false;
  }
  return Array.from(activeNodeMap.values()).every((node) =>
    ["image", "video", "text", "sticker", "effect"].includes(node.type)
  );
});
const canRotate = computed(() => {
  if (activeNodeMap.size <= 0) {
    return false;
  }
  return Array.from(activeNodeMap.values()).every((node) =>
    ["image", "video", "text", "sticker", "effect", "graphic"].includes(
      node.type
    )
  );
});
const isImage = computed(() => Boolean(imageNode.value));
const canRemove = computed(() => activeNodeMap.size > 0);
const canScaleDown = computed(
  () => canPlay.value && timeline.scale > timeline.minScale
);
const canScaleUp = computed(
  () => canPlay.value && timeline.scale < timeline.maxScale
);

onMounted(() => {
  window.addEventListener("keydown", keyDown);
  window.addEventListener("wheel", wheel, { passive: false });
  document.addEventListener("visibilitychange", visibilityChange);
});
onUnmounted(() => {
  window.removeEventListener("keydown", keyDown);
  window.removeEventListener("wheel", wheel);
  document.removeEventListener("visibilitychange", visibilityChange);
});

function submit() {
  commit();
  updateDraft();
}

function visibilityChange() {
  if (document.visibilityState === "hidden") {
    pause();
  }
}

function keyDown(e) {
  if (["TEXTAREA", "INPUT"].includes(e.target.tagName)) {
    return;
  }
  if (canPlay.value) {
    switch (e.code) {
      case "Space":
        playing.value ? pause() : play();
        break;
      case "KeyQ":
        canSplit.value && deleteLeftFunc();
        break;
      case "KeyW":
        canSplit.value && deleteRightFunc();
        break;
      case "ArrowLeft":
        seekTo(currentFrame.value - 1);
        e.preventDefault();
        break;
      case "ArrowRight":
        seekTo(currentFrame.value + 1);
        e.preventDefault();
        break;
      case "KeyA":
        timeline.mode = "select";
        break;
      case "KeyB":
        timeline.mode = "split";
        break;
      case "KeyP":
        toggleMagnet();
        break;
      case "KeyN":
        toggleAutoSnap();
        break;
      case "KeyS":
        togglePreviewAxis();
        break;
      case "KeyZ":
        e.shiftKey && scaleTofit();
        break;
    }
  }
  if (pressed.value) {
    switch (e.code) {
      case "KeyZ":
        e.shiftKey ? canRedo.value && redoFunc() : canUndo.value && undoFunc();
        e.preventDefault();
        break;
      case "KeyB":
        canSplit.value && splitFunc();
        e.preventDefault();
        break;
      case "Minus":
        canScaleDown.value && scaleDown(1);
        e.preventDefault();
        break;
      case "Equal":
        canScaleUp.value && scaleUp(1);
        e.preventDefault();
        break;
      case "KeyC":
        copy();
        break;
      case "KeyX":
        canCut.value && cutFunc();
        break;
      case "KeyV":
        pasteFunc();
        break;
      case "KeyD":
        duplicateFunc();
        e.preventDefault();
        break;
    }
  }
}

function wheel(e) {
  if (e.ctrlKey) {
    e.preventDefault();
  }
}

function undoFunc() {
  undo();
  updateDraft();
}

function redoFunc() {
  redo();
  updateDraft();
}

function splitFunc() {
  splitNode();
  submit();
}

function deleteLeftFunc() {
  deleteLeft();
  submit();
}

function deleteRightFunc() {
  deleteRight();
  submit();
}

function handleMirror() {
  for (const node of activeNodeMap.values()) {
    const [flipX, flipY] = node.getFlip();
    node.conf.flip = [-flipX, flipY];
  }
  submit();
}

function handleRotate() {
  for (const node of activeNodeMap.values()) {
    const rotation = node.getRotation();
    node.conf.rotate = rotation - Math.PI / 2;
  }
  submit();
}

function removeFunc() {
  removeActiveNodes();
  submit();
}

function scaleTimeline(value) {
  timeline.scale = value;
  timeline.autoFit = false;
}

function toggleMagnet() {
  magnet.value = !magnet.value;

  if (magnet.value) {
    adsorb();
    nextTick(refresh);
    submit();
  }
}

function toggleAutoSnap() {
  autoSnap.value = !autoSnap.value;
}

function togglePreviewAxis() {
  previewAxis.value = !previewAxis.value;
}

function scaleTofit() {
  const newScale = Math.round(
    frameToWidth(timelineRef.value.clientWidth) /
      frameToWidth(totalFrame.value * 1.2) /
      timeline.baseline
  );
  timeline.scale = clamp(newScale, timeline.minScale, timeline.maxScale);
}

function scaleDown(value) {
  timeline.scale = Math.max(timeline.minScale, timeline.scale - value);
  timeline.autoFit = false;
}

function scaleUp(value) {
  timeline.scale = Math.min(timeline.maxScale, timeline.scale + value);
  timeline.autoFit = false;
}

function cutFunc() {
  cut();
  submit();
}

function pasteFunc() {
  if (canPaste.value) {
    paste();
    submit();
  }
  if (canPasteKeyframe.value) {
    pasteKeyframe();
    submit();
  }
}

function duplicateFunc() {
  duplicate();
  submit();
}
</script>
<style scoped>
.timeline-tools {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e8e9ec;
  border-bottom: 1px solid #e8e9ec;
  padding: 5px 20px;
  background-color: #ffffff;
  position: relative;
}

.timeline-tools-left {
  flex: 1 1;
  display: flex;
  align-items: center;
  position: relative;
}

.timeline-tools-center {
  display: flex;
  align-items: center;
}

.timeline-tools-right {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.timeline-tools-left .icon-button {
  margin-right: 24px;
}

.timeline-tools-center svg {
  margin-right: 14px;
}

.timeline-tools-center .time {
  font-size: 14px;
  height: 22px;
}

.timeline-tools-center .time.disabled .current-time,
.timeline-tools-center .time.disabled .duration {
  color: #bbbfc4;
}

.timeline-tools-center .time .current-time {
  color: #000000;
  line-height: 22px;
  font-variant: tabular-nums;
}

.timeline-tools-center .time .duration {
  color: #646a73;
  line-height: 22px;
}

.timeline-tools-right > .icon-button {
  margin-right: 16px;
}

.timeline-scale-group {
  height: 100%;
  display: flex;
  align-items: center;
}

:deep(.timeline-scale-group .el-slider) {
  width: 94px;
  margin-left: 12px;
  margin-right: 12px;
}
</style>
