import { useScriptStore } from "./script";
import { getFontFamilyByLabel } from "@/constants/text";
import { presetTypes, defaultStyle } from "../constants/text";

const defaultSetting = {
  defBgmConf: {
    name: "",
    src: "",
    duration: 0,
    ss: 0,
  },
  bgmName: "",
  bgmVol: 0.08,
  speechVol: 1,
  posY: 0,
  presetId: "default",
  fontSetting: {
    fontFamily: "Inter",
    fontSize: 33,
    fill: "#FFFFFF",
    stroke: "#000000",
    opacity: 1,
    background: "",
    animation: null,
    highlight: "#FAFF00",
  },
};

const setting = ref(null);

export const useSettingStore = () => {
  const { getTrack } = useScriptStore();

  const initGlobalSetting = (config) => {
    const scene = getTrack("video").children[0];
    const subtitle = getTrack("subtitle").children[0];

    if (scene.conf.backgroundColor) {
      defaultSetting.backgroundColor = scene.conf.backgroundColor;
    }
    if (scene.conf.backgroundBlur) {
      defaultSetting.backgroundBlur = scene.conf.backgroundBlur;
    }
    defaultSetting.bgmName = config.defBgmConf.name;
    defaultSetting.posY = subtitle.conf.y || 100;
    defaultSetting.fontSetting.animation = subtitle.conf.animation || null;
    defaultSetting.fontSetting.highlight = subtitle.conf.highlight || "#FAFF00";
    setting.value = {
      ...defaultSetting,
      ...config,
      fontSetting: { ...defaultSetting.fontSetting, ...config.fontSetting },
    };
  };

  const getGlobalFontSetting = () => {
    const { fontFamily } = setting.value.fontSetting;
    return {
      ...defaultStyle,
      ...presetTypes[setting.value.presetId],
      ...setting.value.fontSetting,
      fontFamily: Array.isArray(fontFamily) ? fontFamily : getFontFamilyByLabel(fontFamily),
    };
  };

  return {
    setting,
    getGlobalFontSetting,
    initGlobalSetting,
  };
};
