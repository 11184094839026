<script setup>
import { useCreatorStore } from "../../stores";
import MediaMaterial from "./meidamaterial.vue";
import LibraryMaterial from "./librarymaterial.vue";
import TextMaterial from "./textmaterial.vue";
import GraphicMaterial from "./graphicmaterial.vue";
import EffectMaterial from "./effectmaterial.vue";
import StickerMaterial from "./stickermaterial.vue";
import FilterMaterial from "./filtermaterial.vue";
import AudioMaterial from "./audiomaterial.vue";
import BrandKitMaterial from "./brandkitmaterial.vue";
import VoiceoverMaterial from "./voiceovermaterial.vue";
import TransitionMaterial from "./transitionmaterial.vue";
import { clamp } from "../../utils";

const materials = {
  media: MediaMaterial,
  library: LibraryMaterial,
  audio: AudioMaterial,
  text: TextMaterial,
  graphic: GraphicMaterial,
  effect: EffectMaterial,
  sticker: StickerMaterial,
  filter: FilterMaterial,
  brandKit: BrandKitMaterial,
  voiceover: VoiceoverMaterial,
  transition: TransitionMaterial,
};

const { creator, empty, materialTab, showMaterial } = useCreatorStore();
const route = useRoute();
const active = ref(false);
const width = ref(263);
const beforePosition = reactive({ pageX: 0, width: 0 });
const style = computed(() => ({
  width: `${width.value}px`,
}));

onBeforeMount(resetPosition);

onMounted(() => {
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);
});
onBeforeUnmount(() => {
  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);
});

function mouseMove(e) {
  if (active.value) {
    resize(e);
  }
}

function handlerDown(e) {
  active.value = true;

  savePosition(e);
}

function mouseUp() {
  active.value = false;

  resetPosition();
}

function resize(e) {
  const deltaX = beforePosition.pageX - e.pageX;
  const newWidth = clamp(beforePosition.width - deltaX, 230, 532);

  width.value = newWidth;
}

function savePosition(e) {
  beforePosition.pageX = e.pageX;
  beforePosition.width = width.value;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.width = 0;
}

watch(
  creator,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      showMaterial.value = empty.value;

      if (route.query.mat) {
        showMaterial.value = true;
        materialTab.value = route.query.mat;
      }
    }
  },
  { flush: "post" }
);

provide("materialWidth", width);
</script>
<template>
  <div class="material-main" v-show="showMaterial" :style="style">
    <keep-alive>
      <component :is="materials[materialTab]" />
    </keep-alive>
    <div class="handler" :class="{ active }" @mousedown="handlerDown"></div>
  </div>
</template>
<style scoped>
.material-main {
  position: relative;
  height: 100%;
  width: 263px;
  background-color: #ffffff;
  border-right: 1px solid #e8e9ec;
}
:deep(.material-content) {
  padding: 0 16px 150px;
}
.handler {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 3px;
  z-index: 1;
}
.handler:before {
  height: 100%;
  content: "";
  position: absolute;
  width: calc(100% + 6px);
  transform: translateX(-3px);
  cursor: row-resize;
}
.handler.active {
  background-color: #875eff;
}
</style>
