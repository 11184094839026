<template>
  <base-layout>
    <div class="ont-Poppins">
      <Header></Header>
      <div
        class="home-container"
        ref="containerRef"
        :style="{
          transformOrigin: 'top left',
          transform: `scale(${viewScale})`,
          paddingTop: `${(60 / viewScale) | 0}px`,
        }"
      >
        <main class="main-content">
          <router-view></router-view>
        </main>
        <Footer></Footer>
      </div>
    </div>
  </base-layout>
</template>

<script setup lang="ts">
import BaseLayout from "./base-layout.vue";
import { Header, Footer } from "./components/home";
import { useWindowSize } from "@vueuse/core";
import { useViewStore } from "@/store/view";
import { debounce } from "@/utils/debounce";

const containerRef = ref(null as unknown as HTMLElement);
const viewScale = ref(1);
const isPE = /mobile|android|iphone|ios/i.test(navigator.userAgent);
const viewStore = useViewStore();

if (isPE) {
  viewStore.setMinViewWidth("1440px");
} else {
  watch([useWindowSize().width], () => {
    viewScale.value = calcViewScale();
  });
}

const updateResizeStyle = debounce(() => {
  const scale = viewScale.value;
  const layoutElement = containerRef.value.parentElement!;

  Object.assign(
    layoutElement.style,
    scale === 1
      ? {
          width: null,
          height: null,
        }
      : {
          width: `${(containerRef.value!.clientWidth * scale) | 0}px`,
          height: `${(containerRef.value!.clientHeight * scale) | 0}px`,
        }
  );
}, 100);

watch([containerRef, viewScale], updateResizeStyle);

function calcViewScale() {
  const containerWidth = isPE ? window.innerWidth : document.body.clientWidth;
  if (containerWidth >= 1440) return 1;

  return Number((((containerWidth / 1440) * 1000) | 0) / 1000);
}

onMounted(() => {
  if (!isPE) {
    viewScale.value = calcViewScale();
  }

  const prevTheme = viewStore.header.theme;
  viewStore.header.theme = "light";

  onBeforeUnmount(() => {
    viewStore.header.theme = prevTheme;
  });
});
</script>

<style>
@font-face {
  font-family: "Poppins";
  src: url(/fonts/Poppins-300.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url(/fonts/Poppins-400.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url(/fonts/Poppins-500.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url(/fonts/Poppins-600.ttf) format("truetype");
  font-weight: 600;
}

.home-container {
  --view-width: 1250px;

  min-width: 1440px;
}
</style>
