import { defineStore } from "pinia";
import { safeDeepAssign } from "@/utils/util";

let isScaled = false;
const DEFAULT_VIEWPORT_WIDTH = "390px";
const viewportMeta = document.querySelector("meta[name=viewport]")!;

// 兼容原因，手动实现
class CSSUnitValue {
  static SUPPORT_UNIT = ["px"];
  value: number;
  unit: string;

  constructor(value: number, unit: string) {
    if (!CSSUnitValue.SUPPORT_UNIT.includes(unit)) {
      throw new Error(`${unit} is not supported`);
    }

    this.value = value;
    this.unit = unit;
  }

  to(value: "px") {
    return this;
  }

  toString() {
    return `${this.value}${this.unit}`;
  }
}

function toUnitValue(str: string) {
  if (str === "device-width") {
    return new CSSUnitValue(document.body.clientWidth, "px");
  }

  const match = str.match(/^(\d*\.\d+|\d+)([a-z]+)$/);
  if (!match || CSSUnitValue.SUPPORT_UNIT.indexOf(match[2]) === -1) {
    return;
  }

  // CSSUnitValue是Web API中的一个接口，用于表示CSS中的数字和单位。它允许你以编程方式操作和计算CSS值。
  return new CSSUnitValue(+match[1], match[2]).to("px");
}

function setViewportWidth(width: string, isForce = false) {
  let deviceWidth = "device-width";
  let scaleValue = 1;

  // visualViewport是Web API中的一个接口，提供了有关视口（viewport）的信息和控制。
  calc: {
    const unitValue = toUnitValue(width);
    if (!visualViewport) {
      return;
    } else if (!unitValue) {
      break calc;
    }

    const actualViewportWidth = visualViewport.width * visualViewport.scale;
    const flag = actualViewportWidth <= unitValue.value;
    const temp = isScaled;

    isScaled = flag;

    if (!isForce && flag === temp) {
      return;
    }

    if (flag) {
      deviceWidth = unitValue.toString();
      scaleValue = actualViewportWidth / unitValue.value;
    }
  }

  viewportMeta.setAttribute(
    "content",
    `width=${deviceWidth},initial-scale=${scaleValue.toFixed(
      5,
    )},minimum-scale=0.1`,
  );
}

function handleWindowResize() {
  const viewStore = useViewStore();

  setViewportWidth(viewStore.minViewWidth);
}

window.addEventListener("resize", handleWindowResize);

export const useViewStore = defineStore("view", {
  state: () => {
    return {
      pageContainer: null as HTMLElement | null,
      minViewWidth: DEFAULT_VIEWPORT_WIDTH,
      header: {
        disabled: false,
        theme: "normal",
      },
    };
  },

  actions: {
    reset() {
      this.$reset();
    },

    changeState({ minViewWidth = "", ...otherState }) {
      this.reset();

      if (minViewWidth !== "") {
        this.setMinViewWidth(minViewWidth);
      }

      safeDeepAssign(this, otherState);
    },

    setMinViewWidth(width: string) {
      setViewportWidth(width, true);
      this.minViewWidth = width;
    },
  },
});
