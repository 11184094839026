<template>
  <List></List>
</template>

<script setup>
import List from "./components/list/list.vue";
import { useTrackStore } from '@/store/modules/track';
const {  track } = useTrackStore();

track('similarvideo_page_avatar_view');

</script>

<style lang="scss" scoped>

</style>