<template>
  <section class="mobile-use-case-part">
    <h2>Engaging use case</h2>
    <div>
      <ul
        class="type-list"
        v-if="!(route.name in routeMap)"
        @click="handleClickNav"
      >
        <li
          v-for="item of previewList"
          :key="item.key"
          :data-key="item.key"
          :class="{ active: currentKey === item.key }"
        >
          <h3>{{ item.name }}</h3>
        </li>
      </ul>
      <div class="px-5">
        <carousel :list="currentItem.assets"/>
      </div>
    </div>
  </section>
</template>

<script setup>
import previewList from './data';
import Carousel from './carousel.vue';
import { MutexSpace } from '@/utils/type';
import { useRoute } from 'vue-router';
import { routeMap } from './data';

const route = useRoute();
const mutexSpace = new MutexSpace();
const currentKey = ref(routeMap[route.name] ?? previewList[0].key);
const currentItem = computed(() =>
  previewList.find((item) => item.key === currentKey.value)
);

const handleClickNav = (e) => {
  const target = e.target.closest('li[data-key]');

  if (target === null) return;
  currentKey.value = target.dataset.key;
};


provide('mutexSpace', mutexSpace);
</script>

<style scoped lang="scss">
.mobile-use-case-part {
  margin-top: 60px;
  padding: 0px 16px;

  & > h2 {
    color: #060606;
    text-align: center;
    font-family: Inter-variant;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.type-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 11px 10px;
  margin-top: 42px;

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7.006px;
    border: 0.584px solid #e5e7eb;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
    color: #060606;
    padding: 21px 0px;
    &.active {
      color: #6741ff;
      border-radius: 7.006px;
      border: 0.584px solid #875eff;
      background: #f8f5ff;
    }
  }
  h3 {
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.013px;
  }
}

.case-list {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 108px;

  & > li {
    border: 1px solid #e5e7eb;
    background-color: #e5e7eb;
    border-radius: 12px;
    overflow: hidden;
    height: 525px;
    flex: 1;

    & > div {
      height: 100%;
    }
  }
}
</style>