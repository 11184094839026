<template>
  <section class="comment-quote-part">
    <h2>So much love in the air</h2>
    <ul ref="listRef" class="comment-list">
      <li v-for="list of renderList">
        <Comment v-for="item of list" :key="item.key" :quote="item" />
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import quoteList, { type CommentQuote } from './data';
import Comment from './comment.vue';
import { ref } from 'vue';

const listRef = ref(null as unknown as HTMLElement);
const renderList = reactive(Array.from({length: 4}).map(() => [] as CommentQuote[]));

const getMinHeightElementIndex = () => {
  const children = listRef.value?.children;
  const min = {
    index: 0,
    height: Infinity,
  };

  for (let i = 0; i < children?.length; i++) {
    const element = children[i] as HTMLElement;

    if (element.offsetHeight < min.height) {
      min.index = i;
      min.height = element.offsetHeight;
    }
  }

  return min.index;
};

onMounted(async () => {
  for (const item of quoteList) {
    const index = getMinHeightElementIndex();
    renderList[index].push(item);
    await nextTick();
  }
});
</script>

<style scoped lang="scss">
.comment-quote-part {
  padding: 130px calc((100% - var(--view-width)) / 2) 190px;
  background: linear-gradient(180deg, #FFEFC5, rgba(246, 250, 255, 0.00));
  overflow: hidden;

  & > h2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;
    font-size: 60px;
    font-weight: 700;
    z-index: 1;

    &::before {
      content: '';
      display: inline-block;
      width: 57px;
      height: 51px;
      background: url(./assets/love.png) no-repeat center / contain;
    }
  }
}

.comment-list {
  position: relative;
  display: flex;
  gap: 22px;
  margin-top: 170px;

  & > li {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 22px;
    flex: 1;
    height: fit-content;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 595px;
    transform: translate3d(-10%, -70%, 0);
    background: url(./assets/floating_top.png) no-repeat center / contain;
    aspect-ratio: 1800 / 595;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 712px;
    transform: translateY(50%);
    background: url(./assets/floating_bottom.png) no-repeat center / contain;
    transform: translate3d(-16%, 80%, 0);
    aspect-ratio: 1700 / 712;
  }
}
</style>