<template>
  <div
    class="dialog-close-icon hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
    @click="handleClose">
    <svg-icon name="icon_close" :size="20" color="#646A73"></svg-icon>
  </div>
</template>

<script setup>
const emit = defineEmits(['close'])
const handleClose = () => {
  emit('close')
}
</script>

<style lang="scss">

.dialog-close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
</style>