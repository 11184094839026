<template>
  <Teleport to="body">
    <div
      v-show="segmentMenu.visible"
      v-click-outside:[element]="hide"
      class="segment-menu"
      ref="element"
      :style="style"
    >
      <div
        v-for="(option, i) in options"
        class="menu-item"
        :class="{ disabled: option.disabled }"
        :key="i"
        @click="handleClick(option.onClick)"
      >
        <div class="item-left">
          <svg-icon
            class="prefix"
            :disabled="option.disabled"
            :name="option.icon"
            :size="18"
          />
          {{ option.label }}
        </div>
        <div v-if="option.description" class="description">
          <div v-if="option.prefix" class="left-text">{{ keyCode + "+" }}</div>
          <div class="right-text">{{ option.description }}</div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { useTrackStore } from "@/store/modules/track";
import {
  useCreatorStore,
  useCopyStore,
  useDraftStore,
  useHistoryStore,
  useKeyboard,
} from "../../stores";

const {
  replaceData,
  showCropper,
  showMaterialDialog,
  showMaterial,
  widthToFrame,
  materialTab,
  voiceoverText,
  segmentMenu,
  currentNode,
  removeActiveNodes,
  splitNode,
} = useCreatorStore();
const { collectData, track } = useTrackStore();
const { canPaste, copy, cut, paste, duplicate } = useCopyStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { keyCode } = useKeyboard();

const globalDomain = inject("globalDomain");
const element = ref(null);
const style = computed(() => ({
  left: `${segmentMenu.left}px`,
  top: `${segmentMenu.top}px`,
}));
const currentFrame = computed(() => {
  const element = document.querySelector(".track-list");

  if (!element) {
    return 0;
  }
  const bounding = element.getBoundingClientRect();
  return widthToFrame(segmentMenu.left - bounding.x);
});
const canSplit = computed(() => {
  const node = currentNode.value;
  if (
    node.startFrame < currentFrame.value &&
    currentFrame.value < node.endFrame
  ) {
    return true;
  }
  return false;
});
const options = computed(() => {
  if (!currentNode.value) {
    return [
      {
        label: "Paste",
        icon: "icon_paste",
        prefix: true,
        description: "V",
        disabled: !canPaste.value,
        onClick: () => {
          canPaste.value && paste();
        },
      },
    ];
  }
  const baseMenu = [
    {
      label: "Copy",
      icon: "icon_copy",
      prefix: true,
      description: "C",
      onClick: copy,
    },
    {
      label: "Cut",
      icon: "icon_cut",
      prefix: true,
      description: "X",
      onClick: () => {
        cut();
        submit();
      },
    },
    {
      label: "Paste",
      icon: "icon_paste",
      prefix: true,
      description: "V",
      disabled: !canPaste.value,
      onClick: () => {
        if (!canPaste.value) {
          return;
        }
        paste();
        submit();
      },
    },
    {
      label: "Duplicate",
      icon: "editor_duplicate",
      prefix: true,
      description: "D",
      onClick: () => {
        duplicate();
        submit();
      },
    },
    {
      label: "Delete",
      icon: "icon_delete",
      description: "⌫",
      onClick: () => {
        removeActiveNodes();
        submit();
      },
    },
  ];
  if (currentNode.value.type !== "transition") {
    baseMenu.unshift({
      label: "Split",
      icon: "editor_split",
      prefix: true,
      description: "B",
      disabled: !canSplit.value,
      onClick: () => {
        if (!canSplit.value) {
          return;
        }
        splitNode(currentFrame.value);
      },
    });
  }
  switch (currentNode.value.type) {
    case "image":
    case "video":
      baseMenu.push(
        {
          label: "Replace",
          icon: "icon_replace",
          onClick: () => {
            replaceData.node = currentNode.value;
            showMaterialDialog.value = true;

            collectData("boolvideo_right_replace");
            track("boolvideo_right_replace");
          },
        },
        {
          label: "Crop",
          icon: "editor_crop",
          onClick: () => {
            showCropper.value = true;
          },
        }
      );
      break;
    case "text":
    case "subtitle":
      if (globalDomain === 1) {
        baseMenu.push([
          {
            label: "Text to voice",
            icon: "icon_voiceover",
            onClick: () => {
              showMaterial.value = true;
              materialTab.value = "voiceover";
              voiceoverText.value = props.node.conf.text;
            },
          },
        ]);
      }
      break;
  }
  return baseMenu;
});

watch(
  () => segmentMenu.visible,
  (value) => {
    if (value) {
      setTimeout(() => {
        const { left, top, width, height } =
          element.value.getBoundingClientRect();

        if (left + width > window.innerWidth) {
          segmentMenu.left -= width;
        }
        if (top + height > window.innerHeight) {
          segmentMenu.top -= height;
        }
      });
    }
  },
  { flush: "post" }
);

function submit() {
  commit();
  updateDraft();
}

function hide() {
  segmentMenu.visible = false;
}

function handleClick(callback) {
  callback();
  setTimeout(() => hide());
}
</script>
<style>
.segment-menu {
  position: fixed;
  width: 200px;
  z-index: 1000;
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
}
.segment-menu .menu-item {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px;
  color: #060606;
  font-size: 14px;
  line-height: 22px;
  user-select: none;
  cursor: pointer;
}
.segment-menu .menu-item:hover {
  background-color: rgba(31, 35, 41, 0.08);
}
.segment-menu .menu-item.disabled {
  color: #bbbfc4;
}
.segment-menu .menu-item.disabled:hover {
  background: none;
}

.segment-menu .prefix {
  display: inline;
  margin-right: 12px;
}
.segment-menu .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8f959e;
}
.segment-menu .right-text {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
