<template>
  <!-- pricing FAQ -->
  <div class="faq-container">
    <!-- faq标题 -->
    <p class="faq-title">Frequently asked questions</p>
    <el-collapse v-model="activeFaqNames">
      <el-collapse-item :name="index + 1" v-for="(item, index) in faqList">
        <template #title>
          <div class="faq-item-title">
            <p>{{ item.title }}</p>
          </div>
        </template>
        <div class="faq-item-content">
          <p>{{ item.content }}</p>
        </div>
      </el-collapse-item>
      <el-collapse-item>
        <template #title>
          <div class="faq-item-title">
            <p>Can I cancel my subscription plan?</p>
          </div>
        </template>
        <div class="faq-item-content">
          <p>You can cancel the monthly subscription at any time by yourself. Please follow these steps:</p>
          <p>1. Log into your account and click "Manage My Plan"</p>
          <p>2. Click the "Cancel Subscription" button</p>
          <p>3. Complete the questionnaire and submit it</p>
          <p>4. Click "Cancel Plan"</p>
          <p>5. On the final "Subscription Cancelled" page, your subscription will be officially cancelled</p>
          <p>Please note that <strong>self-cancellation is the only way to cancel.</strong> We <strong>don't allow any other way</strong> (e.g. emailing us) to unsubscribe. For more information, please visit this article: 
            <a href="https://help.boolv.video/en/articles/1-how-to-cancel-my-subscription">How to cancel my subscription</a>
          </p>
        </div>
      </el-collapse-item>
      <el-collapse-item>
        <template #title>
          <div class="faq-item-title">
            <p>How to activate the coupon code?</p>
          </div>
        </template>
        <div class="faq-item-content">
          <p>1. <strong>Using the Coupon Code for the First Time:</strong></p>
          <p>Copy the [Coupon Code] following "with code".Proceed to the subscription payment page. Enter the coupon code in the designated field and click submit. Please note, the <strong>coupon code</strong> can only be used once and <strong>must be submitted. Failure to submit will result in the coupon not being applied.</strong></p>
          <p>2. <strong>Confirmation on the Payment Page:</strong></p>
          <p>Upon submission, the payment page will display the total amount due after the discount.</p>
          <p>3. <strong>Handling Forgotten Coupon Codes:</strong></p>
          <p>If you forget to use the coupon code, please contact our customer service team. We will provide you with a discount coupon, which will automatically apply a discount on your next month's billing date and you don't need to do anything else. Please be aware that you cannot upgrade or downgrade before using the coupon, as it will render the coupon invalid.</p>
          <p>For more information, please visit this article:
            <a href="https://help.boolv.video/en/articles/8-how-to-activate-the-coupon-code">How to activate the coupon code?</a>
          </p>
        </div>
      </el-collapse-item>
      <el-collapse-item>
        <template #title>
          <div class="faq-item-title">
            <p>How do I request a refund?</p>
          </div>
        </template>
        <div class="faq-item-content">
          <p v-show="subscriptionState.supportRefundBoolean">
            Your subscription is covered by a 7-day money-back guarantee. If you
            are not satisfied with your purchase, you can click
            <a @click="handleRefund">here</a> to request a refund.
          </p>
          <p v-show="!subscriptionState.supportRefundBoolean">
            Usually, we don't process refunds, since we offer free trial for all
            users. However, if you are not 100% happy, contact us! As a startup,
            we will try our best to deliver delightful experiences for our
            customers :)
          </p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <refund-modal v-model="showRefundModal" />
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";
import { faqList } from "../../constants";
import { useSubscriptionInfo } from "@/store/modules/user";
import RefundModal from "../modal/refundmodal.vue";

const props = defineProps({
  canCancel: Boolean,
  userOperationMap: {
    type: Object,
    required: true,
  },
});

const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const activeFaqNames = ref(["1"]);
const showRefundModal = ref(false);

const handleRefund = () => {
  showRefundModal.value = true;
};

const handleCancel = () => {
  props.userOperationMap.subscribing({}, 1);
}
</script>

<style lang="scss" scoped>
.faq-container {
  max-width: 782px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 449px;
  padding: 0 1.5rem;
  user-select: none;

  :deep(.el-collapse) {
    margin-top: 64px;
    border-top: none;
  }

  :deep(.el-collapse-item) {
    padding-bottom: 36px;
    padding-top: 36px;
    border-bottom: 1px solid #ebeef5;
  }

  :deep(.el-collapse-item__header) {
    height: fit-content;
    border: none;
  }

  :deep(.el-collapse-item__content) {
    padding: 0;
    padding-top: 24px;
  }

  :deep(.el-collapse-item__wrap) {
    border: none;
  }
}

.faq-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 51px;
  text-align: center;
  color: #060606;
}

.faq-item-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.faq-item-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8f959e;
  white-space: pre-wrap;
  font-variant-numeric: tabular-nums;
  
  & a {
    cursor: pointer;
    color: #875eff;

    &:hover {
      color: #a987ff;
    }
  }
}

@media screen and (min-width: 820px) {
  .faq-container {
    padding: 0 2.25rem;
  }
}
</style>