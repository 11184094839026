<template>
  <el-popover 
    popper-class="industry-select-popper"
    :width="500"
    :visible="visible" 
    :show-arrow="false"
    :teleported="false"
    placement="bottom-start"
  >
    <div 
      ref="popperRef"
      class="popper-container"
    >
      <div  
        v-if="options.length > 0"
        class="industry-select-popper-wrapper"
      >
        <div class="industry-first-select-wrapper">
          <div class="seperate"></div>
          <el-scrollbar height="328">
            <ul>
              <li class="first-options-item" @click="selectFirstAll">
                <svg-icon 
                  class="prefix"
                  :name="firstSelectAllIcon" 
                  :size="16"/>
                <span>Select all</span>
              </li>
              <li 
                v-for="first in options" 
                class="first-options-item" 
                :class="{active: first == currentFirst}"
                @click="currentFirst = first"
              >
                <svg-icon 
                  class="prefix"
                  @click="clickFirstPrefix(first)"
                  :name="getSecondSelectAllIcon(first)" 
                  :size="16"
                />
                <span>{{ first.label }}</span>
                <svg-icon 
                  name="icon_arrow" 
                  color="#646A73"
                  :size="12"
                />
              </li>
            </ul>
          </el-scrollbar>
        </div>
        <div class="industry-second-select-wrapper">
          <el-scrollbar height="328">
            <ul>
              <li class="second-options-item">
                <svg-icon 
                  class="prefix"
                  @click="selectSecondAll"
                  :name="getSecondSelectAllIcon(currentFirst)" 
                  :size="16"
                />
                <span>Select all</span>
              </li>
              <li 
                v-for="second in currentFirst?.children" 
                class="second-options-item"
                @click="clickSecond(second)"
              >
                <svg-icon 
                  class="prefix"
                  v-show="currentSecond.find(i => i.value === second.value)" 
                  name="icon_sqaure_select" 
                  :size="16"/>
                <svg-icon 
                  class="prefix"
                  v-show="!currentSecond.find(i => i.value === second.value)" 
                  name="icon_square_unselect" 
                  :size="16"
                />
                <bv-tip
                  show-after="100"
                  placement="right"
                  :content="second.label"
                >
                  <span>{{ second.label }}</span>
                </bv-tip>
              </li>
            </ul>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <template #reference>
      <div
        class="industry-select-input-wrapper" 
        @click="visible = !visible"
        v-click-outside:[popperRef]="() => visible = false"
      >
        <div class="industry-select-input-inner">
          <input :value="displayValue" readonly placeholder="Industry">
          <svg-icon
            v-show="currentSecond.length > 0"
            name="select_clear"
            clickable
            :size="24"
            @click.stop="clear"
          />
          <svg-icon 
            class="icon-arrow"
            :class="{focused: visible}"
            name="icon_arrow" 
            color="#646A73"
            :size="12"
          />
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script setup>
const emit = defineEmits(["change"]);
const props = defineProps({
  options: {
    type: Array,
    default: [],
  },
});

const visible = ref(false);
const popperRef = ref(null);
const optionsTotal = ref(0);
const currentFirst = ref(null);
const currentSecond = ref([]);
const displayValue = computed(() => {
  if (currentSecond.value.length === 0){
    return "";
  }
  else {
    return currentSecond.value.reduce((a, c) => {
      return a.concat(c.label);
    }, "");
  }
});
const firstSelectAllIcon = computed(() => {
  if(currentSecond.value.length === 0) {
    return "icon_square_unselect";
  }
  else if(currentSecond.value.length === optionsTotal.value) {
    return "icon_sqaure_select";
  }
  else {
    return "icon_square_reselect";
  }
});

watch(
  () => props.options,
  (newOptions) => {
    if (newOptions.length > 0) {
      currentFirst.value = newOptions[0];
      optionsTotal.value = newOptions.reduce((a,c) => {
        return a + c.children.length
      }, 0)
    }
  },
  {
    immediate: true,
  }
);

watch(
  currentSecond,
  (newValue) => {
    emit("change", newValue.map(i => i.value));
  }
);

const getSecondSelectAllIcon = (item) => {
  if (!item) return "icon_square_unselect";
  const secondSelectSome = currentSecond.value.some(
    i => i.parentLabel === item.label
  );
  const secondSelectEvery = item.children.every(
    i => currentSecond.value.find(s => s.label === i.label)
  );

  if(!secondSelectSome) {
    return "icon_square_unselect"; 
  }
  else if(secondSelectEvery) {
    return "icon_sqaure_select";
  }
  else {
    return "icon_square_reselect";
  }
};

const clickSecond = (item) => {
  const isSelected = currentSecond.value.find(i => i.value === item.value);
  if (isSelected) {
    const index = currentSecond.value.findIndex(i => i.value === item.value);
    currentSecond.value = [
      ...currentSecond.value.slice(0, index), 
      ...currentSecond.value.slice(index + 1)
    ];
  }
  else {
    currentSecond.value = [
      ...currentSecond.value, 
      {
        ...item,
        parentLabel: currentFirst.value.label
      }
    ];
  }
};

const clickFirstPrefix = (item) => {
  const cancelSelect = currentSecond.value.some(i => i.parentLabel === item.label);
  if (cancelSelect) {
    currentSecond.value = currentSecond.value.filter(
      i => i.parentLabel !== item.label
    );
  }
  else {
    currentSecond.value = currentSecond.value.filter(
      i => i.parentLabel !== item.label
    );
    currentSecond.value = [ 
      ...currentSecond.value, 
      ...item.children.map(i => ({...i, parentLabel: item.label}))
    ];
  }
};

const selectSecondAll = () => {
  clickFirstPrefix(currentFirst.value);
};

const selectFirstAll = () => {
  if (currentSecond.value.length > 0) {
    currentSecond.value = [];
  }
  else {
    currentSecond.value = props.options.reduce((a,c) => {
      return a.concat(c.children.map(
        i => ({...i, parentLabel: c.label})
      ));
    }, []);
  }
};

const clear = () => {
  currentSecond.value = [];
};
</script>

<style lang="scss">
.industry-select-popper {
  &.el-popover.el-popper {
    padding: 8px 0 0 0;
    border-radius: 4px;
    border: 1px solid #E5E7EB;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
  }
}
</style>
<style lang="scss" scoped>
.popper-container {
  width: 100%;
  height: 100%;
}

.industry-select-popper-wrapper {
  position: relative;
  color: #646A73;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; 
  display: flex;
}

.seperate {
  position: absolute;
  top: 50%;
  left: calc(50% - 0.5px);
  transform: translate(-50%, -50%);
  width: 1px;
  height: 305px;
  background: #E5E7EB;
}

.industry-select-input-wrapper {
  width: 20%;
  padding: 12px 21px;
  border-radius: 6px;
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  cursor: pointer;
}

.industry-select-input-inner {
  width: 100%;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;

  & > input {
    flex: 1 1;
    min-width: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  & > input::placeholder {
    color: #8F959E;
  }

  & > .icon-arrow {
    margin-left: 8px;
    transform: rotate(-90deg);
    transition: transform 0.2s;
  }

  & > .icon-arrow.focused {
    transform: rotate(90deg);
  }
}

.industry-first-select-wrapper {
  flex: 1 1;
}

.industry-second-select-wrapper {
  flex: 1 1;
}

.first-options-item {
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 20px;
  cursor: pointer;

  &.active {
    background: rgba(31, 35, 41, 0.08);
  }

  & > .prefix {
    margin-right: 6px;
  }

  & > span {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.second-options-item {
  display: flex;
  align-items: center;
  padding: 5px 25px 5px 20px;
  cursor: pointer;  

  & > .prefix {
    margin-right: 6px;
  }

  & > :deep(.bv-tooltip-content-container) {
    max-width: 183px;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>