<script setup>
import { getSoundEffectList } from "@/api/resources";
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { secondsToHms } from "../../utils";
import Empty from "./empty.vue";
import Loading from "./loading.vue";
import vDragClick from "../../directives/v-drag-click";

const props = defineProps({
  currentTab: {
    type: String,
    default: "music",
  },
});
const { playing, materialTab, showMaterial, pause } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const materialWidth = inject("materialWidth");
const list = ref(null);
const files = ref([]);
const hoverFile = ref(null);
const page = ref(1);
const total = ref(0);
const loading = ref(false);
const empty = computed(() => files.value.length === 0);
const disabled = computed(
  () => loading.value || (total.value > 0 && files.value.length >= total.value)
);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  duration: 0,
  currentTime: 0,
});

watch(
  [materialWidth, files],
  () => {
    if (
      (!list.value ||
        list.value.scrollHeight <
          list.value.parentElement.parentElement.clientHeight) &&
      !disabled.value
    ) {
      getAudios();
    }
  },
  { immediate: true }
);
watch(
  [showMaterial, materialTab, playing, () => props.currentTab],
  ([isShow, newTab, playing, newSubTab]) => {
    if (!isShow || newTab !== "audio" || playing || newSubTab !== "effect") {
      destroyAudio();
    }
  }
);
onBeforeUnmount(destroyAudio);

function submit() {
  commit();
  updateDraft();
}

async function getAudios() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  const params = { page: page.value, size: 20 };
  const response = await getSoundEffectList(params);

  if (response.success) {
    const records = response.data.records;

    total.value = response.data.total;

    if (records.length > 0) {
      files.value = files.value.concat(records);
      page.value++;
    }
  }
  loading.value = false;
}

function playAudio(file) {
  pause();

  if (currentAudio.url !== file.url) {
    destroyAudio();

    const audio = new Audio(file.url);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.duration = file.duration;
    currentAudio.url = file.url;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
}

function destroyAudio() {
  if (currentAudio.audio) {
    pauseAudio();
    currentAudio.url = null;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.duration = 0;
  }
}

function pauseAudio() {
  currentAudio.playing = false;
  currentAudio.audio.pause();
}

function handleSeek(value) {
  currentAudio.audio.currentTime = value;
}
</script>
<template>
  <el-scrollbar>
    <el-skeleton animated :loading="loading && page === 1">
      <template #template>
        <div v-for="(_, i) in Array(16)" class="skeleton-item" :key="i">
          <el-skeleton-item variant="rect" />
          <div class="skeleton-right">
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" />
          </div>
        </div>
      </template>
      <empty v-if="empty" />
      <div
        v-else
        ref="list"
        class="file-list"
        v-infinite-scroll="getAudios"
        :infinite-scroll-immediate="false"
        :infinite-scroll-disabled="disabled"
      >
        <div
          class="file-item"
          v-for="(file, i) in files"
          :key="i"
          @mouseenter="hoverFile = file"
          @mouseleave="hoverFile = null"
        >
          <div
            v-drag-click="{
              file,
              type: 'audio',
              selector: '.cover',
              trigger: 'drag',
              callback: submit,
            }"
            class="item-top"
          >
            <div class="image-wrapper">
              <div v-show="hoverFile === file" class="image-mask">
                <svg-icon
                  clickable
                  v-show="
                    currentAudio.url !== file.url ||
                    (currentAudio.url === file.url && !currentAudio.playing)
                  "
                  name="editor_music_play"
                  :size="24"
                  @click="playAudio(file)"
                />
                <svg-icon
                  clickable
                  v-show="currentAudio.url === file.url && currentAudio.playing"
                  name="editor_music_pause"
                  :size="24"
                  @click="pauseAudio(file)"
                />
              </div>
              <div class="cover">
                <svg-icon name="editor_audio" color="#FFFFFF" :size="18" />
              </div>
            </div>
            <div class="right">
              <span class="title">{{ file.name }}</span>
              <div class="item-rb">
                <span
                  class="duration"
                  v-if="
                    currentAudio.url === file.url && currentAudio.currentTime
                  "
                  >{{
                    `${secondsToHms(currentAudio.currentTime)} / ${secondsToHms(
                      currentAudio.duration
                    )}`
                  }}</span
                >
                <span class="duration" v-else>{{
                  secondsToHms(file.duration)
                }}</span>
                <div class="button-group" v-show="hoverFile === file">
                  <icon-button
                    primary
                    round
                    class="trigger"
                    name="editor_material_plus"
                    :size="18"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="currentAudio.url === file.url && currentAudio.playing"
            class="item-bottom"
          >
            <bv-slider
              :model-value="currentAudio.currentTime"
              :min="0"
              :max="currentAudio.duration"
              :step="1"
              :show-tooltip="false"
              @input="handleSeek"
            />
          </div>
        </div>
      </div>
      <loading v-show="loading && page > 1" />
    </el-skeleton>
  </el-scrollbar>
</template>
<style scoped>
.file-list,
:deep(.el-skeleton) {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  padding: 22px 16px 0;
}
.file-item {
  display: flex;
  flex-direction: column;
}
.item-top {
  display: flex;
  align-items: center;
}
.skeleton-item {
  display: flex;
  align-items: center;
}
.item-rb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}
.item-bottom {
  margin-top: 10px;
  display: flex;
}
.button-group {
  display: flex;
  align-items: center;
}
.button-group svg + svg {
  margin-left: 8px;
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  flex: 0 0 70px;
  height: 70px;
  border-radius: 4px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
}
:deep(.el-skeleton__text) {
  margin: 2px 0;
  height: 14px;
}
.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
}
.file-list .right,
.skeleton-right {
  height: 70px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #93c9ac;
}
.title {
  color: #646a73;
  font-size: 14px;
  line-height: 22px;
}
.duration {
  color: #8f959e;
  font-size: 12px;
  line-height: 20px;
  font-variant: tabular-nums;
}
</style>
