<template>
    <div class="audio-wrap">
      <div class="audio-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <img :src="item.coverPic || 'https://cdn.boolv.video/pgc/music/4E93HSxsQ997tzRooNu2xb_cover.jpg'"
          class="image-cover" />
  
        <div class="audio-mask" v-if="showMask"></div>
        <div class="audio-icon" @click="handleClickItem(item)" v-if="showMask">
          <svg-icon 
            :name="currentAudio.playing && currentAudio.url === item.url ? 'editor_music_pause' : 'editor_music_play'" 
            :size="24"
            class="control-icon"
          />
        </div>
  
        <div class="flex-1 h-full flex flex-col justify-center">
          <p class="audio-name">{{ item.name || 'untitled' }}</p>
  
          <div class="flex items-center justify-between">
            <p class="audio-time" v-if="item.isPlaying">{{
              `${secondsToHms(
                currentTime || 0,
              )} / ${secondsToHms(item.duration)}`
            }}</p>
            <p class="audio-time" v-else>{{ secondsToHms(item.duration) }}</p>
            <div class="add-btn flex justify-center items-center" :style="{ 'opacity': showMask ? 1 : 0 }">
              <svg-icon name="icon_add_audio" :size="24" @click="handleReplace(item)"></svg-icon>
            </div>
          </div>
        </div>
      </div>
      <bv-slider 
        class="audio-slider" 
        :colors="{ main: '#646A73', track: '#646A73' }" 
        v-model="currentAudio.currentTime"
        :min="0"
        :max="currentAudio.audio?.duration || 0"
        :step="0.1" 
        :show-tooltip="false" 
        :style="{opacity: currentAudio.playing && currentAudio.url === item.url ? 1 : 0}" 
        @change="handleAudioTime"
      />
      <div class="loading-mask" v-if="item.state == 2">
        <div class="mask"></div>
        <div class="progress">
          <el-progress :percentage="item.progress" :show-text="false" :indeterminate="true" />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { secondsToHms } from "@/utils/util.ts";
  const props = defineProps({
    item: Object,
    currentAudio: Object,
  });
  
  const emit = defineEmits(['onReplace', 'play', 'pause', 'seekTo'])
  
  const showMask = ref(false)
  const currentTime = ref(0)
  
  const handleMouseEnter = () => {
    showMask.value = true
  }

  const handleMouseLeave = () => {
    showMask.value = false
  }
  
  const handleClickItem = (item) => {
    const { currentAudio } = props;

    if (currentAudio.playing && currentAudio.url === item.url) {
      emit('pause');
    }
    else {
      emit('play', item.url);
    }
  }
  
  const handleAudioTime = (value) => {
    emit("seekTo", value);
  };
  
  // 替换
  const handleReplace = (item) => {
    emit("onReplace", item);
  };
  </script>
  
<style lang="scss" scoped>
.audio-wrap {
position: relative;
width: 100%;
height: 100%;

.loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .mask {
    width: 100%;
    height: 100%;
    background: #0c0c0c;
    opacity: 0.5;
    border-radius: 8px;
    }

    .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0px 20px;

    .el-progress {
        width: 300px;
    }

    .el-progress-bar__outer {
        height: 4px !important;
        border-radius: 0px;
    }

    .el-progress-bar__inner {
        border-radius: 0px;
    }
    }

}
}

.audio-item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: relative;

    .add-btn {
        margin-left: 10px;
        width: 28px;
        height: 28px;
        background: #875eff;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
    }

    .audio-mask {
        width: 70px;
        height: 70px;
        background: #000;
        opacity: 0.5;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 8px;
    }

    .audio-icon {
        width: 70px;
        height: 70px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;
    }

    .image-cover {
        width: 70px;
        height: 70px;
        border-radius: 8px;
        margin-right: 10px;
        object-fit: cover;
    }

    .audio-name {
        max-width: 135px;
        color: #646A73;
        font-size: 14px;
        max-height: 50px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        line-height: 22px;
    }

    .audio-time {
        font-size: 12px;
        color: #8F959E;
        line-height: 22px;
    }

    .audio-slider {
        margin-top: 5px;
    }
}
</style>