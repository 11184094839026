<template>
  <div class="card-slider">
    <div 
      class="card-slider-runway"
      ref="sliderRef"
      @mousedown="sliderMouseDown"
    >
      <div 
        class="card-slider-bar" 
        :style="{width: `${percent}%`}"
      />
      <div 
        class="card-slider-button-wrapper"
        ref="buttonRef"
        :style="{left: `${percent}%`}"
        @mousedown="buttonMouseDown"
      >
        <div class="card-slider-button">
          {{ modelValue }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits("update:modelValue");
const props = defineProps({
  modelValue: {
    type: Number,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  }
});

const dragging = ref(false);
const sliderRef = ref(null);
const buttonRef = ref(null);
const percent = computed(() => {
  const { modelValue, min, max, step } = props;
  return (modelValue - min + step) / (max - min + step) * 100
});

const sliderMouseDown = async (e) => {
  dragging.value = true;
  mouseMove(e);
  await nextTick();
  buttonMouseDown();
}

const buttonMouseDown = () => {
  dragging.value = true;
  document.addEventListener('mousemove', mouseMove);
  document.addEventListener('mouseup', mouseUp);
};

const mouseMove = (e) => {
  if (dragging.value) {
    const sliderRect = sliderRef.value.getBoundingClientRect();
    const { max, min, step } = props;
    const pieces = (max - min) / step;
    const pieceWidth = (sliderRect.width * 1 / (pieces + 1));
    const range = pieces * pieceWidth;
    let position = e.clientX - (sliderRect.left + pieceWidth);
    if (position <= 0) {
      position = 0;
    }
    else if (position >  range) {
      position = range;
    }
    const rawPercent = position / range;
    const newValue = Math.round((max - min) / step * rawPercent) * step + min;
    emit('update:modelValue', newValue);
  }
};


const mouseUp = () => {
  dragging.value = false;
  document.removeEventListener('mousemove', mouseMove);
  document.removeEventListener('mouseup', mouseUp);
};
</script>

<style lang="scss" scoped>
.card-slider {
  --card-slider-height: 32px;
  --card-slider-runway-height: 12px;
  --card-slider-border-radius: 16px;
  width: 100%;
  height: var(--card-slider-height);
  display: flex;
  align-items: center;
}
.card-slider-runway {
  flex: 1;
  height: var(--card-slider-runway-height);
  border-radius: var(--card-slider-border-radius);
  background: #EBEDEF;
  position: relative;
  cursor: pointer;
}

.card-slider-bar {
  width: 20%;
  height: var(--card-slider-runway-height);
  position: absolute;
  left: 0;
  border-radius: var(--card-slider-border-radius);
  background: #875EFF;
}

.card-slider-button-wrapper {
  left: 20%;
  width: var(--card-slider-height);
  height: var(--card-slider-height);
  position: absolute;
  z-index: 1;
  top: -12px;
  transform: translateX(-50%);
  background: transparent;
  user-select: none;
}

.card-slider-button {
  width: var(--card-slider-height);
  height: var(--card-slider-height);
  background: #FFFFFF;
  border: 1px solid #E5E7EB; 
  border-radius: var(--card-slider-border-radius);
  color: #060606;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
</style>