import { onBeforeUnmount, h } from "vue";
import { ElMessage, type MessageHandler } from "element-plus";
import { SvgIcon } from "@/components/common";
import { generateGID } from "./common";
import type { Component, VNode, ComponentInternalInstance } from "vue";

/* --- 合并classNames --- */
type ClassNames = string | string[] | Record<string, boolean>;

function classNamesToMap(classNames: ClassNames) {
  const map: Record<string, boolean> = Object.create(null);

  if (typeof classNames === "string") {
    classNames.split(" ").forEach((className) => {
      if (className !== "") {
        map[className] = true;
      }
    });
  } else if (Array.isArray(classNames)) {
    classNames.forEach((className) => {
      if (className !== "") {
        map[className] = true;
      }
    });
  } else if (typeof classNames === "object") {
    Object.entries(classNames).forEach(([className, condition]) => {
      map[className] = condition;
    });
  }

  return map;
}

export function concatClassNames(source: string, target: ClassNames): string;
export function concatClassNames(
  source: string[],
  target: ClassNames,
): string[];
export function concatClassNames(
  source: Record<string, boolean>,
  target: ClassNames,
): Record<string, boolean>;
export function concatClassNames(
  source: ClassNames,
  target: ClassNames,
): ClassNames {
  const sourceMap = classNamesToMap(source);
  const targetMap = classNamesToMap(target);

  if (typeof source === "string") {
    return [
      ...new Set(Object.keys(sourceMap).concat(Object.keys(targetMap))),
    ].join(" ");
  } else if (Array.isArray(source)) {
    return [...new Set(Object.keys(sourceMap).concat(Object.keys(targetMap)))];
  } else {
    return { ...sourceMap, ...targetMap };
  }
}

/* --- 生成随机类名(主要用于获取特定dom元素) --- */
export function randomClassName() {
  return `rc-${generateGID()}`;
}

/* --- 使用适合vue生命周期的动态style --- */
type StyleDeclarationName = ValueOf<{
  [K in keyof CSSStyleDeclaration]: CSSStyleDeclaration[K] extends string
    ? K
    : never;
}>;

type StyleDeclaration = Partial<
  Pick<CSSStyleDeclaration, StyleDeclarationName>
> &
  Record<string, string>;
type Style = [string, StyleDeclaration];

export const useStyle = (() => {
  const styleElement = document.createElement("style");
  document.head.appendChild(styleElement);

  const styleSheet = styleElement.sheet!;

  class DynamicRule {
    _styles: CSSStyleDeclaration[];

    constructor(styles: Style[] = []) {
      this._styles = [];

      styles.forEach(([selector, declarations]) => {
        this.insertStyle(selector, declarations);
      });
    }

    insertStyle(selector: string, declarations: StyleDeclaration) {
      const index = styleSheet.insertRule(
        `${selector} {${Object.entries(declarations)
          .map(([key, value]) => `${key}: ${value};`)
          .join("")}}`,
      );

      const style = (styleSheet.cssRules[index] as CSSStyleRule).style;
      this._styles.push(style);

      return style;
    }

    deleteStyle(style: CSSStyleDeclaration) {
      const styleIndex = this._styles.indexOf(style);
      
      if (styleIndex !== -1) {
        const ruleIndex = Array.prototype.findIndex.call(styleSheet.cssRules, rule => rule.style === style);
        
        if (ruleIndex !== -1) {
          styleSheet.deleteRule(ruleIndex);
          return true;
        }

        this._styles.splice(styleIndex, 1);
      }

      return false;
    }

    item(index: number) {
      return this._styles[index];
    }

    get length() {
      return this._styles.length;
    }

    [Symbol.iterator](): IterableIterator<CSSStyleDeclaration> {
      return this._styles[Symbol.iterator]();
    }
  }

  return (styles: Style[] = [], isSetup = true) => {
    const rule = new DynamicRule(styles);

    isSetup &&
      onBeforeUnmount(() => {
        for (let i = rule.length - 1; i >= 0; i--) {
          rule.deleteStyle(rule.item(i));
        }
      });

    return rule;
  };
})();

type MessageType = "warn" | "error" | "loading" | "success" | "orangeWarn";
type MessageProxy = Record<
  MessageType,
  (message: string | VNode, config?: MessageConfig) => MessageHandler
>;
type MessageConfig = {
  customClass?: string;
  duration?: number;
  showClose?: boolean;
  center?: boolean;
  onClose?(): void;
  offset?: number;
  appendTo?: HTMLElement;
  grouping?: boolean;
  repeatNum?: number;
  icon?: Component;
};

export const Message = (() => {
  const loadingIcon = h(SvgIcon, { name: "icon_loading" });
  const successIcon = h(SvgIcon, { name: "icon_success" });
  const warnIcon = h(SvgIcon, { name: "icon_warn" });
  const errorIcon = h(SvgIcon, { name: "icon_error" });
  const orangeWarnIcon = h(SvgIcon, { name: "icon_orange_warn"})
  const commonConfig: MessageConfig = {
    customClass: "",
    duration: 3000,
    showClose: false,
    center: false,
    onClose() {},
    offset: 72,
    appendTo: document.body,
    grouping: false,
    repeatNum: 1,
  };

  const config: Record<MessageType, MessageConfig & { type: string }> = {
    warn: {
      type: "warning",
      customClass: "el-message--warning",
      icon: () => warnIcon,
    },
    error: {
      type: "error",
      customClass: "el-message--error",
      icon: () => errorIcon,
    },
    loading: {
      type: "info",
      customClass: "el-message--loading",
      icon: () => loadingIcon,
      showClose: true,
    },
    success: {
      type: "success",
      customClass: "el-message--success",
      icon: () => successIcon,
    },
    orangeWarn: {
      type: "orangeWarn",
      customClass: "el-message--orangewarining",
      icon: () => orangeWarnIcon,
    }
  };

  const message = (
    type: MessageType,
    message: string | VNode,
    userConfig: MessageConfig = {},
  ) => {
    const usedConfig: MessageConfig & { message: typeof message } = {
      ...commonConfig,
      ...config[type],
      ...userConfig,
      message,
    };

    return ElMessage(usedConfig);
  };

  return new Proxy<MessageProxy>({} as any, {
    get(_, key) {
      return message.bind(null, key as MessageType);
    },
    has(_, key) {
      return key in config;
    },
  });
})();

/** 全局Message (单例) */
export const SingleMessage = (() => {
  let focus: MessageHandler | null = null;
  const close = () => {
    focus?.close();
  };

  return new (Proxy as ObjectProxyConstructor<
    MessageProxy,
    MessageProxy & { close(): void }
  >)(Message, {
    get(target, key) {
      if (key === "close") return close;
      const fn = target[key as MessageType];

      return (message: string | VNode, config: MessageConfig = {}) => {
        const handler = fn(message, config);

        focus?.close();
        focus = handler;

        return handler;
      };
    },
  });
})();

/** 符合vue生命周期的Message */
export function useMessage() {
  const handlerSet = new Set<MessageHandler>();

  const Message = (() => {
    const loadingIcon = h(SvgIcon, { name: "icon_loading" });
    const successIcon = h(SvgIcon, { name: "icon_success" });
    const warnIcon = h(SvgIcon, { name: "icon_warn" });
    const errorIcon = h(SvgIcon, { name: "icon_error" });
    const orangeWarnIcon = h(SvgIcon, { name: "icon_orange_warn"});
    const commonConfig: MessageConfig = {
      customClass: "",
      duration: 3000,
      showClose: false,
      center: false,
      onClose() {},
      offset: 72,
      appendTo: document.body,
      grouping: false,
      repeatNum: 1,
    };
  
    const config: Record<MessageType, MessageConfig & { type: string }> = {
      warn: {
        type: "warning",
        customClass: "el-message--warning",
        icon: () => warnIcon,
      },
      error: {
        type: "error",
        customClass: "el-message--error",
        icon: () => errorIcon,
      },
      loading: {
        type: "info",
        customClass: "el-message--loading",
        icon: () => loadingIcon,
        showClose: true,
      },
      success: {
        type: "success",
        customClass: "el-message--success",
        icon: () => successIcon,
      },
      orangeWarn: {
        type: "orangeWarn",
        customClass: "el-message--orangewarining",
        icon: () => orangeWarnIcon,
      }
    };
  
    const message = (
      type: MessageType,
      message: string | VNode,
      userConfig: MessageConfig = {},
    ) => {
      const usedConfig: MessageConfig & { message: typeof message } = {
        ...commonConfig,
        ...config[type],
        ...userConfig,
        message,
      };
  
      return ElMessage(usedConfig);
    };
  
    return new Proxy<MessageProxy>({} as any, {
      get(_, key) {
        return message.bind(null, key as MessageType);
      },
      has(_, key) {
        return key in config;
      },
    });
  })();
  
  onBeforeUnmount(() => {
    handlerSet.forEach((handler) => handler.close());
  });

  return new Proxy<MessageProxy>(Message, {
    get(target, key) {
      const fn = target[key as MessageType];

      return (message: string | VNode, config: MessageConfig = {}) => {
        const handler = fn(message, config);
        const close = handler.close.bind(handler);

        handlerSet.add(handler);
        handler.close = () => {
          close();
          handlerSet.delete(handler);
        };

        return handler;
      };
    },
  });
}

/** 符合vue生命周期的Message (单例) */
type SingleMessageProxy = MessageProxy & { close(): void };
export const useSingleMessage = (() => {
  const messageMap = new Map<ComponentInternalInstance, SingleMessageProxy>();

  return function () {
    const instance = getCurrentInstance();
    if (instance === null)
      throw new Error("useSingleMessage must be called in setup");
    if (messageMap.has(instance)) return messageMap.get(instance)!;

    let focus: MessageHandler | null = null;
    const close = () => {
      focus?.close();
    };

    onBeforeUnmount(() => {
      close();
      messageMap.delete(instance);
    });

    const handler = new (Proxy as ObjectProxyConstructor<
      MessageProxy,
      SingleMessageProxy
    >)(Message, {
      get(target, key) {
        if (key === "close") return close;
        const fn = target[key as MessageType];

        return (message: string | VNode, config: MessageConfig = {}) => {
          const handler = fn(message, config);

          focus?.close();
          focus = handler;

          return handler;
        };
      },
    });

    messageMap.set(instance, handler);
    return handler;
  };
})();

export function queryVNode(vnode: VNode, name: string): VNode | undefined {
  // @ts-ignore
  if (vnode.type["__name"] === name || vnode.type["name"] === name) {
    return vnode;
  } else if (vnode.component !== null) {
    return queryVNode(vnode.component.subTree, name);
  } else if (Array.isArray(vnode.children)) {
    for (const child of vnode.children as Array<VNode>) {
      const node = queryVNode(child, name);
      if (node !== null) return node;
    }
  } else {
    return undefined;
  }
}

export const keyMap = {
  KeyA: 0x41,
  KeyB: 0x42,
  KeyC: 0x43,
  KeyD: 0x44,
  KeyE: 0x45,
  KeyF: 0x46,
  KeyG: 0x47,
  KeyH: 0x48,
  KeyI: 0x49,
  KeyJ: 0x4a,
  KeyK: 0x4b,
  KeyL: 0x4c,
  KeyM: 0x4d,
  KeyN: 0x4e,
  KeyO: 0x4f,
  KeyP: 0x50,
  KeyQ: 0x51,
  KeyR: 0x52,
  KeyS: 0x53,
  KeyT: 0x54,
  KeyU: 0x55,
  KeyV: 0x56,
  KeyW: 0x57,
  KeyX: 0x58,
  KeyY: 0x59,
  KeyZ: 0x5a,
  Digit0: 0x30,
  Digit1: 0x31,
  Digit2: 0x32,
  Digit3: 0x33,
  Digit4: 0x34,
  Digit5: 0x35,
  Digit6: 0x36,
  Digit7: 0x37,
  Digit8: 0x38,
  Digit9: 0x39,
  Numpad0: 0x60,
  Numpad1: 0x61,
  Numpad2: 0x62,
  Numpad3: 0x63,
  Numpad4: 0x64,
  Numpad5: 0x65,
  Numpad6: 0x66,
  Numpad7: 0x67,
  Numpad8: 0x68,
  Numpad9: 0x69,
  NumpadMultiply: 0x6a,
  NumpadAdd: 0x6b,
  NumpadSubtract: 0x6d,
  NumpadDecimal: 0x6e,
  NumpadDivide: 0x6f,
  NumpadEnter: 0x0d,
  NumpadEqual: 0x0d,
  Comma: 0xbc,
  Period: 0xbe,
  Slash: 0xbf,
  Semicolon: 0xba,
  Quote: 0xde,
  BracketLeft: 0xdb,
  BracketRight: 0xdd,
  Backslash: 0xdc,
  Backquote: 0xc0,
  Minus: 0xbd,
  Equal: 0xbb,
  Enter: 0x0d,
  Space: 0x20,
  Meta: 0x5b,
  Shift: 0x10,
  Ctrl: 0x11,
  Alt: 0x12,
} satisfies Record<string, number>;

export class ShortcutKey {
  key: number;

  /** bits: `ctrl`,`alt`,`shift` */
  modifier: number;

  /** @param modifier bits: `ctrl`,`alt`,`shift` */
  constructor(key: number, modifier = 0b000) {
    this.key = key;
    this.modifier = modifier & 0b111;
  }

  static fromKeyboardEvent(keyboardEvent: KeyboardEvent): ShortcutKey {
    return new ShortcutKey(
      keyboardEvent.code in keyMap
        ? keyMap[keyboardEvent.code as keyof typeof keyMap]
        : -1,
      (keyboardEvent.ctrlKey ? 0b100 : 0b000) |
        (keyboardEvent.altKey ? 0b010 : 0b000) |
        (keyboardEvent.shiftKey ? 0b001 : 0b000),
    );
  }
}

export interface ShortcutKeyEvent {
  type: string;
  manager: ShortcutKeyManager;
  shortcutKey: ShortcutKey;
  keyboardEvent: KeyboardEvent;
}

export class ShortcutKeyManager {
  bindings: Map<number, [ShortcutKey, (event: ShortcutKeyEvent) => void][]>;
  destroy: () => void;

  constructor() {
    const keyDownHandler = this._handleKeyDown.bind(this);
    const keyUpHandler = this._handleKeyUp.bind(this);

    window.addEventListener("keydown", keyDownHandler);
    window.addEventListener("keyup", keyUpHandler);

    this.bindings = new Map();
    this.destroy = () => {
      window.removeEventListener("keydown", keyDownHandler);
      window.removeEventListener("keyup", keyUpHandler);
    };
  }

  register(shortcut: ShortcutKey, callback: (event: ShortcutKeyEvent) => void) {
    if (!this.bindings.has(shortcut.key)) {
      this.bindings.set(shortcut.key, []);
    }

    this.bindings.get(shortcut.key)!.push([shortcut, callback]);
  }

  unregister(shortcut: ShortcutKey) {
    const items = this.bindings.get(shortcut.key) ?? [];
    const index = items.findIndex(([key]) => key === shortcut);

    index !== -1 && items.splice(index, 1);
  }

  match(shortcutKey: ShortcutKey) {
    const items = this.bindings.get(shortcutKey.key) ?? [];
    return items.filter(([{ modifier }]) => modifier === shortcutKey.modifier);
  }

  private _createEvent(keyboardEvent: KeyboardEvent, shortcutKey: ShortcutKey) {
    return {
      type: keyboardEvent.type,
      manager: this,
      shortcutKey,
      keyboardEvent,
    };
  }

  private _handleKeyDown(event: KeyboardEvent) {
    const items = this.match(ShortcutKey.fromKeyboardEvent(event));

    for (const [shortcut, callback] of items) {
      callback(this._createEvent(event, shortcut));
    }

    if (items.length > 0) {
      event.preventDefault();
    }
  }

  private _handleKeyUp(event: KeyboardEvent) {
    const items = this.match(ShortcutKey.fromKeyboardEvent(event));

    for (const [shortcut, callback] of items) {
      callback(this._createEvent(event, shortcut));
    }
  }
}

export function useShortcutKeyManager() {
  const manager = new ShortcutKeyManager();

  onUnmounted(manager.destroy);
  return manager;
}
