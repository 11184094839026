<template>
  <div class="stickers-wrapper">
    <div class="stickers-header">
      <span class="label">Make Stickers for Your Avatar</span>
      <bv-tip
        placement="bottom"
        content="The video length should be between 2s and 5min."
        :showAfter="0"
      >
        <svg-icon 
          name="script_help"
          :size="18"
        />
      </bv-tip>
    </div>
    <div class="stickers-desc">
      Upload a video file for the AI to remove the background
    </div>
    <div class="stickers-list">
      <div class="new-sticker" @click="uploader?.handleClickUpload">
        <bv-uploader
          v-model="files"
          ref="uploader"
          :accept="accept"
          :preUploadOptions="{ toolName: 'similarCharacterSticker' }"
          :beforePreProcess="beforePreProcess"
           @beforeAddFiles="handleBeforeAddFiles"
          @success="refresh"
        />
        <svg-icon name="editor_plus" :size="36" color="#646A73" />
      </div>
      <div v-for="file in files" class="sticker-container">
        <div class="processing-mask" v-if="file.state === 2">
          <div class="progress-box">
            <span class="progress-tip">{{
              `Background removing ${file.progress.toFixed(2)}%`
            }}</span>
            <div class="progress-bar">
              <div
                class="progress"
                :style="{ width: `${file.progress}%` }"
              ></div>
            </div>
          </div>
        </div>
        <div class="error-mask" v-else-if="file.state === 1">
          <div class="error-tip-container">
            <svg-icon
              name="icon_warn"
              :size="24"
              color="#FFFFFF"
              alt="warn icon"
            />
            <span class="error-tip">Background removal failed</span>
          </div>
        </div>
        <svg-icon
          v-if="file.state === 0"
          color="#FFFFFF"
          name="icon_delete"
          class="delete-button"
          :size="24"
          @click="deleteSticker(file)"
        />
        <div v-if="file.state === 0" class="duration">
          {{ secondsToMs(file.duration) }}
        </div>
        <svg-icon
          v-if="file.state === 0"
          :size="24"
          class="preview"
          name="editor_library_preview"
          @mousedown.stop="preview(file)"
        />
        <img v-if="file.coverPic" :src="file.coverPic" alt="" />
      </div>
    </div>
    <Previewer ref="previewer" :showArrow="false" :record="previewFile" />
  </div>
</template>

<script setup>
import dayjs from 'dayjs';
import { DataUnit } from '@/utils';
import { secondsToMs } from '@/utils/util';
import { VIDEOTYPE } from '@/utils/type.ts';
import { useSubscriptionInfo } from '@/store/modules/user';
import { deleteCharacterMaterial, startAiProcess } from '@/api/character';
import { useModalManager } from '@/components/common/custom-modal/instance';
import Previewer from '@/pages/space/components/material-previewer/index.vue';
import { useTrackStore } from '@/store/modules/track';
const { collectData, track } = useTrackStore();

const modalManager = useModalManager();
const subscriptionStore = useSubscriptionInfo();
const { refresh, getUserResource } = subscriptionStore;
const { subscriptionState } = storeToRefs(subscriptionStore);

const accept = [
  {
    types: VIDEOTYPE.split(','),
    maxSize: 200 * DataUnit.MB,
  },
];

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});

const files = ref([]);
const uploader = ref(null);
const previewer = ref(null);
const previewFile = ref(null);

const preview = (value) => {
  previewFile.value = {
    ...value,
    preview1080Url: value.preview1080Url || value.url,
    type: 'video',
  };
  previewer.value.open = true;
};

const deleteSticker = (file) => {
  let deleteLoading = false;
  const confirm = async () => {
    if (deleteLoading) return;
    deleteLoading = true;
    const params = {
      mid: file.mid,
      type: 3,
    };
    const { success } = await deleteCharacterMaterial(params);
    if (success) {
      const index = files.value.findIndex((i) => i.mid === file.mid);
      files.value.splice(index, 1);
    }
    modalManager.modal.open = false;
    deleteLoading = null;
  };

  const refreshData = subscriptionState.value.nextRenewalDateTime
    ? dayjs(subscriptionState.value.nextRenewalDateTime).format(
        'MMM DD, YYYY, H:mm:ss'
      )
    : '-';
  const removalTimes =
    getUserResource('SIMILAR_VIDEO_MAKER_STICKER_30')?.num || 0;
  modalManager.applyTemplate('warning', {
    icon: 'orangeWarn',
    key: 'delete-character-sticker-modal',
    title: 'Deletion cannot be restored',
    content: h('div', { style: { width: '100%' } }, [
      h(
        'p',
        {},
        'Stickers cannot be restored after being deleted. Continue deleting?'
      ),
      h('div', { style: { color: '#8F959E' } }, [
        h('p', {}, `Background removal: ${removalTimes} remaining`),
        h('p', {}, `Refresh date: ${refreshData}`),
      ]),
    ]),
    confirmText: 'Delete',
    closeAfterConfirm: false,
    onConfirm: confirm,
  });
};

const handleBeforeAddFiles = (file) => {
  collectData('similarvideo_page_avatar_upload', {
    click: 'stickers',
    video_size: file[0].size,
    video_time:  files.value[1]?.duration,
    video_type: file[0]?.type
  });
  track('similarvideo_page_avatar_upload');
}

const beforePreProcess = async (file) => {
  collectData('similarvideo_page_avatar_click', {
    click: 'stickers',
  });
  track('similarvideo_page_avatar_click');

  return new Promise((resolve, reject) => {
    const params = {
      characterId: props.info.characterId,
      mid: file.mid,
      type: 3,
    };
    startAiProcess(params).then(({ success, data, code, msg }) => {
      if (success) {
        file.mid = data.aiMid;
        resolve();
      } else {
        if ([90015, 30403].includes(code)) {
          modalManager.applyTemplate('upgradeTips', {
            msg,
            code,
            onConfirm: () => {
              uploader.value?.openSubscriptionModal();
            },
          });
        }
        reject({ type: 'beforePreProcess' });
      }
    });
  });
};

const setup = () => {
  const { info } = props;
  if (info.stickers.length > 0) {
    files.value = files.value.concat(info.stickers);
  }
  for (const file of files.value) {
    if (file.state > 1) {
      uploader.value
        ?.getUploadState(file)
        .then((info) => Object.assign(file, info))
        .catch((e) => Object.assign(file, e));
    }
  }
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.stickers-wrapper {
  width: 100%;

  & :deep(.bv-tooltip-content-container) {
    width: fit-content;
    height: fit-content;
  }
}

.stickers-header {
  width: 60%;
  min-width: 430px;
  display: flex;
  align-items: center;

  & svg {
    cursor: pointer;
  }
}

.label {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-right: 6px;
}

.stickers-desc {
  width: 60%;
  min-width: 430px;
  color: #8f959e;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 6px;
}

.stickers-list {
  width: 100%;
  min-height: 224px;
  display: grid;
  gap: 18px;
  margin-top: 18px;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
}

.new-sticker {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  border: 1px solid rgba(31, 35, 41, 0.08);
  background: #fff;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #f4f5f7;
  }
}

.sticker-container {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
    background: #f7f7f8;
    -webkit-user-drag: none;
  }
}

.processing-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.54);
  z-index: 1;
}

.progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
}

.progress-bar {
  position: relative;
  width: 70%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow-x: hidden;
}

.progress {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s;
}

.progress-tip {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.error-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.54);
  z-index: 1;
}

.error-tip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error-tip {
  color: #ffffff;
  font-size: 14px;
  margin-top: 4px;
}

.delete-button {
  position: absolute;
  top: 12px;
  right: 14px;
  padding: 3px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}

.sticker-container:hover .delete-button,
.sticker-container:hover .preview {
  opacity: 1;
}

.duration {
  position: absolute;
  bottom: 14px;
  right: 14px;
  padding: 0 8px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.preview {
  position: absolute;
  top: 40px;
  right: 14px;
  padding: 3px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}
</style>
