import Cor from "@boolv/creator";
import { useCreatorStore } from "./creator";
import { ratioMap, useDraftStore } from "./draft";

const { creator, tracks, activeNodeMap, refresh } = useCreatorStore();
const undoStack = ref([]);
const redoStack = ref([]);
const last = ref(null);
const undoing = ref(false);
const redoing = ref(false);
const history = computed(() => [last.value, ...undoStack.value]);
const canUndo = computed(() => undoStack.value.length > 0);
const canRedo = computed(() => redoStack.value.length > 0);

watch(creator, (newCreator) => {
  if (newCreator && !last.value) {
    last.value = JSON.stringify(creator.value.conf);
  }
});

export function useHistoryStore() {
  const { setRatio } = useDraftStore();

  function commit() {
    undoStack.value.unshift(last.value);
    last.value = JSON.stringify(creator.value.conf);

    if (redoStack.value.length) {
      redoStack.value.splice(0, redoStack.value.length);
    }
  }

  function undo() {
    if (undoing.value) {
      return;
    }
    undoing.value = true;
    const config = undoStack.value.shift();

    if (config) {
      redoStack.value.unshift(last.value);
      last.value = config;
      process();
      triggerRef(creator);
      nextTick()
        .then(refresh)
        .then(() => (undoing.value = false));
    }
  }

  function redo() {
    if (redoing.value) {
      return;
    }
    redoing.value = true;
    const config = redoStack.value.shift();

    if (config) {
      undoStack.value.unshift(last.value);
      last.value = config;
      process();
      triggerRef(creator);
      nextTick()
        .then(refresh)
        .then(() => (redoing.value = false));
    }
  }

  function process() {
    const config = JSON.parse(last.value);
    const oldNodes = createVNodes(tracks.value);
    const newNodes = config.children;
    const parent = creator.value;
    const { width, height } = creator.value.conf.conf;

    if (width !== config.width || height !== config.height) {
      creator.value.resize(config.width, config.height);
      setRatio(ratioMap.get(`${config.width}*${config.height}`));
    }

    activeNodeMap.clear();
    patchChildren(oldNodes, newNodes, parent);
  }

  function createVNodes(nodes) {
    const vnodes = [];

    for (const node of nodes) {
      const { conf, children } = node;
      const newNode = { ...conf, id: node.id, node };

      if (children) {
        newNode.children = createVNodes(children);
      }
      vnodes.push(newNode);
    }
    return vnodes;
  }

  function patch(n1, n2) {
    if (n2.type === "track") {
      patchChildren(n1.children, n2.children, n1.node);
    } else if (n2.type === "scene") {
      patchNode(n1.node, n2);
      patchChildren(n1.children, n2.children, n1.node);
    } else {
      patchNode(n1.node, n2);
    }
  }

  function patchChildren(c1, c2, parent) {
    let i = 0;
    let e1 = c1.length - 1;
    let e2 = c2.length - 1;

    while (i <= e1 && i <= e2) {
      const n1 = c1[i];
      const n2 = c2[i];

      if (n1.id === n2.id) {
        patch(n1, n2);
      } else {
        break;
      }
      i++;
    }

    while (i <= e1 && i <= e2) {
      const n1 = c1[e1];
      const n2 = c2[e2];

      if (n1.id === n2.id) {
        patch(n1, n2);
      } else {
        break;
      }
      e1--;
      e2--;
    }

    if (i > e1) {
      if (i <= e2) {
        while (i <= e2) {
          mount(c2[i], parent, i);
          i++;
        }
      }
    } else if (i > e2) {
      while (i <= e1) {
        unmount(c1[i].node);
        i++;
      }
    } else {
      const s1 = i;
      const s2 = i;
      const newIndexMap = new Map();

      for (i = s2; i <= e2; i++) {
        newIndexMap.set(c2[i].id, i);
      }

      let moved = false;
      let lastPlacedIndex = 0;
      let patched = 0;
      let toBePatched = e2 - s2 + 1;
      const oldIndexMap = new Array(toBePatched);

      for (i = 0; i < toBePatched; i++) {
        oldIndexMap[i] = 0;
      }
      for (i = s1; i <= e1; i++) {
        const n = c1[i];

        if (patched >= toBePatched) {
          unmount(n.node);
          continue;
        }
        const newIndex = newIndexMap.get(n.id);

        if (newIndex === undefined) {
          unmount(n.node);
        } else {
          oldIndexMap[newIndex - s2] = i + 1;
          if (newIndex >= lastPlacedIndex) {
            lastPlacedIndex = newIndex;
          } else {
            moved = true;
          }
          patch(n, c2[newIndex]);
          patched++;
        }
      }
      const increasingSequence = moved ? getSequence(oldIndexMap) : [];
      let j = increasingSequence.length - 1;

      for (i = toBePatched - 1; i >= 0; i--) {
        const newIndex = s2 + i;
        const n = c2[newIndex];

        if (oldIndexMap[i] === 0) {
          mount(n, parent);
        } else if (moved) {
          if (j < 0 || i !== increasingSequence[j]) {
            move(n, parent, newIndex);
          } else {
            j--;
          }
        }
      }
    }
  }

  function mount(json, parent, index) {
    console.log("[mount]", json, index);
    function helper(json, parent, index) {
      const { type, id, children = [], ...conf } = json;
      const Node = Cor.NODE_MAP[type];
      const newConf = creator.value.conf.normalizeConfig(type, conf);
      const node = new Node(newConf);
      node.id = id;

      if (typeof index === "number") {
        parent.addChildAt(node, index);
      } else {
        parent.addChild(node);
      }
      for (const child of children) {
        helper(child, node);
      }
      return node;
    }
    const n = helper(json, parent, index);
    n.start().then(refresh);
  }

  function move(json, parent, index) {
    console.log("[move]", json);
    const node = parent.getChildById(json.id);
    parent.addChildAt(node, index);
  }

  async function unmount(node) {
    console.log("[unmount]", node);
    node.destroy();
    triggerRef(creator);
  }

  function patchNode(node, json) {
    const { type, children, ...conf } = json;
    const newConf = creator.value.conf.normalizeConfig(type, conf);

    if (["image", "video"].includes(type) && node.conf.src === newConf.src) {
      node.setCrop(newConf.crop);
    }
    node.conf = { type, ...newConf };
  }

  function getSequence(arr) {
    const p = arr.slice();
    const result = [0];
    let i, j, u, v, c;
    const len = arr.length;
    for (i = 0; i < len; i++) {
      const arrI = arr[i];
      if (arrI !== 0) {
        j = result[result.length - 1];
        if (arr[j] < arrI) {
          p[i] = j;
          result.push(i);
          continue;
        }
        u = 0;
        v = result.length - 1;
        while (u < v) {
          c = (u + v) >> 1;
          if (arr[result[c]] < arrI) {
            u = c + 1;
          } else {
            v = c;
          }
        }
        if (arrI < arr[result[u]]) {
          if (u > 0) {
            p[i] = result[u - 1];
          }
          result[u] = i;
        }
      }
    }
    u = result.length;
    v = result[u - 1];
    while (u-- > 0) {
      result[u] = v;
      v = p[v];
    }
    return result;
  }

  return {
    history,
    last,
    canUndo,
    canRedo,
    undoStack,
    redoStack,
    commit,
    undo,
    redo,
    redoing,
    undoing,
  };
}
