<template>
  <div class="form-wrapper" id="form-wrapper">
    <el-scrollbar class="form-scrollbar">
      <div class="script-render-form-container">
        <div class="prompt-container">
          <div class="title flex">Script</div>

          <el-input
            @input="handleInput"
            :class="{
              exceeded: prompt?.length >= 7000,
              focused: promptFocused,
            }"
            v-model="prompt"
            resize="none"
            type="textarea"
            show-word-limit
            placeholder="Enter your script to generate video"
            :rows="6"
            :maxlength="7000"
            @focus="handleFocus"
            @blur="handleBlur"
          />

          <div
            class="prompt-popover"
            :class="{ hide: !showPrompt, appear: showPrompt }"
          >
            <p>Try one of these:</p>
            <div class="prompt-preset-grid">
              <div
                v-for="item in promptPreset"
                class="prompt-preset-grid-item"
                @mousedown.stop="clickPrompt(item.value)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>

        <!-- media upload -->
        <div class="media-container item-container">
          <div class="title">
            <div class="flex items-center w-full">
              <p>Media</p>
              <p class="tips">( optional )</p>
              <el-tooltip
                popper-class="box-item"
                effect="dark"
                placement="bottom"
              >
                <template #content>
                  <div class="px-6 py-3 tips-content">
                    <p class="list-item">
                      Upload your own media, or skip this <br />
                      option let AI ​​match automatically
                    </p>
                    <p class="list-item">
                      Up to 15 files, including max 5 videos <br />
                      ( each &lt 30s ), visuals over 1080P will <br />
                      be compressed and take more time.
                    </p>
                  </div>
                </template>
                <img :src="icon_tip" class="icon-tip" />
              </el-tooltip>
            </div>
          </div>

          <div class="media-box">
            <div
              v-for="item in mediaList"
              :key="item.url"
              class="image-box"
              :class="{ 'bg-uploadBg': item.type != 'upload' }"
            >
              <media-uploader
                v-if="item.type == 'upload'"
                :isMultiple="true"
                v-model="mediaFile"
                ref="mediaUploader"
                :accept="mediaAccept"
                :limitNumber="15"
                :limitVideoNumber="5"
                @checkLimitFail="handleCheckLimitFail"
                @success="handleUploadMediaSuccess"
                @beforeUpload="handleBeforeUploadMediaFiles"
              />

              <!-- 上传按钮 -->
              <div
                class="upload-btn"
                v-if="item.type == 'upload'"
                @mousemove="handleBtnMouseMove"
                @mouseleave="handleBtnMouseLeave"
              >
                <div class="upload-content" v-if="showUploadBtn">
                  Add images from
                  <div class="upload-computer" @click="handleMediaUpload">
                    Computer
                  </div>
                  <div class="upload-cloud" @click="handleUpFromCloud">
                    Cloud
                  </div>
                </div>
                <img :src="icon_add_file" class="icon-add-file" v-else />
              </div>

              <div
                v-else
                class="media-box"
                @click="handlePreview(item)"
                @mouseenter="(e) => handleShowDelete(e, item)"
                @mouseleave="(e) => handleHiddenDelete(e, item)"
              >
                <div
                  class="flex justify-center items-center"
                  v-if="item.type == 'video' && item.state == 2"
                >
                  <img :src="poster" class="poster-image" />
                </div>
                <img :src="item.coverPic" v-else />
                <div
                  class="video-duration"
                  v-if="item.type == 'video' && item.state == 0"
                >
                  {{ formatDuration(item.duration) }}
                </div>

                <div
                  class="w-6 h-6 absolute bottom-3 right-3 delete-btn"
                  v-if="item.state == 0 && item.showRemove"
                >
                  <div
                    class="h-full w-full flex justify-center items-center rounded bg-maskBg"
                  >
                    <svg-icon
                      name="icon_delete"
                      :style="{ width: '18px', height: '18px', color: '#fff' }"
                      @click.stop="handleRemove(item)"
                    ></svg-icon>
                  </div>
                </div>
                <div
                  class="upload-loading"
                  v-if="item.state == 2 && item.tempId"
                >
                  <div>uploading...</div>
                  <ProgressBar :isCompleted="item.state == 0" />
                </div>
              </div>
            </div>
          </div>

          <teleport :to="previewerRoot">
            <material-previewer
              ref="previewerRef"
              :record="previewData"
              @change="changePreview"
              :canScroll="false"
            />
          </teleport>
        </div>
      </div>
    </el-scrollbar>

    <PrimaryButton class="button-container" padding="16px 48px" @click="submit">
      <div class="button-text">
        <svg-icon
          v-if="loading"
          class="loading-icon"
          name="icon_loading"
          :size="14"
        />

        Next
      </div>
    </PrimaryButton>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />

    <CloudAssetModal
      ref="cloudAssetModal"
      :confirmNoClose="true"
      :visible="isOpenAssetDialog"
      @close="handleCloseAssetDialog"
      @confirm="handleConfirm"
      :filterTypes="filterTypes"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue';
import { checkResource } from '@/api/script';
import { getLanguageList } from '@/api/language';
import { useMessage, removeEscapeHtml } from '@/utils';
import { useRoute, useRouter } from 'vue-router';

import MaterialPreviewer from '@/components/material-previewer/index.vue';
import MediaUploader from '../components/uploader.vue';
import ProgressBar from '../components/progressBar.vue';

import { DataUnit } from '@/utils';
import { IMAGEANDVIDEOTYPE } from '@/utils/type.ts';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import CloudAssetModal from '@/components/asset-modal/cloudAssetDialog/index.vue';
import { sessionSetItem } from '@/utils/storage';
import { useSimilarVideo } from '../store/index.js';

import icon_upload from '@/assets/similar-video/icon_upload.svg';
import icon_tip from '@/assets/similar-video/icon_tip.svg';
import icon_warning from '@/assets/similar-video/icon_warning.svg';
import icon_add_file from '@/assets/similar-video/icon_add_file.svg';
import poster from '@/assets/icons/space/icon_video.svg';

const similarVideoStore = useSimilarVideo();
const { formParams, cacheMediaList } = storeToRefs(similarVideoStore);

const modalManager = useModalManager();

const previewerRoot = document.createElement('div');
document.body.appendChild(previewerRoot);

const { collectData, track } = useTrackStore();
const router = useRouter();
const route = useRoute();
const message = useMessage();
const promptFocused = ref(false);
const prompt = ref('');
const showPrompt = ref('');
const ratio = ref('9:16');
const language = ref('');
const loading = ref(false);
const languageOptions = ref([]);
const leftPage = ref(false);

const promptPreset = [
  {
    label: 'Three shortcuts to create viral videos with Similarvideo',
    value: `Explore Similarvideo with me that brings you the most efficient video production journey. 

First, create short videos in just 3 minutes, which is 10 times faster than editing them all by yourself!

Second, featuring interesting celebrities in your videos can triple engagement by boosting comments and discussions on your content.

Last but not least, using emotional voiceovers that support different languages for targeted audiences in various regions can boost your conversion rate by 30%.

Stop hesitating, sign up right now! `,
  },
  {
    label:
      'Step-by-step to create an effective product promotion video with Similarvideo',
    value: `Let me show you some simple steps to create product promotion videos:

1. First, paste your product URL link. Our AI will crawl your listing page to create video script options. Similarvideo will generate high-quality, high-performing personalized video scripts for your product.

2. Then, review the generated scripts and select the one that best fits your product and brand style. Unsatisfied with the scripts? You can always generate more until you find the perfect one!

3. Lastly, choose the perfect visual style, voice, and avatar to customize your video.

Once satisfied, hit 'Generate' to create a final video that will drive your business marketing success.
  `,
  },
  {
    label: 'How to extract video subtitles from videos?',
    value: `
    Effortlessly extract subtitles from hardcoded or audio sources with Similarvideo's AI-driven tool. 

    Similarvideo subtitle extracted supports video from different platforms, supporting extraction for TikTok and YouTube Shorts.

    Utilizing advanced AI for subtitle extraction ensures high-quality results with a 35% improvement.

    Our efficient AI process quickly extracts subtitles without compromising quality, saving time in video creation.

    What are you waiting for? Sign up to Similarvideo right now!
    `,
  },
  {
    label: 'Six Powerful Features of Similarvideo',
    value: `
    Explore the six most powerful features of SImilarvideo with me.

    1. AI-driven script generator, supports input using competitors' URLs.

    2. Extensive celebrity voice library to boost video engagement

    3. Compatible with multiple platforms, including TikTok, Shorts, and Reels.

    4. Stock footage library with millions of High-Quality Videos

    5. Support multi-national voice. You can translate the voice-over into multi-national voice version

    6. Powerful video timeline editor supports your own customizations

    There are more features waiting for you to discover!
    `,
  },
];

const subscribeModalVisible = ref(false);

const mediaList = ref([{ type: 'upload' }]);
const previewList = ref([]);
const previewData = ref([]);
const previewerRef = ref(null);
const uploadLoading = ref(false);
const fileInfo = ref(null);

const cloudAssetModal = ref(null);
const filterTypes = ref(['image', 'video']);
const showUploadBtn = ref(false);
const isOpenAssetDialog = ref(false);
const mediaUploader = ref(null);
const mediaFile = ref([]);
const mediaAccept = [
  {
    types: IMAGEANDVIDEOTYPE.split(','),
    maxSize: 100 * DataUnit.MB,
  },
];
const handleInput = (inputValue) => {
  if (inputValue.trim() === '') {
    prompt.value = '';
  } else {
    prompt.value = inputValue;
  }

  showPrompt.value = prompt.value === '';
};

const submit = async () => {
  prompt.value = removeEscapeHtml(prompt.value);

  if (prompt.value.trim() === '') {
    message.error('Script can not be empty', { duration: 2000 });
    return;
  }

  if (mediaList.value.find((item) => item.state == 2)) {
    message.error('Please wait, the file is uploading.', { duration: 2000 });
    return;
  }

  const params = {
    prompt: prompt.value,
    mids: mediaList.value.map((item) => item.mid).filter((id) => id),
  };

  const { noCredit, msg, code } = await checkResource({
    resource: [90011],
  });

  if (code == 101011) {
    modalManager.applyTemplate('paymentFail', {});
    return;
  }

  if (noCredit) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  collectData('similarvideo_script_input', {
    clone_script: prompt.value,
    video_type: "similar_video_script_to_video",
  });
  track('similarvideo_script_input');

  similarVideoStore.setMediaList(mediaList.value);
  similarVideoStore.setFormParams(params);

  router.push({
    path: '/similar-video/script-setting',
  });
};

const handleFocus = () => {
  promptFocused.value = true;
  showPrompt.value = prompt.value === '';
};

const handleBlur = () => {
  promptFocused.value = false;
  showPrompt.value = false;
  prompt.value = removeEscapeHtml(prompt.value);
};

const clickPrompt = (value) => {
  prompt.value = value;
};

// 素材上传
const handleMediaUpload = () => {
  if (mediaUploader.value[0]) {
    mediaUploader.value[0].handleClickUpload();
  }
};

const handleConfirm = async (list) => {
  const tempList = [...mediaList.value, ...list];

  try {
    await checkLimit(tempList);
  } catch (e) {
    handleCheckLimitFail(e);
    return;
  }

  mediaList.value = tempList;
  isOpenAssetDialog.value = false;
  cloudAssetModal.value.clearSelectedMidList();
};

const handleUpFromCloud = () => {
  isOpenAssetDialog.value = true;
};

const handleCloseAssetDialog = () => {
  isOpenAssetDialog.value = false;
};

const handleBtnMouseMove = () => {
  showUploadBtn.value = true;
};

const handleBtnMouseLeave = () => {
  showUploadBtn.value = false;
};

const handleCheckLimitFail = ({ errorType }) => {
  if (errorType == 'video') {
    message.error(`Upload failed. Max 5 videos, each video < 30S.`, {
      duration: 2000,
    });
  }

  if (errorType == 'max') {
    message.error(`Upload failed. Up to 15 files.`, {
      duration: 2000,
    });
  }
};

const handleUploadMediaSuccess = (file) => {
  const index = mediaList.value.findIndex((item) => item.tempId == file.tempId);

  if (index >= 0) {
    mediaList.value.splice(index, 1, file);
  }
};

const handleBeforeUploadMediaFiles = async (files) => {
  const list = [...mediaList.value, ...files];
  try {
    await checkLimit(list);
  } catch (e) {
    handleCheckLimitFail(e);
    return;
  }
  mediaList.value = list;
};

const checkLimit = (files) => {
  return new Promise((resolve, reject) => {
    if (files.length > 16) {
      reject({
        errorType: 'max',
      });
    }

    let videoCount = 0;
    files.forEach((file) => {
      console.log('file===', file, file.type);
      if (file.type?.includes('video')) {
        videoCount += 1;
      }
    });

    if (videoCount > 5) {
      reject({
        errorType: 'video',
      });
    }

    resolve(true);
  });
};

const handlePreview = (item) => {
  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type,
    coverPic: item.coverPic,
    duration: item.duration,
  };

  if (previewerRef.value) {
    previewerRef.value.open = true;
  }
};

watch(
  () => mediaList.value,
  (list) => {
    previewList.value = list
      .filter((item) => item.preview1080Url)
      .map((image) => {
        return {
          mid: image.mid,
          coverPic: image.coverPic,
          preview1080Url: image.preview1080Url || image.url,
          type: image.type,
        };
      });
  },
  {
    deep: true,
    immediate: true,
  }
);

const changePreview = (mid, direction) => {
  const index = previewList.value?.findIndex((e) => e.mid == mid);
  let item = null;
  if (direction == 1) {
    item = previewList.value[index + 1];
  } else if (direction == -1) {
    item = previewList.value[index - 1];
  }
  console.log('changePreview', item);
  if (!item) {
    return;
  }

  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type,
    coverPic: item.coverPic,
    duration: item.duration,
  };
};

const handleShowDelete = (e, item) => {
  item.showRemove = true;
  mediaList.value.forEach((media) => {
    if (media.mid != item.mid) {
      media.showRemove = false;
    }
  });
};

const handleHiddenDelete = (e, item) => {
  item.showRemove = false;
  mediaList.value.forEach((media) => {
    if (media.mid != item.mid) {
      media.showRemove = false;
    }
  });
};

const handleRemove = (item) => {
  const idx = mediaList.value.findIndex((media) => media.mid == item.mid);
  if (idx >= 0) {
    mediaList.value.splice(idx, 1);
  }
};

const formatDuration = (duration) => {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${(minutes | 0).toString().padStart(2, '0')}:${(seconds | 0)
    .toString()
    .padStart(2, '0')}`;
};

onMounted(() => {
  // 官网携带参数
  const script = route.query.script;

  if (script) {
    prompt.value = script;
    return;
  }

  if (formParams.value?.prompt) {
    prompt.value = formParams.value.prompt;
    mediaList.value = cacheMediaList.value?.length
      ? cacheMediaList.value
      : mediaList.value;
  }
});

onBeforeUnmount(() => {
  leftPage.value = true;
});
</script>

<style lang="scss" scoped>
:deep(.el-scrollbar) {
  width: 100%;
}

:deep(.form-scrollbar .el-scrollbar__view) {
  display: flex;
  justify-content: center;
}

.form-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
}

.script-render-form-container {
  width: 50%;
  min-width: 500px;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  color: #060606;
  padding-bottom: 200px;

  & > div {
    width: 100%;
  }
}

.input-container {
  width: 100%;
  padding-left: 9px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tips {
  color: rgb(100, 106, 115);
}

.select-container {
  height: 46px;
}

.prompt-container {
  position: relative;

  & > :deep(.el-textarea) {
    padding-bottom: 40px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    transition: border-color 0.2s;

    &:hover {
      border-color: #be9fff;
    }

    &.focused {
      border-color: #875eff;
    }

    &.exceeded > .el-input__count {
      color: #ff5449;
    }

    & > .el-textarea__inner {
      padding: 20px 20px 0;
    }

    & > .el-input__count {
      right: 12px;
      bottom: 12px;
      transition: color 0.2s;
    }
  }
}

.media-container {
  .media-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 18px;
    cursor: pointer;
    border-radius: 4px;
  }

  .poster-image {
    width: 36px;
    height: 36px;
  }

  .video-duration {
    position: absolute;
    left: 14px;
    bottom: 14px;
    padding-inline: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    background-color: #00000073;
    border-radius: 2px;
  }

  .delete-btn {
    cursor: pointer;
  }

  .upload-loading {
    position: absolute;
    background: #00000080;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
  }

  .image-box {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    border-radius: 4px;
  }

  .upload-btn {
    width: 100%;
    height: 100%;
    border: 1px solid #e5e7eb;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  .upload-content {
    height: 100%;
    padding: 36px 0;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upload-content {
    color: #060606;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .upload-computer,
  .upload-cloud {
    display: flex;
    width: 138px;
    padding: 7px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    border: 1px solid #875eff;
    color: #a378ff;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    &:hover {
      background: #f8f5ff;
    }
  }

  .media-box {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .icon-add-file {
    width: 36px;
    height: 36px;
  }

  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }

  .icon-tip {
    width: 18px !important;
    height: 18px;
    margin-left: 6px;
    cursor: pointer;
  }

  .tips-content {
    background: #000;
    width: 100%;
    height: 100%;
    color: #fff;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.hide {
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  opacity: 1;
  pointer-events: all;
}

.prompt-popover {
  position: absolute;
  width: 100%;
  bottom: -4px;
  transform: translateY(100%);
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
  z-index: 2;
  transition: opacity 0.08s linear 0s;

  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
  }
}

.prompt-preset-grid {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.prompt-preset-grid-item {
  width: 100%;
  height: 100%;
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fd;
  }
}

.button-container {
  position: fixed;
  bottom: 58px;
  width: fit-content !important;
}

.button-text {
  position: relative;
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & > .star {
    margin-right: 6px;
  }

  & > .loading-icon {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }
}

.loading-container {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

:deep(.el-slider) {
  --el-slider-button-size: 18px;
  --el-slider-button-wrapper-size: 18px;
}

:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #060606;
}

:deep(.el-textarea__inner) {
  font-family: Inter-variant !important;
  color: #060606;
  font-size: 14px;
  line-height: 20px;
  border: none;
  box-shadow: none;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::placeholder {
    color: #8f959e;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e5e7eb inset !important;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper.show-box-shadow.is-focus) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #875eff inset !important;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
  font-size: 14px;
  line-height: 20px;
}

:deep(.el-input__wrapper) {
  border: 1px solid #e5e7eb;
  box-shadow: none;
  transition: all 200ms;
  padding: 0 12px;
}

:deep(.el-input .el-input__wrapper:hover),
:deep(.el-textarea__inner:hover) {
  outline-color: #be9fff;
  border-color: #be9fff;
  box-shadow: none !important;
}

:deep(.el-input .el-input__wrapper.is-focus),
:deep(.el-textarea__inner:focus) {
  border-color: #875eff !important;
  outline-color: #875eff !important;
  outline-offset: 0;
  box-shadow: none !important;
}

:deep(.el-input__prefix) {
  margin-right: 6px;
  color: #646a73;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.el-input__prefix-inner > :last-child) {
  margin: 0;
}

:deep(.idea-language-select .bv-select-dropdown) {
  height: 400px;
}
</style>

<style lang="scss">
.bv-select-dropdown.idea-language-select ul {
  max-height: 250px;
}

.box-item.el-popper {
  padding: 0;
}

.box-item.el-popper.is-dark {
  border: 1px solid #000;
}

.box-item.el-popper.is-dark .el-popper__arrow::before {
  background: #000 !important;
  border: 1px solid #000 !important;
}
</style>
