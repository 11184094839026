<template>
  <div>
    <form class="w-full" id="payment-form">
      <div id="payment-element" />
      <PrimaryButton
        class="mt-5 box-border text-center w-full rounded-full h-12 payment-button"
        @click="handleSubmit"
        v-if="isPaymentReady"
        :disabled="btnDisabled"
        :loading="btnLoading"
      >
        <span class="text-sm">Complete purchase</span>
      </PrimaryButton>
      <p
        class="confirm-error"
        :style="{ minHeight: errorMessage ? '30px' : 0 }"
      >
        {{ errorMessage }}
      </p>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted, defineEmits } from "vue";
import {
  loadStripe,
  type Stripe,
  type StripeElements,
} from "@stripe/stripe-js";

const emits = defineEmits(["ready"]);
const props = defineProps({
  clientSecret: {
    type: String,
    required: true,
  },
  customerId: {
    type: String,
    required: true,
  },
  packageCode: {
    type: Number,
    required: true,
  },
  couponCode: String,
  isSetup: {
    type: Boolean,
    default: true,
  },
  handleCreateSubscription: {
    type: Function,
    required: true,
  },
  email: {
    type: String,
  },
});

const isLoading = ref(false);
const isPaymentReady = ref(false);
const btnDisabled = ref(false);
const btnLoading = ref(false);
const errorMessage = ref("");
const { VITE_STRIPE_PK } = import.meta.env;

// stripeElements
let paymentElement = null;
let stripe: Stripe | null;
let elements: StripeElements;

const setup = async () => {
  isLoading.value = true;
  btnDisabled.value = true;
  stripe = await loadStripe(VITE_STRIPE_PK);
  const appearance = {
    variables: {},
    rules: {
      ".Label": {
        fontSize: "14px",
        fontWeight: "600",
      },
      ".Input": {
        fontSize: "14px",
        padding: "12px",
        boxSizing: "content-box",
      },
      ".Input::placeholder": {
        color: "#646A73",
      },
    },
  };
  if (stripe) {
    elements = stripe.elements({
      locale: "en",
      clientSecret: props.clientSecret,
      appearance,
    });
    paymentElement = elements.create("payment", {
      layout: "tabs",
    });
    paymentElement.mount("#payment-element");
    paymentElement.on("change", (event) => {
      errorMessage.value = "";
      if (event.complete) {
        btnDisabled.value = false;
      } else {
        btnDisabled.value = true;
      }
    });
    paymentElement.on("ready", () => {
      isPaymentReady.value = true;
      emits("ready", isPaymentReady.value);
    });
  } else {
    console.log("strip load failed");
  }
  isLoading.value = false;
};

watch(
  () => props.clientSecret,
  setup,
  {
    immediate: true,
  }
);

const handleSubmit = async () => {
  if (isLoading.value || btnDisabled.value) {
    return;
  }
  btnLoading.value = true;
  if (stripe) {
    if (props.isSetup) {
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: `${window.location.href}?customer_id=${props.customerId}&package_code=${props.packageCode}&coupon_code=${props.couponCode}`,
          payment_method_data: {
            billing_details: {
              email: props.email
            },
          },
        },
      });
      if (setupIntent && setupIntent.status !== "succeeded")  {
        btnLoading.value = false;
        return ;
      }
      if (error && error.message) {
        errorMessage.value = error.message as string;
      } else {
        try {
          await props.handleCreateSubscription().then((res: any) => {
            if (res.error) {
              throw res.error;
            }
            if (res.code !== 0) {
              throw error;
            }
          });
        } catch (error: any) {
          errorMessage.value = error;
        }
      }
    } else {
      let orderId = 0;
      let packageCode = 0;
      try {
        await props.handleCreateSubscription().then((res: any) => {
          const { error } = res;
          if (error) {
            throw error;
          } else {
            const { data, packageCode: _packageCode } = res;
            if (data.orderId) {
              orderId = data.orderId;
              packageCode = _packageCode;
            }
          }
        });
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                email: props.email
              },
            },
            return_url:
              window.location.origin +
              "/ltd/query?orderId=" +
              orderId +
              "&packageCode=" +
              packageCode,
          },
        });
        if (error && error.message) {
          throw error.message;
        }
      } catch (error: any) {
        errorMessage.value = error;
      }
    }
  }
  btnLoading.value = false;
};
</script>

<style lang="scss" scoped>
.payment-button {
  :deep(.el-button) {
    width: 100%;
  }
}

.confirm-error {
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-top: 12px;
  padding: 8px;
  color: #f54a45;
  text-align: left;

  transition-property: all;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.shimmer {
}
</style>
