import { _get, _post, _put } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

const URL = {
  login: VITE_API_BASE + "/user/login", //登录
  logout: VITE_API_BASE + "/user/logout", // 退出登录
  profile: VITE_API_BASE + "/user/current", //获取用户信息
  sendCode: VITE_API_BASE + "/email/send/register_code", //发送验证码
  register: VITE_API_BASE + "/user/register/email_code", //注册
  help: VITE_API_BASE + "/user/password/help", //重置密码 发送邮件
  resetPwd: VITE_API_BASE + "/user/password", //设置新的密码
  userInfoByAccessToken: VITE_API_BASE + "/google/login/userInfoByAccessToken", //google登录
  queryQuestion:  VITE_API_BASE + "/user/question", //查询是否填写过用户画像 
};

const getUserProfile = async () => {
  return await _get(URL.profile)
};

const login = async (data: { password: string; username: string }) => {
  return await _post(URL.login, data);
}

const logout = async () => {
  return await _put(URL.logout)
};

const register = async (data: {
  email: string;
  password: string;
  registerSource: number;
  username: string;
  verifyCode: string;
}) => {
  return await _post(URL.register, data)
};

const sendCode = async (data: Object) => {
  return await _post(URL.sendCode, data)
};

const help = async (data: { email: string }) => { 
  return await _put(URL.help, data)
};

const resetPwd = async (data: {
  email: string;
  password: string;
  state: string;
}) => {
  return await _put(URL.resetPwd, data)
};

const getUserInfoByAccessToken = async (data: Object) => {
  return await _get(URL.userInfoByAccessToken, data);
};

const queryUserPortrait = async () => {
  return await _get(URL.queryQuestion);
};

const postUserPortrait = async (data: Object) => {
  return await _post(URL.queryQuestion, data);
};

export {
  getUserProfile,
  logout,
  login,
  sendCode,
  help,
  register,
  resetPwd,
  getUserInfoByAccessToken,
  queryUserPortrait,
  postUserPortrait,
};
