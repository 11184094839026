type os =
  | "iOS"
  | "Android OS"
  | "Amazon OS"
  | "Windows"
  | "Linux"
  | "Mac OS"
  | "Chrome OS";
type OsRule = [os, RegExp];

const osRules: OsRule[] = [
  ["iOS", /iP(hone|od|ad)/],
  ["Android OS", /Android/],
  ["Amazon OS", /Kindle/],
  ["Windows", /(Windows)|(Win64)|(Win32)/],
  ["Chrome OS", /CrOS/],
  ["Linux", /(Linux)|(X11)/],
  ["Mac OS", /(Macintosh)|(MacIntel)|(MacPPC)|(Mac68K)/],
];

export function getOS(): os | null {
  for (let i = 0; i < osRules.length; i++) {
    const [os, regex] = osRules[i];
    const match = regex.exec(navigator.userAgent);
    if (match) {
      return os;
    }
  }
  return null;
}
