import { createHead } from "@vueuse/head";

export const head = createHead();

const headConfig: Record<string, any> = {
  home: {
    title: 'Boolvideo - AI Video Generator - Turn Everything into Videos',
    meta: [
      {
        name: 'keywords',
        content: 'AI video generator'
      },
      {
        name: 'description',
        content: 'Elevate your content with Boolvideo: Turn your resources into engaging videos easily! Drive leads, boost sales, amplify reach with the AI Video Generator.'
      },
    ],
  },
  ['feature-product-to-video']: {
    title: 'AI Product Video Generator - Transform product links into captivating videos',
    meta: [
      {
        name: 'keywords',
        content: 'AI Product video generator'
      },
      {
        name: 'description',
        content: 'Discover the power of Boolvideo\'s AI Product Video Generator, a revolutionary tool to turn Shopify, Etsy and Amazon product links into captivating videos.'
      },
    ],
  },
  ['feature-idea-to-video']: {
    title: 'Idea to Video - Convert ideas into stunning videos',
    meta: [
      {
        name: 'keywords',
        content: 'Idea to Video'
      },
      {
        name: 'description',
        content: 'Transform your text into engaging videos effortlessly with Boolvideo\'s AI Video Generator. No video editing skills required.'
      },
    ],
  },
  ['feature-script-to-video']: {
    title: 'Script to Video - Turn scripts into compelling videos',
    meta: [
      {
        name: 'keywords',
        content: 'Script to Video'
      },
      {
        name: 'description',
        content: 'Transform your scripts into captivating videos with our Script to Video tool. Engage your audience like never before.'
      },
    ],
  },
  ['feature-blog-to-video']: {
    title: 'Blog to Video - Easily transform blogs into engaging videos',
    meta: [
      {
        name: 'keywords',
        content: 'Blog to Video'
      },
      {
        name: 'description',
        content: 'Effortlessly convert your blogs into captivating videos with our Blog to Video converter. Create engaging content in minutes.'
      },
    ],
  },
  ['feature-visuals-to-video']: {
    title: 'Visuals to Video - Convert visuals into dynamic videos',
    meta: [
      {
        name: 'keywords',
        content: 'Visuals to Video'
      },
      {
        name: 'description',
        content: 'Convert your visuals into dynamic videos with ease using our Visuals to Video tool. Bring your ideas to life in seconds.'
      },
    ],
  },
  ['feature-template-to-video']: {
    title: 'Video Templates - 500+ Creative Online Video Templates for Your Video Creation',
    meta: [
      {
        name: 'keywords',
        content: 'Video Templates'
      },
      {
        name: 'description',
        content: 'Boolvideo\'s video templates allow you to create high-quality videos in just minutes and for free.'
      },
    ],
  },
  ['feature-web-editor']: {
    title: 'Web Editor - Simplify Your Online Editing Experience',
    meta: [
      {
        name: 'keywords',
        content: 'Web Editor'
      },
      {
        name: 'description',
        content: 'Experience seamless editing with our Online Editor. Edit your videos anytime, anywhere, with ease and convenience.'
      },
    ],
  },
  ['feature-ai-tools']: {
    title: 'Boolvideo AI Video Generator - AI Tools',
    meta: [
      {
        name: 'keywords',
        content: 'AI tools',
      },
      {
        name: 'description',
        content: 'Boolvideo offers a suite of AI tools to transform your content'
      },
    ],
  },
  Pricing: {
    title: 'Boolvideo AI Video Generator - Pricing&Plans',
    meta: [
      {
        name: 'keywords',
        content: 'pricing',
      },
      {
        name: 'description',
        content: 'Create high-quality videos at an affordable price to grow your business with Boolvideo AI Video Generator.'
      },
    ],
  },
  premium: {
    title: 'Boolvideo AI Video Generator - Pricing&Plans',
    meta: [
      {
        name: 'keywords',
        content: 'pricing',
      },
      {
        name: 'description',
        content: 'Create high-quality videos at an affordable price to grow your business with Boolvideo AI Video Generator.'
      },
    ],
  },
  ['case-ecommerce-seller']: {
    title: 'Boolvideo - The Ultimate Video Solution for eCommerce Seller',
    meta: [
      {
        name: 'keywords',
        content: 'ecommerce seller, Boolvideo, drive sales',
      },
      {
        name: 'description',
        content: 'Unlock the power of video for your eCommerce business with Boolvideo. Create engaging videos that drive sales, build brand, and enhance customer experience.',
      }
    ]
  },
  ['case-content-creator']: {
    title: 'Boolvideo - Engage Your Audience with Captivating Video',
    meta: [
      {
        name: 'keywords',
        content: 'content creator, Boolvideo, drive sales',
      },
      {
        name: 'description',
        content: 'Elevate your content strategy with Boolvideo. Craft highly engaging video that resonate audience. Boost watch time, drive traffic, and monetize your content.',
      }
    ]
  },
  ['case-social-media-marketer']: {
    title: 'Boolvideo - Elevate Your Social Media Game for Marketers',
    meta: [
      {
        name: 'keywords',
        content: 'social media marketing, Boolvideo, boost engagement',
      },
      {
        name: 'description',
        content: 'Take your social media marketing to new heights with Boolvideo. Craft captivating videos that boost engagement and amplify your brand reach across social media.',
      }
    ]
  },
  ['case-photographer']: {
    title: 'Boolvideo - Showcase Artistry with Videos for Photographer',
    meta: [
      {
        name: 'keywords',
        content: 'photographer, Boolvideo, showcase art',
      },
      {
        name: 'description',
        content: 'Transform photography into immersive video experiences with Boolvideo. It empowers you to create stunning videos to highlight unique style and attract clients.',
      }
    ]
  },
  ['case-artist']: {
    title: 'Boolvideo - Express Creativity Through Video for Artists',
    meta: [
      {
        name: 'keywords',
        content: 'artist, Boolvideo, express creativity',
      },
      {
        name: 'description',
        content: 'Unleash your artistic vision with Boolvideo\'s video creation suite. Share your artwork with the world through engaging video content that captivates audience.',
      }
    ]
  },
  about: {
    title: 'Boolvideo AI Video Generator - About Boolvideo',
    meta: [
      {
        name: 'keywords',
        content: 'about',
      },
      {
        name: 'description',
        content: 'Boolvideo is an AI Video Generator that can turn your resources into engaging videos easily!',
      }
    ]
  },
  terms: {
    title: 'Boolvideo AI Video Generator- Terms',
    meta: [
      {
        name: 'keywords',
        content: 'terms',
      },
      {
        name: 'description',
        content: "You can find the terms (rules and guidelines) that apply to the use of Boolvideo's website. Please read the terms before using the services offered by Boolv.tech.",
      },
    ]
  },
  ['privacy-policy']: {
    title: 'Boolvideo AI Video Generator - Privacy Policy',
    meta: [
      {
        name: 'keywords',
        content: 'privacy policy',
      },
      {
        name: 'description',
        content: "We value your privacy and believe in transparency. It's crucial to us how we gather, utilize, and disseminate your information. Discover more in our Privacy Policy!",
      },
    ]
  },
  ['cookies-policy']: {
    title: 'Boolvideo AI Video Generator - Cookies Policy',
    meta: [
      {
        name: 'keywords',
        content: 'cookies policy',
      },
      {
        name: 'description',
        content: "Discover how Boolvideo AI Video Generator uses cookies to improve user experience, analyze usage data, and personalize content. Manage your preferences easily.",
      },
    ]
  },
  ['affiliate-program']: {
    title: 'Boolvideo Affiliate Program',
    meta: [
      {
        name: 'keywords',
        content: 'affiliate program',
      },
      {
        name: 'description',
        content: "Join our affiliate program now to earn a 40% commission on every sale. Share your passion and monetize your influence!",
      },
    ]
  },
};

export const headMap = headConfig;