<template>
  <div class="tag tag-popular" 
    v-if="card.name === 'Pro'"
  >
    Most popular
  </div>
  <div class="tag tag-save" 
    v-if="card.name === 'Standard'"
  >
    Best price
  </div>
  <div class="card-header">
    <!-- card标题 -->
    <p class="card-title">{{ card.title }}</p>
    <!-- 价格 -->
    <div class="card-price">
      <!-- 模拟价格的骨架 -->
      <el-skeleton
        style="width: 80px; height: 60px"
        :loading="isLoading"
        animated
      >
        <template #template>
          <el-skeleton-item variant="text" style="width: 100%; height: 100%" />
        </template>
        <template #default>
          <span v-if="card.couponCode" style="height: 60px; color: #6741FF">
            {{ card.monthlyDiscount }}
          </span>
          <span v-else style="height: 60px">
            {{ card.monthlyPrice }}
          </span>
        </template>
      </el-skeleton>
    </div>
    <p class="text-titleColor price-description" v-if="card.couponCode">
      {{ `Per user${ card.cycleType === "year"? " / mon" : "" } for first ${card.cycleType}` }}
    </p>
    <p class="text-titleColor price-description" v-else>
      Per user / month
    </p>
    <div class="payment-description-container">
      <p class="payment-description" v-if="card.couponCode">
        {{ `Then ${card.monthlyPrice} / mon starting next ${card.cycleType}` }}
      </p>
      <p class="payment-description">
        {{ card.paymentDescription }}
      </p>
    </div>
    <!-- card plan描述 -->
    <p
      class="card-plan-description"
      v-if="card.couponCode"
    >
      <span style="color: #6741FF">{{ `With code: ${card.couponCode}` }}</span>
    </p>
    <p v-else class="card-plan-description">
      {{ card.planDescription }}
    </p>
  </div>
  <!-- 卡片按钮 -->
  <div
    :class="`card-button relative ${
      isLoading || btnDisabled ? 'pointer-events-none cursor-default' : ''
    }`"
  >
    <el-icon :style="loadingStyle" v-if="btnDisabled">
      <Loading />
    </el-icon>
    <button
      v-if="canCancel"
      class="current-button"
      :disabled="btnDisabled"
      @click="handleSubscribing(card)"
    >
      {{ initButtonType(card) }}
    </button>
    <button
      v-else
      class="upgrade-button"
      :class="{'plain-button': card.packageCode == 0, 'contact-button': card.name == 'Ultra'}" 
      @click="enquiryOperType(card)"
      :disabled="btnDisabled"
    >
      {{ initButtonType(card) }}
    </button>
  </div>
  <!-- 卡片主体 -->
  <div class="card-body">
    <div class="free-body">
      <div class="tool-wrapper">
        <ul class="free-tool-box">
          <li v-for="(tool, index) in card.priorityList" :key="index" class="flex items-start">
            <img :src="icon_selected" style="marginTop: 4px" /> <span> {{ tool }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <button 
    v-if="canCancel"
    class="cancel-button"
    @click="handleCancel"
  >
    Cancel subscription
  </button>
</template>

<script setup lang="ts">
import { reactive, defineProps, ref, type PropType } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useMessage } from "@/utils";
import { debounce } from "@/utils/debounce";
import { isLogin, getToken } from "@/utils/auth";
import { getOperType } from "@/api/premium";
import type { CardItemInfo } from "../../type";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import icon_selected from '@/assets/images/premium/icon_selected.png'

const message = useMessage();
const route = useRoute();
const router = useRouter();
const { collectData, getTrackPlanName } = useTrackStore();
const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { updateSubscriptionState } = useSubscriptionInfo();

const props = defineProps({
  card: {
    type: Object as PropType<CardItemInfo>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
});

const ltdPackageCode = [300204, 300205, 300304, 300305, 300404, 300405];
const btnDisabled = ref(false);
// 按钮loading时Spin图标样式
const loadingStyle = reactive({
  position: "absolute",
  left: "50px",
  top: "50%",
  color: "#fff",
  transform: "translate(-50%,-50%)",
});
const canCancel = computed(() => {
  const state = subscriptionState.value;
  return state.packageCode === props.card.packageCode && !state.cancelSubAtPeriodEndBoolean
})
const initButtonType = (card: CardItemInfo) => {
  const state = subscriptionState.value;
  if (card.packageCode > 0 && ltdPackageCode.includes(state.packageCode)) {
    return "Update Plan";
  }

  if (state.packageCode === card.packageCode) {
    if (state.cancelSubAtPeriodEndBoolean) {
      return "Resume Plan";
    } else {
      return "Subscribing";
    }
  } else if (state.packageCode > 0 && card.packageCode > 0) {
    return "Update Plan";
  } else {
    return card.buttonText;
  }
};

const updateTriggerGA = (card: CardItemInfo) => {
  const state = subscriptionState.value;

  if (state.packageCode === card.packageCode) {
    if (state.cancelSubAtPeriodEndBoolean) {
      gtag("event", "click_resume_subscribe", {
        type: card.cycleType,
        package_code: card.packageCode,
      });
    }
  } else {
    if (state.packageCode == -1) {
      gtag("event", `click_subscribe`, {
        type: card.cycleType,
        package_code: card.packageCode,
      });
      return;
    }
    // update订阅埋点
    gtag("event", `click_update_subscribe`, {
      type: card.cycleType,
      package_code: card.packageCode,
    });
  }
};

// 查询当前操作状态
const enquiryOperType = debounce(async (card: CardItemInfo) => {
  updateTriggerGA(card);
  if (!isLogin.value) {
    router.push({ path: "/signup", query: { redirect: route.fullPath } });
  }
  if (card.name === "Ultra") {
    props.userOperationMap.contactUs();
    return;
  }
  const state = subscriptionState.value;
  if (ltdPackageCode.includes(state.packageCode)) {
    props.userOperationMap.ltdTips();
    return;
  }
  const packageCode = card.packageCode;

  if (packageCode > 0) {
    btnDisabled.value = true;
    const cancelOperBoolean = false;
    await getOperType({ cancelOperBoolean, packageCode })
      .then((res) => {
        if (res.code === 102022) {
          props.userOperationMap.yearToMonthTips();
          return;
        } else if (res.code === 0) {
          const {
            currentPeriodPaymentStatus,
            subscriptionOperType,
            boolvideoUpdateSubPopMsg,
          } = res.data;
          if (currentPeriodPaymentStatus === "UNPAID") {
          } else {
            switch (subscriptionOperType) {
             
              case "NEW_SUBSCRIPTION":
                props.userOperationMap.subscribe(props.card);
                break;
              case "UPGRADE_SUBSCRIPTION":
                collectData("boolvideo_subscribe_upgrade_confirm", {
                  plan_name: getTrackPlanName(props.card.packageCode),
                });
                props.userOperationMap.upgrade(props.card);
                break;
              case "RECOVER_SUBSCRIPTION":
                (async () => {
                  await props.userOperationMap.recover();
                  await updateSubscriptionState();
                })();
                if (
                  window.opener &&
                  window.opener?.location.host === window.location.host
                ) {
                  window.opener.postMessage(
                    { subscribeSuccess: "true", token: getToken() },
                    window.opener.location.origin,
                  );
                }
                break;
              case "DEGRADE_SUBSCRIPTION":
                collectData("boolvideo_subscribe_downgrade_confirm", {
                  plan_name: getTrackPlanName(props.card.packageCode),
                });
                props.userOperationMap.downgrade(
                  props.card,
                  boolvideoUpdateSubPopMsg,
                );
                break;
              default:
                message.error("Oops, this is a error message.");
            }
          }
        }
      })
      .finally(() => {
        btnDisabled.value = false;
      });
  }
}, 500);

const subscribeTriggerGA = (card: CardItemInfo) => {
  const state = subscriptionState.value;
  if (state.packageCode === card.packageCode) {
    if (!state.cancelSubAtPeriodEndBoolean) {
      gtag("event", `click_subscribing`, {
        type: card.cycleType,
        package_code: card.packageCode,
      });
    }
  } else {
    // 初次订阅埋点
    gtag("event", `click_subscribe`, {
      type: card.cycleType,
      package_code: card.packageCode,
    });
  }
};

const handleCancel = (card: CardItemInfo) => {
  collectData("boolvideo_subscribe_cancel_confirm", {
    plan_name: getTrackPlanName(props.card.packageCode),
  });
  props.userOperationMap.subscribing(card, 2);
};

const handleSubscribing = (card: CardItemInfo) => {
  subscribeTriggerGA(card);
  props.userOperationMap.subscribing(card, 1);
};
</script>

<style lang="scss" scoped>
.tag {
  position: absolute;
  background: #6741ff;
  top: 12px;
  left: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 8px;
  background: #fff500;
  border-radius: 4px;
}

.tag-popular {
  background: #fff500;
  color: #000000;
}

.tag-save {
  background: #6741FF;
  color: #ffffff;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #060606;
  margin-top: 32px;
}

.card-price {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: normal;
  text-align: center;
  color: #060606;
  margin-top: 12px;
}

.payment-description {
  margin-top: 4px;
  font-size: 16px;
  color: #646a73;

  &::after {
    content: "\200B";
  }
}

.card-plan-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #646a73;
  height: 57px;
  text-align: center;
  margin-top: 24px;
}

.card-body {
  flex: 1 1;
  margin-top: 36px;
}

.free-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.free-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #060606;
}

.tool-wrapper {
  max-width: 282px;
}

.free-tool-box {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  list-style: disc;
  color: #060606;
  padding-left: 8px;
  word-wrap: break-word;

  li {
    color: #060606;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 220%;
    margin-bottom: 8px;

    img {
      width: 14px;
      margin-right: 6px;
    }
  }

  span {
    vertical-align: middle;
  }
}

.payment-description-container {
  flex: 0 0 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// button
.card-button {
  width: 100%;
  height: 48px;
  border-radius: 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.free-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  color: #8f959e;
  border: 1px solid #d0d0d0;
}

.upgrade-button {
  width: 100%;
  max-width: 223px;
  height: 100%;
  border-radius: 36px;
  color: #ffffff;
  transition: all 0.5s;
  background: #6741ff;
  box-sizing: border-box;
  font-size: 16px;
  &:hover {
    background: #7856ff;
  }

  &:disabled {
   
    background: #bbbfc4;
  }
}

.contact-button {
  border: 1px solid #6741FF;
  background: #fff !important;
  color: #6741FF;
  font-size: 16px;
}

.plain-button {
  background: #fff;
  border-radius: 36px;
  border: 1px solid #6741FF;
  color: #6741FF;
  &:hover {
    background: #F8F5FF;
  }
}

.current-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  border: 1px solid #875eff;
  color: #875eff;

  &:disabled {
    background: #bbbfc4;
  }
}

.cancel-button {
  margin-top: 46px; 
  color: #646A73;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (min-width: 1340px) {
  .card-button {
    max-width: 255px;
  }
}
</style>
