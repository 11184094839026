<template>
  <section class="comment-quote-part">
    <div class="bg-image" />
    <div class="comment-bg-top"></div>
    <div class="comment-bg-footer"></div>

    <div class="content-box">
      <h2 class="flex justify-center items-center">
        <img :src="iconLove" style="margin-right: 2px; width: 29px; height: 26px"/>
        So much love 
        </h2>
      <h2 class="flex justify-center" style="margin-bottom:38px">
        in the air
      </h2>
      <ul ref="listRef" class="comment-list">
        <li v-for="list of renderList">
          <Comment v-for="item of list" :key="item.key" :quote="item" />
        </li>
      </ul>
      <router-link to="/workspace">
        <div class="cta-button">
          <p class="button-text">Try BoolVideo Now!</p>
          <!-- <p class="button-tip">3-day-free-trial</p> -->
        </div>
      </router-link>
    </div>
  </section>
</template>
<script setup>
import quoteList from './data';
import Comment from './comment.vue';
import iconLove from '@/assets/bvMobile/iconLove.svg'
import { ref, onMounted, reactive } from 'vue';

const renderList = reactive(Array.from({ length: 4 }).map(() => []));
const listRef = ref(null);

const getMinHeightElementIndex = () => {
  const children = listRef.value?.children;
  const min = {
    index: 0,
    height: Infinity,
  };

  for (let i = 0; i < children?.length; i++) {
    const element = children[i];

    if (element.offsetHeight < min.height) {
      min.index = i;
      min.height = element.offsetHeight;
    }
  }

  return min.index;
};

onMounted(async () => {
  for (const item of quoteList) {
    const index = getMinHeightElementIndex();
    renderList[index].push(item);
    await nextTick();
  }
});
</script>

<style scoped lang="scss">
.comment-quote-part {
  margin-top: 60px;
  height: 787px;
  padding: 25px 16px 70px 16px;
  background: #f6faff;
  position: relative;

  .bg-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 449px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      #ffefc5 0%,
      rgba(246, 250, 255, 0) 103.48%
    );
  }

  .comment-bg-top {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 176.195px;
    flex-shrink: 0;
    background-size: cover;
    background-image: url('@/assets/bvMobile/commentBgTop.png');
  }

  .comment-bg-footer {
    position: absolute;
    left: 0;
    bottom: 60px;
    z-index: 2;
    width: 100%;
    height: 211.118px;
    flex-shrink: 0;
    background-size: cover;

    background-image: url('@/assets/bvMobile/commentBgFooter.png');
  }

  .content-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    margin-top: 25px;
    padding: 0px 16px;
  }

  .content-box > h2 {
    color: #060606;
    text-align: center;
    font-family: Inter-variant;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 1;
  }
}

.comment-list {
  position: relative;
  display: flex;
  gap: 6.25px;

  & > li {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6.25px;
    flex: 1;
    height: fit-content;
    z-index: 1;
  }
}

.cta-button {
  display: flex;
  margin-top: 60px;
  width: 353px;
  height: 74px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 500px;
  background: #181818;
  cursor: pointer;
}

.button-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.button-tip {
  color: #bfbfbf;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 200% */
}
</style>
