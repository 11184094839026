import { defineStore } from "pinia";
import { ParseConfig } from "@/libs/parse-config/index";
import { EditableAssetItem, EditOffsetData } from "@/typings/editVideo";
import {
  getEditDraftDetail,
  putDraft,
  exportVideo,
  getSingleRenderInfo,
} from "@/api/index";
import { fileUploadPre, uploadFile } from "@/api/upload";
import { dataURLtoBlob, blobToFile } from "@/utils/index";
import { Message } from "@/utils/dom";
import modalInstance from "@/components/common/custom-modal/instance";

type Counter = {
  totalCount: number;
  imageCount: number;
  textCount: number;
};

type ExportParams = {
  name: string;
  resolution: string;
};

type PreUploadParams = {
  suffix: string;
  size?: number;
  type: string;
  draftId?: string | number;
  cropMaterial?: string;
};

interface EditVideoState {
  rid: string;
  reqId: string;
  ratio: string;
  tags: string[];
  logo: boolean;
  saveTime: number;
  saveError?: boolean;
  isSaving: boolean;
  templateUrl?: string;
  templateId: string;
  draftId: string | undefined;
  editAssetInfo: EditableAssetItem[];
  saveAssetInfo: SaveAssetItem[];
  renderConfig?: string;
  layersData?: any[];
  uiAssets?: {};
  fps: number;
  counter: Counter;
  isLoaded: boolean;
  duration: number;
  templateHeight: number;
  templateWidth: number;
  verticalVersion?: boolean;
}

type SaveAssetItem = {
  key: string;
  type: number;
  layerId: string;
  material: string;
  adaptType: number;
  editable: boolean;
  fontSize?: number;
  height?: number;
  width?: number;
  index: number;
  positioningFrame: boolean;
  isReverseColor?: boolean;
  reverseColor?: string;
  fillColor?: string;
  offsetData: EditOffsetData;
};

export const useEditVideo = defineStore("editVideo", {
  state: (): EditVideoState => ({
    rid: "",
    reqId: "",
    draftId: "",
    ratio: "",
    tags: [],
    logo: false,
    templateUrl: "",
    templateId: "",
    // 后端传入的可编辑素材
    editAssetInfo: [],
    saveAssetInfo: [],
    // D 类视频Json数据
    renderConfig: "",
    // config图层
    layersData: [],
    // config assets
    uiAssets: [],
    // 是否为竖屏
    verticalVersion: true,
    counter: {
      totalCount: 0,
      imageCount: 0,
      textCount: 0,
    },
    fps: 0,
    duration: 0,
    templateHeight: 0,
    templateWidth: 0,
    isLoaded: false,
    saveTime: 0,
    saveError: false,
    isSaving: false,
  }),

  actions: {
    setLayerId(layersData: any[]) {
      this.editAssetInfo.forEach((asset) => {
        let target = layersData.find((layer) => layer.source == asset.key);
        if (target) {
          asset.layerId = target.layerId;
        }
      });
    },

    initEditVideo(animationData: Object) {
      const parseManage = new ParseConfig(animationData);
      const {
        fps,
        verticalVersion,
        templateHeight,
        templateWidth,
        duration,
        totalTime,
        layersData,
        uiAssets,
      } = parseManage;
      this.fps = fps;
      this.duration = duration;
      this.verticalVersion = verticalVersion;
      this.templateHeight = templateHeight;
      this.templateWidth = templateWidth;
      this.layersData = layersData;
      this.uiAssets = uiAssets;
      this.setLayerId(layersData!);
    },

    initAssets(assets: EditableAssetItem[]) {
      const newAsset = assets
        .sort(function (a, b) {
          return a.index - b.index;
        })
        .map((asset: EditableAssetItem) => {
          if (asset.type == 1) {
            this.counter.imageCount++;
          } else {
            this.counter.textCount++;
          }
          this.counter.totalCount++;

          if (asset.type === 1) {
            // 图片 预设用于图片裁剪
            asset.cropUrl = asset.material;
          } else if(asset.type === 9) {
            asset.originCoverPic = asset.coverPic
            asset.originMaterial = asset.material;
            asset.originDuration = asset.duration;
            asset.originAudioName = asset.audioName;
          } else {
            // 文案, 预设用于文案编辑
            asset.originMaterial = asset.material;
            asset.originFontSize = asset.fontSize;
            asset.originFillColor = asset.fillColor;
          }

          return {
            ...asset,
            layerId: asset.layerId,
            highLight: false,
          };
        });
      return newAsset || [];
    },

    updateEditSaveTime(time: number) {
      this.saveTime = time;
    },

    isBase64(str: string) {
      if (str?.startsWith("data") && str?.includes("base64")) {
        return true;
      }
      return false;
    },

    formateParamsFn(assets: EditableAssetItem[]) {
      return new Promise((resolve, reject) => {
        let assetCount = 0;
        // 用于保存草稿的素材数组
        let saveAssets: SaveAssetItem[] = [];

        assets.forEach(async (asset) => {
          let saveAsset = {
            key: asset.key,
            type: asset.type,
            layerId: asset.layerId,
            material: asset.material,
            adaptType: asset.adaptType,
            editable: asset.editable,
            fontSize: asset.fontSize,
            height: asset.height,
            width: asset.width,
            index: asset.index,
            positioningFrame: asset.positioningFrame,
            isReverseColor: asset.isReverseColor,
            reverseColor: asset.reverseColor,
            fillColor: asset.fillColor,
            offsetData: asset.offsetData,
            logo: asset.logo,
            max: asset.max,
            size: asset.size,
            duration: asset.duration,
            coverPic: asset.coverPic,
            audioName: asset.audioName,
            disabled: asset.disabled,
          };

          if (asset.type === 1) {
            saveAsset.material = asset.cropUrl;

            if (!asset.cropUrl) {
              return;
            }

            // 上传裁剪之后的图片 换取S3 URL
            if (this.isBase64(asset.cropUrl)) {
              try {
                const cropUrl = await this.getPreUpload(
                  asset.cropUrl,
                  asset.material,
                  "Unnamed",
                );
                saveAsset.material = cropUrl;
              } catch {
                reject();
              }
            }
          }

          assetCount++;
          saveAssets.push(saveAsset);

          if (assetCount == assets.length) {
            resolve(saveAssets);
          }
        });
      });
    },

    updateEditAssetInfo(assets: EditableAssetItem[]) {
      this.editAssetInfo = assets;
      this.saveDraft();
    },

    // 保存草稿
    async saveDraft() {
      try {
        if (!this.duration) {
          return;
        }

        let saveAssets = await this.formateParamsFn(this.editAssetInfo);
        this.saveAssetInfo = saveAssets as SaveAssetItem[];
        this.isSaving = true;

        const params = {
          assets: saveAssets as SaveAssetItem[],
          draftId: this.draftId,
          duration: Math.floor(Number(this.duration / this.fps)),
          ratio: this.ratio,
          rid: this.rid,
        };
        const {
          data: { draftId, lastUpdateTime },
        } = await putDraft(params);
        this.saveError = false;
        this.isSaving = false;
        this.draftId = draftId;
        this.saveTime = lastUpdateTime;
      } catch {
        this.saveError = true;
        this.isSaving = false;
        Message.error("Editing can not be synchronized", { duration: 3000 });
      }
    },

    // 预上传
    async getPreUpload(base64Data: string, material: any, filename: string) {
      var blob = dataURLtoBlob(base64Data);
      var file = blobToFile(blob, filename);
      const uploadParams: PreUploadParams = {
        suffix: ".jpg",
        type: "image",
        draftId: this.draftId,
        cropMaterial: material,
      };

      const data = await fileUploadPre(uploadParams);
      if (data.code === 40001) {
        modalInstance.modalManager.applyTemplate("upgradeTips", {
          msg: data.msg,
        });
        return "";
      }
      const { preSignUrl, cropUrl } = data.data;
      const { code } = await uploadFile(preSignUrl, file);
      if (code === 0) {
        return cropUrl;
      }
      return "";
    },

    // 获取draftId
    async getEditId(rid: string) {
      const { data, code } = await getSingleRenderInfo(rid);

      if (code !== 0) {
        return;
      }

      // 如果草稿不存在，保存草稿
      if (data.draftId) {
        this.draftId = data.draftId;
        this.getEditRenderInfo(this.draftId);
      } else {
        this.rid = data.rid;
        this.reqId = data.reqId;
        this.ratio = data.ratio;
        this.editAssetInfo = this.initAssets(data.assets);
        this.draftId = data.draftId;
        this.tags = data.tags;
        this.templateUrl = data.templateUrl;
        this.templateId = data.templateId;
        this.renderConfig = data.renderConfig;
        // 根据 draftID 获取编辑素材
        // this.updateEditAssetInfo(this.editAssetInfo)
      }
    },

    // 获取render info editId: draftId
    async getEditRenderInfo(editId: string) {
      try {
        const { code, data, msg } = await getEditDraftDetail(editId);

        if (code !== 0) {
          console.log("error ==== ", msg);
          return;
        }

        this.rid = data.rid;
        this.reqId = data.reqId;
        this.ratio = data.ratio;
        this.editAssetInfo = this.initAssets(data.assets);
        this.draftId = data.draftId;
        this.tags = data.tags;
        this.templateUrl = data.templateUrl;
        this.templateId = data.templateId;
        this.renderConfig = data.renderConfig;
      } catch (error) {
        return error;
      }
    },

    clearDraftId() {
      this.draftId = "";
      this.rid = "";
      this.reqId = "";
      this.duration = 0;
      this.templateUrl = "";
      this.templateId = "";
      this.editAssetInfo = [];
      this.renderConfig = "";
    },
  },

  getters: {
    getHorizontalVersion: (state) => state.verticalVersion,
    getEditAssetInfo: (state) => state.editAssetInfo,
    getLayersData: (state) => state.layersData,
    getUiAssets: (state) => state.uiAssets,
    getIsLoaded: (state) => state.isLoaded,
  },
});
