<template>
  <common-button
    v-bind="commonProps"
    type="primary"
    :class="{
      'bv-primary-button': !disabled,
      'bv-primary-button-disabled': disabled,
      'bv-primary-button-shadow': shadow,
      flex: true,
      'items-center': true,
    }"
  >
    <slot name="preIcon"></slot>
    <slot></slot>
    <slot name="suffixIcon"></slot>
  </common-button>
</template>
<script setup lang="ts">
import CommonButton from "./common-button.vue";
import { buttonProps } from "element-plus";
import { useNamespace } from "element-plus";
import { type PropType, toRefs, reactive } from "vue";
import type { ButtonType } from "../type";

const ns = useNamespace("button");
const props = defineProps({
  ...buttonProps,
  padding: String,
  fontColor: String,
  width: String,
  showDefaultIcon: {
    type: Boolean,
    default: true,
  },
  shadow: Boolean,
  size: {
    type: String as PropType<ButtonType>,
    default: "default",
  },
  round: {
    type: Boolean,
    default: true,
  },
  onclick: Function as PropType<(e: MouseEvent) => void>,
});

const { shadow, ..._commonProps } = toRefs(props);
const commonProps = reactive(_commonProps);
</script>

<style lang="scss" scoped>
.bv-primary-button-shadow {
  :deep(.el-button--primary) {
    box-shadow: 0px 6px 18px 0px rgba(138, 98, 255, 0.43);
  }
}

.bv-primary-button {
  :deep(.el-button--primary) {
    // padding: 7px 36px;
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;
  }

  :deep(.el-button) {
    border: none;
    background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;

    span {
      color: #fff !important;
    }

    &:hover {
      background: linear-gradient(314deg, #6f46f4 7.72%, #957aec 100%);
    }
  }
}

.bv-primary-button-disabled {
  :deep(.el-button--primary) {
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;
    --el-button-disabled-bg-color: none !important;
  }

  :deep(.el-button) {
    border: none;
    background: #bbbfc4 !important;
    --el-button-active-bg-color: none !important;
    --el-button-active-border-color: none !important;
    cursor: default;

    span {
      color: #fff !important;
    }
  }
}
</style>
