<template>
  <el-dialog
    title="Subscription Details"
    v-model="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :align-center="true"
    class="subscribing-modal"
  >
    <template #header>
      <div class="flex justify-between items-center">
        <p v-if="step === 1" class="title">Subscription Details</p>
        <div v-if="step === 2" class="flex">
          <div
            :class="`cursor-pointer rounded-sm  hover:bg-closeBtnHoverColor mr-1`"
            @click="stepBack"
          >
            <svg
              width="24"
              height="24"
              viewBox="1 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0303 5.46967C16.3232 5.76256 16.3232 6.23744 16.0303 6.53033L10.5607 12L16.0303 17.4697C16.3232 17.7626 16.3232 18.2374 16.0303 18.5303C15.7374 18.8232 15.2626 18.8232 14.9697 18.5303L8.96967 12.5303C8.67678 12.2374 8.67678 11.7626 8.96967 11.4697L14.9697 5.46967C15.2626 5.17678 15.7374 5.17678 16.0303 5.46967Z"
                fill="#1C1B1E"
              />
            </svg>
          </div>
          <p class="title">Premium benefits that end with your subscription</p>
        </div>
        <div
          class="hover:bg-closeBtnHoverColor rounded-sm cursor-pointer"
          @click="close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 7L17 17"
              stroke="#646A73"
              stroke-width="1.2"
              stroke-linecap="round"
            />
            <path
              d="M7 17L17 7"
              stroke="#646A73"
              stroke-width="1.2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </template>
    <div :class="`${step === 1 && 'h-[198px]'}`">
      <!-- step1 -->
      <div v-if="step === 1">
        <div
          class="w-full px-7 py-4 my-4 bg-primaryBgColor rounded-md text-black"
        >
          <div class="pb-2">
            <div class="flex justify-between relative">
              <p>Current Plan:</p>
              <p>{{ subscribingDetails.currentPlanName }}</p>
            </div>
          </div>
          <hr />
          <div class="flex justify-between py-3">
            <p>Subscription Date:</p>
            <p>{{ subscribingDetails.expiry }}</p>
          </div>
          <div class="flex justify-between">
            <p>Next Renewal Date:</p>
            <p v-if="!subscriptionState.cancelSubAtPeriodEndBoolean">
              {{ subscribingDetails.renewal }}
            </p>
            <button
              v-else
              class="cursor-pointer text-tipColor hover:text-primaryColor"
              @click="recover"
            >
              {{ subscribingDetails.renewal }}
            </button>
          </div>
          <div class="pt-3">
            <button
              v-if="!subscriptionState.cancelSubAtPeriodEndBoolean"
              class="text-tipColor hover:text-primaryColor"
              @click="stepForward"
              v-track:click="'boolvideo_subscribe_cancel'"
            >
              Cancel subscription
            </button>
          </div>
        </div>
        <p class="text-black">
          Service support:
          <a class="text-primaryColor" href="mailto:support@boolvector.com"
            >support@boolvector.com</a
          >
        </p>
      </div>
      <!-- step2 -->
      <div v-if="step === 2" class="h-full flex flex-col items-center">
        <el-scrollbar height="200px" class="items-scrollbar w-full mt-5">
          <div class="w-full flex flex-col justify-between text-black">
            <div v-for="(item, index) in cancelTips[subscriptionState.packageCode]" class="cancel-box">
              <svg-icon :name="emojiList[index % emojiList.length]" :size="30"/>
              <p class="image-details">{{ item }}</p>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <template #footer>
      <Space reversed v-if="step === 1">
        <CommonButton
          v-if="!subscriptionState.cancelSubAtPeriodEndBoolean && subscriptionState.packageCode !== 300101"
          class="text-tipColor hover:text-primaryColor"
          @click="handleContinueCancel"
          v-track:click="'boolvideo_subscribe_cancel'"
        >
          Cancel subscription
        </CommonButton>
      </Space>
      <Space reversed :size="20" v-if="step === 2">
        <PrimaryButton size="extra-small" @click="close">
          <p class="w-[88px] flex justify-center">No, keep my plan</p>
        </PrimaryButton>
        <CommonButton size="extra-small" @click="handleContinueCancel">
          <p class="w-[88px] flex justify-center">Continue</p>
        </CommonButton>
      </Space>
    </template>
  </el-dialog>
  <QuestionnaireModal 
    v-if="questionnaireVisible"
    v-model="questionnaireVisible" 
    @close="questionnaireVisible = false" 
    @submit="submit"
  />
  <GiftcardModal 
    v-model="giftcardVisible" 
    @close="close"
  />
</template>

<script setup lang="tsx">
import QuestionnaireModal from "./questionnaire.vue"; 
import GiftcardModal from "./giftcard.vue";
import { useModalManager } from "@/components/common/custom-modal/instance";
import Space from "@/components/common/space/index.vue";
import { CommonButton, PrimaryButton } from "@/components/common";
import { modal_banner, Success } from "@/assets/images/premium";
import { queryCancelAlert, ltdRefund, recoverSubscription } from "@/api/premium";
import { useSubscriptionInfo } from "@/store/modules/user";
import { usePricingStore } from "../../stores";
import { useTrackStore } from "@/store/modules/track";
import { cancelTips } from "../../constants";
import dayjs from "dayjs";


const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { updateSubscriptionState } = useSubscriptionInfo();
const { getCardInfo } = usePricingStore();
const { collectData, track, getTrackPlanName } = useTrackStore();
const modalManager = useModalManager();

const emit = defineEmits(['close'])
const props = defineProps({
  closeCallback: {
    type: Function,
    default: () => {},
  },
  userOperationMap: {
    type: Object,
    default: () => {},
  },
  initStep: {
    type: Number,
    default: 1,
  },
});

const visible = ref(true);
const step = ref(1);
const giftcardVisible = ref(false);
const questionnaireVisible = ref(false);

const canCancelCode = [300204, 300205, 300304,300305, 300404, 300405, -1 ]
const emojiList = [
  "style_pleading",
  "style_depressed",
  "style_crying",
  "style_confound",
  "style_tired",
  "style_perservering",
  "style_sorry",
  "style_frowning",
];

const initExpiry = () => {
  let expiry = "";
  if (subscriptionState.value.trialingBoolean) {
    expiry += "Trial starts on ";
  }

  expiry += dayjs(subscriptionState.value.currentSubCreateTime).format(
    "MMM DD, YYYY, H:mm:ss",
  );


  if(subscriptionState.value.currentSubCreateTime == 0) {
    expiry =  "-"
  }

  return expiry;
};

const initRenewal = () => {
  let renewal = "";
  renewal += dayjs(subscriptionState.value.nextRenewalDateTime).format(
    "MMM DD, YYYY, H:mm:ss",
  );
  if (subscriptionState.value.cancelSubAtPeriodEndBoolean) {
    renewal = "Recover my plan";
  }

    if(subscriptionState.value.nextRenewalDateTime == 0) {
    renewal =  "-"
  }
  return renewal;
};

const subscribingDetails = reactive({
  currentPlanName: subscriptionState.value.currentPlanName,
  expiry: initExpiry(),
  renewal: initRenewal(),
});

const tips: Ref<string[]> = ref([]);

const stepForward = () => {
  collectData("boolvideo_subscribe_cancel", {
    click: "click_cancel",
    plan_name: getTrackPlanName(subscriptionState.value.packageCode),
  });

  const cardInfo = getCardInfo(subscriptionState.value.packageCode);

  gtag("event", "click_cancel_subscribe", {
    type:cardInfo?.subscriptionCycle == "MONTH"
        ? "month"
        : cardInfo?.subscriptionCycle == "YEAR"
        ? "year"
        : "",
    package_code: subscriptionState.value.packageCode,
  });
  step.value += 1;
};

const stepBack = () => {
  step.value -= 1;
};

const handleContinueCancel = () => {
  collectData("boolvideo_subscribe_cancel", {
    click: "warning_tips",
  });
  track("boolvideo_subscribe_cancel");
  // 打开调查问卷
  questionnaireVisible.value = true;
};

const cancelLtd = async () => {
  const res = await ltdRefund();
  if (res.success) {
    refundSuccessModal();
  } else {
    return;
  }
};

const recover = async () => {
  await recoverSubscription().then((res) => {
    if (res.code === 0) {
      const cardInfo = getCardInfo(subscriptionState.value.packageCode);
      const pricingArr = cardInfo?.monthlyPrice?.split('$') || []
      gtag("event", `click_resume_success`, {
        type:
          cardInfo?.subscriptionCycle == "MONTH"
            ? "month"
            : cardInfo?.subscriptionCycle == "YEAR"
              ? "year"
              : "",
        package_code: cardInfo?.packageCode,
        price: Number(pricingArr[1]),
        currency: 'USD'
      });
      recoverSuccessModal();
    }
  });
}

const submit = async () => {
  if (subscriptionState.value.supportRefundBoolean) {
    await cancelLtd();
    updateSubscriptionState();
  } else {
    giftcardVisible.value = true;
  }
  questionnaireVisible.value = false;
};

const close = () => {
  questionnaireVisible.value = false;
  giftcardVisible.value = false;
  visible.value = false;
  updateSubscriptionState();
  emit("close");
};

onUnmounted(() => {
  close()
});

onMounted(async () => {
  if (props.initStep === 2) {
    stepForward();
  }

  await updateSubscriptionState()
  subscribingDetails.currentPlanName =  subscriptionState.value.currentPlanName
  subscribingDetails.expiry = initExpiry()
  subscribingDetails.renewal = initRenewal()
});

const refundSuccessModal = () => {
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="w-max flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Refund has been initiated
            </p>
          </div>
        </div>
        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p class="pb-6">
            Your bank may take upto 5-7 business days to credit the refund to
            your account. If you have any issues, please contact{" "}
            <a href="mailto:support@boolvector.com" class="text-primaryColor">
              support@boolvector.com
            </a>
          </p>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    close();
  };
};

const recoverSuccessModal = () => {
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Resume successfully !
            </p>
          </div>
        </div>

        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 pt-6 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p>As our esteemed member, you will :</p>
          <ul class="my-3 list-disc pl-5">
            <li>Enjoy exclusive features</li>
            <li>Priority support</li>
            <li>Regular updates</li>
            <li>Unique privileges</li>
          </ul>
          <p>
            We are always here to provide assistance and support to ensure you
            have a pleasant experience while using our services.
          </p>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
    close();
  };
};
</script>

<style lang="scss">
.subscribing-modal {
  width: 36%;
  min-width: 430px;
  max-width: 540px;
  border-radius: 8px;
  padding: 24px;

  .el-dialog__header {
    margin-right: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    padding: 0;
    padding-top: 30px;
  }
}
</style>
<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000;
}

.image-details {
  margin-left: 8px;
  text-align: center;
  color: #ff4204;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.cancel-box {
  display: flex;
  align-items: center;
  background: #fff4f3;
  border-radius: 4px;
  margin-bottom: 13px;
  height: 58px;
  padding: 14px;
}

.modal-container {
  padding: 0;
  position: relative;
  z-index: 10;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 36%;
  max-width: 530px;
  min-width: 430px;
  border-radius: 8px;
}

.modal-header {
  width: 100%;

  p:first-child {
    width: 100%;
  }
}

.modal-footer {
  padding: 24px;
  margin-top: 0;
}

.modal-content {
  position: relative;
}

.modal-content {
  margin-top: 0;
}
</style>