import { type RouteRecordRaw } from 'vue-router';
import AffiliateProgramLayout from '@/layout/AffiliateProgramLayout.vue';
import HomeLayout from '@/layout/HomeLayout.vue';
import WorkspaceLayout from '@/layout/WorkspaceLayout.vue';
import FullScreenLayout from '@/layout/FullScreenLayout.vue';
import icon_video from '@/assets/icons/workspace/icon_video.svg';
import icon_space from '@/assets/icons/workspace/icon_space.svg';
import icon_character from '@/assets/icons/workspace/icon_character.svg';
import icon_brand from '@/assets/icons/workspace/icon_brand.svg';
import icon_premium from '@/assets/icons/workspace/icon_premium.svg';
import icon_magic from '@/assets/icons/workspace/icon_magic.svg';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: HomeLayout,
    name: 'Home',
    meta: {
      title: 'Boolvideo',
      isMenu: false,
      isAuth: false,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home/index.vue'),
      },
      {
        path: `/feature/ai-tools`,
        name: `feature-ai-tools`,
        component: () => import('@/pages/home/aiToolPage.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/home/about.vue'),
      },
      {
        path: '/terms',
        name: 'terms',
        component: () => import('@/pages/home/terms.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/pages/home/privacyPolicy.vue'),
      },
      {
        path: '/cookies-policy',
        name: 'cookies-policy',
        component: () => import('@/pages/home/cookiesPolicy.vue'),
      },

      ...[
        'product-to-video',
        'idea-to-video',
        'script-to-video',
        'blog-to-video',
        'visuals-to-video',
        'template-to-video',
        'web-editor',
      ].map((name) => ({
        path: `/feature/${name}`,
        name: `feature-${name}`,
        component: () => import('@/pages/home/featurePage.vue'),
      })),

      ...[
        'ecommerce-seller',
        'content-creator',
        'social-media-marketer',
        'photographer',
        'artist',
      ].map((name) => ({
        path: `/use-case/${name}`,
        name: `case-${name}`,
        component: () => import('@/pages/home/useCasePage.vue'),
      })),
    ],
  },
  {
    // 工作台-首页
    path: '/workspace',
    component: WorkspaceLayout,
    name: 'Create Video',
    meta: {
      title: 'Create Video',
      icon: icon_video,
      isMenu: true,
      isAuth: true,
    },
    children: [
      {
        meta: {
          menuName: 'Create Video',
        },
        path: '/workspace',
        component: () => import('@/pages/workspace/index.vue'),
      },
    ],
  },
  {
    path: '/goldendeal',
    redirect: '/',
    component: () => import('@/pages/newLandingpage/index.vue'),
    name: 'Landing Page',
    meta: {
      title: 'Landing Page',
      isMenu: false,
      isAuth: false,
    },
  },
  {
    path: '/product-to-video',
    component: FullScreenLayout,
    name: 'productToVideo',
    meta: {
      title: 'AI Video Creator',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/product-to-video/generating',
        meta: {
          title: 'Product to video',
          menuName: 'Product to video',
        },
        component: () =>
          import('@/pages/createVideos/productToVideo/loading.vue'),
      },
      {
        path: '/product-to-video/voice/generating',
        meta: {
          title: 'Product to video',
          menuName: 'Product to video',
        },
        component: () =>
          import('@/pages/createVideos/productToVideo/loading.vue'),
      },
      {
        path: '/product-to-video',
        meta: {
          title: 'product to video',
          menuName: 'Upload',
        },
        component: () =>
          import('@/pages/createVideos/productToVideo/index.vue'),
      },
      {
        path: '/product-to-video/previewer',
        meta: {
          title: 'product to video',
          menuName: 'preview 480P',
        },
        component: () => import('@/pages/previewer/index.vue'),
      },
      {
        path: '/product-to-video/editVideo',
        meta: {
          title: 'product to video',
          menuName: 'edit',
        },
        component: () => import('@/pages/editVideo/index.vue'),
      },
    ],
  },
  {
    path: '/product-to-oral-video',
    component: FullScreenLayout,
    name: 'productToOralVideo',
    meta: {
      title: 'AI Video Creator',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/product-to-oral-video/generating',
        meta: {
          title: 'Product to oral video',
          menuName: 'Product to oral video',
        },
        component: () =>
          import('@/pages/createVideos/productToOralVideo/loading.vue'),
      },
      {
        path: '/product-to-oral-video/voice/generating',
        meta: {
          title: 'Product to oral video',
          menuName: 'Product to oral video',
        },
        component: () =>
          import('@/pages/createVideos/productToOralVideo/loading.vue'),
      },
      {
        path: '/product-to-oral-video',
        meta: {
          title: 'product to oral video',
          menuName: 'Upload',
        },
        component: () =>
          import('@/pages/createVideos/productToOralVideo/index.vue'),
      },
      {
        path: '/product-to-oral-video/previewer',
        meta: {
          title: 'product to oral video',
          menuName: 'preview 480P',
        },
        component: () => import('@/pages/previewer/index.vue'),
      },
      {
        path: '/product-to-oral-video/editVideo',
        meta: {
          title: 'product to oral video',
          menuName: 'edit',
        },
        component: () => import('@/pages/editVideo/index.vue'),
      },
      {
        path: '/product-to-oral-video/script-write',
        meta: {
          title: 'product to oral video',
          menuName: 'script write',
        },
        component: () => import('@/pages/createVideos/productToOralVideo/components/scriptEdit/scriptEdit.vue'),
      }
    ],
  },
  {
    path: '/visuals-to-video',
    component: FullScreenLayout,
    name: 'visualsToVideo',
    meta: {
      title: 'Visuals To Video',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/visuals-to-video/generating',
        meta: {
          title: 'Visuals To Video',
          menuName: 'Visuals To Video',
        },
        component: () =>
          import('@/pages/createVideos/visualsToVideo/loading.vue'),
      },
      {
        path: '/visuals-to-video/voice/generating',
        meta: {
          title: 'Visuals To Video',
          menuName: 'Visuals To Video',
        },
        component: () =>
          import('@/pages/createVideos/visualsToVideo/loading.vue'),
      },
      {
        path: '/visuals-to-video',
        meta: {
          title: 'Visuals To Video',
          menuName: 'Visuals To Video',
        },
        component: () =>
          import('@/pages/createVideos/visualsToVideo/index.vue'),
      },
      {
        path: '/visuals-to-video/previewer',
        meta: {
          title: 'Visuals To Video',
          menuName: 'Visuals To Video',
        },
        component: () => import('@/pages/previewer/index.vue'),
      },
      {
        path: '/visuals-to-video/editVideo',
        meta: {
          title: 'Visuals To Video',
          menuName: 'Visuals To Video',
        },
        component: () => import('@/pages/editVideo/index.vue'),
      },
    ],
  },
  {
    path: '/editor',
    name: 'Editor',
    component: () => import('@/pages/editor/index.vue'),
    meta: {
      title: 'Editor',
      isMenu: false,
      isAuth: true,
    },
  },
  {
    // 指定模版生成
    path: '/template-video-list',
    component: FullScreenLayout,
    name: 'templateToVideo',
    meta: {
      title: 'Template',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/template-video-list',
        component: () =>
          import('@/pages/createVideos/templateToVideo/list.vue'),
      },
      {
        path: '/template-to-video',
        component: () =>
          import('@/pages/createVideos/templateToVideo/index.vue'),
      },
      {
        path: '/template-to-video/generating',
        meta: {
          title: 'Template To Video',
          menuName: 'Template To Video',
        },
        component: () =>
          import('@/pages/createVideos/templateToVideo/loading.vue'),
      },
      {
        path: '/template-to-video/previewer',
        component: () => import('@/pages/previewer/singlePreviewer.vue'),
      },
      {
        path: '/template-to-video/editVideo',
        component: () => import('@/pages/editVideo/index.vue'),
      },
    ],
  },
  {
    path: '/scene-editor',
    component: () => import('@/pages/clipEditor/index.vue'),
    name: 'clipEditor',
    meta: {
      title: 'Scene editor',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
  },
  {
    path: '/script-to-video',
    component: FullScreenLayout,
    name: 'scriptToVideo',
    meta: {
      title: 'Script To Video',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/script-to-video',
        component: () => import('@/pages/createVideos/scriptToVideo/index.vue'),
      },
      {
        path: '/script-to-video/generating',
        meta: {
          title: 'Script To Video',
          menuName: 'Script To Video',
        },
        component: () =>
          import('@/pages/createVideos/scriptToVideo/loading.vue'),
      },
    ],
  },
  {
    path: '/blog-to-video',
    component: FullScreenLayout,
    name: 'blogToVideo',
    meta: {
      title: 'Blog To Video',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/blog-to-video',
        component: () => import('@/pages/createVideos/blogToVideo/index.vue'),
      },
      {
        path: '/blog-to-video/generating',
        meta: {
          title: 'Blog To Video',
          menuName: 'Blog To Video',
        },
        component: () => import('@/pages/createVideos/blogToVideo/loading.vue'),
      },
    ],
  },
  {
    path: '/idea-to-video',
    component: FullScreenLayout,
    name: 'ideaToVideoParameter',
    meta: {
      title: 'Idea To Video',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/idea-to-video',
        component: () => import('@/pages/createVideos/ideaToVideo/index.vue'),
      },
      {
        path: '/idea-to-video/generating',
        meta: {
          title: 'Idea to video',
          menuName: 'Idea to video',
        },
        component: () => import('@/pages/createVideos/ideaToVideo/loading.vue'),
      },
    ],
  },
  {
    path: '/similar-video',
    component: FullScreenLayout,
    name: 'similarVideo',
    meta: {
      title: 'Similar video',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/similar-video',
        component: () => import('@/pages/similarVideo/index.vue'),
      },
      {
        path: '/similar-script-to-video',
        component: () =>
          import('@/pages/similarVideo/scriptToVideo/script.vue'),
      },
      {
        path: '/similar-video/script-write',
        component: () => import('@/pages/similarVideo/scriptWrite.vue'),
      },
      {
        path: '/similar-video/setting',
        component: () => import('@/pages/similarVideo/setting.vue'),
      },
      {
        path: '/similar-video/script-setting',
        component: () =>
          import('@/pages/similarVideo/scriptToVideo/setting.vue'),
      },
      {
        path: '/similar-video/script-generating',
        component: () =>
          import('@/pages/similarVideo/scriptToVideo/scriptLoading.vue'),
      },
      {
        path: '/similar-video/generating',
        meta: {
          title: 'Video generating',
          menuName: 'Video generating',
        },
        component: () => import('@/pages/similarVideo/loading.vue'),
      },
    ],
  },
  {
    // 指定模版生成
    path: '/template-gallery',
    component: FullScreenLayout,
    name: 'templateGallery',
    meta: {
      title: 'templateGallery',
      icon: icon_space,
      isMenu: false,
      isAuth: false,
    },
    children: [
      {
        path: '/template-gallery',
        component: () =>
          import('@/pages/createVideos/templateToVideo/list.vue'),
      },
    ],
  },
  {
    // 云空间
    path: '/space',
    component: WorkspaceLayout,
    name: 'My Space',
    meta: {
      title: 'My Space',
      icon: icon_space,
      isMenu: true,
      isAuth: true,
    },
    children: [
      {
        path: '/space',
        component: () => import('@/pages/space/index.vue'),
      },
    ],
  },
  {
    // 云空间
    path: '/space/previewer',
    component: FullScreenLayout,
    name: 'space previewer',
    meta: {
      title: 'My Space',
      icon: icon_space,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/space/previewer',
        component: () => import('@/pages/space/previewer.vue'),
      },
    ],
  },
  {
    // s 类视频编辑
    path: '/space/editVideo',
    component: FullScreenLayout,
    name: 'space editVideo',
    meta: {
      title: 'My Space',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/space/editVideo',
        component: () => import('@/pages/editVideo/index.vue'),
      },
    ],
  },
  {
    path: '/affiliate-program',
    component: AffiliateProgramLayout,
    name: 'Affiliate Program',
    meta: {
      title: 'Affiliate Program',
      isMenu: false,
      isAuth: false,
    },
    children: [
      {
        path: '/affiliate-program',
        name: 'affiliate-program',
        component: () => import('@/pages/home/affiliateProgram.vue'),
      },
    ],
  },
  {
    path: '/brand',
    component: WorkspaceLayout,
    name: 'Brand Kit',
    meta: {
      title: 'Brand Kit',
      icon: icon_brand,
      isMenu: true,
      isAuth: true,
    },
    children: [
      {
        path: '/brand',
        component: () => import('@/pages/brand/index.vue'),
      },
    ],
  },
  {
    path: '/brand/edit',
    component: FullScreenLayout,
    name: 'brand edit',
    meta: {
      title: 'Brand Kit',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/brand/edit',
        component: () => import('@/pages/brand/components/edit/edit.vue'),
      },
    ],
  },
  {
    path: '/avatar',
    component: WorkspaceLayout,
    name: 'My Avatar',
    meta: {
      title: 'My Avatar',
      icon: icon_character,
      isMenu: true,
      isAuth: true,
    },
    children: [
      {
        name: "avatarList",
        path: '',
        component: () => import('@/pages/characters/index.vue'),
      },
      {
        name: "avatarEdit",
        path: 'edit',
        component: () => import('@/pages/characters/components/edit/index.vue'),
      }
    ]
  },
  {
    // 小工具
    path: '/aiTools',
    component: WorkspaceLayout,
    name: 'AI Tools',
    meta: {
      title: 'AI Tools',
      icon: icon_magic,
      isMenu: true,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools',
        component: () => import('@/pages/aiTools/index.vue'),
      },
    ],
  },
  {
    // s 类视频编辑
    path: '/aiTools/upload',
    component: FullScreenLayout,
    name: 'aiTools upload',
    meta: {
      title: 'aiTools upload',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools/upload',
        component: () => import('@/pages/aiTools/upload/index.vue'),
      },
    ],
  },

  {
    // s 类视频编辑
    path: '/aiTools/image-background-remove',
    component: FullScreenLayout,
    name: 'bg remove',
    meta: {
      title: 'bg remove',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools/image-background-remove',
        component: () =>
          import('@/pages/aiTools/views/bgRemove/imageBgRemove.vue'),
      },
    ],
  },
  {
    // s 类视频编辑
    path: '/aiTools/video-background-remove',
    component: FullScreenLayout,
    name: 'video bg remove',
    meta: {
      title: 'video bg remove',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools/video-background-remove',
        component: () =>
          import('@/pages/aiTools/views/bgRemove/videoBgRemove.vue'),
      },
    ],
  },
  {
    path: '/aiTools/video-enhancer',
    component: FullScreenLayout,
    name: 'video enhancer',
    meta: {
      title: 'video enhancer',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools/video-enhancer',
        component: () =>
          import('@/pages/aiTools/views/enhancer/videoEnhancer.vue'),
      },
    ],
  },
  {
    path: '/aiTools/image-enhancer',
    component: FullScreenLayout,
    name: 'image enhancer',
    meta: {
      title: 'image enhancer',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools/image-enhancer',
        component: () =>
          import('@/pages/aiTools/views/enhancer/imageEnhancer.vue'),
      },
    ],
  },
  {
    // s 类视频编辑
    path: '/aiTools/object-eraser',
    component: FullScreenLayout,
    name: 'object eraser',
    meta: {
      title: 'object eraser',
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/aiTools/object-eraser',
        component: () => import('@/pages/aiTools/views/objectEraser/index.vue'),
      },
    ],
  },
  {
    path: '/premium',
    component: HomeLayout,
    name: 'Pricing',
    meta: {
      title: 'Pricing',
      icon: icon_premium,
      isMenu: true,
      isAuth: false,
    },
    children: [
      {
        path: '/premium',
        component: () => import('@/pages/premium/index.vue'),
      },
    ],
  },
  {
    path: '/payment',
    component: FullScreenLayout,
    name: 'Payment',
    meta: {
      title: 'Pricing',
      icon: icon_video,
      isMenu: false,
      isAuth: true,
    },
    children: [
      {
        path: '/payment',
        component: () => import('@/pages/premium/PcIndex.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/pages/account/index.vue'),
    name: 'Login',
    meta: {
      title: 'Login',
      icon: icon_video,
      isMenu: false,
      isAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/pages/account/index.vue'),
    meta: {
      title: 'Signup',
      icon: icon_video,
      isMenu: false,
      isAuth: false,
    },
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () =>
      import('@/pages/account/components/forgot-password/index.vue'),
    meta: {
      title: 'Reset Password',
      icon: icon_video,
      isMenu: false,
      isAuth: false,
    },
  },
  {
    path: '/ltd',
    redirect: '/',
    name: 'LTD payment',
    component: () => import('@/pages/premium/components/ltd/ltd.vue'),
    meta: {
      title: 'LTD Payment',
      icon: icon_premium,
      isMenu: false,
      isAuth: false,
    },
  },
  {
    path: '/ltd/query',
    name: 'LTD payment querying',
    component: () => import('@/pages/premium/components/ltd/waiting.vue'),
    meta: {
      title: 'LTD Payment',
      icon: icon_premium,
      isMenu: false,
      isAuth: false,
    },
  },
  {
    path: '/redeem/rockethub',
    component: () => import('@/pages/redeem/rockethub/index.vue'),
    name: 'ExchangeFromRocketHub',
    meta: {
      title: 'Exchange',
      isMenu: false,
      isAuth: false,
    },
  },
];

export const breadcrumbList = [
  {
    menuName: 'Home',
    path: '/workspace',
    preMenu: null,
  },

  // product-to-video
  {
    path: '/product-to-video',
    menuName: 'Product to video',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/product-to-video/script-write',
    menuName: 'Script',
    preMenu: {
      menuName: 'Product to video',
      path: '/product-to-video',
    }
  },
  {
    path: '/product-to-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Product to video',
      path: '/product-to-video',
    },
  },
  {
    path: '/product-to-video/voice/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Product to video',
      path: '/product-to-video',
    },
  },
  {
    path: '/product-to-video/previewer',
    menuName: 'Preview 480P',
    preMenu: {
      menuName: 'Product to video',
      path: '/product-to-video',
    },
  },
  {
    path: '/product-to-video/editVideo',
    menuName: 'Edit',
    preMenu: {
      menuName: 'Preview 480P',
      path: '/product-to-video/previewer',
    },
  },
  // product-to-oral-video
  {
    path: '/product-to-oral-video',
    menuName: 'Product to oral video',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/product-to-oral-video/script-write',
    menuName: 'Script',
    preMenu: {
      menuName: 'Product to oral video',
      path: '/product-to-oral-video',
    }
  },
  {
    path: '/product-to-oral-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Product to oral video',
      path: '/product-to-oral-video',
    },
  },
  {
    path: '/product-to-oral-video/voice/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Product to oral video',
      path: '/product-to-oral-video',
    },
  },
  {
    path: '/product-to-oral-video/previewer',
    menuName: 'Preview 480P',
    preMenu: {
      menuName: 'Product to oral video',
      path: '/product-to-oral-video',
    },
  },
  {
    path: '/product-to-oral-video/editVideo',
    menuName: 'Edit',
    preMenu: {
      menuName: 'Preview 480P',
      path: '/product-to-oral-video/previewer',
    },
  },
  // template-to-video
  {
    path: '/template-video-list',
    menuName: 'Templates',
    preMenu: {
      menuName: 'Create Video',
      path: '/workspace',
    },
  },
  {
    path: '/template-to-video',
    menuName: 'Templates to video',
    preMenu: {
      menuName: 'Templates',
      path: '/template-video-list',
    },
  },
  {
    path: '/template-to-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Templates to video',
      path: '/template-to-video',
    },
  },
  {
    path: '/template-to-video/previewer',
    menuName: 'Preview 480P',
    preMenu: {
      menuName: 'Templates to video',
      path: '/template-to-video',
    },
  },
  {
    path: '/template-to-video/editVideo',
    menuName: 'Edit',
    preMenu: {
      menuName: 'Preview 480P',
      path: '/template-to-video/previewer',
    },
  },
  // visuals-to-video
  {
    path: '/visuals-to-video',
    menuName: 'Visuals to video',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/visuals-to-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Visuals to video',
      path: '/visuals-to-video',
    },
  },
  {
    path: '/visuals-to-video/previewer',
    menuName: 'Preview 480P',
    preMenu: {
      menuName: 'Visuals to video',
      path: '/visuals-to-video',
    },
  },
  {
    path: '/visuals-to-video/editVideo',
    menuName: 'Edit',
    preMenu: {
      menuName: 'Visuals to video',
      path: '/visuals-to-video/previewer',
    },
  },

  {
    path: '/space',
    menuName: 'My space',
    preMenu: null,
  },
  {
    path: '/space/previewer',
    menuName: 'Preview',
    preMenu: {
      menuName: 'My space',
      path: '/space',
    },
  },
  {
    path: '/space/editVideo',
    menuName: 'Edit',
    preMenu: {
      menuName: 'My space',
      path: '/space',
    },
  },
  // 小工具
  {
    path: '/aiTools',
    menuName: 'AI Tools',
    preMenu: null,
  },
  {
    path: '/aiTools/upload',
    menuName: 'Upload',
    preMenu: {
      menuName: 'AI Tools',
      path: '/aiTools',
    },
  },
  {
    path: '/aiTools/image-background-remove',
    menuName: 'Preview',
    preMenu: {
      menuName: 'Upload',
      path: '/aiTools/upload',
    },
  },
  {
    path: '/aiTools/video-background-remove',
    menuName: 'Preview',
    preMenu: {
      menuName: 'Upload',
      path: '/aiTools/upload',
    },
  },
  {
    path: '/aiTools/object-eraser',
    menuName: 'Preview',
    preMenu: {
      menuName: 'Upload',
      path: '/aiTools/upload',
    },
  },
  {
    path: '/aiTools/image-enhancer',
    menuName: 'Preview',
    preMenu: {
      menuName: 'Upload',
      path: '/aiTools/upload',
    },
  },
  // brand kit
  {
    path: '/brand',
    menuName: 'Brand kit',
    preMenu: null,
  },
  {
    path: '/brand/edit',
    menuName: 'Edit',
    preMenu: {
      menuName: 'Brand kit',
      path: '/brand',
    },
  },
  // idea to video
  {
    path: '/idea-to-video',
    menuName: 'Idea to video',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/idea-to-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Idea to video',
      path: '/idea-to-video',
    },
  },
  // blog
  {
    path: '/blog-to-video',
    menuName: 'Blog to video',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/blog-to-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Blog to video',
      path: '/blog-to-video',
    },
  },
  // script
  {
    path: '/script-to-video',
    menuName: 'Script to video',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/script-to-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Script to video',
      path: '/script-to-video',
    },
  },

  // similarVideo
  {
    path: '/similar-video',
    menuName: 'Video URL inputs',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/similar-script-to-video',
    menuName: 'Video script inputs',
    preMenu: {
      menuName: 'Home',
      path: '/workspace',
    },
  },
  {
    path: '/similar-video/script-write',
    menuName: 'Rewrite script',
    preMenu: {
      menuName: 'Video URL inputs',
      path: '/similar-video',
    },
  },

  {
    path: '/similar-video/setting',
    menuName: 'Video Setting',
    preMenu: {
      menuName: 'Rewrite script',
      path: '/similar-video/script-write',
    },
  },
  {
    path: '/similar-video/script-setting',
    menuName: 'Video Setting',
    preMenu: {
      menuName: 'Video script inputs',
      path: '/similar-script-to-video',
    },
  },
  {
    path: '/similar-video/generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Video Setting',
      path: '/similar-video/setting',
    },
  },
  {
    path: '/similar-video/script-generating',
    menuName: 'Generating',
    preMenu: {
      menuName: 'Video Setting',
      path: '/similar-video/script-setting',
    },
  },
];

export const routeGroupForAuth = (() => {
  const result = (function foreachRoutes(routes) {
    const group = {
      need: [] as string[],
      notNeed: [] as string[],
    };

    routes.forEach((route) => {
      if (route.meta?.isAuth) {
        group.notNeed.push(route.path);
      } else {
        group.need.push(route.path);

        if ('children' in route) {
          const result = foreachRoutes(route.children!);
          group.need.push(...result.need);
          group.notNeed.push(...result.notNeed);
        }
      }
    });

    return group;
  })(routes);

  return {
    need: [...new Set(result.need)],
    notNeed: [...new Set(result.notNeed)],
  };
})();
