<template>
  <div>
    <div
      class="collection-item"
      :class="{ 'active-item': innerItem.isSelected || innerItem.isPlay }"
      @click="handleSelected(innerItem)"
      @mousemove="(e) => handleMouseMove(e, innerItem)"
      @mouseleave="(e) => handleMouseLeave(e, innerItem)"
    >
      <div class="detail">
        <audio
          ref="audioRef"
          :src="innerItem.url"
          preload="metadata"
        ></audio>

        <div class="flex items-center">
          <div class="number-box">
            <div class="image-select">
              <img
                :src="icon_selected"
                v-if="innerItem.isSelected"
                @click.stop="handleCancelSelected(innerItem)"
              />
              <spa v-else> {{ index + 1 }}</spa>
            </div>
          </div>
          <p class="video-name">{{ innerItem.text }}</p>
          <div class="video-item-right">
            <img
              v-if="!innerItem.isPlay"
              :src="icon_hook_stop"
              @click.stop="handlePlay(innerItem)"
              class="icon-hook-stop"
            />
            <img
              v-else
              :src="icon_hook_play"
              @click.stop="handlePlay(innerItem)"
              class="icon-hook-stop"
            />
            <div class="video-duration" v-if="innerItem.isSelected || isHover">
              <p class="time" v-if="innerItem.isPlay">
                <span class="time-number">{{
                  formatDuration(currentFrame)
                }}</span>
              </p>

              <p class="time" v-else>{{ formatDuration(duration) }}</p>
            </div>
            <p class="video-likes" v-else>
              {{ formatNumber(innerItem.likes) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import header from '@/assets/similar-video/header_portrait.png';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';

import icon_hook_stop from '@/assets/similar-video/icon_hook_stop.svg';
import icon_hook_play from '@/assets/similar-video/icon_hook_play.svg';

const props = defineProps({
  item: Object,
  index: Number,
  list: Array,
  selectedMediaId: Number,
});

const emit = defineEmits([
  'onSelected',
  'onPlay',
  'onPause',
  'onCancelSelected',
]);

const audioRef = ref(null);
const currentFrame = ref(0);
const innerItem = ref(props.item);
const duration = ref(0);
const isHover = ref(false);
const formatNumber = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return (num / 1000000).toFixed(1) + 'M';
  }
};

const handleCancelSelected = (item) => {
  emit('onCancelSelected', item.mediaId);
};

const handleSelected = (item) => {
  item.isSelected = !item.isSelected;

  if (!item.isSelected) {
    emit('onCancelSelected', item.mediaId);
    emit('onPause', item.mediaId);
    return;
  }

  emit('onSelected', item.mediaId, item.voiceId);
  emit('onPlay', item.mediaId);
};

const handleMouseMove = (e, item) => {
  isHover.value = true;
};

const handleMouseLeave = (e, item) => {
  isHover.value = false;
};

const handleScale = (item) => {};

function formatDuration(duration) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${(minutes | 0).toString().padStart(2, '0')}:${(seconds | 0)
    .toString()
    .padStart(2, '0')}`;
}

const handlePlay = async (item) => {
  item.isPlay = !item.isPlay;

  if (item.isPlay == false) {
    currentFrame.value = 0;
    emit('onPause', item.mediaId);
    audioRef.value.pause();
    return;
  }

  emit('onPlay', item.mediaId);
  emit('onSelected', item.mediaId, item.voiceId);
  audioRef.value.play();
};

const handleStop = () => {

}

const handleSeekTo = (value) => {
  audioRef.value.currentTime = value;
};

watch(
  () => props.item.isSelected,
  (isSelected) => {
    if (!isSelected && audioRef.value) {
      audioRef.value.pause();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.item.isPlay,
  (isPlay) => {
    if (!isPlay && audioRef.value) {
      audioRef.value.pause();
    }

    if (isPlay) {
      innerItem.value.isSelected = true;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.selectedMediaId,
  () => {
    if (props.item.mediaId == props.selectedMediaId) {
      innerItem.value.isSelected = true;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => audioRef.value,
  (audioRef) => {
    if (audioRef) {
      handleControlVideo();
    }
  },
  {
    immediate: true,
  }
);

const handleAudioLoadedMetadata = () => {
  duration.value = audioRef.value.duration;
}

const handleAudioTimeUpdate = () => {
  currentFrame.value = audioRef.value.currentTime;
}

const handleAudioEnded = () => {
  emit('onPause', props.item.mediaId);
}

const handleControlVideo = () => {
  const audio = audioRef.value;

  if (audio) {
    audio.addEventListener('loadedmetadata', handleAudioLoadedMetadata);
    audio.addEventListener('timeupdate', handleAudioTimeUpdate);
    audio.addEventListener('ended', handleAudioEnded);
  }
};

onBeforeUnmount(() => {
  const audio = audioRef.value;

  if (audio) {
    audio.removeEventListener('loadedmetadata', handleAudioLoadedMetadata);
    audio.removeEventListener('timeupdate', handleAudioTimeUpdate);
    audio.removeEventListener('ended', handleAudioEnded);
  }
  audioRef.value = null;
});
</script>
<style lang="scss" scoped>
.collection-item {
  width: 100%;
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  border-radius: 4px;
  background: #f3f5f7;

  &:hover {
    background: #f8f5ff;
  }

  :deep(.el-slider) {
    max-width: 187px;
  }

  :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  :deep(.el-slider__bar) {
    height: 4px;
  }

  :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }

  .video-name {
    min-width: 180px;
    min-height: 44px;
    padding-right: 28px;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .video-item-right {
    min-width: 70px;
    display: flex;
    justify-content: flex-end;
  }
  .video-likes {
    width: 50px;
    margin-left: 6px;
    color: #646a73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .number-box {
    width: 33px;
    display: flex;
    justify-content: center;
    margin-right: 14px;
  }

  .image-select {
    width: 24px;
    height: 24px;
    text-align: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.active-item {
  background: #f8f5ff;
}

.detail {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  flex: 1;
}

.video-duration {
  width: 50px;
  color: #060606;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-left: 6px;
}

.time-number {
  display: block;
  width: 45px;
  text-align: center;
}

.image-box {
  min-width: 100px;
  border-radius: 4px;
  position: relative;
  margin-right: 12px;
  .coverPic {
    width: 100px;
    height: 100px;
  }
  audio {
    width: 100px;
    height: 100px;
    border-radius: 4px;
  }
}

.icon-hook-stop {
  width: 24px;
  height: 24px;
}
</style>
