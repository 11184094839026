import step1 from '../Workflow/assets/general/step_1.webp';
import step2 from '../Workflow/assets/general/step_2.webp';
import step3 from '../Workflow/assets/general/step_3.webp';
import step4 from '../Workflow/assets/general/step_4.webp';

export const generalPreview  = {
  step1,
  step2,
  step3,
  step4,
};


export const homeStepList =   {
  title: 'General workflow',
  steps: [
    {
      title: 'Step 1',
      description: 'Input your product URL, blog URL, images, videos, or text.',
      preview: {
        src: generalPreview.step1,
        alt: 'Step 1 preview',
      },
    },
    {
      title: 'Step 2',
      description: 'Customize your AI co-pilot.',
      preview: {
        src: generalPreview.step2,
        alt: 'Step 2 preview',
      },
    },
    {
      title: 'Step 3',
      description: 'Generate and adjust whatever you want with the lightweight editor.',
      preview: {
        src: generalPreview.step3,
        alt: 'Step 3 preview',
      },
    },
    {
      title: 'Step 4',
      description: 'Make more advanced edits or just export.',
      preview: {
        src: generalPreview.step4,
        alt: 'Step 4 preview',
      },
      link: {
        text: 'Start creating',
        path: '/workspace',
      },
    },
  ],
}