import { defineStore } from 'pinia';
import { getToken } from '@/utils/auth';
import { useTrackStore } from '@/store/modules/track';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { onUnmounted } from 'vue'
import {
  sessionList,
  createSession,
  updateSession,
  deleteSession,
} from '@/api/index';

export const useAssistStore = defineStore('assist', () => {
  const { collectData, track } = useTrackStore();
  const { VITE_API_BASE } = import.meta.env;
  let imageId = 0;
  const messages: Ref<Array<Message>> = ref([]);
  const showPanel = ref('preset');
  const sessionId: Ref<string | null | undefined> = ref(null);
  const historyList: Ref<Array<SessionList>> = ref([]);
  const refreshSpaceTime: Ref<number | null | undefined> = ref(null);
  const loading = ref(false);
  const pagination = ref({
    page: 1,
    size: 20,
  });
  const disabledLoad = ref(false);
  const prePage = ref('');

  type AssistantContent = {
    content: string;
    type: string;
  };

  type SessionList = {
    content: [];
    name: string;
    sessionId?: string;
  };

  type Session = {
    content: string;
    name: string;
    sessionId?: string;
  };

  type Message = {
    role: 'assistant' | 'user';
    content?: string;
    currentPage?: number;
    totalPage?: number;
    contents?: AssistantContent[];
    state: string;
  };

  const postMessage = async (message: string) => {
    pushMessageByUser(message);
    changeCurrentPanel('chat');
    await generate();

    if (sessionId.value) {
      handleUpdateSession(messages.value);
    } else {
      handleCreateSession(messages.value);
    }
  };

  const fetchContent = async (
    payload: Array<{ role: string; content: string }>,
    pos: number
  ) => {
    let created = false;
    const errorArray = ['nocredit', 'error'];
    await fetchEventSource(VITE_API_BASE + '/ai/assistant/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
      body: JSON.stringify({
        // 你的请求体数据
        messages: payload,
      }),
      openWhenHidden: true,
      onmessage(evt) {
        const { type, content } = JSON.parse(evt.data);
        if (type === 'resource_deduction_error') {
          setNocreditByIndex(pos);
        } else if (type === 'error') {
          setErrorByIndex(pos);
        } else if (type === 'text') {
          if (!created) {
            createContentBySystem(pos);
            created = true;
          }
          setTypingByIndex(pos);
          if (content && content.length > 0) {
            requestAnimationFrame(() =>
              putCurrentContentBySystem(pos, {
                content,
                type,
              })
            );
          }
        } else if (['sticker', 'image'].includes(type)) {
          if (content && content !== 'loading') {
            if (!created) {
              createContentBySystem(pos);
              created = true;
            }
            const url = content;
            putCurrentContentBySystem(pos, {
              content: url,
              type,
            });
          }
        }
      },
      onclose() {
        if (!errorArray.includes(messages.value[pos].state)) {
          const { type } = messages.value[pos].contents[0];
          collectData('boolvideo_ai_assistant_use', {
            click: type,
          });
          track('boolvideo_ai_assistant_use');

          setSuccessByIndex(pos);
        }
      },
      onerror(err) {
        console.log('[error]', err);
        setErrorByIndex(pos);
        if (err instanceof FatalError) {
          throw err; // rethrow to stop the operation
        } else {
          // do nothing to automatically retry. You can also
          // return a specific retry interval here.
        }
      },
    });
  };

  const generate = async () => {
    const pos = messages.value.length;
    const payload = parseData(pos);
    pushMessageBySystem();
    await fetchContent(payload, pos);
  };

  const regenerate = async (index: number) => {
    const pos = index;
    const payload = parseData(pos);

    setLoadingByIndex(pos);
    await fetchContent(payload, pos);
    handleUpdateSession(messages.value);
  };

  const setLoadingByIndex = (index: number) => {
    messages.value[index].state = 'loading';
  };

  const setSuccessByIndex = (index: number) => {
    messages.value[index].state = 'success';
  };

  const setTypingByIndex = (index: number) => {
    messages.value[index].state = 'typing';
  };

  const setErrorByIndex = (index: number) => {
    messages.value[index].state = 'error';
  };

  const setNocreditByIndex = (index: number) => {
    messages.value[index].state = 'nocredit';
  };

  const pushMessageByUser = (value: string) => {
    messages.value.push({
      role: 'user',
      content: value,
      state: 'success',
    });
  };

  const pushMessageBySystem = () => {
    messages.value.push({
      role: 'assistant',
      contents: [],
      currentPage: 0,
      totalPage: 0,
      state: 'loading',
    });
  };

  const createContentBySystem = (pos: number) => {
    let newMessage = messages.value[pos];
    const { contents, currentPage, totalPage } = newMessage;
    contents.push({
      content: '',
      type: '',
    });
    newMessage = {
      ...newMessage,
      contents,
      currentPage: currentPage + 1,
      totalPage: totalPage + 1,
    };
    messages.value.splice(pos, 1, newMessage);
  };

  const putContentBySystem = (
    pos: number,
    value: AssistantContent,
    contentPos: number
  ) => {
    let newMessage = messages.value[pos];
    const { contents } = newMessage;
    const { content, type } = value;
    if (content) {
      contents[contentPos - 1].content += content;
    }
    if (type) {
      contents[contentPos - 1].type = type;
    }
    newMessage = {
      ...newMessage,
      contents,
    };
    messages.value.splice(pos, 1, newMessage);
  };

  const putCurrentContentBySystem = (pos: number, value: AssistantContent) => {
    const { currentPage } = messages.value[pos];
    putContentBySystem(pos, value, currentPage);
  };

  const parseData = (index: number) => {
    const pos = index - 1;
    const payload = [];
    const data = messages.value.slice(0, pos + 1);
    data.forEach((item) => {
      const { role, currentPage } = item;
      if (role === 'user') {
        const { content } = item;
        payload.push({
          role,
          content,
        });
      } else if (role === 'assistant') {
        const { contents, state } = item;
        if (state !== 'success') return;
        let content;
        const type = contents[currentPage - 1].type;
        if (['sticker', 'image'].includes(type)) {
          content = '[image' + imageId + ']';
          imageId++;
        } else if (type === 'text') {
          content = contents[currentPage - 1].content;
        }
        payload.push({
          role,
          content,
        });
      } else {
        console.log('json error');
        return;
      }
    });
    return payload;
  };

  const clearMessages = () => {
    messages.value = [];
    sessionId.value = null;
  };

  const resetHistoryList = () => {
    historyList.value = [];
    pagination.value.page = 1;
  };

  const getHistoryList = async () => {
    loading.value = true
    const response = await sessionList(pagination.value);
    loading.value = false
    if (!response.success) {
      disabledLoad.value = true;
      return;
    }
    const { records, current } = response.data;
    
    if (!records.length) {
      disabledLoad.value = true;
      return;
    } else {
      disabledLoad.value = false;
    }

    const list = records.map((item) => {
      return {
        ...item,
        content: JSON.parse(item.content),
      };
    });

    
    pagination.value.page = current + 1;

    list.forEach((item) => {
      const idx = historyList.value.findIndex(
        (e) => e.sessionId == item.sessionId
      );
      if (idx < 0) {
        historyList.value.push(item);
      }
    });
   
  };

  const removeHistory = async (id: string) => {
    // 删除会话
    await deleteSession(id);
    const index = historyList.value.findIndex((item) => item.sessionId == id);
    historyList.value.splice(index, 1);
    if (id == sessionId.value) {
      clearMessages();
    }
  };

  const handleCreateSession = async (list: any) => {
    const params = {
      name: list[0].content,
      content: JSON.stringify(list),
    } as Session;
    const { data } = await createSession(params);
    sessionId.value = data.sessionId;
  };

  const handleUpdateSession = async (list: Session[]) => {
    const params = {
      name: list[0].content,
      content: JSON.stringify(list),
      sessionId: sessionId.value,
    } as Session;

    await updateSession(params);
  };

  const getHistoryDetail = (session: Session) => {
    const item = historyList.value.find(
      (item) => session.sessionId == item.sessionId
    );

    if (item) {
      sessionId.value = session.sessionId;
      messages.value = item?.content;
    }
  };

  const changeCurrentPanel = (state: string, item?: Session) => {
    showPanel.value = state;
    if (state == 'preset') {
      clearMessages();
      resetHistoryList();
    }

    if (item) {
      getHistoryDetail(item);
    }
  };

  const savePrePage = (pageName: string) => {
    prePage.value = pageName;
  };

  const refreshSpaceMedia = () => {
    refreshSpaceTime.value = new Date().getTime();
  };

  return {
    loading,
    messages,
    showPanel,
    historyList,
    prePage,
    disabledLoad,
    refreshSpaceTime,
    resetHistoryList,
    generate,
    regenerate,
    postMessage,
    clearMessages,
    pushMessageByUser,
    changeCurrentPanel,
    getHistoryList,
    removeHistory,
    savePrePage,
    handleUpdateSession,
    refreshSpaceMedia,
  };
});
