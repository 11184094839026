<template>
  <section ref="containerRef" class="feature-list-part">
    <h2>Create videos in clicks with AI</h2>
    <div class="content-container">
      <div class="preview-part">
        <img 
          :src="currentFeature.preview" 
          alt="feature preview"
          loading="lazy"
        >
        <StarButton :link="currentFeature.link.path" @click="handleClickStarButton">
          {{ currentFeature.link.text }}
        </StarButton>
      </div>
      <ul class="feature-list" @click="handleClickItem">
        <li
          v-for="item of featureList"
          :key="item.key"
          :data-key="item.key"
          :style="item.key === currentKey ? `background-color:${item.itemColor}` : ''"
        >
          <v-render :vnode="item.icon.vnode" alt="feature icon" />
          <section class="feature-item-info">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </section>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import featureList from './data';
import StarButton from '../star-button.vue';
import { useTimedTask } from '@/utils/type';
import { sessionSetItem } from '@/utils/storage';

const currentKey = ref(featureList[0].key);
const containerRef = ref(null as unknown as HTMLElement);
const currentFeature = computed(() => featureList.find(feature => feature.key === currentKey.value)!);
const timedTask = useTimedTask(() => {
  const index = featureList.findIndex(feature => feature.key === currentKey.value);

  if (index === featureList.length - 1) {
    currentKey.value = featureList[0].key;
  } else {
    currentKey.value = featureList[index + 1].key;
  }
}, 2000);

const intersectionObserver = new IntersectionObserver(([entry]) => {
  if (entry.isIntersecting) {
    timedTask.start();
  }
});

const handleClickItem = (e: MouseEvent) => {
  const target = (e.target as HTMLElement).closest<HTMLElement>('li[data-key]');

  if (target === null) return;
  currentKey.value = target.dataset.key!;
  timedTask.stop();
};

const handleClickStarButton = () => {
  sessionSetItem('create_from', 'homepage')
}

onMounted(() => {
  intersectionObserver.observe(containerRef.value);
});

onBeforeUnmount(() => {
  intersectionObserver.disconnect();
});
</script>

<style scoped lang="scss">
.feature-list-part {
  padding: 120px calc((100% - var(--view-width)) / 2);

  & > h2 {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
  }
}

.content-container {
  display: flex;
  justify-content: space-between;
  gap: 95px;
  margin-top: 115px;
}

.preview-part {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 625px;
    height: 400px;
    margin-top: 21px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    overflow: hidden;
  }

  & > button {
    margin-top: 48px;
  }
}

.feature-list {
  display: flex;
  flex-direction: column;

  & > li {
    display: flex;
    gap: 22px;
    height: 108px;
    padding: 14px;
    border-radius: 10px;
    background-color: transparent;
    transition: background-color .2s;
    cursor: pointer;

    & > img {
      width: 60px;
      height: 60px;
      margin-top: 7px;
    }
  }
}

.feature-item-info {
  display: flex;
  flex-direction: column;
  gap: 7px;

  & > h3 {
    font-size: 20px;
    font-weight: 500;
  }

  & > p {
    font-size: 14px;
    color: #646A73;
  }
}
</style>
