import { _get, _post, _put } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

interface getVideoRenderInfoResponse {
  draftId?: string;
  renderConfig: string;
  ratio: string;
  name: string;
  formattingId?: number[];
  scriptId?: number;
  filterId?: number;
  musicId?: number;
}

interface GetDraftDetailResponse {
  draftId: string;
  renderConfig: string;
  ratio: string;
  name: string;
  formattingId?: number[];
  scriptId?: number;
  filterId?: number;
  musicId?: number;
}

type SaveAssetItem = {
  key: string;
  type: number;
  layerId: string;
  material: string;
  adaptType: number;
  editable: boolean;
  fontSize?: number;
  height?: number;
  width?: number;
  index: number;
  positioningFrame: boolean;
  isReverseColor?: boolean;
  reverseColor?: string;
  fillColor?: string;
};

interface PutDraftData {
  draftId?: string;
  renderConfig?: string;
  assets?: SaveAssetItem[];
  duration?: number;
  ratio?: string;
  rid?: string;
}

interface PostExportVideoData {
  draftId: string;
  name: string;
  resolution: number;
}

interface PutDraftResponse {
  draftId: string;
  lastUpdateTime: number;
}

interface RenameDraftParams {
  name: string;
}

export async function getVideoRenderInfo(rid: string) {
  return await _get<getVideoRenderInfoResponse>(
    `${VITE_API_BASE}/video/render/${rid}/info`,
  );
}

export async function getDraftDetail(draftId: string) {
  return await _get<GetDraftDetailResponse>(
    `${VITE_API_BASE}/draft/${draftId}/render_info`,
  );
}

export async function putDraft(data: PutDraftData) {
  return await _put<PutDraftResponse>(`${VITE_API_BASE}/draft/update`, data);
}

export async function postExportVideo(data: PostExportVideoData) {
  return await _post<PutDraftResponse>(
    `${VITE_API_BASE}/video/export/edit_page`,
    data,
  );
}

export async function putDraftName(draftId: string, parmas: RenameDraftParams) {
  return await _put(`${VITE_API_BASE}/draft/rename/${draftId}`, {}, parmas);
}
